/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateStringOrTimestamp: { input: any; output: any; }
  EmailAddress: { input: string; output: string; }
  GraphQLCreditRating: { input: any; output: any; }
  GraphQLDateString: { input: string; output: string; }
  GraphQLJSON: { input: any; output: any; }
  GraphQLJSONObject: { input: any; output: any; }
  GraphQLLei: { input: any; output: any; }
  GraphQLObjectId: { input: string; output: string; }
  GraphQLPatchInstrumentDate: { input: string; output: string; }
  GraphQLSwedenDate: { input: string; output: string; }
  ISIN: { input: string; output: string; }
  Timestamp: { input: any; output: any; }
  TwoDecimalString: { input: any; output: any; }
  UriPartString: { input: any; output: any; }
};

export type AcceptTermsInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  noteIds: Array<Scalars['GraphQLObjectId']['input']>;
};

export enum AccountingBatchType {
  /** Automated, similar to BVU but automatically added adjustment */
  A = 'A',
  /** Automated reverse, similar to BVI but closing previous A */
  AR = 'AR',
  /** Balance valuation ingoing, closing previous BVU */
  BVI = 'BVI',
  /** Balance valuation outgoing */
  BVU = 'BVU',
  /** Ingoing balance */
  IB = 'IB',
  /** Manual, similar to BVU but manually added */
  M = 'M',
  /** Manual reverse, similar to BVI but closing previous M */
  MR = 'MR',
  /** Transaction */
  T = 'T'
}

export enum AccountingCompanyTypeEnum {
  GeneralPortfolio = 'GeneralPortfolio',
  None = 'None',
  SwedishMutualFund = 'SwedishMutualFund'
}

export enum AccountingFrequencyEnum {
  Annually = 'Annually',
  Monthly = 'Monthly',
  None = 'None',
  Quarterly = 'Quarterly',
  SemiAnnually = 'SemiAnnually'
}

export enum AccountingLanguageEnum {
  Sv = 'Sv'
}

export type AccountingRun = {
  __typename?: 'AccountingRun';
  _id: Scalars['GraphQLObjectId']['output'];
  accountingPeriod: Scalars['String']['output'];
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  clientTAccountChart?: Maybe<TAccountChart>;
  clientTAccountChartId: Scalars['GraphQLObjectId']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  createUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  endDate: Scalars['GraphQLDateString']['output'];
  journalEntries?: Maybe<Array<Maybe<JournalEntry>>>;
  masterTAccountChart?: Maybe<TAccountChart>;
  masterTAccountChartId: Scalars['GraphQLObjectId']['output'];
  number: Scalars['Int']['output'];
  roundingDecimals?: Maybe<Scalars['Int']['output']>;
  status: AccountingRunStatusEnum;
  type: AccountingRunTypeEnum;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
};

export enum AccountingRunStatusEnum {
  Confirmed = 'Confirmed',
  Deleted = 'Deleted',
  Pending = 'Pending',
  Preliminary = 'Preliminary'
}

export enum AccountingRunTypeEnum {
  Manual = 'Manual',
  Transaction = 'Transaction'
}

export type AccountingTransaction = {
  __typename?: 'AccountingTransaction';
  amount?: Maybe<Scalars['Float']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  tAccountNumber?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AccountingTransactionType>;
  valueDate?: Maybe<Scalars['GraphQLDateString']['output']>;
};

export type AccountingTransactionInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  tAccountNumber?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AccountingTransactionType>;
  valueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export enum AccountingTransactionType {
  AccruedInterest = 'AccruedInterest',
  Commission = 'Commission',
  CustodyFee = 'CustodyFee',
  Dividend = 'Dividend',
  DividendPaid = 'DividendPaid',
  Equity = 'Equity',
  Fee = 'Fee',
  ForeignTax = 'ForeignTax',
  ForwardCash = 'ForwardCash',
  InitialCost = 'InitialCost',
  Interest = 'Interest',
  ManagementCost = 'ManagementCost',
  ManagementFee = 'ManagementFee',
  OpeningBalance = 'OpeningBalance',
  Realized = 'Realized',
  RealizedFx = 'RealizedFx',
  Rebate = 'Rebate',
  Rounding = 'Rounding',
  ShareCapital = 'ShareCapital',
  StampDuty = 'StampDuty',
  Tax = 'Tax',
  TaxRestitution = 'TaxRestitution',
  Unrealized = 'Unrealized',
  UnrealizedFx = 'UnrealizedFx',
  ValueChange = 'ValueChange'
}

export type Agreement = {
  __typename?: 'Agreement';
  _id: Scalars['GraphQLObjectId']['output'];
  approvedInfo?: Maybe<Array<ApprovedInfo>>;
  attachments: Array<Maybe<Attachment>>;
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  counterParties: Array<PartyInfo>;
  counterPartyIds: Array<Scalars['GraphQLObjectId']['output']>;
  createTimestamp: Scalars['Timestamp']['output'];
  description: Scalars['String']['output'];
  parties?: Maybe<Array<Maybe<PartyInfo>>>;
  partyIds: Array<Scalars['GraphQLObjectId']['output']>;
  status: AgreementStatusEnum;
  type: AgreementTypeEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo: UserInfo;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type AgreementFilterInput = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  counterPartyIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  partyIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<AgreementStatusEnum>>;
  typeIn?: InputMaybe<Array<AgreementTypeEnum>>;
};

export type AgreementInput = {
  /** if _id is omitted, an agreement will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  approvedInfo?: InputMaybe<Array<ApprovedInfoInput>>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  clientId: Scalars['GraphQLObjectId']['input'];
  counterPartyIds: Array<Scalars['GraphQLObjectId']['input']>;
  description: Scalars['String']['input'];
  partyIds: Array<Scalars['GraphQLObjectId']['input']>;
  status: AgreementStatusEnum;
  type: AgreementTypeEnum;
};

export enum AgreementStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted',
  Pending = 'Pending',
  Superseded = 'Superseded'
}

export enum AgreementType {
  ClearingAccount = 'ClearingAccount',
  Gmra = 'Gmra',
  Gmsla = 'Gmsla',
  Isda = 'Isda',
  OmnibusClearingAccount = 'OmnibusClearingAccount'
}

export enum AgreementTypeEnum {
  CashTrading = 'CashTrading',
  Cdea = 'Cdea',
  ClearingBroker = 'ClearingBroker',
  CustodyAgreement = 'CustodyAgreement',
  Emir = 'Emir',
  EquityDma = 'EquityDma',
  FxTrading = 'FxTrading',
  Gmra = 'Gmra',
  Gmsla = 'Gmsla',
  Gua = 'Gua',
  InvestmentManagement = 'InvestmentManagement',
  InvestmentPolicy = 'InvestmentPolicy',
  Isda = 'Isda',
  Mt101 = 'Mt101',
  ProcessAgent = 'ProcessAgent',
  Sftr = 'Sftr',
  Ssi = 'Ssi',
  SubscriptionRedemptionForm = 'SubscriptionRedemptionForm',
  TriParty = 'TriParty',
  UserAgreement = 'UserAgreement'
}

export type Alias = {
  __typename?: 'Alias';
  collection: CollectionNameEnum;
  /** Markdown comment */
  comment: Scalars['String']['output'];
  documentId: Scalars['GraphQLObjectId']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AliasInput = {
  collection: CollectionNameEnum;
  comment: Scalars['String']['input'];
  documentId: Scalars['GraphQLObjectId']['input'];
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type AnnualReportType = {
  __typename?: 'AnnualReportType';
  /** Name, year, lastYear */
  balanceSheet: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  clientId: Scalars['GraphQLObjectId']['output'];
  /** Name, year, lastYear */
  equityChange: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  /** Name, year, lastYear, secondLastYear, thirdLastYear, fourthLastYear... ninthLastYear */
  fundFigures?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
  /** Name, weight(%) */
  issuers: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  /** Name, year, lastYear, secondLastYear, thirdLastYear, fourthLastYear */
  keyFigures: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  /** InstrumentName, country/category, quantity, exposure(tkr), share(%) */
  positions: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  /** Name, creditRating, share(%) */
  ratings: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  /** Name, year, lastYear */
  resultSheet: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
};

export type ApprovedInfo = {
  __typename?: 'ApprovedInfo';
  comment?: Maybe<Scalars['String']['output']>;
  status: ApprovedStatusEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo?: Maybe<UserInfo>;
};

export type ApprovedInfoInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  status: ApprovedStatusEnum;
  /** if updateTimestamp is omitted, it is seen as new info and updateTimestamp is added */
  updateTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  /** if updateUserId is omitted, it is seen as new info and updateUserId is added */
  updateUserId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};

export enum ApprovedStatusEnum {
  Accepted = 'Accepted',
  Rejected = 'Rejected'
}

export type Attachment = {
  __typename?: 'Attachment';
  /**
   * base64 is a available on attachment but
   * not on attachments
   */
  base64?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  fileId: Scalars['GraphQLObjectId']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
  mD5?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
};

export type AttachmentInput = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  fileId: Scalars['GraphQLObjectId']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  mD5?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  updateTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type BankAccountBalance = {
  __typename?: 'BankAccountBalance';
  balance: Scalars['TwoDecimalString']['output'];
  date: Scalars['GraphQLDateString']['output'];
  instrumentId: Scalars['GraphQLObjectId']['output'];
};

export type BankAccountBalanceFilterInput = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<BankAccountTransactionStatusEnum>>;
};

export type BankAccountTransaction = {
  __typename?: 'BankAccountTransaction';
  _id: Scalars['GraphQLObjectId']['output'];
  amount: Scalars['TwoDecimalString']['output'];
  /**
   * Balance for instrument on "date". Calculated by adding all transactions from last two days to
   * balance position received from custodian two days prior to "date"
   */
  balance: Scalars['TwoDecimalString']['output'];
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  date: Scalars['GraphQLDateString']['output'];
  description: Scalars['String']['output'];
  error?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  other?: Maybe<Scalars['GraphQLJSON']['output']>;
  reference: Scalars['String']['output'];
  status: BankAccountTransactionStatusEnum;
  /**
   * Ids of transactionItems that the bankAccountTransaction amount has matched with, used for reconciliation
   * of bank accounts
   */
  transactionItemIds: Array<Scalars['GraphQLObjectId']['output']>;
  /** Array of matched transactionItems that the bankAccountTransaction amount has matched with */
  transactionItems: Array<TransactionItem>;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type BankAccountTransactionFilterInput = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  ibanIn?: InputMaybe<Array<Scalars['String']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<BankAccountTransactionStatusEnum>>;
};

export type BankAccountTransactionInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  amount: Scalars['TwoDecimalString']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  date: Scalars['GraphQLDateString']['input'];
  description: Scalars['String']['input'];
  error?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  other?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  reference: Scalars['String']['input'];
  status: BankAccountTransactionStatusEnum;
  transactionItemIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};

export enum BankAccountTransactionStatusEnum {
  Deleted = 'Deleted',
  Pending = 'Pending',
  Settled = 'Settled'
}

export type BaseCalendar = {
  __typename?: 'BaseCalendar';
  _id: Scalars['GraphQLObjectId']['output'];
  baseEvents: Array<BaseCalendarEvent>;
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients: Array<PartyInfo>;
  createTimestamp: Scalars['Timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isBusinessDay?: Maybe<Scalars['Boolean']['output']>;
  isEarlyClose?: Maybe<Scalars['Boolean']['output']>;
  name: CalendarNameEnum;
  source?: Maybe<Scalars['String']['output']>;
  status: CalendarStatusEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo?: Maybe<UserInfo>;
  year: Scalars['String']['output'];
};


export type BaseCalendarIsBusinessDayArgs = {
  date: Scalars['GraphQLDateString']['input'];
};


export type BaseCalendarIsEarlyCloseArgs = {
  date: Scalars['GraphQLDateString']['input'];
};

export type BaseCalendarEvent = {
  __typename?: 'BaseCalendarEvent';
  date: Scalars['GraphQLDateString']['output'];
  event: Scalars['String']['output'];
  state: CalendarStateEnum;
};

export type BaseCalendarEventInput = {
  date: Scalars['GraphQLDateString']['input'];
  event: Scalars['String']['input'];
  state: CalendarStateEnum;
};

export type BaseCalendarFilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  dates?: InputMaybe<Array<Scalars['GraphQLDateString']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  names?: InputMaybe<Array<CalendarNameEnum>>;
  statusIn?: InputMaybe<Array<CalendarStatusEnum>>;
  years?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BaseCalendarInput = {
  /** If _id is omitted, a Calendar will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  baseEvents: Array<BaseCalendarEventInput>;
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: CalendarNameEnum;
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CalendarStatusEnum>;
};

export type Benchmark = {
  __typename?: 'Benchmark';
  currency?: Maybe<Scalars['String']['output']>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  offset?: Maybe<Scalars['Float']['output']>;
};

export type BenchmarkInput = {
  currency?: InputMaybe<CurrencyEnum>;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  offset?: InputMaybe<Scalars['Float']['input']>;
};

export type BeneficialOwner = {
  __typename?: 'BeneficialOwner';
  _id?: Maybe<Scalars['GraphQLObjectId']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BeneficialOwnerInput = {
  /** _id is a pointer to a document in a not yet implemented collection BeneficialOwner */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum BestExecutionType {
  /**
   * Client denotes transactions entered by a client it self as
   * a part of an asset management agreement.
   */
  Client = 'Client',
  /**
   * Execution denotes a trade executed on behalf a client
   * outside an asset management agreement.
   */
  Execution = 'Execution',
  /**
   * Maintenance denotes transactions such as dividends, coupons etc.
   * that follows from positions held.
   */
  Maintenance = 'Maintenance',
  /**
   * Portfolio denotes a trade conducted as under an asset
   * management agreement.
   */
  Portfolio = 'Portfolio',
  /**
   * Transmission denotes a trade passed on to another broker on behalf
   * of a client outside an asset management agreement.
   */
  Transmission = 'Transmission'
}

export type BrokerTransaction = {
  __typename?: 'BrokerTransaction';
  _id: Scalars['GraphQLObjectId']['output'];
  broker?: Maybe<Party>;
  brokerId: Scalars['GraphQLObjectId']['output'];
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  commission: Scalars['Float']['output'];
  correspondingTransactionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  currency: CurrencyEnum;
  description?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  instrument?: Maybe<Instrument>;
  isin: Scalars['ISIN']['output'];
  /** https://www.iso20022.org/market-identifier-codes */
  mic?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  reportId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  settlementAmount?: Maybe<Scalars['Float']['output']>;
  stampDuty: Scalars['Float']['output'];
  status?: Maybe<BrokerTransactionStatus>;
  tradeCurrency?: Maybe<CurrencyEnum>;
  tradeDate: Scalars['GraphQLDateString']['output'];
  type: BrokerTransactionType;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  valueDate: Scalars['GraphQLDateString']['output'];
};

export type BrokerTransactionFilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<BrokerTransactionStatus>>;
  tradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export enum BrokerTransactionStatus {
  Confirmed = 'Confirmed',
  Deleted = 'Deleted',
  Pending = 'Pending'
}

export enum BrokerTransactionType {
  Buy = 'Buy',
  Sell = 'Sell'
}

export type Calendar = {
  __typename?: 'Calendar';
  calendarEvents?: Maybe<Array<CalendarEvent>>;
  isBusinessDay?: Maybe<Scalars['Boolean']['output']>;
  isEarlyClose?: Maybe<Scalars['Boolean']['output']>;
  names?: Maybe<Array<CalendarNameEnum>>;
};


export type CalendarIsBusinessDayArgs = {
  date: Scalars['GraphQLDateString']['input'];
};


export type CalendarIsEarlyCloseArgs = {
  date: Scalars['GraphQLDateString']['input'];
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  date: Scalars['GraphQLDateString']['output'];
  events: Array<EventInfo>;
  state: CalendarStateEnum;
};

export enum CalendarNameEnum {
  SwedenFixedIncome = 'SwedenFixedIncome',
  UnitedKingdom = 'UnitedKingdom',
  UnitedStates = 'UnitedStates'
}

export enum CalendarStateEnum {
  Closed = 'Closed',
  EarlyClose = 'EarlyClose'
}

export enum CalendarStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted'
}

export type CashAccountBalance = {
  __typename?: 'CashAccountBalance';
  balanceItems: Array<CashBalance>;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
};

export type CashAccountLadder = {
  __typename?: 'CashAccountLadder';
  amounts: Array<Scalars['Float']['output']>;
  balances: Array<Scalars['Float']['output']>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  dates: Array<Scalars['GraphQLDateString']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
};

export type CashBalance = {
  __typename?: 'CashBalance';
  amount: Scalars['Float']['output'];
  balance: Scalars['Float']['output'];
  tradeDate: Scalars['GraphQLDateString']['output'];
  transaction?: Maybe<Transaction>;
  transactionId: Scalars['GraphQLObjectId']['output'];
  transactionItem?: Maybe<TransactionItem>;
  transactionItemId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  valueDate: Scalars['GraphQLDateString']['output'];
};

export type CashFlowItem = {
  __typename?: 'CashFlowItem';
  amount?: Maybe<Scalars['Float']['output']>;
  baseCurrency?: Maybe<CurrencyEnum>;
  currency?: Maybe<CurrencyEnum>;
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  fxRate?: Maybe<Scalars['Float']['output']>;
};

export enum CdsType {
  Basket = 'Basket',
  Index = 'Index'
}

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type ClientInfo = {
  __typename?: 'ClientInfo';
  _id: Scalars['GraphQLObjectId']['output'];
  name: Scalars['String']['output'];
};

export enum CollectionNameEnum {
  BrokerTransaction = 'BrokerTransaction',
  Instrument = 'Instrument',
  IssuerProgram = 'IssuerProgram',
  None = 'None',
  Party = 'Party',
  Screen = 'Screen',
  Swift = 'Swift',
  Transaction = 'Transaction'
}

export type Cost = {
  __typename?: 'Cost';
  _id: Scalars['GraphQLObjectId']['output'];
  amount?: Maybe<Scalars['Float']['output']>;
  attachments?: Maybe<Array<Attachment>>;
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  creator: UserInfo;
  creatorId: Scalars['GraphQLObjectId']['output'];
  currency?: Maybe<CurrencyEnum>;
  date: Scalars['GraphQLDateString']['output'];
  debtorIds: Array<Scalars['GraphQLObjectId']['output']>;
  debtors: Array<PartyInfo>;
  description: Scalars['String']['output'];
  endDate: Scalars['GraphQLDateString']['output'];
  startDate: Scalars['GraphQLDateString']['output'];
  status: CostStatusEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  /** A list of versions of the document */
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type CostFilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<CostStatusEnum>>;
};

export type CostInput = {
  /** if _id is omitted, a Cost will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  clientId: Scalars['GraphQLObjectId']['input'];
  currency?: InputMaybe<CurrencyEnum>;
  date: Scalars['GraphQLDateString']['input'];
  debtorIds: Array<Scalars['GraphQLObjectId']['input']>;
  description: Scalars['String']['input'];
  endDate: Scalars['GraphQLDateString']['input'];
  startDate: Scalars['GraphQLDateString']['input'];
  status: CostStatusEnum;
  /** A list of versions of the document */
  versions?: InputMaybe<Scalars['GraphQLJSON']['input']>;
};

export enum CostStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted'
}

export enum CountryCodeEnum {
  /** UnitedArabEmirates */
  AE = 'AE',
  /** Argentina */
  AR = 'AR',
  /** Austria */
  AT = 'AT',
  /** Australia */
  AU = 'AU',
  /** Belgium */
  BE = 'BE',
  /** Bulgaria */
  BG = 'BG',
  /** Bermuda */
  BM = 'BM',
  /** Canada */
  CA = 'CA',
  /** Switzerland */
  CH = 'CH',
  /** Chile */
  CL = 'CL',
  /** China */
  CN = 'CN',
  /** Cyprus */
  CY = 'CY',
  /** CzechRepublic */
  CZ = 'CZ',
  /** Germany */
  DE = 'DE',
  /** Denmark */
  DK = 'DK',
  /** Estonia */
  EE = 'EE',
  /** Spain */
  ES = 'ES',
  /** Finland */
  FI = 'FI',
  /** FaroeIslands */
  FO = 'FO',
  /** France */
  FR = 'FR',
  /** UnitedKingdom */
  GB = 'GB',
  /** Greece */
  GR = 'GR',
  /** HongKong */
  HK = 'HK',
  /** Croatia */
  HR = 'HR',
  /** Hungary */
  HU = 'HU',
  /** Ireland */
  IE = 'IE',
  /** Israel */
  IL = 'IL',
  /** IsleOfMan */
  IM = 'IM',
  /** Iran */
  IR = 'IR',
  /** Italy */
  IT = 'IT',
  /** Jersey */
  JE = 'JE',
  /** Jordan */
  JO = 'JO',
  /** Japan */
  JP = 'JP',
  /** CaymanIslands */
  KY = 'KY',
  /** Lithuania */
  LT = 'LT',
  /** Luxembourg */
  LU = 'LU',
  /** Latvia */
  LV = 'LV',
  /** Macao */
  MO = 'MO',
  /** Malta */
  MT = 'MT',
  /** Mexico */
  MX = 'MX',
  /** Netherlands */
  NL = 'NL',
  /** Norway */
  NO = 'NO',
  /** NewZealand */
  NZ = 'NZ',
  /** Poland */
  PL = 'PL',
  /** Portugal */
  PT = 'PT',
  /** Romania */
  RO = 'RO',
  /** Sweden */
  SE = 'SE',
  /** Singapore */
  SG = 'SG',
  /** Slovenia */
  SI = 'SI',
  /** Slovakia */
  SK = 'SK',
  /** UnitedStates */
  US = 'US',
  /** Unknown */
  Unknown = 'Unknown',
  /** SouthAfrica */
  ZA = 'ZA'
}

export type CreateAccountingRunInput = {
  accountingPeriod: Scalars['String']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  clientTAccountChartId: Scalars['GraphQLObjectId']['input'];
  createTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  createUserId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  endDate: Scalars['GraphQLDateString']['input'];
  journalEntries: Array<JournalEntryInput>;
  masterTAccountChartId: Scalars['GraphQLObjectId']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  type: AccountingRunTypeEnum;
};

export type CreateAttachmentInput = {
  base64: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type CreateCurveDefinitionInput = {
  clientId: Scalars['GraphQLObjectId']['input'];
  definition?: InputMaybe<CurveDefinitionDefinitionInput>;
  name: Scalars['String']['input'];
  type: CurveDefinitionType;
};

export type CreateInstrumentInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  aliases?: InputMaybe<Array<AliasInput>>;
  benchmarkId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  bic?: InputMaybe<Scalars['String']['input']>;
  bloombergDailyDownload?: InputMaybe<Scalars['Boolean']['input']>;
  bloombergQuoteMultiplier?: InputMaybe<Scalars['Float']['input']>;
  bloombergTicker?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<InstrumentCategory>;
  cic?: InputMaybe<Scalars['String']['input']>;
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  creditRatings?: InputMaybe<Array<InputMaybe<CreditRatingInput>>>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  exchange?: InputMaybe<Scalars['String']['input']>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  isin?: InputMaybe<Scalars['ISIN']['input']>;
  issuerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  issuerProgramId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  /** https://www.iso20022.org/market-identifier-codes */
  mic?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<InstrumentModelInput>;
  name: Scalars['String']['input'];
  placeOfSettlementId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  productType?: InputMaybe<InstrumentProductTypeEnum>;
  quantityDecimals?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<InstrumentStatusEnum>;
  /** type defaults to Instrument */
  type?: InputMaybe<InstrumentTypeEnum>;
};

export type CreateJournalEntryInput = {
  accountingRunId: Scalars['GraphQLObjectId']['input'];
  batch?: InputMaybe<AccountingBatchType>;
  clientId: Scalars['GraphQLObjectId']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  externalNumber?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  portfolioTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  transactions?: InputMaybe<Array<AccountingTransactionInput>>;
};

export type CreateMiniTransactionInput = {
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  accruedInterest?: InputMaybe<Scalars['Float']['input']>;
  agreementType?: InputMaybe<AgreementType>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  bestExecutionType?: InputMaybe<BestExecutionType>;
  bonusShares?: InputMaybe<Scalars['Float']['input']>;
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  brokerTradeId?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  clearingBrokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clearingHouseId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  close?: InputMaybe<Scalars['Boolean']['input']>;
  commission?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  dividendPaid?: InputMaybe<Scalars['Boolean']['input']>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fee?: InputMaybe<Scalars['Float']['input']>;
  foreignTax?: InputMaybe<Scalars['Float']['input']>;
  fxRate?: InputMaybe<Scalars['Float']['input']>;
  initialMargin?: InputMaybe<Scalars['Boolean']['input']>;
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  itemType?: InputMaybe<FeeInterestItemType>;
  loan?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  reinvestedDividend?: InputMaybe<Scalars['Boolean']['input']>;
  reinvestedRebate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reverts + open a new transaction from input, available for dividend/interest in gui only */
  revertTransaction?: InputMaybe<Scalars['Boolean']['input']>;
  rounding?: InputMaybe<Scalars['Float']['input']>;
  secondAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  secondAmount?: InputMaybe<Scalars['Float']['input']>;
  secondCurrency?: InputMaybe<CurrencyEnum>;
  secondInstrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  source?: InputMaybe<SourceType>;
  stampDuty?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TransactionStatus>;
  taxRestitution?: InputMaybe<Scalars['Boolean']['input']>;
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  type: TransactionType;
  uniqueTradeId?: InputMaybe<Scalars['String']['input']>;
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type CreatePartyInput = {
  accountant?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  approval?: InputMaybe<Scalars['String']['input']>;
  bloombergEquityTicker?: InputMaybe<Scalars['String']['input']>;
  businessIdentifierCode?: InputMaybe<Scalars['String']['input']>;
  ceo?: InputMaybe<Scalars['String']['input']>;
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  complaintsManager?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryCodeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  inLedgerTradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  legalEntityIdentifier?: InputMaybe<Scalars['String']['input']>;
  legalNumber?: InputMaybe<Scalars['String']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  nace?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  regulator?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PartyStatusEnum>;
  sustainalyticsId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<TimezoneEnum>;
  types: Array<PartyType>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReportInput = {
  base64?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  data?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  name: Scalars['String']['input'];
  sourceId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  status?: InputMaybe<ReportStatusEnum>;
  type: Scalars['String']['input'];
};

export type CreateRoleInput = {
  assets: Array<InputMaybe<PermissionAssetEnum>>;
  clientIds: Array<InputMaybe<Scalars['GraphQLObjectId']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  permissionType: PermissionTypeEnum;
  roleType: RoleTypeEnum;
  status: DocumentStatusEnum;
};

export type CreateSwiftInput = {
  clientId: Scalars['GraphQLObjectId']['input'];
  name: Scalars['String']['input'];
  parsed: Scalars['GraphQLJSON']['input'];
  reportIds: Array<Scalars['GraphQLObjectId']['input']>;
  status: SwiftStatusEnum;
  text: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateTAccountChartInput = {
  clientId: Scalars['GraphQLObjectId']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tAccountMappings?: InputMaybe<Array<TAccountMappingInput>>;
  tAccounts?: InputMaybe<Array<TAccountInput>>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateTransactionInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  bestExecutionType: BestExecutionType;
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  brokerTradeId?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  clearingHouseId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  items: Array<CreateTransactionItemInput>;
  number?: InputMaybe<Scalars['Int']['input']>;
  status: TransactionStatus;
  tradeTimestamp: Scalars['Timestamp']['input'];
  type: TransactionType;
  uniqueTradeId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTransactionItemInput = {
  accountId: Scalars['GraphQLObjectId']['input'];
  amount?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  fxRate?: InputMaybe<Scalars['Float']['input']>;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  type: TransactionItemType;
  valueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type CreateUpdateCashInstrumentInput = {
  /** Create: Not included Update: Required */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** Create: Optional Update: Optional */
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  bic?: InputMaybe<Scalars['String']['input']>;
  create: Scalars['Boolean']['input'];
  /** Create: Required Update: Optional */
  currency?: InputMaybe<CurrencyEnum>;
  /** Create: Required Update: Optional */
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  /** Account number, Create: Optional Update: Optional */
  isin?: InputMaybe<Scalars['String']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  quantityDecimals?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateUpdateExternalAccountInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  baseCurrency?: InputMaybe<CurrencyEnum>;
  /** only for omnibusClearingAccount + clearingAccount */
  clearingBrokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** only for omnibusClearingAccounts */
  clearingHousesIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  create: Scalars['Boolean']['input'];
  custodianAccountNumber?: InputMaybe<Scalars['String']['input']>;
  custodianId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  emirReporting?: InputMaybe<Scalars['Boolean']['input']>;
  initialMarginCurrencies?: InputMaybe<Array<CurrencyEnum>>;
  minimumTransferAmount?: InputMaybe<Scalars['Float']['input']>;
  principalCurrencies?: InputMaybe<Array<CurrencyEnum>>;
  roundingAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Need to be able to separate collateral securities posted as IM from what's posted as VM */
  separatedInitialMargin?: InputMaybe<Scalars['Boolean']['input']>;
  settlementOnVMInstrument?: InputMaybe<Scalars['Boolean']['input']>;
  threshold?: InputMaybe<Scalars['Float']['input']>;
  type: PartyExternalAccountType;
  variationMarginCurrencies?: InputMaybe<Array<CurrencyEnum>>;
};

export type CreateUpdatePartyCashOrBalanceInstrumentInput = {
  /** Create: Not included Update: Required */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** Create: Optional Update: Optional */
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  aliases?: InputMaybe<Array<AliasInput>>;
  bic?: InputMaybe<Scalars['String']['input']>;
  /** Create: Required Update: Not included */
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  create: Scalars['Boolean']['input'];
  /** Create: Required Update: Optional */
  currency?: InputMaybe<CurrencyEnum>;
  /** Create: Required Update: Optional */
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  /** Account number, Create: Optional Update: Optional */
  isin?: InputMaybe<Scalars['String']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Only acceptable options are CashAccount or BalanceAccount */
  productType: InstrumentProductTypeEnum;
  quantityDecimals?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateUserInput = {
  bidExpiration: Scalars['Int']['input'];
  company: Scalars['String']['input'];
  description: Scalars['String']['input'];
  email: Scalars['EmailAddress']['input'];
  frontendRoleId: Scalars['GraphQLObjectId']['input'];
  name: Scalars['String']['input'];
  noteIds: Array<Scalars['GraphQLObjectId']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  personalNumber: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  roleIds: Array<Scalars['GraphQLObjectId']['input']>;
  status: UserStatusEnum;
};

export type CreateValuationInput = {
  bloombergTicker?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['GraphQLSwedenDate']['input'];
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  instrumentName?: InputMaybe<Scalars['String']['input']>;
  prices: Array<ValuationPriceInput>;
  source?: InputMaybe<Scalars['String']['input']>;
  writeUser?: InputMaybe<Scalars['String']['input']>;
};

export type CreditRatingInput = {
  creditRatingAgencyId: Scalars['GraphQLObjectId']['input'];
  grade: Scalars['GraphQLCreditRating']['input'];
};

export type CreditStatus = {
  pariPassu: TrueFalseNone;
  secured: TrueFalseNone;
  senior: TrueFalseNone;
};

export type CreditStatusType = {
  __typename?: 'CreditStatusType';
  pariPassu: TrueFalseNone;
  secured: TrueFalseNone;
  senior: TrueFalseNone;
};

export enum CurrencyEnum {
  AUD = 'AUD',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  CNY = 'CNY',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  ILS = 'ILS',
  INR = 'INR',
  ISK = 'ISK',
  JPY = 'JPY',
  KRW = 'KRW',
  MXN = 'MXN',
  NOK = 'NOK',
  NZD = 'NZD',
  RUB = 'RUB',
  SEK = 'SEK',
  SGD = 'SGD',
  TRY = 'TRY',
  USD = 'USD',
  ZAR = 'ZAR'
}

export type CurveDefinition = {
  __typename?: 'CurveDefinition';
  _id: Scalars['GraphQLObjectId']['output'];
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  definition?: Maybe<CurveDefinitionDefinition>;
  name: Scalars['String']['output'];
  type: CurveDefinitionType;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type CurveDefinitionDefinition = {
  __typename?: 'CurveDefinitionDefinition';
  currency: CurrencyEnum;
  dayCount: Scalars['String']['output'];
  forwardPeriod: Scalars['String']['output'];
  instruments: Array<CurveDefinitionInstrument>;
  issuerProgram?: Maybe<IssuerProgram>;
  issuerProgramId: Scalars['GraphQLObjectId']['output'];
  type: CurveDefinitionType2;
};

export type CurveDefinitionDefinitionInput = {
  currency: CurrencyEnum;
  dayCount: Scalars['String']['input'];
  forwardPeriod: Scalars['String']['input'];
  instruments: Array<CurveDefinitionInstrumentInput>;
  issuerProgramId: Scalars['GraphQLObjectId']['input'];
  type: CurveDefinitionType2;
};

export type CurveDefinitionInstrument = {
  __typename?: 'CurveDefinitionInstrument';
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
};

export type CurveDefinitionInstrumentInput = {
  instrumentId: Scalars['GraphQLObjectId']['input'];
};

export enum CurveDefinitionType {
  BasisCurve = 'BasisCurve',
  CreditCurve = 'CreditCurve',
  FixingCurve = 'FixingCurve',
  FxCurve = 'FxCurve',
  YieldCurve = 'YieldCurve'
}

export enum CurveDefinitionType2 {
  YieldCurveDefinition = 'YieldCurveDefinition'
}

export type CustodianApiPosition = {
  __typename?: 'CustodianApiPosition';
  _id: Scalars['GraphQLObjectId']['output'];
  accountNumber: Scalars['String']['output'];
  attachments: Array<Attachment>;
  cashAccountNumber: Scalars['String']['output'];
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  currency: CurrencyEnum;
  custodian: PartyInfo;
  custodianId: Scalars['GraphQLObjectId']['output'];
  date: Scalars['GraphQLDateString']['output'];
  externalAccount?: Maybe<PartyExternalAccount>;
  externalAccountId: Scalars['GraphQLObjectId']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  isin: Scalars['ISIN']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  status: StatusEnum;
  type: CustodianPositionType;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type CustodianApiPositionInput = {
  /** if _id is omitted, a document will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  accountNumber: Scalars['String']['input'];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  cashAccountNumber: Scalars['String']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  currency: CurrencyEnum;
  custodianId: Scalars['GraphQLObjectId']['input'];
  date: Scalars['GraphQLDateString']['input'];
  externalAccountId: Scalars['GraphQLObjectId']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  isin: Scalars['ISIN']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  status: StatusEnum;
  type: CustodianPositionType;
};

export type CustodianPosition = {
  __typename?: 'CustodianPosition';
  amount?: Maybe<Scalars['Float']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<CurrencyEnum>;
  custodianAccountNumber?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export enum CustodianPositionType {
  Automatic = 'Automatic',
  Manual = 'Manual'
}

export type Customer = {
  __typename?: 'Customer';
  _id: Scalars['GraphQLObjectId']['output'];
  clientAccount: Scalars['String']['output'];
  clientAccountLabel: Scalars['String']['output'];
  /** clientIds are used for access control */
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clientNumber: Scalars['String']['output'];
  clients: Array<PartyInfo>;
  organizationName: Scalars['String']['output'];
  swedishFinancialAuthorityCategory: SwedishFinancialAuthorityCategoryEnum;
  swedishFinancialAuthorityCategoryString: Scalars['String']['output'];
};

export type CustomerFilterInput = {
  categoryIn?: InputMaybe<Array<SwedishFinancialAuthorityCategoryEnum>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};

export type CustomerInput = {
  /** if _id is omitted, a document will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientAccount: Scalars['String']['input'];
  clientAccountLabel: Scalars['String']['input'];
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  clientNumber: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
  swedishFinancialAuthorityCategory: SwedishFinancialAuthorityCategoryEnum;
};

export type DartBasic = {
  __typename?: 'DartBasic';
  analysisFeeDaily?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  fund?: Maybe<Scalars['String']['output']>;
  fundFigureAttribute?: Maybe<Scalars['String']['output']>;
  fundName?: Maybe<Scalars['String']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  managementFeeDaily?: Maybe<Scalars['Float']['output']>;
  navAfterManagementFee?: Maybe<Scalars['Float']['output']>;
  navBeforeManagementFee?: Maybe<Scalars['Float']['output']>;
  /**
   * // DART data reporting requirements SLA 20 Appendix 2
   * // NAV basic data description
   */
  navDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  navPerUnit?: Maybe<Scalars['Float']['output']>;
  numberOfFundUnits?: Maybe<Scalars['Float']['output']>;
  organisationNo?: Maybe<Scalars['String']['output']>;
  portfolio?: Maybe<Scalars['String']['output']>;
  taxRestitutions?: Maybe<Scalars['Float']['output']>;
};

export type DartHolding = {
  __typename?: 'DartHolding';
  accruedInterestLocalCurrency?: Maybe<Scalars['Float']['output']>;
  accruedInterestSek?: Maybe<Scalars['Float']['output']>;
  balanceNominal?: Maybe<Scalars['Float']['output']>;
  bloombergTicker?: Maybe<Scalars['String']['output']>;
  cfi?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  fundName?: Maybe<Scalars['String']['output']>;
  /**
   * // DART data reporting requirements SLA 20 Appendix 2
   * // Holdings, cash and other assets & liabilities
   */
  fundOrganisationNumber?: Maybe<Scalars['String']['output']>;
  fundShort?: Maybe<Scalars['String']['output']>;
  fxRate?: Maybe<Scalars['Float']['output']>;
  instrumentId?: Maybe<Scalars['String']['output']>;
  instrumentType?: Maybe<Scalars['String']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  marketValueLocalCurrency?: Maybe<Scalars['Float']['output']>;
  marketValueSek?: Maybe<Scalars['Float']['output']>;
  navDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  priceFuture?: Maybe<Scalars['Float']['output']>;
  pricePerUnit?: Maybe<Scalars['Float']['output']>;
  priceType?: Maybe<Scalars['String']['output']>;
  quotation?: Maybe<Scalars['String']['output']>;
  securityGroup?: Maybe<Scalars['String']['output']>;
  securityName?: Maybe<Scalars['String']['output']>;
  securityShort?: Maybe<Scalars['String']['output']>;
  securityType?: Maybe<Scalars['String']['output']>;
  tradingCurrency?: Maybe<Scalars['String']['output']>;
  unsettledAmountIncomingLocalCurrency?: Maybe<Scalars['Float']['output']>;
  unsettledAmountIncomingSek?: Maybe<Scalars['Float']['output']>;
  unsettledAmountOutgoingLocalCurrency?: Maybe<Scalars['Float']['output']>;
  unsettledAmountOutgoingSek?: Maybe<Scalars['Float']['output']>;
  unsettledQuantity?: Maybe<Scalars['Float']['output']>;
};

export type DartReport = {
  __typename?: 'DartReport';
  /** // NAV basic data description */
  basics?: Maybe<Array<Maybe<DartBasic>>>;
  holdings?: Maybe<Array<Maybe<DartHolding>>>;
  transactions?: Maybe<Array<Maybe<DartTransaction>>>;
};

export type DartTransaction = {
  __typename?: 'DartTransaction';
  cashAccount?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  grossAmount?: Maybe<Scalars['Float']['output']>;
  investor?: Maybe<Scalars['String']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  shareClassInternalCode?: Maybe<Scalars['String']['output']>;
  shareClassName?: Maybe<Scalars['String']['output']>;
  /**
   * // DART data reporting requirements SLA 20 Appendix 2
   * // Fund unit transactions
   */
  transactionId?: Maybe<Scalars['String']['output']>;
  transactionType?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['Float']['output']>;
  valuationDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  valueDate?: Maybe<Scalars['GraphQLDateString']['output']>;
};

export type DateQuote = {
  __typename?: 'DateQuote';
  date: Scalars['GraphQLDateString']['output'];
  type: QuoteTypeEnum;
  value: Scalars['Float']['output'];
};

export type DateValue = {
  __typename?: 'DateValue';
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum DayAdjustmentEnum {
  FixedPeriods = 'FixedPeriods',
  Following = 'Following',
  ModifiedFollowing = 'ModifiedFollowing'
}

export type DefaultCashInstrument = {
  __typename?: 'DefaultCashInstrument';
  currency: CurrencyEnum;
  partyInstrument?: Maybe<Instrument>;
  partyInstrumentId: Scalars['GraphQLObjectId']['output'];
};

export type DefaultCashInstrumentInput = {
  currency?: InputMaybe<CurrencyEnum>;
  partyInstrumentId: Scalars['GraphQLObjectId']['input'];
};

export enum DocumentStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted'
}

export enum EngagementMethod {
  Dialog = 'Dialog',
  Voting = 'Voting'
}

export enum EsgActivityEnum {
  ApprovedScreen = 'ApprovedScreen',
  Dialogue = 'Dialogue',
  Divestment = 'Divestment',
  Investment = 'Investment',
  Other = 'Other',
  OtherActivity = 'OtherActivity',
  ProxyVoting = 'ProxyVoting',
  RatingEvent = 'RatingEvent',
  ShareholderMeeting = 'ShareholderMeeting',
  Voting = 'Voting'
}

export enum EsgAreaEnum {
  BusinessEthics = 'BusinessEthics',
  Climate = 'Climate',
  Environment = 'Environment',
  HumanRights = 'HumanRights',
  LabourRights = 'LabourRights',
  Other = 'Other',
  Ratings = 'Ratings',
  Social = 'Social'
}

export type EsgComment = {
  __typename?: 'EsgComment';
  comment: Scalars['String']['output'];
  date: Scalars['GraphQLDateString']['output'];
};

export type EsgCommentInput = {
  comment: Scalars['String']['input'];
  date: Scalars['GraphQLDateString']['input'];
};

export enum EsgComponentEnum {
  CdsIndexEsgScreenings = 'CdsIndexEsgScreenings',
  EsgCategory = 'EsgCategory',
  GlobalStandardScreen = 'GlobalStandardScreen',
  ProductInvolvement = 'ProductInvolvement',
  ProductInvolvementRanges = 'ProductInvolvementRanges',
  ScreeningInformation = 'ScreeningInformation',
  SpoProviders = 'SpoProviders',
  SustainableDevelopmentGoals = 'SustainableDevelopmentGoals',
  TransitionCompanies = 'TransitionCompanies',
  UseOfProceeds = 'UseOfProceeds'
}

export enum EuTaxonomyEnvironmentalObjective {
  /** Climate change adaptation */
  ClimateChangeAdaption = 'ClimateChangeAdaption',
  /** Climate change mitigation */
  ClimateChangeMitigation = 'ClimateChangeMitigation',
  /** Pollution prevention and control */
  PollutionPreventionAndControl = 'PollutionPreventionAndControl',
  /** Protection and restoration of biodiversity and ecosystems */
  ProtectionAndRestorationBioDiversityAndEcosystems = 'ProtectionAndRestorationBioDiversityAndEcosystems',
  /** Sustainable use and protection of water and marine resources */
  SustainableUseAndProtectionWaterAndMarineResources = 'SustainableUseAndProtectionWaterAndMarineResources',
  /** Transition to a circular economy */
  TransitionToCircularEconomy = 'TransitionToCircularEconomy'
}

export type EventInfo = {
  __typename?: 'EventInfo';
  event: Scalars['String']['output'];
  name: CalendarNameEnum;
  state: CalendarStateEnum;
};

export type ExposureCollateral = {
  __typename?: 'ExposureCollateral';
  _id: Scalars['GraphQLObjectId']['output'];
  agreementType: AgreementType;
  /** Exposure/Collateral (agreement currency), from sources perspective. */
  amount: Scalars['Float']['output'];
  client: Party;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  /** Exposure/Collateral agreement currency */
  currency: CurrencyEnum;
  /** Can be used to find client, we store this number on externalAccount */
  custodianAccountNumber?: Maybe<Scalars['String']['output']>;
  /** Valuation date. */
  date: Scalars['GraphQLDateString']['output'];
  /** The reference used by the source. */
  externalId: Scalars['String']['output'];
  instrument?: Maybe<Instrument>;
  /** Can be either RED code or instrumentId */
  instrumentAlias?: Maybe<Scalars['String']['output']>;
  isin?: Maybe<Scalars['ISIN']['output']>;
  /** Required for type CollateralCash/CollateralSecurity */
  marginType?: Maybe<MarginType>;
  /** Exposure/Collateral quantity, from sources perspective. */
  quantity: Scalars['Float']['output'];
  /** database id of original document. Only used internally */
  reportId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  source: Party;
  sourceId: Scalars['GraphQLObjectId']['output'];
  type: ExposureCollateralType;
  uniqueTransactionIdentifier?: Maybe<Scalars['String']['output']>;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  /** A list of versions of the document */
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type ExposureCollateralInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  agreementType: AgreementType;
  /** Exposure (local), from sources perspective. */
  amount: Scalars['Float']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  /** Exposure/Collateral agreement currency */
  currency: CurrencyEnum;
  /** Can be used to find client, we store this number on externalAccount */
  custodianAccountNumber?: InputMaybe<Scalars['String']['input']>;
  /** Valuation date. */
  date: Scalars['GraphQLDateString']['input'];
  /** The reference used by the source. */
  externalId: Scalars['String']['input'];
  /** Can be either RED code or instrumentId */
  instrumentAlias?: InputMaybe<Scalars['String']['input']>;
  isin?: InputMaybe<Scalars['ISIN']['input']>;
  /** Required for type CollateralCash/CollateralSecurity */
  marginType?: InputMaybe<MarginType>;
  /** Exposure/Collateral quantity, from sources perspective. */
  quantity: Scalars['Float']['input'];
  /** database id of original document. Only used internally */
  reportId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  sourceId: Scalars['GraphQLObjectId']['input'];
  type: ExposureCollateralType;
  uniqueTransactionIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export enum ExposureCollateralType {
  CollateralCash = 'CollateralCash',
  CollateralSecurity = 'CollateralSecurity',
  Exposure = 'Exposure'
}

export enum FeeInterestItemType {
  AccruedInterest = 'AccruedInterest',
  Commission = 'Commission',
  CustodyFee = 'CustodyFee',
  Fee = 'Fee',
  ForeignTax = 'ForeignTax',
  Interest = 'Interest',
  /** ManagementCost is an type used for costs due to management of funds, e.g. Bloomberg license. */
  ManagementCost = 'ManagementCost',
  ManagementFee = 'ManagementFee',
  Rebate = 'Rebate',
  TaxRestitution = 'TaxRestitution'
}

export type FieldDataItem = {
  __typename?: 'FieldDataItem';
  field?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type FilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<DocumentStatusEnum>>;
};

export type FredObservation = {
  __typename?: 'FredObservation';
  date?: Maybe<Scalars['Date']['output']>;
  realtime_end?: Maybe<Scalars['Date']['output']>;
  realtime_start?: Maybe<Scalars['Date']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FredSeries = {
  __typename?: 'FredSeries';
  frequency?: Maybe<Scalars['String']['output']>;
  frequency_short?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_updated?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  observation_end?: Maybe<Scalars['Date']['output']>;
  observation_start?: Maybe<Scalars['Date']['output']>;
  popularity?: Maybe<Scalars['Int']['output']>;
  realtime_end?: Maybe<Scalars['Date']['output']>;
  realtime_start?: Maybe<Scalars['Date']['output']>;
  seasonal_adjustment?: Maybe<Scalars['String']['output']>;
  seasonal_adjustment_short?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['String']['output']>;
  units_short?: Maybe<Scalars['String']['output']>;
};

export enum FundReportComponentEnum {
  AttributionMonth = 'AttributionMonth',
  AtttributionTotal = 'AtttributionTotal',
  BarReturnsMonth = 'BarReturnsMonth',
  BarReturnsYearly = 'BarReturnsYearly',
  CdsIndexComponents = 'CdsIndexComponents',
  CdsIndexEsgScreenings = 'CdsIndexEsgScreenings',
  CdsIndexHYComponents = 'CdsIndexHYComponents',
  CdsIndexIGComponents = 'CdsIndexIGComponents',
  CountryPieChart = 'CountryPieChart',
  CreditRating = 'CreditRating',
  CreditSpreadRisk = 'CreditSpreadRisk',
  CurrencyBarChart = 'CurrencyBarChart',
  CurrencyPieChart = 'CurrencyPieChart',
  EsgCategory = 'EsgCategory',
  InterestRateBuckets = 'InterestRateBuckets',
  KeyNumbers = 'KeyNumbers',
  ReturnVsVolatility = 'ReturnVsVolatility',
  Sectors = 'Sectors',
  SustainableDevelopmentGoals = 'SustainableDevelopmentGoals',
  TopHoldings = 'TopHoldings',
  TopIssuers = 'TopIssuers',
  TransitionCompanies = 'TransitionCompanies',
  UseOfProceeds = 'UseOfProceeds'
}

export enum FundReportEnum {
  Benchmark = 'Benchmark',
  Bonds = 'Bonds',
  Derivatives = 'Derivatives',
  Equities = 'Equities',
  Leverage = 'Leverage'
}

export enum FundTypeEnum {
  AIF = 'AIF',
  UCITS = 'UCITS'
}

export type FundUnit = {
  __typename?: 'FundUnit';
  instrument: Instrument;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  totalUnits?: Maybe<Scalars['Float']['output']>;
};

export type FundUnitReconciliationType = {
  __typename?: 'FundUnitReconciliationType';
  calculatedNbr?: Maybe<Scalars['Float']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  custodianAccountNumber?: Maybe<Scalars['String']['output']>;
  date: Scalars['GraphQLDateString']['output'];
  diff?: Maybe<Scalars['Float']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  party?: Maybe<PartyInfo>;
  partyId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  reportedNbr?: Maybe<Scalars['Float']['output']>;
  reporter?: Maybe<PartyInfo>;
  reporterId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  shareRegisterDiff?: Maybe<Scalars['Float']['output']>;
  shareRegisterNbr?: Maybe<Scalars['Float']['output']>;
  unitsAfterOrders?: Maybe<Scalars['String']['output']>;
};

export type FxValuation = {
  __typename?: 'FxValuation';
  currency?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  instrumentId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export type GlobalStandardScreen = {
  __typename?: 'GlobalStandardScreen';
  comment: Scalars['String']['output'];
  date: Scalars['GraphQLDateString']['output'];
  norm: StandardScreenNorm;
  principle: StandardScreenPrinciple;
  status: StandardScreenStatus;
};

export type GlobalStandardScreenInput = {
  comment: Scalars['String']['input'];
  date: Scalars['GraphQLDateString']['input'];
  norm: StandardScreenNorm;
  principle: StandardScreenPrinciple;
  status: StandardScreenStatus;
};

export type HistoricalData = {
  __typename?: 'HistoricalData';
  dates?: Maybe<Array<Maybe<Scalars['GraphQLDateString']['output']>>>;
  eidDataArray?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  field?: Maybe<Scalars['String']['output']>;
  fieldExceptionsArray?: Maybe<Array<RefDataFieldExceptionsArray>>;
  id?: Maybe<Scalars['GraphQLObjectId']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  security?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  securityError?: Maybe<Array<RefDataError>>;
  values?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export enum HistoricalFieldsEnum {
  PX_LAST = 'PX_LAST',
  PX_VOLUME = 'PX_VOLUME'
}

export type ImportBondTradeInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  accruedInterest?: InputMaybe<Scalars['Float']['input']>;
  bestExecutionType: BestExecutionType;
  /** Name/LEI/_id */
  brokerAlias: Scalars['String']['input'];
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  currency: CurrencyEnum;
  externalId: Scalars['String']['input'];
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  source: SourceType;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportCloseDerivativeTradeInput = {
  amount: Scalars['Float']['input'];
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  externalId: Scalars['String']['input'];
  source: SourceType;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportCollateralInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  agreementType: AgreementType;
  /** Counterparty - Name/LEI/_id */
  brokerAlias: Scalars['String']['input'];
  currency: CurrencyEnum;
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** To determine if variation margin/initial margin collateral */
  initialMargin?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  /** To determine if loan -> itemType SecurityLoan or collateral -> itemType CollateralPledge */
  loan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Collateral quantity */
  quantity: Scalars['Float']['input'];
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportCouponInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  agreementType?: InputMaybe<AgreementType>;
  amount: Scalars['Float']['input'];
  /** Name/LEI/_id */
  brokerAlias?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyEnum;
  externalId: Scalars['String']['input'];
  foreignTax?: InputMaybe<Scalars['Float']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  source: SourceType;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportCreateRedeemInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  accountSwitchOrder?: InputMaybe<Scalars['Boolean']['input']>;
  accountingAmount: Scalars['Float']['input'];
  adjustmentAmount?: InputMaybe<Scalars['Float']['input']>;
  adjustmentShares?: InputMaybe<Scalars['Float']['input']>;
  amount: Scalars['Float']['input'];
  currency: CurrencyEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['GraphQLDateString']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportDerivativeTradeInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  accruedInterest?: InputMaybe<Scalars['Float']['input']>;
  agreementType: AgreementType;
  bestExecutionType: BestExecutionType;
  /** Name/LEI/_id */
  brokerAlias: Scalars['String']['input'];
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  /** Name/LEI/_id(only necessary for agreementType: ClearingAccount and OmnibusClearingAccount) */
  clearingBrokerAlias?: InputMaybe<Scalars['String']['input']>;
  /** Name/LEI/_id (only necessary for agreementType: ClearingAccount and OmnibusClearingAccount) */
  clearingHouseAlias?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyEnum;
  externalId: Scalars['String']['input'];
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity: Scalars['Float']['input'];
  source: SourceType;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  uniqueTradeId: Scalars['String']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportDividendInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  currency: CurrencyEnum;
  /** Default: False */
  dividendPaid?: InputMaybe<Scalars['Boolean']['input']>;
  externalId: Scalars['String']['input'];
  /** ForeignTax amount */
  foreignTax?: InputMaybe<Scalars['Float']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  source: SourceType;
  status?: InputMaybe<TransactionStatus>;
  /** Default: False */
  taxRestitution?: InputMaybe<Scalars['Boolean']['input']>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportFeeInterestInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  agreementType?: InputMaybe<AgreementType>;
  amount: Scalars['Float']['input'];
  /** Name/LEI/_id */
  brokerAlias?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  foreignTax?: InputMaybe<Scalars['Float']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  itemType: FeeInterestItemType;
  rounding?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportFundTradeInput = {
  /** Name or _id, will use default if not stated */
  accountAlias?: InputMaybe<Scalars['String']['input']>;
  /** Need to state amount + quantity or quantity + price */
  amount?: InputMaybe<Scalars['Float']['input']>;
  commission?: InputMaybe<Scalars['Float']['input']>;
  currency: CurrencyEnum;
  /** Name or _id, will use default security external account if not stated */
  externalAccountAlias?: InputMaybe<Scalars['String']['input']>;
  /** If set, settle in accounting currency */
  fxRate?: InputMaybe<Scalars['Float']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  /** Need to state amount + quantity or quantity + price */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Need to state amount + quantity or quantity + price */
  quantity: Scalars['Float']['input'];
  reinvestedDividend?: InputMaybe<Scalars['Boolean']['input']>;
  reinvestedRebate?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportFxSpotInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  bestExecutionType: BestExecutionType;
  /** Name/LEI/_id */
  brokerAlias: Scalars['String']['input'];
  brokerTradeId?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  currency: CurrencyEnum;
  externalId: Scalars['String']['input'];
  /** If related to fxSwap, should be set to the fxSwap derivativeTrade */
  parentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** Name or _id, if not stated, will be set to accountAlias or default on party instrument */
  secondAccountAlias?: InputMaybe<Scalars['String']['input']>;
  secondAmount: Scalars['Float']['input'];
  secondCurrency: CurrencyEnum;
  source: SourceType;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  uniqueTradeId?: InputMaybe<Scalars['String']['input']>;
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportInsertCashInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  currency: CurrencyEnum;
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportInsertDerivativeTradeInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  agreementType: AgreementType;
  /** Name/LEI/_id */
  brokerAlias: Scalars['String']['input'];
  /** Name/LEI/_id (only necessary for agreementType: ClearingAccount and OmnibusClearingAccount) */
  clearingBrokerAlias?: InputMaybe<Scalars['String']['input']>;
  /** Name/LEI/_id (only necessary for agreementType: ClearingAccount and OmnibusClearingAccount) */
  clearingHouseAlias?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyEnum;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  uniqueTradeId: Scalars['String']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportInsertSecurityInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  /** Only needed when security and quantity = 0, price = 0 and amount != 0 */
  amount?: InputMaybe<Scalars['Float']['input']>;
  currency: CurrencyEnum;
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportStockTradeInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  bestExecutionType: BestExecutionType;
  /** Name/LEI/_id */
  brokerAlias?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  commission?: InputMaybe<Scalars['Float']['input']>;
  currency: CurrencyEnum;
  externalId: Scalars['String']['input'];
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  source: SourceType;
  stampDuty?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportTransferInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  bestExecutionType: BestExecutionType;
  /** Name/LEI/_id */
  brokerAlias?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  currency: CurrencyEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  /** Name or _id, if not stated, will be set to accountAlias or default on party instrument */
  secondAccountAlias?: InputMaybe<Scalars['String']['input']>;
  secondAmount: Scalars['Float']['input'];
  secondCurrency: CurrencyEnum;
  /** Name/ISIN/BloombergTicker/_id */
  secondInstrumentAlias: Scalars['String']['input'];
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type Instrument = {
  __typename?: 'Instrument';
  _id: Scalars['GraphQLObjectId']['output'];
  account?: Maybe<PartyAccount>;
  accountId: Scalars['GraphQLObjectId']['output'];
  accruedInterest?: Maybe<Scalars['Float']['output']>;
  /**
   * Accrued interest using only model cash-flows and thus much quicker than
   * bootstrapping all curves needed for risk parameters above
   */
  accruedInterestOnly?: Maybe<Scalars['Float']['output']>;
  aliases: Array<Alias>;
  benchmark?: Maybe<Instrument>;
  benchmarkId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  bic: Scalars['String']['output'];
  bloombergDailyDownload: Scalars['Boolean']['output'];
  bloombergQuoteMultiplier: Scalars['Float']['output'];
  bloombergTicker?: Maybe<Scalars['String']['output']>;
  category?: Maybe<InstrumentCategory>;
  cic?: Maybe<Scalars['String']['output']>;
  cleanPrice?: Maybe<Scalars['Float']['output']>;
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients: Array<Party>;
  /** Calculated country of listing */
  countryOfListing?: Maybe<Scalars['String']['output']>;
  couponFrequency?: Maybe<Scalars['Int']['output']>;
  couponRate?: Maybe<Scalars['Float']['output']>;
  creditQualityStep?: Maybe<Scalars['Int']['output']>;
  /** Calculated credit ratings */
  creditRating?: Maybe<Scalars['String']['output']>;
  creditRatings?: Maybe<Array<PartyCreditRating>>;
  creditYieldDelta?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<CurrencyEnum>;
  description?: Maybe<Scalars['String']['output']>;
  exchange?: Maybe<Scalars['String']['output']>;
  externalAccount?: Maybe<PartyExternalAccount>;
  externalAccountId: Scalars['GraphQLObjectId']['output'];
  /** First trade date if traded */
  firstTradeDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  /** First external account id if traded */
  firstTradeExternalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** First transaction id if traded */
  firstTradeTransactionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  iban: Scalars['String']['output'];
  id: Scalars['GraphQLObjectId']['output'];
  interestRateYieldDelta?: Maybe<Scalars['Float']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  issuer?: Maybe<Party>;
  issuerId: Scalars['GraphQLObjectId']['output'];
  issuerProgram?: Maybe<IssuerProgram>;
  issuerProgramId: Scalars['GraphQLObjectId']['output'];
  longName?: Maybe<Scalars['String']['output']>;
  /** Always true after first margin call transaction has been created */
  marginCallsMade?: Maybe<Scalars['Boolean']['output']>;
  maturityDate?: Maybe<Scalars['String']['output']>;
  /** https://www.iso20022.org/market-identifier-codes */
  mic?: Maybe<Scalars['String']['output']>;
  model?: Maybe<InstrumentModel>;
  /** For derivative models where we trade one unit and notional is on model, modelNotionalScaling can be used to match counterparty notional */
  modelNotionalScaling?: Maybe<Scalars['Float']['output']>;
  /** Calculated fields from model */
  modelType?: Maybe<InstrumentModelTypeEnum>;
  name?: Maybe<Scalars['String']['output']>;
  placeOfSettlementId: Scalars['GraphQLObjectId']['output'];
  /**
   * Swaplab2 calculated properties. If no date given default to yesterday.
   * Not optimal but for now. Should be last business day but hard without
   * calendars
   */
  presentValue?: Maybe<Scalars['Float']['output']>;
  productType?: Maybe<InstrumentProductTypeEnum>;
  /** Last available time series value */
  pxLast?: Maybe<DateValue>;
  quantityDecimals?: Maybe<Scalars['Int']['output']>;
  /** Quote at date - null if no quote found for that date */
  quote?: Maybe<DateQuote>;
  /** Used as issueDate */
  startDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  /** If exchanged is asked for, only exchanged is returned */
  timeSeries?: Maybe<Array<TimeSeries>>;
  type: InstrumentTypeEnum;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  /** Id of the user that updated/created */
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  valuations?: Maybe<InstrumentValuation>;
  /** A list of versions of the document */
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
  zeroSpread?: Maybe<Scalars['Float']['output']>;
};


export type InstrumentAccruedInterestArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentAccruedInterestOnlyArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentCleanPriceArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentCreditYieldDeltaArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentFirstTradeDateArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type InstrumentFirstTradeExternalAccountIdArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type InstrumentFirstTradeTransactionIdArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type InstrumentInterestRateYieldDeltaArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentPresentValueArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentPxLastArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentQuoteArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentTimeSeriesArgs = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  exchanged?: InputMaybe<CurrencyEnum>;
  fxHedged?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type InstrumentValuationsArgs = {
  currency?: InputMaybe<CurrencyEnum>;
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentZeroSpreadArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type InstrumentAccountPosition = {
  __typename?: 'InstrumentAccountPosition';
  account?: Maybe<PartyAccount>;
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  currency: CurrencyEnum;
  externalAccount?: Maybe<PartyExternalAccount>;
  externalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  quantity: Scalars['Float']['output'];
};

export enum InstrumentCategory {
  Alternative = 'Alternative',
  CashAccount = 'CashAccount',
  Equity = 'Equity',
  Fx = 'Fx',
  HedgeFund = 'HedgeFund',
  Interest = 'Interest',
  InterestRate = 'InterestRate',
  Liability = 'Liability',
  Margin = 'Margin',
  None = 'None',
  RealEstate = 'RealEstate',
  System = 'System'
}

export type InstrumentFilterInput = {
  bloombergDailyDownload?: InputMaybe<Scalars['Boolean']['input']>;
  bloombergTickerIn?: InputMaybe<Array<Scalars['String']['input']>>;
  categoryIn?: InputMaybe<Array<InstrumentCategory>>;
  categoryNotIn?: InputMaybe<Array<InstrumentCategory>>;
  currencyIn?: InputMaybe<Array<CurrencyEnum>>;
  currencyNotIn?: InputMaybe<Array<CurrencyEnum>>;
  exchangeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  exchangeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  isinIn?: InputMaybe<Array<Scalars['ISIN']['input']>>;
  isinNotIn?: InputMaybe<Array<Scalars['ISIN']['input']>>;
  issuerIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  issuerProgramIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  modelTypeIn?: InputMaybe<Array<InstrumentModelTypeEnum>>;
  modelTypeNotIn?: InputMaybe<Array<InstrumentModelTypeEnum>>;
  productTypeIn?: InputMaybe<Array<InstrumentProductTypeEnum>>;
  productTypeNotIn?: InputMaybe<Array<InstrumentProductTypeEnum>>;
  statusIn?: InputMaybe<Array<InstrumentStatusEnum>>;
  typeIn?: InputMaybe<Array<InstrumentTypeEnum>>;
  updatedAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedBefore?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type InstrumentModel = {
  __typename?: 'InstrumentModel';
  _t: InstrumentModelTypeEnum;
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  expiryDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  legs?: Maybe<Array<InstrumentModelLegsItem>>;
  notionalScaling?: Maybe<Scalars['Float']['output']>;
  objectId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  objectType?: Maybe<InstrumentModelObjectTypeEnum>;
  quoteCurrency: CurrencyEnum;
  quoteType: Scalars['String']['output'];
  startDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  type: InstrumentModelTypeEnum;
};

export type InstrumentModelInput = {
  _t: InstrumentModelTypeEnum;
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  expiryDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
  legs?: InputMaybe<Array<InstrumentModelLegsItemInput>>;
  notionalScaling?: InputMaybe<Scalars['Float']['input']>;
  objectId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  objectType?: InputMaybe<InstrumentModelObjectTypeEnum>;
  quoteCurrency: CurrencyEnum;
  quoteType: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
};

export enum InstrumentModelLegFixingTypesEnum {
  Compound = 'Compound',
  Single = 'Single'
}

export enum InstrumentModelLegTypesEnum {
  CashFlowLeg = 'CashFlowLeg',
  EquityBasketOptionLeg = 'EquityBasketOptionLeg',
  EquityUnitOptionLeg = 'EquityUnitOptionLeg',
  FixedAmountLeg = 'FixedAmountLeg',
  FixedRateLeg = 'FixedRateLeg',
  FloatRateLeg = 'FloatRateLeg',
  FtaLeg = 'FtaLeg',
  GenericFixedAmountLeg = 'GenericFixedAmountLeg',
  GenericFixedRateLeg = 'GenericFixedRateLeg',
  GenericFloatRateLeg = 'GenericFloatRateLeg',
  SwaptionLeg = 'SwaptionLeg'
}

export type InstrumentModelLegsItem = {
  __typename?: 'InstrumentModelLegsItem';
  _t: InstrumentModelLegTypesEnum;
  calendars: Array<Scalars['String']['output']>;
  call?: Maybe<Scalars['Boolean']['output']>;
  cashFlows?: Maybe<Array<InstrumentModelLegsItemCashFlowsItem>>;
  creditSpread?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<CurrencyEnum>;
  dayAdjustment: DayAdjustmentEnum;
  dayCount?: Maybe<Scalars['String']['output']>;
  exercise?: Maybe<Scalars['String']['output']>;
  expiry?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  fixingType?: Maybe<Scalars['String']['output']>;
  flatAdjustment?: Maybe<Scalars['Float']['output']>;
  issuerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  longStub?: Maybe<Scalars['Boolean']['output']>;
  maturityDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  maturityPeriod?: Maybe<Scalars['String']['output']>;
  multiplier?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Int']['output']>;
  payLeg: Scalars['Boolean']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  rateIndexId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  recoveryRate?: Maybe<Scalars['Float']['output']>;
  relativeAdjustment?: Maybe<Scalars['Float']['output']>;
  rollingDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  rollingPeriod?: Maybe<Scalars['String']['output']>;
  settlementType?: Maybe<Scalars['String']['output']>;
  spread?: Maybe<Scalars['Float']['output']>;
  startDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  startDateOffset?: Maybe<Scalars['Int']['output']>;
  startPeriod?: Maybe<Scalars['String']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  type: InstrumentModelLegTypesEnum;
  ultimateForwardRate?: Maybe<Scalars['Float']['output']>;
  underlyingId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  underlyingInstrument?: Maybe<Instrument>;
  underlyings?: Maybe<Array<InstrumentModelLegsItemUnderlyingsItem>>;
  volatility?: Maybe<Scalars['Float']['output']>;
  zeroFloor?: Maybe<Scalars['Boolean']['output']>;
};

export type InstrumentModelLegsItemCashFlowsItem = {
  __typename?: 'InstrumentModelLegsItemCashFlowsItem';
  endDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  exDate: Scalars['GraphQLPatchInstrumentDate']['output'];
  fixings?: Maybe<Array<InstrumentModelLegsItemCashFlowsItemFixingsItem>>;
  notional: Scalars['Float']['output'];
  payDate: Scalars['GraphQLPatchInstrumentDate']['output'];
  startDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
};

export type InstrumentModelLegsItemCashFlowsItemFixingsItem = {
  __typename?: 'InstrumentModelLegsItemCashFlowsItemFixingsItem';
  date: Scalars['GraphQLPatchInstrumentDate']['output'];
  endDate: Scalars['GraphQLPatchInstrumentDate']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  startDate: Scalars['GraphQLPatchInstrumentDate']['output'];
};

export type InstrumentModelLegsItemCashFlowsItemFixingsItemInput = {
  date: Scalars['GraphQLPatchInstrumentDate']['input'];
  endDate: Scalars['GraphQLPatchInstrumentDate']['input'];
  rate?: InputMaybe<Scalars['Float']['input']>;
  startDate: Scalars['GraphQLPatchInstrumentDate']['input'];
};

export type InstrumentModelLegsItemCashFlowsItemInput = {
  endDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
  exDate: Scalars['GraphQLPatchInstrumentDate']['input'];
  fixings?: InputMaybe<Array<InstrumentModelLegsItemCashFlowsItemFixingsItemInput>>;
  notional: Scalars['Float']['input'];
  payDate: Scalars['GraphQLPatchInstrumentDate']['input'];
  startDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
};

export type InstrumentModelLegsItemInput = {
  _t: InstrumentModelLegTypesEnum;
  calendars: Array<Scalars['String']['input']>;
  call?: InputMaybe<Scalars['Boolean']['input']>;
  cashFlows?: InputMaybe<Array<InstrumentModelLegsItemCashFlowsItemInput>>;
  creditSpread?: InputMaybe<Scalars['Float']['input']>;
  currency: CurrencyEnum;
  dayAdjustment: DayAdjustmentEnum;
  dayCount?: InputMaybe<Scalars['String']['input']>;
  exercise?: InputMaybe<Scalars['String']['input']>;
  expiry?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
  fixingType?: InputMaybe<Scalars['String']['input']>;
  flatAdjustment?: InputMaybe<Scalars['Float']['input']>;
  issuerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  longStub?: InputMaybe<Scalars['Boolean']['input']>;
  maturityDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
  maturityPeriod?: InputMaybe<Scalars['String']['input']>;
  multiplier?: InputMaybe<Scalars['Float']['input']>;
  notional?: InputMaybe<Scalars['Int']['input']>;
  payLeg: Scalars['Boolean']['input'];
  rate?: InputMaybe<Scalars['Float']['input']>;
  rateIndexId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  recoveryRate?: InputMaybe<Scalars['Float']['input']>;
  relativeAdjustment?: InputMaybe<Scalars['Float']['input']>;
  rollingDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
  rollingPeriod?: InputMaybe<Scalars['String']['input']>;
  settlementType?: InputMaybe<Scalars['String']['input']>;
  spread?: InputMaybe<Scalars['Float']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
  startDateOffset?: InputMaybe<Scalars['Int']['input']>;
  startPeriod?: InputMaybe<Scalars['String']['input']>;
  strike?: InputMaybe<Scalars['Float']['input']>;
  ultimateForwardRate?: InputMaybe<Scalars['Float']['input']>;
  underlyingId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  underlyings?: InputMaybe<Array<InstrumentModelLegsItemUnderlyingsItemInput>>;
  volatility?: InputMaybe<Scalars['Float']['input']>;
  zeroFloor?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InstrumentModelLegsItemUnderlyingsItem = {
  __typename?: 'InstrumentModelLegsItemUnderlyingsItem';
  instrumentId: Scalars['String']['output'];
  quanto: Scalars['Boolean']['output'];
  startPrice: Scalars['Float']['output'];
  weight: Scalars['Float']['output'];
};

export type InstrumentModelLegsItemUnderlyingsItemInput = {
  instrumentId: Scalars['String']['input'];
  quanto: Scalars['Boolean']['input'];
  startPrice: Scalars['Float']['input'];
  weight: Scalars['Float']['input'];
};

export enum InstrumentModelObjectTypeEnum {
  Account = 'Account',
  Client = 'Client',
  Instrument = 'Instrument',
  Portfolio = 'Portfolio'
}

export enum InstrumentModelTypeEnum {
  /** Balance model is used for bank accounts and such, a placeholder for a currency position. */
  Balance = 'Balance',
  /**
   * Bond model is used for fixed coupon bonds, floating rate bonds and zero coupon bonds.
   * The model needs one fixed amount leg for principal and one coupon leg for coupons.
   * For zero coupon bonds, use fixed rate leg type and 0 coupon.
   */
  Bond = 'Bond',
  /**
   * DO NOT USE! CashFlowEngineering is not implemented.
   * @deprecated DO NOT USE! CashFlowEngineering is not implemented.
   */
  CashFlowEngineering = 'CashFlowEngineering',
  /**
   * CdsBasket model is used for valuation of a custom CDS Basket traded as a warrant, i.e.
   * as a transferable security.
   */
  CdsBasket = 'CdsBasket',
  /** CdsIndex model is used for standard CDS index instruments cleared at a CCP. */
  CdsIndex = 'CdsIndex',
  /**
   * DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2! EquityBasketOption model is used for valuations of
   * equity basket options. Volatility surfaces are stored in system.
   * @deprecated DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2!
   */
  EquityBasketOption = 'EquityBasketOption',
  /**
   * DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2! EquityUnitOption model is used for valuations of
   * single underlying equity options. Volatility surfaces are stored in system.
   * @deprecated DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2!
   */
  EquityUnitOption = 'EquityUnitOption',
  /**
   * DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2! Fta model is used for liability valuation.
   * @deprecated PortfolioSwap is deprecated, use PortfolioSwapX instead
   */
  Fta = 'Fta',
  /**
   * FundExternal model is used for fund instruments not connected to their own portfolio within the system.
   * The valuation has to be provided from external source.
   */
  FundExternal = 'FundExternal',
  /**
   * FundInternal model is used for fund instruments linked to their own portfolio within the system. The issuerId field
   * serves as a link to the portfolio and can be used for more advanced calculations.
   */
  FundInternal = 'FundInternal',
  /**
   * FXSwap model is used for the outright leg of an FX Swap position. Model uses a set of FixedAmountLegs
   * in different currencies.
   */
  FxSwap = 'FxSwap',
  /**
   * GenericDeposit model is used for generic deposit instruments used in curve building.
   * This instrument type should never be connected to a traded position.
   */
  GenericDeposit = 'GenericDeposit',
  /**
   * GenericFxSpot model is used for generic swap instruments used in curve building.
   * This instrument type should never be connected to a traded position.
   */
  GenericFxSpot = 'GenericFxSpot',
  /**
   * GenericFxSwap model is used for generic swap instruments used in curve building.
   * This instrument type should never be connected to a traded position.
   */
  GenericFxSwap = 'GenericFxSwap',
  /**
   * GenericSwap model is used for generic swap instruments used in curve building.
   * This instrument type should never be connected to a traded position.
   */
  GenericSwap = 'GenericSwap',
  /**
   * SHOULD NEVER BE SET MANUALLY! Pending model is set for trades uploaded where no instrument exists in system. A fund can not be valued
   * while an instrument with model type Pending exists in the underlying portfolio.
   */
  Pending = 'Pending',
  /**
   * DEPRECATED! PortfolioSwap model is the OLD Portfolio Swap model based on keeping positions
   * using swap counterparty as owner of portfolio in system.
   * @deprecated PortfolioSwap is deprecated, use PortfolioSwapX instead
   */
  PortfolioSwap = 'PortfolioSwap',
  /** PortfolioSwapX model is the new Portfolio Swap model. */
  PortfolioSwapX = 'PortfolioSwapX',
  /**
   * RealEstate model is a spot valuation model which differentiates Real Estate
   * risk factor instruments from other spot valuation models.
   */
  RealEstate = 'RealEstate',
  /**
   * DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2! Spot model is a generic spot model, please use Stock or RealEstate models instead.
   * @deprecated DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2!
   */
  Spot = 'Spot',
  /**
   * Stock model is a spot valuation model used for all normal equity like instruments, including stock, dividend rights and such.
   * All valuations are done T+0 i.e. no T+2 discounting.
   */
  Stock = 'Stock',
  /** Swap model is used for interest rate swaps. Currently the model does not support OIS-swaps. */
  Swap = 'Swap',
  /**
   * Swaption model is used for valuation of swaptions. Using the call/put and pay/recieve properties
   * of the SwaptionLeg gives call: false = Payer Swaption, payleg: false = sold (short) position.
   */
  Swaption = 'Swaption',
  /**
   * ZeroCouponLinear model is used as an amortizing model for not marked valued corporate certificates
   * and other not market valued zero coupon structures. Valuation is a linear amortizing model towards par.
   */
  ZeroCouponLinear = 'ZeroCouponLinear'
}

export type InstrumentPerformance = {
  __typename?: 'InstrumentPerformance';
  account?: Maybe<PartyAccount>;
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  cashFlows?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  currency?: Maybe<CurrencyEnum>;
  dates?: Maybe<Array<Maybe<Scalars['GraphQLDateString']['output']>>>;
  firstTradeDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  localCurrency?: Maybe<CurrencyEnum>;
  positions?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  series?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  values?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type InstrumentPerformance2 = {
  __typename?: 'InstrumentPerformance2';
  account?: Maybe<PartyAccount>;
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  cashFlows?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  currency?: Maybe<CurrencyEnum>;
  dates?: Maybe<Array<Maybe<Scalars['GraphQLDateString']['output']>>>;
  firstTradeDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  fx?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  positions?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  prices?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  series?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  totalReturns?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  values?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type InstrumentPosition = {
  __typename?: 'InstrumentPosition';
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients?: Maybe<Array<Maybe<Party>>>;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
};

export enum InstrumentPositionType {
  All = 'All',
  CashAccounts = 'CashAccounts',
  Securities = 'Securities'
}

export enum InstrumentProductTypeEnum {
  Account = 'Account',
  /** For PayableReceivable transactions just a balance since no cash involved */
  BalanceAccount = 'BalanceAccount',
  Bond = 'Bond',
  Cash = 'Cash',
  CashAccount = 'CashAccount',
  Fund = 'Fund',
  FutureForward = 'FutureForward',
  FxPair = 'FxPair',
  Index = 'Index',
  InitialMarginAccount = 'InitialMarginAccount',
  Liability = 'Liability',
  None = 'None',
  Option = 'Option',
  PrincipalAccount = 'PrincipalAccount',
  RealEstate = 'RealEstate',
  Shares = 'Shares',
  Swap = 'Swap',
  System = 'System',
  VariationMarginAccount = 'VariationMarginAccount'
}

export type InstrumentSelector = {
  __typename?: 'InstrumentSelector';
  alias?: Maybe<Scalars['String']['output']>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
};

export type InstrumentSelectorInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  instrumentId: Scalars['GraphQLObjectId']['input'];
};

export enum InstrumentStatusEnum {
  Confirmed = 'Confirmed',
  Deleted = 'Deleted',
  Pending = 'Pending'
}

export enum InstrumentTypeEnum {
  Instrument = 'Instrument',
  PartyInstrument = 'PartyInstrument'
}

export type InstrumentValuation = {
  __typename?: 'InstrumentValuation';
  accruedInterest?: Maybe<Scalars['Float']['output']>;
  cleanPrice?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<CurrencyEnum>;
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export enum IorpEnum {
  None = 'None',
  RiskFree = 'RiskFree',
  SOBL = 'SOBL'
}

export type IssuerProgram = {
  __typename?: 'IssuerProgram';
  _id: Scalars['GraphQLObjectId']['output'];
  attachments: Array<Attachment>;
  category?: Maybe<IssuerProgramCategory>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  covered: TrueFalseNone;
  createTimestamp: Scalars['Timestamp']['output'];
  creditStatus: CreditStatusType;
  crossDefault: TrueFalseNone;
  euTaxonomyEnvironmentalObjectives: Array<EuTaxonomyEnvironmentalObjective>;
  guarantor?: Maybe<Party>;
  guarantorId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  instruments: Array<Instrument>;
  issuer?: Maybe<Party>;
  issuerId: Scalars['GraphQLObjectId']['output'];
  name?: Maybe<Scalars['String']['output']>;
  negativePledge: TrueFalseNone;
  /** A list of screens for program */
  screens?: Maybe<Array<Maybe<Screen>>>;
  spoProvider?: Maybe<Party>;
  spoProviderId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  status?: Maybe<DocumentStatusEnum>;
  sustainableDevelopmentGoals?: Maybe<Array<SustainableDevelopmentGoal>>;
  updateTimestamp: Scalars['Timestamp']['output'];
  /** Id of the user that updated/created */
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  useOfProceeds?: Maybe<Array<UseOfProceed>>;
  /** A list of versions of the document */
  versions?: Maybe<Array<Maybe<Scalars['GraphQLJSON']['output']>>>;
};

export enum IssuerProgramCategory {
  Green = 'Green',
  None = 'None',
  Social = 'Social',
  SustainabilityLinked = 'SustainabilityLinked',
  Sustainable = 'Sustainable',
  Transition = 'Transition'
}

export type IssuerProgramInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  category?: InputMaybe<IssuerProgramCategory>;
  clientId: Scalars['GraphQLObjectId']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  covered?: InputMaybe<TrueFalseNone>;
  creditRatings?: InputMaybe<Array<CreditRatingInput>>;
  creditStatus?: InputMaybe<CreditStatus>;
  crossDefault?: InputMaybe<TrueFalseNone>;
  euTaxonomyEnvironmentalObjectives?: InputMaybe<Array<EuTaxonomyEnvironmentalObjective>>;
  guarantorId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  issuerId: Scalars['GraphQLObjectId']['input'];
  name: Scalars['String']['input'];
  negativePledge?: InputMaybe<TrueFalseNone>;
  spoProviderId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  status?: InputMaybe<DocumentStatusEnum>;
  sustainableDevelopmentGoals?: InputMaybe<Array<SustainableDevelopmentGoal>>;
  useOfProceeds?: InputMaybe<Array<UseOfProceed>>;
};

export type IssuerProgramsFilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  issuerIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<DocumentStatusEnum>>;
};

export enum IssuerTypeEnum {
  CreditInstitution = 'CreditInstitution',
  EuCovered = 'EuCovered',
  Government = 'Government',
  None = 'None'
}

export type Job = {
  __typename?: 'Job';
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<JobStateEnum>;
  lastExecutionTime?: Maybe<Scalars['Timestamp']['output']>;
  lastStatusCode?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  nextExecutionTime?: Maybe<Scalars['Timestamp']['output']>;
  resultUrl?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export enum JobResultEnum {
  Enqueued = 'Enqueued',
  Failure = 'Failure',
  Processing = 'Processing',
  Running = 'Running',
  Succeeded = 'Succeeded'
}

export enum JobStateEnum {
  disabled = 'disabled',
  enabled = 'enabled'
}

export type JournalEntry = {
  __typename?: 'JournalEntry';
  _id: Scalars['GraphQLObjectId']['output'];
  accountingRunId: Scalars['GraphQLObjectId']['output'];
  batch?: Maybe<AccountingBatchType>;
  clientId: Scalars['GraphQLObjectId']['output'];
  description?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  /** External verification number */
  externalNumber?: Maybe<Scalars['Int']['output']>;
  /** Batch specific verification number */
  number?: Maybe<Scalars['Int']['output']>;
  portfolioTransaction?: Maybe<Transaction>;
  portfolioTransactionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  transactions?: Maybe<Array<AccountingTransaction>>;
};

export type JournalEntryInput = {
  batch?: InputMaybe<AccountingBatchType>;
  clientId: Scalars['GraphQLObjectId']['input'];
  createTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  createUserId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  externalNumber?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  portfolioTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  transactions?: InputMaybe<Array<AccountingTransactionInput>>;
};

export type LimitData = {
  __typename?: 'LimitData';
  /** // Risk data */
  creditYieldDelta?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  custodian?: Maybe<Scalars['String']['output']>;
  /** // Exposure */
  exposure?: Maybe<Scalars['Float']['output']>;
  externalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  externalAccountName?: Maybe<Scalars['String']['output']>;
  externalAccountType?: Maybe<PartyExternalAccountType>;
  fxRate?: Maybe<Scalars['Float']['output']>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  instrumentLongName?: Maybe<Scalars['String']['output']>;
  instrumentName?: Maybe<Scalars['String']['output']>;
  interestRateYieldDelta?: Maybe<Scalars['Float']['output']>;
  /** // Eligible asset categories under UCITS */
  isAncillaryLiquidAsset?: Maybe<Scalars['Boolean']['output']>;
  isCashAccount?: Maybe<Scalars['Boolean']['output']>;
  /** // Position type */
  isCollateral?: Maybe<Scalars['Boolean']['output']>;
  isCollateralInitialMargin?: Maybe<Scalars['Boolean']['output']>;
  isCreditInstitution?: Maybe<Scalars['Boolean']['output']>;
  isDeposit?: Maybe<Scalars['Boolean']['output']>;
  isDerivative?: Maybe<Scalars['Boolean']['output']>;
  isEquity?: Maybe<Scalars['Boolean']['output']>;
  isEuCovered?: Maybe<Scalars['Boolean']['output']>;
  isFinancialIndex?: Maybe<Scalars['Boolean']['output']>;
  isFund?: Maybe<Scalars['Boolean']['output']>;
  isGovernment?: Maybe<Scalars['Boolean']['output']>;
  isGreenBond?: Maybe<Scalars['Boolean']['output']>;
  isInterestBearing?: Maybe<Scalars['Boolean']['output']>;
  isLookThrough?: Maybe<Scalars['Boolean']['output']>;
  isMoneyMarket?: Maybe<Scalars['Boolean']['output']>;
  isNonGuaranteed?: Maybe<Scalars['Boolean']['output']>;
  isNonVoting?: Maybe<Scalars['Boolean']['output']>;
  isOtc?: Maybe<Scalars['Boolean']['output']>;
  isSecurity?: Maybe<Scalars['Boolean']['output']>;
  isTransferableSecurity?: Maybe<Scalars['Boolean']['output']>;
  isTsmmEmbeddedDerivative?: Maybe<Scalars['Boolean']['output']>;
  isUcitsFund?: Maybe<Scalars['Boolean']['output']>;
  /** // Instrument properties */
  isUnlisted?: Maybe<Scalars['Boolean']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  /** // Issuer data */
  issuerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  issuerName?: Maybe<Scalars['String']['output']>;
  localExposure?: Maybe<Scalars['Float']['output']>;
  localValue?: Maybe<Scalars['Float']['output']>;
  parentIssuerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  portfolioCurrency?: Maybe<Scalars['String']['output']>;
  /** // Meta data - For visibility */
  portfolioName?: Maybe<Scalars['String']['output']>;
  positionType?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  separatedInitialMargin?: Maybe<Scalars['Boolean']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type LimitedUserFilterInput = {
  roleAsset?: InputMaybe<PermissionAssetEnum>;
  status?: InputMaybe<UserStatusEnum>;
};

export enum MappingCollectionNameEnum {
  CurveDefinition = 'CurveDefinition'
}

export enum MappingParameterEnum {
  CreditCurve = 'CreditCurve',
  DiscountCurve = 'DiscountCurve',
  FixingCurve = 'FixingCurve',
  ForwardCurve = 'ForwardCurve',
  FxCurve = 'FxCurve'
}

export enum MappingTypeEnum {
  Currency = 'Currency',
  Instrument = 'Instrument',
  IssuerProgram = 'IssuerProgram',
  Model = 'Model'
}

export type MarginRequirement = {
  __typename?: 'MarginRequirement';
  _id: Scalars['GraphQLObjectId']['output'];
  agreementType: AgreementType;
  amount: Scalars['Float']['output'];
  client: Party;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  currency: CurrencyEnum;
  date: Scalars['GraphQLDateString']['output'];
  externalId: Scalars['String']['output'];
  source: Party;
  sourceId: Scalars['GraphQLObjectId']['output'];
  type: MarginRequirementType;
  updateTimestamp: Scalars['Timestamp']['output'];
};

export enum MarginRequirementType {
  InitialMarginRequirement = 'InitialMarginRequirement',
  VariationMarginRequirement = 'VariationMarginRequirement'
}

export enum MarginType {
  InitialMargin = 'InitialMargin',
  VariationMargin = 'VariationMargin'
}

export type MatchBankAccountTransactionsInput = {
  bankAccountTransactionError?: InputMaybe<Scalars['String']['input']>;
  bankAccountTransactionIds: Array<Scalars['GraphQLObjectId']['input']>;
  matchingStatus: MatchingStatus;
  transactionIdentifierIds: Array<TransactionIdentifier>;
  transactionItemError?: InputMaybe<Scalars['String']['input']>;
};

export type MatchCoacsTransactionsInput = {
  matchingStatus?: InputMaybe<MatchingCoacsStatus>;
  swiftExternalId?: InputMaybe<Scalars['String']['input']>;
  swiftTransactionError?: InputMaybe<Scalars['String']['input']>;
  swiftTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  transactionError?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};

export type MatchTransactionsInput = {
  brokerExternalId?: InputMaybe<Scalars['String']['input']>;
  brokerTransactionError?: InputMaybe<Scalars['String']['input']>;
  brokerTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  matchingStatus?: InputMaybe<MatchingStatus>;
  transactionError?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};

export type MatchedBankAccountTransaction = {
  __typename?: 'MatchedBankAccountTransaction';
  bankAccountTransactionIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  bankAccountTransactions?: Maybe<Array<BankAccountTransaction>>;
  transactionItemIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  transactionItems?: Maybe<Array<TransactionItem>>;
};

export type MatchedCoacsTransaction = {
  __typename?: 'MatchedCoacsTransaction';
  swiftTransaction?: Maybe<MiniSwift>;
  transaction?: Maybe<Transaction>;
};

export type MatchedTransaction = {
  __typename?: 'MatchedTransaction';
  brokerTransaction?: Maybe<BrokerTransaction>;
  transaction?: Maybe<Transaction>;
};

export enum MatchingCoacsStatus {
  Confirmed = 'Confirmed',
  /** Matched with a transaction but found at least on error */
  Matched = 'Matched',
  Mismatch = 'Mismatch',
  Preliminary = 'Preliminary',
  /** Messages of type 566 gets status settled when matched */
  Settled = 'Settled'
}

export enum MatchingStatus {
  Confirmed = 'Confirmed',
  Deleted = 'Deleted',
  /** Matched with a transaction but found at least on error */
  Matched = 'Matched',
  Mismatch = 'Mismatch'
}

export type Me = {
  __typename?: 'Me';
  _id: Scalars['GraphQLObjectId']['output'];
  bidExpiration?: Maybe<Scalars['Int']['output']>;
  company: Scalars['String']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  enabled: Scalars['Boolean']['output'];
  frontendRole: Role;
  frontendRoleId: Scalars['GraphQLObjectId']['output'];
  lastBankIdLoginTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  lastLoginTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  lastPasswordUpdate?: Maybe<Scalars['Timestamp']['output']>;
  name: Scalars['String']['output'];
  noteIds: Array<Scalars['GraphQLObjectId']['output']>;
  notes: Array<Note>;
  personalNumber: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  roleIds: Array<Scalars['GraphQLObjectId']['output']>;
  roles?: Maybe<Array<Role>>;
  status: UserStatusEnum;
  /** Latest terms of service from notes collection */
  termsOfService?: Maybe<Note>;
  unreadTicketsCount?: Maybe<Scalars['Int']['output']>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
};

export type MiniSwift = {
  __typename?: 'MiniSwift';
  _id: Scalars['GraphQLObjectId']['output'];
  /** Gross amount */
  amount?: Maybe<Scalars['Float']['output']>;
  caev: Scalars['String']['output'];
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  correspondingTransaction?: Maybe<Transaction>;
  correspondingTransactionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  createTimestamp: Scalars['Timestamp']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  exDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  externalId: Scalars['String']['output'];
  /** Tax amount */
  foreignTax?: Maybe<Scalars['Float']['output']>;
  fund?: Maybe<Party>;
  /** Id of fund */
  fundId: Scalars['GraphQLObjectId']['output'];
  instrument?: Maybe<Instrument>;
  isin?: Maybe<Scalars['String']['output']>;
  msgType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parsed: Scalars['GraphQLJSON']['output'];
  paymentDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  recordDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  /**
   * Currently the swift original is a report. We want to move it to
   * the "text" field.
   */
  reportIds: Array<Scalars['GraphQLObjectId']['output']>;
  status: SwiftStatusEnum;
  text: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
};

export type MiniSwiftFilterInput = {
  /** Corporate action event */
  caev?: InputMaybe<Array<Scalars['String']['input']>>;
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  /** Corresponding transaction */
  correspondingTransactionId?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  /** Corporate action event reference */
  externalId?: InputMaybe<Array<Scalars['String']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  /** Message type */
  msgType?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Record date, format: YYYY-MM-DD */
  rdteEnd?: InputMaybe<Scalars['String']['input']>;
  /** Record date, format: YYYY-MM-DD */
  rdteStart?: InputMaybe<Scalars['String']['input']>;
  statusIn?: InputMaybe<Array<SwiftStatusEnum>>;
};

export type MiniTransaction = {
  __typename?: 'MiniTransaction';
  _id: Scalars['GraphQLObjectId']['output'];
  account?: Maybe<PartyAccount>;
  /** For partyInstruments with accountId set on the instrument, that accountId is used otherwise the accountId in input is used */
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** For BondTrade */
  accruedInterest?: Maybe<Scalars['Float']['output']>;
  /** For DerivativeTrade/ Collateral */
  agreementType?: Maybe<AgreementType>;
  /**
   * Amount is used for all types except:
   * collateral, stockTrade, bondTrade, derivativeTrade
   */
  amount?: Maybe<Scalars['Float']['output']>;
  attachments?: Maybe<Array<Attachment>>;
  bestExecutionType?: Maybe<BestExecutionType>;
  /** For CreateRedeem/FundTrade */
  bonusShares?: Maybe<Scalars['Float']['output']>;
  broker?: Maybe<Party>;
  brokerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  brokerTradeId?: Maybe<Scalars['String']['output']>;
  brokerTransaction?: Maybe<BrokerTransaction>;
  carryOwnCostDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  clearingBroker?: Maybe<Party>;
  /** For agreementTypes OmnibusClearingAccount and ClearingAccount */
  clearingBrokerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  clearingHouse?: Maybe<Party>;
  clearingHouseId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  client?: Maybe<Party>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** For StockTrade / BondTrade / FundTrade */
  commission?: Maybe<Scalars['Float']['output']>;
  corporateActionSwift?: Maybe<MiniSwift>;
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  currency?: Maybe<CurrencyEnum>;
  description?: Maybe<Scalars['String']['output']>;
  dividendPaid?: Maybe<Scalars['Boolean']['output']>;
  effectiveDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  externalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  /** For Dividend */
  foreignTax?: Maybe<Scalars['Float']['output']>;
  fxRate?: Maybe<Scalars['Float']['output']>;
  /** For Collateral */
  initialMargin?: Maybe<Scalars['Boolean']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** For Fee/Interest */
  itemType?: Maybe<FeeInterestItemType>;
  loan?: Maybe<Scalars['Boolean']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** Read only for now, related to portfolio swap (stockTrade) */
  payableReceivable?: Maybe<Scalars['Float']['output']>;
  /** For StockTrade / BondTrade / DerivativeTrade */
  price?: Maybe<Scalars['Float']['output']>;
  /** For StockTrade / BondTrade / DerivativeTrade/ Collateral */
  quantity?: Maybe<Scalars['Float']['output']>;
  reinvestedDividend?: Maybe<Scalars['Boolean']['output']>;
  reinvestedRebate?: Maybe<Scalars['Boolean']['output']>;
  /** Used for when counterparties round settlementAmount */
  rounding?: Maybe<Scalars['Float']['output']>;
  /** If not stated, will be set to accountId or default on party instrument */
  secondAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  secondAmount?: Maybe<Scalars['Float']['output']>;
  secondCurrency?: Maybe<Scalars['String']['output']>;
  secondExternalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** For Transfer */
  secondInstrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** SettlementAmount is used for all types except collateral and transfer */
  settlementAmount?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<SourceType>;
  /** For StockTrade / BondTrade */
  stampDuty?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<TransactionStatus>;
  taxRestitution?: Maybe<Scalars['Boolean']['output']>;
  tradeDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  tradeTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  type?: Maybe<TransactionType>;
  uniqueTradeId?: Maybe<Scalars['String']['output']>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  valueDate?: Maybe<Scalars['GraphQLDateString']['output']>;
};

/**
 * ModelIndexBenchmark calculated within the Party.accounts computed property.
 * Uses introspection to decide calculation and parameter. Thus if type name
 * or parameters to timeSeries are altered this must be mirrored under
 * Party.accounts
 */
export type ModelIndexBenchmark = {
  __typename?: 'ModelIndexBenchmark';
  currency?: Maybe<CurrencyEnum>;
  name: Scalars['String']['output'];
  timeSeries?: Maybe<TimeSeries>;
};


/**
 * ModelIndexBenchmark calculated within the Party.accounts computed property.
 * Uses introspection to decide calculation and parameter. Thus if type name
 * or parameters to timeSeries are altered this must be mirrored under
 * Party.accounts
 */
export type ModelIndexBenchmarkTimeSeriesArgs = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type ModelIndexBenchmarkInput = {
  name: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTerms?: Maybe<User>;
  addTimeSerieItems?: Maybe<TimeSeries>;
  changePassword?: Maybe<UserInfo>;
  cleanPerformanceCache?: Maybe<Scalars['Boolean']['output']>;
  cloneTAccountChart?: Maybe<TAccountChart>;
  createAccountingRun?: Maybe<AccountingRun>;
  createAttachment?: Maybe<Attachment>;
  createCdsValuations?: Maybe<Array<Maybe<Valuation>>>;
  createCurveDefinition?: Maybe<CurveDefinition>;
  createExposureCollateral: ExposureCollateral;
  createInstrument?: Maybe<Instrument>;
  createIssuerProgram?: Maybe<IssuerProgram>;
  createJournalEntry?: Maybe<JournalEntry>;
  createManagementCostTransactions?: Maybe<Array<Maybe<MiniTransaction>>>;
  createMiniTransactions?: Maybe<Array<Maybe<MiniTransaction>>>;
  createNav?: Maybe<Nav>;
  createPartyAccount?: Maybe<Party>;
  createPartyBase?: Maybe<Party>;
  createReport?: Maybe<Report>;
  createRole?: Maybe<Role>;
  createScreen?: Maybe<Screen>;
  createSwift?: Maybe<Swift>;
  createTAccountChart?: Maybe<TAccountChart>;
  createTransaction?: Maybe<Transaction>;
  /** createUpdateExternalAccount also creates partyInstruments */
  createUpdateExternalAccount?: Maybe<PartyExternalAccount>;
  createUpdatePartyCashOrBalanceInstrument?: Maybe<Instrument>;
  createUser?: Maybe<User>;
  createValuation?: Maybe<Valuation>;
  databaseMigration?: Maybe<Scalars['Boolean']['output']>;
  defaultMutation?: Maybe<Scalars['String']['output']>;
  deleteAttachment?: Maybe<Scalars['Boolean']['output']>;
  deleteCurveDefinition?: Maybe<Scalars['Boolean']['output']>;
  deleteInstrument?: Maybe<Scalars['Boolean']['output']>;
  deleteIssuerProgram?: Maybe<Scalars['Boolean']['output']>;
  deleteJournalEntry?: Maybe<Scalars['Boolean']['output']>;
  deleteManagementCostTransactions?: Maybe<Scalars['Boolean']['output']>;
  deleteNav?: Maybe<Scalars['Boolean']['output']>;
  deleteParty?: Maybe<Scalars['Boolean']['output']>;
  deletePartyAccount?: Maybe<Scalars['Boolean']['output']>;
  deletePartyExternalAccount?: Maybe<Scalars['Boolean']['output']>;
  deleteReport?: Maybe<Scalars['Boolean']['output']>;
  deleteReports?: Maybe<Scalars['Boolean']['output']>;
  deleteScreen?: Maybe<Scalars['Boolean']['output']>;
  deleteTAccountChart?: Maybe<Scalars['Boolean']['output']>;
  deleteTimeSerie?: Maybe<Scalars['Boolean']['output']>;
  deleteTransaction?: Maybe<Scalars['Boolean']['output']>;
  deleteTransferAgentOrder?: Maybe<Scalars['Boolean']['output']>;
  deleteValuation?: Maybe<Scalars['Boolean']['output']>;
  derivativesToPrices?: Maybe<Scalars['Boolean']['output']>;
  derivativesToValuations?: Maybe<Scalars['Boolean']['output']>;
  fxPricesToValuations?: Maybe<Scalars['Boolean']['output']>;
  /** fxQuotesToPrices mutation converts fx-quotes to prices and calculates relevant fx-crosses */
  fxQuotesToPrices?: Maybe<Scalars['Boolean']['output']>;
  /** If aggregated amount the created transaction amount will be from current position to input amount */
  importAccruedInterestPortfolioSwapX?: Maybe<Array<Maybe<MiniTransaction>>>;
  importBondTrade?: Maybe<MiniTransaction>;
  importCloseDerivativeTrade?: Maybe<MiniTransaction>;
  importCloseResetPortfolioSwapX?: Maybe<Array<Maybe<MiniTransaction>>>;
  /** NOT creating default valuations if none exists for Insert transactions + Collateral transactions */
  importCollateral?: Maybe<MiniTransaction>;
  importCoupon?: Maybe<MiniTransaction>;
  importCreateRedeems?: Maybe<Array<Maybe<MiniTransaction>>>;
  importDerivativeTrade?: Maybe<MiniTransaction>;
  importDividend?: Maybe<MiniTransaction>;
  importDividendPortfolioSwapX?: Maybe<Array<Maybe<MiniTransaction>>>;
  importFeeInterests?: Maybe<Array<Maybe<MiniTransaction>>>;
  importFundTrades?: Maybe<Array<Maybe<MiniTransaction>>>;
  importFxSpot?: Maybe<MiniTransaction>;
  importInsertCash?: Maybe<MiniTransaction>;
  importInsertCloseDerivativeTrade?: Maybe<MiniTransaction>;
  importInsertDerivativeTrade?: Maybe<MiniTransaction>;
  importInsertSecurity?: Maybe<MiniTransaction>;
  importInterestPortfolioSwapX?: Maybe<Array<Maybe<MiniTransaction>>>;
  /** Portfolio swap transactions */
  importOpenPortfolioSwapX?: Maybe<Array<Maybe<MiniTransaction>>>;
  importStockTradePortfolioSwapX?: Maybe<Array<Maybe<MiniTransaction>>>;
  importStockTrades?: Maybe<Array<Maybe<MiniTransaction>>>;
  importTAccountChartAccounts?: Maybe<TAccountChart>;
  importTAccountChartMappings?: Maybe<TAccountChart>;
  importTransfers?: Maybe<Array<Maybe<MiniTransaction>>>;
  manuallyMatchBankAccountTransactions: Array<MatchedBankAccountTransaction>;
  matchBankAccountTransactions: Array<MatchedBankAccountTransaction>;
  matchCoacsTransactions: Array<MatchedCoacsTransaction>;
  matchTransactions: Array<MatchedTransaction>;
  pricesToValuations?: Maybe<Scalars['Boolean']['output']>;
  /** quotesToPrices mutation converts quotes to prices using specified logic for each QuoteTypeEnum */
  quotesToPrices?: Maybe<Scalars['Boolean']['output']>;
  roundTransaction?: Maybe<MiniTransaction>;
  setRedisKey?: Maybe<Scalars['GraphQLJSON']['output']>;
  updateAccountingRun?: Maybe<AccountingRun>;
  updateAccountingRunStatus?: Maybe<Scalars['Boolean']['output']>;
  updateBrokerTransaction: BrokerTransaction;
  updateCurveDefinition?: Maybe<CurveDefinition>;
  updateExposureCollateral: ExposureCollateral;
  updateInstrument?: Maybe<Instrument>;
  updateIssuerProgram?: Maybe<IssuerProgram>;
  updateJournalEntries?: Maybe<Array<Maybe<JournalEntry>>>;
  updateJournalEntry?: Maybe<JournalEntry>;
  updateMiniTransaction?: Maybe<MiniTransaction>;
  updateNavsPublishedTo?: Maybe<Array<Maybe<Nav>>>;
  updateParty?: Maybe<Party>;
  updatePartyAccount?: Maybe<Party>;
  updatePartyBase?: Maybe<Party>;
  updatePartyExternalAccount?: Maybe<Party>;
  updatePartyFundInfo?: Maybe<Party>;
  updatePartyIssuer?: Maybe<Party>;
  updateReport?: Maybe<Report>;
  updateRole?: Maybe<Role>;
  updateScreen?: Maybe<Screen>;
  updateSwifts?: Maybe<Array<MiniSwift>>;
  updateTAccountChart?: Maybe<TAccountChart>;
  updateTimeSerie?: Maybe<TimeSeries>;
  updateTransactions?: Maybe<Array<Maybe<Transaction>>>;
  updateTransferAgentOrderAttachments?: Maybe<TransferAgentOrder>;
  updateUser?: Maybe<User>;
  upsertAgreements?: Maybe<Array<Maybe<Agreement>>>;
  upsertArrayOfTimeSerieItems: Array<Scalars['Boolean']['output']>;
  upsertBankAccountTransactions: Array<BankAccountTransaction>;
  upsertBaseCalendars?: Maybe<Array<Maybe<BaseCalendar>>>;
  upsertCosts?: Maybe<Array<Maybe<Cost>>>;
  upsertCustodianPositions: Array<CustodianApiPosition>;
  upsertCustomers?: Maybe<Array<Maybe<Customer>>>;
  upsertNotes?: Maybe<Array<Maybe<Note>>>;
  upsertPrices?: Maybe<Array<Maybe<Price>>>;
  upsertQuotes?: Maybe<Array<Maybe<Quote>>>;
  upsertRegisterExtracts?: Maybe<Array<RegisterExtract>>;
  upsertTickets?: Maybe<Array<Maybe<Ticket>>>;
  upsertTimeSerieItems?: Maybe<TimeSeries>;
  upsertTransferAgentOrders?: Maybe<Array<Maybe<TransferAgentOrder>>>;
  upsertValuationMappings?: Maybe<Array<Maybe<ValuationMapping>>>;
  upsertValuations?: Maybe<Array<Maybe<Price>>>;
};


export type MutationAcceptTermsArgs = {
  input: AcceptTermsInput;
};


export type MutationAddTimeSerieItemsArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  items: Array<TimeSeriesItemInput>;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationCleanPerformanceCacheArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type MutationCloneTAccountChartArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAccountingRunArgs = {
  input?: InputMaybe<CreateAccountingRunInput>;
};


export type MutationCreateAttachmentArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  input: CreateAttachmentInput;
};


export type MutationCreateCdsValuationsArgs = {
  cdsTypes?: InputMaybe<Array<CdsType>>;
};


export type MutationCreateCurveDefinitionArgs = {
  input: CreateCurveDefinitionInput;
};


export type MutationCreateExposureCollateralArgs = {
  input: ExposureCollateralInput;
};


export type MutationCreateInstrumentArgs = {
  input: CreateInstrumentInput;
};


export type MutationCreateIssuerProgramArgs = {
  input: IssuerProgramInput;
};


export type MutationCreateJournalEntryArgs = {
  input: CreateJournalEntryInput;
};


export type MutationCreateManagementCostTransactionsArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  date: Scalars['GraphQLDateString']['input'];
  valueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type MutationCreateMiniTransactionsArgs = {
  input: Array<CreateMiniTransactionInput>;
};


export type MutationCreateNavArgs = {
  input: NavInput;
};


export type MutationCreatePartyAccountArgs = {
  input: PartyAccountInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationCreatePartyBaseArgs = {
  input: PartyBaseInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationCreateReportArgs = {
  input: CreateReportInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateScreenArgs = {
  input: ScreenInput;
};


export type MutationCreateSwiftArgs = {
  input: CreateSwiftInput;
};


export type MutationCreateTAccountChartArgs = {
  input?: InputMaybe<CreateTAccountChartInput>;
};


export type MutationCreateTransactionArgs = {
  input: CreateTransactionInput;
};


export type MutationCreateUpdateExternalAccountArgs = {
  input: CreateUpdateExternalAccountInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationCreateUpdatePartyCashOrBalanceInstrumentArgs = {
  input: CreateUpdatePartyCashOrBalanceInstrumentInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateValuationArgs = {
  input: CreateValuationInput;
};


export type MutationDeleteAttachmentArgs = {
  fileId: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteCurveDefinitionArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteInstrumentArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteIssuerProgramArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteJournalEntryArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteManagementCostTransactionsArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  date: Scalars['GraphQLDateString']['input'];
};


export type MutationDeleteNavArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeletePartyArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeletePartyAccountArgs = {
  accountId: Scalars['GraphQLObjectId']['input'];
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeletePartyExternalAccountArgs = {
  externalAccountId: Scalars['GraphQLObjectId']['input'];
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteReportArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteReportsArgs = {
  hard?: InputMaybe<Scalars['Boolean']['input']>;
  idIn: Array<Scalars['GraphQLObjectId']['input']>;
};


export type MutationDeleteScreenArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteTAccountChartArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteTimeSerieArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteTransactionArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteTransferAgentOrderArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteValuationArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDerivativesToPricesArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  instrumentIdIn: Array<Scalars['GraphQLObjectId']['input']>;
};


export type MutationDerivativesToValuationsArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  instrumentIdIn: Array<Scalars['GraphQLObjectId']['input']>;
};


export type MutationFxPricesToValuationsArgs = {
  baseCurrency: CurrencyEnum;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  instrumentIdIn: Array<Scalars['GraphQLObjectId']['input']>;
};


export type MutationFxQuotesToPricesArgs = {
  baseCurrency: CurrencyEnum;
  date: Scalars['GraphQLDateString']['input'];
  instrumentIdIn: Array<Scalars['GraphQLObjectId']['input']>;
};


export type MutationImportAccruedInterestPortfolioSwapXArgs = {
  aggregatedAmount: Scalars['Boolean']['input'];
  portfolioInterestInput: ImportFeeInterestInput;
};


export type MutationImportBondTradeArgs = {
  input: ImportBondTradeInput;
};


export type MutationImportCloseDerivativeTradeArgs = {
  input: ImportCloseDerivativeTradeInput;
};


export type MutationImportCloseResetPortfolioSwapXArgs = {
  accountAlias: Scalars['String']['input'];
  date: Scalars['GraphQLDateString']['input'];
  portfolioSwapAlias: Scalars['String']['input'];
  reset: Scalars['Boolean']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};


export type MutationImportCollateralArgs = {
  input: ImportCollateralInput;
};


export type MutationImportCouponArgs = {
  input: ImportCouponInput;
};


export type MutationImportCreateRedeemsArgs = {
  input?: InputMaybe<Array<ImportCreateRedeemInput>>;
};


export type MutationImportDerivativeTradeArgs = {
  input: ImportDerivativeTradeInput;
};


export type MutationImportDividendArgs = {
  input: ImportDividendInput;
};


export type MutationImportDividendPortfolioSwapXArgs = {
  portfolioSwapAlias: Scalars['String']['input'];
  stockDividendInput: ImportDividendInput;
};


export type MutationImportFeeInterestsArgs = {
  input: Array<ImportFeeInterestInput>;
};


export type MutationImportFundTradesArgs = {
  input: Array<ImportFundTradeInput>;
};


export type MutationImportFxSpotArgs = {
  input: ImportFxSpotInput;
};


export type MutationImportInsertCashArgs = {
  input: ImportInsertCashInput;
};


export type MutationImportInsertCloseDerivativeTradeArgs = {
  input: ImportCloseDerivativeTradeInput;
};


export type MutationImportInsertDerivativeTradeArgs = {
  input: ImportInsertDerivativeTradeInput;
};


export type MutationImportInsertSecurityArgs = {
  input: ImportInsertSecurityInput;
};


export type MutationImportInterestPortfolioSwapXArgs = {
  portfolioInterestInput: ImportFeeInterestInput;
};


export type MutationImportOpenPortfolioSwapXArgs = {
  stockInput: Array<ImportStockTradeInput>;
  swapInput: ImportDerivativeTradeInput;
};


export type MutationImportStockTradePortfolioSwapXArgs = {
  portfolioSwapAlias: Scalars['String']['input'];
  settlementAmount?: InputMaybe<Scalars['Float']['input']>;
  stockInput: ImportStockTradeInput;
};


export type MutationImportStockTradesArgs = {
  input: Array<ImportStockTradeInput>;
};


export type MutationImportTAccountChartAccountsArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  csv: Scalars['String']['input'];
  delimiter?: InputMaybe<Scalars['String']['input']>;
};


export type MutationImportTAccountChartMappingsArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  csv: Scalars['String']['input'];
  delimiter?: InputMaybe<Scalars['String']['input']>;
};


export type MutationImportTransfersArgs = {
  input: Array<ImportTransferInput>;
};


export type MutationManuallyMatchBankAccountTransactionsArgs = {
  input: Array<MatchBankAccountTransactionsInput>;
};


export type MutationMatchBankAccountTransactionsArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type MutationMatchCoacsTransactionsArgs = {
  input: Array<MatchCoacsTransactionsInput>;
};


export type MutationMatchTransactionsArgs = {
  input: Array<MatchTransactionsInput>;
};


export type MutationPricesToValuationsArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  instrumentIdIn: Array<Scalars['GraphQLObjectId']['input']>;
};


export type MutationQuotesToPricesArgs = {
  date: Scalars['GraphQLDateString']['input'];
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type MutationRoundTransactionArgs = {
  input: RoundTransactionInput;
};


export type MutationSetRedisKeyArgs = {
  data: Scalars['GraphQLJSON']['input'];
  key: Scalars['String']['input'];
};


export type MutationUpdateAccountingRunArgs = {
  input?: InputMaybe<UpdateAccountingRunInput>;
};


export type MutationUpdateAccountingRunStatusArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  status: AccountingRunStatusEnum;
};


export type MutationUpdateBrokerTransactionArgs = {
  input: UpdateBrokerTransactionInput;
};


export type MutationUpdateCurveDefinitionArgs = {
  input: UpdateCurveDefinitionInput;
};


export type MutationUpdateExposureCollateralArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  input: ExposureCollateralInput;
};


export type MutationUpdateInstrumentArgs = {
  input: UpdateInstrumentInput;
};


export type MutationUpdateIssuerProgramArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  input: IssuerProgramInput;
};


export type MutationUpdateJournalEntriesArgs = {
  input: Array<UpdateJournalEntryInput>;
};


export type MutationUpdateJournalEntryArgs = {
  input: UpdateJournalEntryInput;
};


export type MutationUpdateMiniTransactionArgs = {
  input: UpdateMiniTransactionInput;
};


export type MutationUpdateNavsPublishedToArgs = {
  publishedToInput: Array<InputMaybe<PublishedToInput>>;
};


export type MutationUpdatePartyArgs = {
  input: UpdatePartyInput;
};


export type MutationUpdatePartyAccountArgs = {
  input: PartyAccountInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationUpdatePartyBaseArgs = {
  input: PartyBaseInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationUpdatePartyExternalAccountArgs = {
  input: PartyExternalAccountInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationUpdatePartyFundInfoArgs = {
  input: PartyFundInfoInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationUpdatePartyIssuerArgs = {
  input: PartyIssuerInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationUpdateReportArgs = {
  input: UpdateReportInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateScreenArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  input: ScreenInput;
};


export type MutationUpdateSwiftsArgs = {
  input: Array<UpdateSwiftInput>;
};


export type MutationUpdateTAccountChartArgs = {
  input?: InputMaybe<UpdateTAccountChartInput>;
};


export type MutationUpdateTimeSerieArgs = {
  input: TimeSeriesInput;
};


export type MutationUpdateTransactionsArgs = {
  input: Array<UpdateTransactionInput>;
};


export type MutationUpdateTransferAgentOrderAttachmentsArgs = {
  input: TransferAgentOrderAttachmentsInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpsertAgreementsArgs = {
  input: Array<AgreementInput>;
};


export type MutationUpsertArrayOfTimeSerieItemsArgs = {
  data: Array<TimeSeriesUpsertInput>;
};


export type MutationUpsertBankAccountTransactionsArgs = {
  input: Array<BankAccountTransactionInput>;
};


export type MutationUpsertBaseCalendarsArgs = {
  input: Array<BaseCalendarInput>;
};


export type MutationUpsertCostsArgs = {
  input: Array<CostInput>;
};


export type MutationUpsertCustodianPositionsArgs = {
  input: Array<CustodianApiPositionInput>;
};


export type MutationUpsertCustomersArgs = {
  input: Array<CustomerInput>;
};


export type MutationUpsertNotesArgs = {
  input: Array<NoteInput>;
};


export type MutationUpsertPricesArgs = {
  input: Array<PriceInput>;
};


export type MutationUpsertQuotesArgs = {
  input: Array<QuoteInput>;
};


export type MutationUpsertRegisterExtractsArgs = {
  input: Array<RegisterExtractInput>;
};


export type MutationUpsertTicketsArgs = {
  input: Array<TicketInput>;
};


export type MutationUpsertTimeSerieItemsArgs = {
  instrumentId: Scalars['GraphQLObjectId']['input'];
  items: Array<TimeSeriesItemInput>;
  type: Scalars['String']['input'];
};


export type MutationUpsertTransferAgentOrdersArgs = {
  input: Array<TransferAgentOrderInput>;
};


export type MutationUpsertValuationMappingsArgs = {
  input: Array<ValuationMappingInput>;
};


export type MutationUpsertValuationsArgs = {
  input: Array<PriceInput>;
};

export type Nav = {
  __typename?: 'Nav';
  _id: Scalars['GraphQLObjectId']['output'];
  /** totalNavBeforeAdjustmentsBeforeOrders must differ less from frontOfficeNav than absHighTolerancePercent */
  absHighTolerancePercent?: Maybe<Scalars['String']['output']>;
  /** if totalNavBeforeAdjustmentsBeforeOrders differs more from frontOfficeNav than absLowTolerancePercent, a comment is required */
  absLowTolerancePercent?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  comment: Scalars['String']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  data: NavData;
  date: Scalars['GraphQLDateString']['output'];
  frontOfficeNav: Scalars['String']['output'];
  /** Copied from Party.fundInfo.riskLevel when calculating NAV */
  fundRiskLevel?: Maybe<Scalars['String']['output']>;
  /** Added when jobs send out navs to external parties */
  publishedTo?: Maybe<Array<PublishedToType>>;
  status: ReportStatusEnum;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo?: Maybe<UserInfo>;
};

export type NavData = {
  __typename?: 'NavData';
  adjustmentAmountPositions: Array<NavPosition>;
  classes: Array<NavFundClass>;
  orders: Array<NavOrder>;
  positions: Array<NavPosition>;
  transactions: Array<NavTransaction>;
};

export type NavDataInput = {
  adjustmentAmountPositions: Array<NavPositionInput>;
  classes: Array<NavFundClassInput>;
  orders: Array<NavOrderInput>;
  positions: Array<NavPositionInput>;
  transactions: Array<NavTransactionInput>;
};

export type NavFundClass = {
  __typename?: 'NavFundClass';
  adjustmentAmount: Scalars['String']['output'];
  dividendPaid: Scalars['String']['output'];
  fxHedge: Scalars['String']['output'];
  fxRate: Scalars['String']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  managementFee: Scalars['String']['output'];
  name: Scalars['String']['output'];
  navAfterAdjustmentsAfterOrders: Scalars['String']['output'];
  navAfterAdjustmentsBeforeOrders: Scalars['String']['output'];
  navBeforeAdjustmentsBeforeOrders: Scalars['String']['output'];
  navCurrency: CurrencyEnum;
  navPerUnit: Scalars['String']['output'];
  navPerUnitReturn1D: Scalars['String']['output'];
  unitsAfterOrders: Scalars['String']['output'];
  unitsBeforeOrders: Scalars['String']['output'];
  weight: Scalars['String']['output'];
};

export type NavFundClassInput = {
  adjustmentAmount: Scalars['String']['input'];
  dividendPaid: Scalars['String']['input'];
  fxHedge: Scalars['String']['input'];
  fxRate: Scalars['String']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  managementFee: Scalars['String']['input'];
  name: Scalars['String']['input'];
  navAfterAdjustmentsAfterOrders: Scalars['String']['input'];
  navAfterAdjustmentsBeforeOrders: Scalars['String']['input'];
  navBeforeAdjustmentsBeforeOrders: Scalars['String']['input'];
  navCurrency: CurrencyEnum;
  navPerUnit: Scalars['String']['input'];
  navPerUnitReturn1D: Scalars['String']['input'];
  unitsAfterOrders: Scalars['String']['input'];
  unitsBeforeOrders: Scalars['String']['input'];
  weight: Scalars['String']['input'];
};

export type NavInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** totalNavBeforeAdjustmentsBeforeOrders must differ less from frontOfficeNav than absHighTolerancePercent */
  absHighTolerancePercent?: InputMaybe<Scalars['String']['input']>;
  /** if totalNavBeforeAdjustmentsBeforeOrders differs more from frontOfficeNav than absLowTolerancePercent, a comment is required */
  absLowTolerancePercent?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  comment: Scalars['String']['input'];
  data: NavDataInput;
  date: Scalars['GraphQLDateString']['input'];
  frontOfficeNav: Scalars['String']['input'];
  /** Copied from Party.fundInfo.riskLevel when calculating NAV */
  fundRiskLevel?: InputMaybe<Scalars['String']['input']>;
  status: ReportStatusEnum;
};

export type NavOrder = {
  __typename?: 'NavOrder';
  amount: Scalars['String']['output'];
  carryOwnTransactionCost: Scalars['Boolean']['output'];
  currency: CurrencyEnum;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  isin: Scalars['ISIN']['output'];
  name: Scalars['String']['output'];
  order?: Maybe<TransferAgentOrder>;
  orderId: Scalars['GraphQLObjectId']['output'];
  transactionType: TransferAgentOrderEnum;
  units: Scalars['String']['output'];
  valueDate: Scalars['GraphQLDateString']['output'];
};

export type NavOrderInput = {
  amount: Scalars['String']['input'];
  carryOwnTransactionCost: Scalars['Boolean']['input'];
  currency: CurrencyEnum;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  isin: Scalars['ISIN']['input'];
  name: Scalars['String']['input'];
  orderId: Scalars['GraphQLObjectId']['input'];
  transactionType: TransferAgentOrderEnum;
  units: Scalars['String']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type NavPosition = {
  __typename?: 'NavPosition';
  account?: Maybe<PartyAccount>;
  accountId: Scalars['GraphQLObjectId']['output'];
  currency: CurrencyEnum;
  fxRate: Scalars['String']['output'];
  fxValuationDate: Scalars['GraphQLDateString']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['String']['output'];
  valuationDate: Scalars['GraphQLDateString']['output'];
  value: Scalars['String']['output'];
};

export type NavPositionInput = {
  accountId: Scalars['GraphQLObjectId']['input'];
  currency: CurrencyEnum;
  fxRate: Scalars['String']['input'];
  fxValuationDate: Scalars['GraphQLDateString']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  name: Scalars['String']['input'];
  quantity: Scalars['String']['input'];
  valuationDate: Scalars['GraphQLDateString']['input'];
  value: Scalars['String']['input'];
};

export type NavTransaction = {
  __typename?: 'NavTransaction';
  account?: Maybe<PartyAccount>;
  accountId: Scalars['GraphQLObjectId']['output'];
  accountSwitchOrder: Scalars['Boolean']['output'];
  accountingAmount?: Maybe<Scalars['String']['output']>;
  accountingCurrency?: Maybe<CurrencyEnum>;
  accountingPrice?: Maybe<Scalars['String']['output']>;
  adjustmentAmount: Scalars['String']['output'];
  adjustmentShares: Scalars['String']['output'];
  amount: Scalars['String']['output'];
  currency: CurrencyEnum;
  externalId: Scalars['String']['output'];
  fxRate?: Maybe<Scalars['String']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  isin: Scalars['ISIN']['output'];
  name: Scalars['String']['output'];
  price: Scalars['String']['output'];
  transactionType: NavTransactionType;
  units: Scalars['String']['output'];
  valueDate: Scalars['GraphQLDateString']['output'];
};

export type NavTransactionInput = {
  accountId: Scalars['GraphQLObjectId']['input'];
  accountSwitchOrder: Scalars['Boolean']['input'];
  accountingAmount: Scalars['String']['input'];
  accountingCurrency: CurrencyEnum;
  accountingPrice: Scalars['String']['input'];
  adjustmentAmount: Scalars['String']['input'];
  adjustmentShares: Scalars['String']['input'];
  amount: Scalars['String']['input'];
  currency: CurrencyEnum;
  externalId: Scalars['String']['input'];
  fxRate: Scalars['String']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  isin: Scalars['ISIN']['input'];
  name: Scalars['String']['input'];
  price: Scalars['String']['input'];
  transactionType: NavTransactionType;
  units: Scalars['String']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export enum NavTransactionType {
  CreateRedeemAmount = 'CreateRedeemAmount',
  CreateRedeemUnits = 'CreateRedeemUnits',
  FxSpot = 'FxSpot',
  ManagementFee = 'ManagementFee',
  Transfer = 'Transfer'
}

export enum NonTradingDayFillMethodEnum {
  NIL_VALUE = 'NIL_VALUE',
  PREVIOUS_VALUE = 'PREVIOUS_VALUE'
}

export enum NonTradingDayFillOptionEnum {
  ACTIVE_DAYS_ONLY = 'ACTIVE_DAYS_ONLY',
  ALL_CALENDAR_DAYS = 'ALL_CALENDAR_DAYS',
  NON_TRADING_WEEKDAYS = 'NON_TRADING_WEEKDAYS'
}

export type Note = {
  __typename?: 'Note';
  _id: Scalars['GraphQLObjectId']['output'];
  asset: NoteAssetEnum;
  clientIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  clients: Array<PartyInfo>;
  createTimestamp: Scalars['Timestamp']['output'];
  creator: UserInfo;
  creatorId: Scalars['GraphQLObjectId']['output'];
  /** Markdown or JSON */
  data: Scalars['String']['output'];
  refs: Array<NoteReference>;
  status: NoteStatusEnum;
  tags: Array<NoteTag>;
  title: Scalars['UriPartString']['output'];
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
};

export enum NoteAssetEnum {
  Admin = 'Admin',
  Base = 'Base',
  Esg = 'Esg'
}

export type NoteFilterInput = {
  assetIn?: InputMaybe<Array<NoteAssetEnum>>;
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  collectionIn?: InputMaybe<Array<CollectionNameEnum>>;
  creatorIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  documentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<NoteStatusEnum>>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  valueIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NoteInput = {
  /** if _id is omitted, a Note will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  asset: NoteAssetEnum;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  data: Scalars['String']['input'];
  refs: Array<NoteReferenceInput>;
  status: NoteStatusEnum;
  tags: Array<NoteTagInput>;
  title: Scalars['String']['input'];
};

export type NoteReference = {
  __typename?: 'NoteReference';
  collection: CollectionNameEnum;
  documentId: Scalars['GraphQLObjectId']['output'];
};

export type NoteReferenceInput = {
  collection: CollectionNameEnum;
  documentId: Scalars['GraphQLObjectId']['input'];
};

export enum NoteStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted'
}

export type NoteTag = {
  __typename?: 'NoteTag';
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type NoteTagFilter = {
  collection?: InputMaybe<CollectionNameEnum>;
  documentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type NoteTagInput = {
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type OrderFilterInput = {
  carryOwnTransactionCost?: InputMaybe<Scalars['Boolean']['input']>;
  clientAccountIn?: InputMaybe<Array<Scalars['String']['input']>>;
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  isinIn?: InputMaybe<Array<Scalars['ISIN']['input']>>;
  sourceIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<TransactionStatus>>;
  tradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  tradeDateEnd?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  tradeDateStart?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export enum OverrideOptionEnum {
  OVERRIDE_OPTION_CLOSE = 'OVERRIDE_OPTION_CLOSE',
  OVERRIDE_OPTION_GPA = 'OVERRIDE_OPTION_GPA'
}

export type Party = {
  __typename?: 'Party';
  _id: Scalars['GraphQLObjectId']['output'];
  accountant?: Maybe<Scalars['String']['output']>;
  accountingCompanyType?: Maybe<AccountingCompanyTypeEnum>;
  accountingCurrency?: Maybe<CurrencyEnum>;
  accountingFrequency?: Maybe<AccountingFrequencyEnum>;
  accounts: Array<PartyAccount>;
  address?: Maybe<Scalars['String']['output']>;
  aliases: Array<Alias>;
  approval?: Maybe<Scalars['String']['output']>;
  beneficialOwner?: Maybe<BeneficialOwner>;
  bloombergEquityTicker?: Maybe<Scalars['String']['output']>;
  businessIdentifierCode?: Maybe<Scalars['String']['output']>;
  ceo?: Maybe<Scalars['String']['output']>;
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients: Array<ClientInfo>;
  complaintsManager?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryCodeEnum>;
  creditRatingScale: Array<Scalars['String']['output']>;
  creditRatings: Array<PartyCreditRating>;
  dashName: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalAccounts: Array<PartyExternalAccount>;
  firstTradeDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  fundInfo?: Maybe<PartyFundInfo>;
  inLedgerNumber?: Maybe<Scalars['Int']['output']>;
  inLedgerTradeDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  industryCode?: Maybe<Scalars['String']['output']>;
  instrumentIds: Array<Scalars['GraphQLObjectId']['output']>;
  instruments: Array<Instrument>;
  issuer?: Maybe<PartyIssuerType>;
  issuerType?: Maybe<IssuerTypeEnum>;
  legalEntityIdentifier?: Maybe<Scalars['String']['output']>;
  legalNumber?: Maybe<Scalars['String']['output']>;
  limitSlackChannel?: Maybe<Scalars['String']['output']>;
  limitStartDate?: Maybe<Scalars['Date']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  nace?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  parentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  regulator?: Maybe<Scalars['String']['output']>;
  standardSettlementInstructions: Array<PartyStandardSettlementInstruction>;
  status?: Maybe<PartyStatusEnum>;
  sustainalyticsData?: Maybe<SustainalyticsDataModelWithStatus>;
  sustainalyticsId?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<TimezoneEnum>;
  topAccount?: Maybe<PartyAccount>;
  types: Array<Maybe<PartyType>>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type PartyAccount = {
  __typename?: 'PartyAccount';
  _id: Scalars['GraphQLObjectId']['output'];
  benchmarks: Array<PartyBenchmark>;
  childrenAccounts: Array<PartyAccount>;
  childrenAccountsIds: Array<Scalars['GraphQLObjectId']['output']>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  defaultAccount: Scalars['Boolean']['output'];
  defaultAccruedInstruments?: Maybe<Array<DefaultCashInstrument>>;
  defaultCashInstruments: Array<DefaultCashInstrument>;
  defaultDebtInstruments: Array<DefaultCashInstrument>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * ModelIndexBenchmark calculated within the Party.accounts computed property.
   * Uses introspection to decide calculation and parameter. Thus if type name
   * or parameters to timeSeries are altered this must be mirrored under
   * Party.accounts
   */
  modelIndexBenchmark?: Maybe<ModelIndexBenchmark>;
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  parentAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  securityExternalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  transientCashInstruments: Array<DefaultCashInstrument>;
  type: PartyAccountType;
  weights: Array<PartyAccountWeight>;
};

export type PartyAccountInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  benchmarks?: InputMaybe<Array<PartyBenchmarkInput>>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  defaultAccount?: InputMaybe<Scalars['Boolean']['input']>;
  defaultAccruedInstruments?: InputMaybe<Array<DefaultCashInstrumentInput>>;
  defaultCashInstruments?: InputMaybe<Array<DefaultCashInstrumentInput>>;
  defaultDebtInstruments?: InputMaybe<Array<DefaultCashInstrumentInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  modelIndexBenchmark?: InputMaybe<ModelIndexBenchmarkInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  securityExternalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  transientCashInstruments?: InputMaybe<Array<DefaultCashInstrumentInput>>;
  type: PartyAccountType;
  weights?: InputMaybe<Array<PartyAccountWeightInput>>;
};

export enum PartyAccountType {
  Physical = 'Physical',
  PortfolioInstrument = 'PortfolioInstrument',
  Sum = 'Sum'
}

export type PartyAccountWeight = {
  __typename?: 'PartyAccountWeight';
  date: Scalars['GraphQLDateString']['output'];
  expectedReturn: Scalars['Float']['output'];
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
  model: Scalars['Float']['output'];
};

export type PartyAccountWeightInput = {
  date: Scalars['GraphQLDateString']['input'];
  expectedReturn: Scalars['Float']['input'];
  max: Scalars['Float']['input'];
  min: Scalars['Float']['input'];
  model: Scalars['Float']['input'];
};

export type PartyBaseInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  accountant?: InputMaybe<Scalars['String']['input']>;
  accountingCompanyType?: InputMaybe<AccountingCompanyTypeEnum>;
  accountingCurrency?: InputMaybe<Scalars['String']['input']>;
  accountingFrequency?: InputMaybe<AccountingFrequencyEnum>;
  address?: InputMaybe<Scalars['String']['input']>;
  aliases?: InputMaybe<Array<AliasInput>>;
  approval?: InputMaybe<Scalars['String']['input']>;
  beneficialOwner?: InputMaybe<BeneficialOwnerInput>;
  bloombergEquityTicker?: InputMaybe<Scalars['String']['input']>;
  businessIdentifierCode?: InputMaybe<Scalars['String']['input']>;
  ceo?: InputMaybe<Scalars['String']['input']>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  complaintsManager?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryCodeEnum>;
  creditRatingScale?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  creditRatings?: InputMaybe<Array<InputMaybe<CreditRatingInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  inLedgerNumber?: InputMaybe<Scalars['Int']['input']>;
  inLedgerTradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  industryCode?: InputMaybe<Scalars['String']['input']>;
  issuerType?: InputMaybe<IssuerTypeEnum>;
  legalEntityIdentifier?: InputMaybe<Scalars['String']['input']>;
  legalNumber?: InputMaybe<Scalars['String']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  nace?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  regulator?: InputMaybe<Scalars['String']['input']>;
  standardSettlementInstructions?: InputMaybe<Array<PartyStandardSettlementInstructionInput>>;
  status?: InputMaybe<PartyStatusEnum>;
  sustainalyticsId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<TimezoneEnum>;
  types: Array<PartyType>;
  updateTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type PartyBenchmark = {
  __typename?: 'PartyBenchmark';
  comment: Scalars['String']['output'];
  currency: CurrencyEnum;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  mainBenchmark: Scalars['Boolean']['output'];
  /** Offset real number, i.e. 0.01 = 1% */
  offset: Scalars['Float']['output'];
  showBenchmark: Scalars['Boolean']['output'];
  showExternal: Scalars['Boolean']['output'];
};

export type PartyBenchmarkInput = {
  comment: Scalars['String']['input'];
  currency: CurrencyEnum;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  mainBenchmark?: InputMaybe<Scalars['Boolean']['input']>;
  offset: Scalars['Float']['input'];
  showBenchmark: Scalars['Boolean']['input'];
  showExternal: Scalars['Boolean']['input'];
};

export type PartyCreditRating = {
  __typename?: 'PartyCreditRating';
  creditRatingAgencyId: Scalars['GraphQLObjectId']['output'];
  grade: Scalars['GraphQLCreditRating']['output'];
};

export type PartyEsg = {
  __typename?: 'PartyEsg';
  attachments?: Maybe<Array<Attachment>>;
  comments: Array<EsgComment>;
  globalStandardScreen: Array<GlobalStandardScreen>;
  productInvolvement: Array<ProductInvolvement>;
  shareholderEngagement: Array<ShareholderEngagement>;
};

export type PartyEsgInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  comments: Array<EsgCommentInput>;
  globalStandardScreen: Array<GlobalStandardScreenInput>;
  productInvolvement: Array<ProductInvolvementInput>;
  shareholderEngagement: Array<ShareholderEngagementInput>;
};

export type PartyExternalAccount = {
  __typename?: 'PartyExternalAccount';
  _id: Scalars['GraphQLObjectId']['output'];
  agreement?: Maybe<Agreement>;
  attachments: Array<Attachment>;
  baseCurrency?: Maybe<CurrencyEnum>;
  clearingBroker?: Maybe<Party>;
  /** only for omnibusClearingAccount + clearingAccount */
  clearingBrokerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  clearingHouses?: Maybe<Array<Party>>;
  /** only for omnibusClearingAccounts */
  clearingHousesIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  custodian?: Maybe<Party>;
  custodianAccountId?: Maybe<Scalars['String']['output']>;
  custodianAccountNumber?: Maybe<Scalars['String']['output']>;
  custodianId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  emirReporting: Scalars['Boolean']['output'];
  initialMarginCurrencies?: Maybe<Array<CurrencyEnum>>;
  minimumTransferAmount?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  partyInstrumentIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  partyInstruments?: Maybe<Array<Instrument>>;
  principalCurrencies?: Maybe<Array<CurrencyEnum>>;
  roundingAmount?: Maybe<Scalars['Float']['output']>;
  /** Need to be able to separate collateral securities posted as IM from what's posted as VM */
  separatedInitialMargin?: Maybe<Scalars['Boolean']['output']>;
  settlementOnVMInstrument?: Maybe<Scalars['Boolean']['output']>;
  threshold?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<PartyExternalAccountType>;
  variationMarginCurrencies?: Maybe<Array<CurrencyEnum>>;
};

export type PartyExternalAccountInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  baseCurrency?: InputMaybe<CurrencyEnum>;
  /** only for omnibusClearingAccount + clearingAccount */
  clearingBrokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** only for omnibusClearingAccounts */
  clearingHousesIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  custodianAccountId?: InputMaybe<Scalars['String']['input']>;
  custodianAccountNumber?: InputMaybe<Scalars['String']['input']>;
  custodianId: Scalars['GraphQLObjectId']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  emirReporting?: InputMaybe<Scalars['Boolean']['input']>;
  minimumTransferAmount?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  number: Scalars['Int']['input'];
  roundingAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Need to be able to separate collateral securities posted as IM from what's posted as VM */
  separatedInitialMargin?: InputMaybe<Scalars['Boolean']['input']>;
  settlementOnVMInstrument?: InputMaybe<Scalars['Boolean']['input']>;
  threshold?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<PartyExternalAccountType>;
};

export enum PartyExternalAccountType {
  CashAccount = 'CashAccount',
  ClearingAccount = 'ClearingAccount',
  Equity = 'Equity',
  FundAccount = 'FundAccount',
  Gmra = 'Gmra',
  Gmsla = 'Gmsla',
  Isda = 'Isda',
  OmnibusClearingAccount = 'OmnibusClearingAccount',
  SecuritiesAccount = 'SecuritiesAccount',
  ShareRegistry = 'ShareRegistry'
}

export type PartyFilterInput = {
  custodianAccountNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fundInfoClassInstrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  legalEntityIdentifierIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  statusIn?: InputMaybe<Array<PartyStatusEnum>>;
  typeIn?: InputMaybe<Array<PartyType>>;
  typeNotIn?: InputMaybe<Array<PartyType>>;
  withEnabledFundClasses?: InputMaybe<Scalars['Boolean']['input']>;
  withTransactions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PartyFundInfo = {
  __typename?: 'PartyFundInfo';
  calendar?: Maybe<Calendar>;
  calendarNames?: Maybe<Array<CalendarNameEnum>>;
  classes: Array<Maybe<PartyFundInfoClass>>;
  createRedeemInstrument?: Maybe<Instrument>;
  createRedeemInstrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  custodian?: Maybe<PartyInfo>;
  custodianId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  depositary?: Maybe<PartyInfo>;
  /** A depositary verifies that a fund's assets exist on behalf of shareholders */
  depositaryId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  endDate: Scalars['GraphQLDateString']['output'];
  esgComponents?: Maybe<Array<EsgComponentEnum>>;
  fundCompany?: Maybe<Party>;
  fundCompanyId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  fundDescription?: Maybe<Scalars['String']['output']>;
  fundManager?: Maybe<Scalars['String']['output']>;
  fundReportComponents?: Maybe<Array<FundReportComponentEnum>>;
  fundReportTypes: Array<FundReportEnum>;
  fundType?: Maybe<FundTypeEnum>;
  portfolio?: Maybe<Scalars['String']['output']>;
  riskLevel?: Maybe<Scalars['Int']['output']>;
  ta?: Maybe<PartyInfo>;
  taId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  tradeFrequency?: Maybe<Scalars['String']['output']>;
};


export type PartyFundInfoCalendarArgs = {
  years?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PartyFundInfoClass = {
  __typename?: 'PartyFundInfoClass';
  carryOwnCostPossible?: Maybe<Scalars['Boolean']['output']>;
  cleanClass?: Maybe<Scalars['Boolean']['output']>;
  dividendPaying?: Maybe<Scalars['Boolean']['output']>;
  earlyCloseTradeCutOff?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  fxHedgeAccount?: Maybe<PartyAccount>;
  fxHedgeAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  fxHedged?: Maybe<Scalars['Boolean']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  mainClass?: Maybe<Scalars['Boolean']['output']>;
  managementFee?: Maybe<Scalars['String']['output']>;
  managementFeeStruct?: Maybe<Scalars['String']['output']>;
  minimumInitialSubscription?: Maybe<Scalars['String']['output']>;
  minimumSubscriptionAdded?: Maybe<Scalars['String']['output']>;
  numberOfDecimalsNAVPerUnit?: Maybe<Scalars['Int']['output']>;
  numberOfDecimalsUnits?: Maybe<Scalars['Int']['output']>;
  redemptionFee?: Maybe<Scalars['String']['output']>;
  sIFA_ID?: Maybe<Scalars['String']['output']>;
  sIFA_UNIT?: Maybe<Scalars['String']['output']>;
  sfdr?: Maybe<Scalars['String']['output']>;
  shareClass?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  startNAVPerUnit?: Maybe<Scalars['String']['output']>;
  subscriptionFee?: Maybe<Scalars['String']['output']>;
  tradeCutOff?: Maybe<Scalars['String']['output']>;
};

export type PartyFundInfoClassInput = {
  carryOwnCostPossible?: InputMaybe<Scalars['Boolean']['input']>;
  cleanClass?: InputMaybe<Scalars['Boolean']['input']>;
  dividendPaying?: InputMaybe<Scalars['Boolean']['input']>;
  earlyCloseTradeCutOff?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fxHedgeAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  fxHedged?: InputMaybe<Scalars['Boolean']['input']>;
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  mainClass?: InputMaybe<Scalars['Boolean']['input']>;
  managementFee?: InputMaybe<Scalars['String']['input']>;
  managementFeeStruct?: InputMaybe<Scalars['String']['input']>;
  minimumInitialSubscription?: InputMaybe<Scalars['String']['input']>;
  minimumSubscriptionAdded?: InputMaybe<Scalars['String']['input']>;
  numberOfDecimalsNAVPerUnit?: InputMaybe<Scalars['Int']['input']>;
  numberOfDecimalsUnits?: InputMaybe<Scalars['Int']['input']>;
  redemptionFee?: InputMaybe<Scalars['String']['input']>;
  sIFA_ID?: InputMaybe<Scalars['String']['input']>;
  sIFA_UNIT?: InputMaybe<Scalars['String']['input']>;
  sfdr?: InputMaybe<Scalars['String']['input']>;
  shareClass?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  startNAVPerUnit?: InputMaybe<Scalars['String']['input']>;
  subscriptionFee?: InputMaybe<Scalars['String']['input']>;
  tradeCutOff?: InputMaybe<Scalars['String']['input']>;
};

export type PartyFundInfoInput = {
  calendarNames: Array<CalendarNameEnum>;
  classes: Array<InputMaybe<PartyFundInfoClassInput>>;
  createRedeemInstrumentId: Scalars['GraphQLObjectId']['input'];
  custodianId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  depositaryId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  esgComponents?: InputMaybe<Array<EsgComponentEnum>>;
  fundCompanyId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  fundDescription?: InputMaybe<Scalars['String']['input']>;
  fundManager?: InputMaybe<Scalars['String']['input']>;
  fundReportComponents?: InputMaybe<Array<FundReportComponentEnum>>;
  fundReportTypes?: InputMaybe<Array<FundReportEnum>>;
  fundType?: InputMaybe<FundTypeEnum>;
  portfolio?: InputMaybe<Scalars['String']['input']>;
  riskLevel?: InputMaybe<Scalars['Int']['input']>;
  taId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  tradeFrequency?: InputMaybe<Scalars['String']['input']>;
};

export type PartyInfo = {
  __typename?: 'PartyInfo';
  _id: Scalars['GraphQLObjectId']['output'];
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  name: Scalars['String']['output'];
};

export type PartyIssuerInput = {
  activitiesAffectingBiodiversityAreas?: InputMaybe<Scalars['Boolean']['input']>;
  captorDnshCompliant?: InputMaybe<Scalars['Boolean']['input']>;
  carbonFootprint?: InputMaybe<Scalars['Boolean']['input']>;
  deforestation?: InputMaybe<Scalars['Boolean']['input']>;
  energyIntensityHighImpact?: InputMaybe<Scalars['Boolean']['input']>;
  esg: PartyEsgInput;
  fossilFuelCompaniesExposure?: InputMaybe<Scalars['Boolean']['input']>;
  ghgEmissions?: InputMaybe<Scalars['Boolean']['input']>;
  ghgIntensityOfInvesteeCompanies?: InputMaybe<Scalars['Boolean']['input']>;
  hazardousWasteRatio?: InputMaybe<Scalars['Boolean']['input']>;
  iorp: IorpEnum;
  lackOfProcessesAndCompliance?: InputMaybe<Scalars['Boolean']['input']>;
  managementSupervisoryGenderDiversity?: InputMaybe<Scalars['Boolean']['input']>;
  nearTermTargetClassification?: InputMaybe<Scalars['String']['input']>;
  netZeroCommitted?: Scalars['Boolean']['input'];
  nonRenewableEnergyShare?: InputMaybe<Scalars['Boolean']['input']>;
  pollutionOfWater?: InputMaybe<Scalars['Boolean']['input']>;
  recordableWorkAccidents?: InputMaybe<Scalars['Boolean']['input']>;
  scienceBasedTargetInitiative?: ScienceBasedTargetInitiativeEnum;
  transitionCompany?: InputMaybe<Scalars['Boolean']['input']>;
  unadjustedGenderPayGap?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PartyIssuerType = {
  __typename?: 'PartyIssuerType';
  /** Activities negatively affecting biodiversity sensitive areas */
  activitiesAffectingBiodiversityAreas?: Maybe<Scalars['Boolean']['output']>;
  captorDnshCompliant?: Maybe<Scalars['Boolean']['output']>;
  carbonFootprint?: Maybe<Scalars['Boolean']['output']>;
  deforestation?: Maybe<Scalars['Boolean']['output']>;
  /** Energy consumption intensity per high impact climate sector */
  energyIntensityHighImpact?: Maybe<Scalars['Boolean']['output']>;
  esg?: Maybe<PartyEsg>;
  fossilFuelCompaniesExposure?: Maybe<Scalars['Boolean']['output']>;
  ghgEmissions?: Maybe<Scalars['Boolean']['output']>;
  ghgIntensityOfInvesteeCompanies?: Maybe<Scalars['Boolean']['output']>;
  hazardousWasteRatio?: Maybe<Scalars['Boolean']['output']>;
  iorp?: Maybe<IorpEnum>;
  lackOfProcessesAndCompliance?: Maybe<Scalars['Boolean']['output']>;
  /** Management and supervisory board gender diversity */
  managementSupervisoryGenderDiversity?: Maybe<Scalars['Boolean']['output']>;
  nearTermTargetClassification?: Maybe<Scalars['String']['output']>;
  netZeroCommitted?: Maybe<Scalars['Boolean']['output']>;
  /** Share of non-renewable energy consumption and production */
  nonRenewableEnergyShare?: Maybe<Scalars['Boolean']['output']>;
  pollutionOfWater?: Maybe<Scalars['Boolean']['output']>;
  /** Rate of recordable work-related accidents */
  recordableWorkAccidents?: Maybe<Scalars['Boolean']['output']>;
  scienceBasedTargetInitiative: ScienceBasedTargetInitiativeEnum;
  transitionCompany?: Maybe<Scalars['Boolean']['output']>;
  /** Unadjusted gender pay gap between female and employees */
  unadjustedGenderPayGap?: Maybe<Scalars['Boolean']['output']>;
};

export type PartyStandardSettlementInstruction = {
  __typename?: 'PartyStandardSettlementInstruction';
  agentBusinessIdentifierCode: Scalars['String']['output'];
  beneficiaryBusinessIdentifierCode: Scalars['String']['output'];
  buyerSellerSafe: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  placeOfSettlementId: Scalars['GraphQLObjectId']['output'];
  securityAccount: Scalars['String']['output'];
  tradeType?: Maybe<SsiTradeType>;
  type: Scalars['String']['output'];
};

export type PartyStandardSettlementInstructionInput = {
  agentBusinessIdentifierCode: Scalars['String']['input'];
  beneficiaryBusinessIdentifierCode: Scalars['String']['input'];
  buyerSellerSafe: Scalars['String']['input'];
  iban: Scalars['String']['input'];
  placeOfSettlementId: Scalars['GraphQLObjectId']['input'];
  securityAccount: Scalars['String']['input'];
  tradeType: SsiTradeType;
  type: PartyStandardSettlementInstructionType;
};

export enum PartyStandardSettlementInstructionType {
  Equity = 'Equity',
  FixedIncome = 'FixedIncome'
}

export enum PartyStatusEnum {
  Confirmed = 'Confirmed',
  Deleted = 'Deleted',
  Inactive = 'Inactive'
}

export enum PartyType {
  Broker = 'Broker',
  ClearingBroker = 'ClearingBroker',
  ClearingHouse = 'ClearingHouse',
  Client = 'Client',
  CreditRatingAgency = 'CreditRatingAgency',
  Custodian = 'Custodian',
  EsgDataProvider = 'EsgDataProvider',
  Fund = 'Fund',
  Issuer = 'Issuer',
  PlaceOfSettlement = 'PlaceOfSettlement',
  PrivateClient = 'PrivateClient'
}

export type PerformanceCache = {
  __typename?: 'PerformanceCache';
  _id: Scalars['GraphQLObjectId']['output'];
  cashFlows: Array<Maybe<Scalars['Float']['output']>>;
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  currency: CurrencyEnum;
  dates: Array<Scalars['GraphQLDateString']['output']>;
  lookThrough: Scalars['Boolean']['output'];
  performanceItemsIds: Array<Scalars['GraphQLObjectId']['output']>;
  values: Array<Scalars['Float']['output']>;
};

export type PerformanceFilterInput = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export enum PeriodicityAdjustmentEnum {
  ACTUAL = 'ACTUAL',
  CALENDAR = 'CALENDAR',
  FISCAL = 'FISCAL'
}

export enum PeriodicitySelectionEnum {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  WEEKLY = 'WEEKLY',
  YEARLY = 'YEARLY'
}

export enum PermissionAssetEnum {
  Accounting = 'Accounting',
  Admin = 'Admin',
  Agreement = 'Agreement',
  Attachment = 'Attachment',
  BackOffice = 'BackOffice',
  BankAccountTransaction = 'BankAccountTransaction',
  Base = 'Base',
  Bloomberg = 'Bloomberg',
  Collateral = 'Collateral',
  ConfirmAccounting = 'ConfirmAccounting',
  Customer = 'Customer',
  Esg = 'Esg',
  Instrument = 'Instrument',
  Job = 'Job',
  Limit = 'Limit',
  Nav = 'Nav',
  Note = 'Note',
  /** Team asset */
  Operations = 'Operations',
  Party = 'Party',
  Performance = 'Performance',
  Playground = 'Playground',
  PortfolioManager = 'PortfolioManager',
  /** Team asset */
  PortfolioManagers = 'PortfolioManagers',
  Position = 'Position',
  Price = 'Price',
  Property = 'Property',
  Quote = 'Quote',
  Reconciliation = 'Reconciliation',
  Report = 'Report',
  Sustainalytics = 'Sustainalytics',
  Ticket = 'Ticket',
  TimeSeries = 'TimeSeries',
  Transaction = 'Transaction',
  User = 'User',
  Valuation = 'Valuation'
}

export type PortfolioPerformance = {
  __typename?: 'PortfolioPerformance';
  cashFlows?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  currency?: Maybe<CurrencyEnum>;
  dates?: Maybe<Array<Maybe<Scalars['GraphQLDateString']['output']>>>;
  id: Scalars['GraphQLObjectId']['output'];
  instrumentPerformances?: Maybe<Array<Maybe<InstrumentPerformance>>>;
  performanceItems?: Maybe<Array<Maybe<PortfolioPerformanceItem>>>;
  series?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  values?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

/** types with more properties for debugging purpose */
export type PortfolioPerformance2 = {
  __typename?: 'PortfolioPerformance2';
  cashFlows?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  currency?: Maybe<CurrencyEnum>;
  dates?: Maybe<Array<Maybe<Scalars['GraphQLDateString']['output']>>>;
  id: Scalars['GraphQLObjectId']['output'];
  instrumentPerformances?: Maybe<Array<Maybe<InstrumentPerformance2>>>;
  performanceItems?: Maybe<Array<Maybe<PortfolioPerformanceItem>>>;
  rawCashFlows?: Maybe<Array<Maybe<CashFlowItem>>>;
  series?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  totalReturns?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  values?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type PortfolioPerformanceItem = {
  __typename?: 'PortfolioPerformanceItem';
  cashFlow?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  serie?: Maybe<Scalars['Float']['output']>;
  totalReturn?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type PortfolioSwapItemValuation = {
  __typename?: 'PortfolioSwapItemValuation';
  date: Scalars['GraphQLDateString']['output'];
  value: PortfolioSwapItemValue;
};

export type PortfolioSwapItemValue = {
  __typename?: 'PortfolioSwapItemValue';
  currency: CurrencyEnum;
  price: Scalars['Float']['output'];
};

export type PortfolioSwapItemsPosition = {
  __typename?: 'PortfolioSwapItemsPosition';
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  isDebtInstrument: Scalars['Boolean']['output'];
  quantity: Scalars['Float']['output'];
  valuation: PortfolioSwapItemValuation;
};

export type Position = {
  __typename?: 'Position';
  account?: Maybe<PartyAccount>;
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  client?: Maybe<Party>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  externalAccount?: Maybe<PartyExternalAccount>;
  externalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  isCashAccount?: Maybe<Scalars['Boolean']['output']>;
  portfolioInstrument?: Maybe<Instrument>;
  portfolioInstrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type PositionFilter = {
  accountIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  instrumentIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<TransactionStatus>>;
};

export enum PositionSelectorType {
  AccountId = 'AccountId',
  ClientId = 'ClientId',
  ExternalAccountId = 'ExternalAccountId'
}

export enum PositionTypeEnum {
  CreateRedeem = 'CreateRedeem',
  OffBalance = 'OffBalance',
  OnBalance = 'OnBalance'
}

export type Price = {
  __typename?: 'Price';
  _id: Scalars['GraphQLObjectId']['output'];
  accruedInterest?: Maybe<Scalars['String']['output']>;
  cleanPrice?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  currency: CurrencyEnum;
  date: Scalars['GraphQLDateString']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  price: Scalars['String']['output'];
  sourceId: Scalars['GraphQLObjectId']['output'];
  spread?: Maybe<Scalars['String']['output']>;
  type: PriceTypeEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type PriceFilterInput = {
  dateIn?: InputMaybe<Array<Scalars['GraphQLDateString']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  typeIn?: InputMaybe<Array<PriceTypeEnum>>;
};

export type PriceInput = {
  accruedInterest?: InputMaybe<Scalars['String']['input']>;
  cleanPrice?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  currency: CurrencyEnum;
  date: Scalars['GraphQLDateString']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  price: Scalars['String']['input'];
  spread?: InputMaybe<Scalars['String']['input']>;
};

export enum PriceType {
  AccruedInterest = 'AccruedInterest',
  CleanPrice = 'CleanPrice',
  Price = 'Price',
  PriceEstimate = 'PriceEstimate'
}

export enum PriceTypeEnum {
  Price = 'Price',
  PriceMapping = 'PriceMapping',
  Valuation = 'Valuation',
  ValuationMapping = 'ValuationMapping'
}

export enum PricingOptionEnum {
  PRICING_OPTION_PRICE = 'PRICING_OPTION_PRICE',
  PRICING_OPTION_YIELD = 'PRICING_OPTION_YIELD'
}

export type ProductInvolvement = {
  __typename?: 'ProductInvolvement';
  involvement: ProductInvolvementIndicator;
  range: RangeEnum;
};

export enum ProductInvolvementIndicator {
  AdultEntertainment = 'AdultEntertainment',
  AlcoholicBeverages = 'AlcoholicBeverages',
  ControversialWeapons = 'ControversialWeapons',
  Gambling = 'Gambling',
  MilitaryContracting = 'MilitaryContracting',
  OilGas = 'OilGas',
  OilSands = 'OilSands',
  SmallArms = 'SmallArms',
  ThermalCoal = 'ThermalCoal',
  TobaccoProducts = 'TobaccoProducts'
}

export type ProductInvolvementInput = {
  involvement: ProductInvolvementIndicator;
  range: RangeEnum;
};

export type PublishedToInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  /** Name of external recipient */
  keys: Array<Scalars['String']['input']>;
};

export type PublishedToType = {
  __typename?: 'PublishedToType';
  /** Name of external recipient */
  key: Scalars['String']['output'];
  timestamp: Scalars['Timestamp']['output'];
  userId: Scalars['GraphQLObjectId']['output'];
};

export type Query = {
  __typename?: 'Query';
  accountPerformance?: Maybe<PortfolioPerformance2>;
  accountingRun?: Maybe<AccountingRun>;
  accountingRunId: Scalars['GraphQLObjectId']['output'];
  accountingRuns?: Maybe<Array<Maybe<AccountingRun>>>;
  adjustmentAmount?: Maybe<Array<Maybe<TradingManagerColumn>>>;
  agreements?: Maybe<Array<Maybe<Agreement>>>;
  allInstrumentsInPosition?: Maybe<Array<Maybe<InstrumentPosition>>>;
  annualReports: Array<AnnualReportType>;
  attachment?: Maybe<Attachment>;
  attachments: Array<Attachment>;
  bankAccountBalances: Array<BankAccountBalance>;
  bankAccountTransactions: Array<BankAccountTransaction>;
  /** Base calendars are unique by year combined with name */
  baseCalendars?: Maybe<Array<Maybe<BaseCalendar>>>;
  brokerTransactions: Array<BrokerTransaction>;
  calculateNav?: Maybe<NavData>;
  /**
   * Combining all dates from different years for calendar with the stated names or for
   * calendars in party.fundInfo for a specific fund
   */
  calendar?: Maybe<Calendar>;
  cashBalances?: Maybe<Array<Maybe<CashAccountBalance>>>;
  cashLadder?: Maybe<Array<Maybe<CashAccountLadder>>>;
  clientAccounts?: Maybe<Array<Maybe<PartyAccount>>>;
  costs?: Maybe<Array<Maybe<Cost>>>;
  curveDefinition?: Maybe<CurveDefinition>;
  curveDefinitionInstruments: Array<Instrument>;
  curveDefinitions?: Maybe<Array<Maybe<CurveDefinition>>>;
  custodianPositions: Array<CustodianApiPosition>;
  customers?: Maybe<Array<Maybe<Customer>>>;
  dart?: Maybe<DartReport>;
  defaultQuery?: Maybe<Scalars['String']['output']>;
  exposuresCollaterals: Array<ExposureCollateral>;
  externalAccountsPositions?: Maybe<Array<Maybe<Position>>>;
  firstTransaction?: Maybe<Transaction>;
  firstTransactions?: Maybe<Array<Transaction>>;
  fredSeriesObservations?: Maybe<Array<Maybe<FredObservation>>>;
  fredSeriess?: Maybe<Array<Maybe<FredSeries>>>;
  fundUnits?: Maybe<Array<Maybe<FundUnit>>>;
  fxValuations?: Maybe<Array<Maybe<FxValuation>>>;
  getRedisKey?: Maybe<Scalars['GraphQLJSON']['output']>;
  historicalData?: Maybe<Array<Maybe<HistoricalData>>>;
  instrument?: Maybe<Instrument>;
  instrumentAccountPositions?: Maybe<Array<Maybe<InstrumentAccountPosition>>>;
  instruments?: Maybe<Array<Maybe<Instrument>>>;
  instrumentsUsedBy?: Maybe<Array<Maybe<Instrument>>>;
  issuerprograms?: Maybe<Array<Maybe<IssuerProgram>>>;
  jobs?: Maybe<Array<Maybe<Job>>>;
  journalEntries?: Maybe<Array<JournalEntry>>;
  journalEntry?: Maybe<JournalEntry>;
  lastValuations?: Maybe<Array<Maybe<Valuation>>>;
  limit?: Maybe<Array<Maybe<LimitData>>>;
  marginRequirements: Array<MarginRequirement>;
  me?: Maybe<Me>;
  miniSwifts?: Maybe<Array<MiniSwift>>;
  miniTransaction?: Maybe<MiniTransaction>;
  miniTransactions?: Maybe<Array<Maybe<MiniTransaction>>>;
  nav?: Maybe<Nav>;
  navs?: Maybe<Array<Maybe<Nav>>>;
  notes?: Maybe<Array<Maybe<Note>>>;
  parties?: Maybe<Array<Maybe<Party>>>;
  party?: Maybe<Party>;
  partyExternalAccount?: Maybe<PartyExternalAccount>;
  partyUsage?: Maybe<Scalars['Boolean']['output']>;
  performance?: Maybe<PortfolioPerformance>;
  performance2?: Maybe<PortfolioPerformance2>;
  /**
   * performanceCA groups performance on external account. Not fully working since accounts by id will not find relevant account.
   * Temporary query to be deleted after analysis
   */
  performanceCA?: Maybe<PortfolioPerformance2>;
  performanceCache: Array<PerformanceCache>;
  portfolioSwapConstituents?: Maybe<Array<Maybe<PortfolioSwapItemsPosition>>>;
  positions?: Maybe<Array<Maybe<Position>>>;
  prices?: Maybe<Array<Maybe<Price>>>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  reconcileExposureCollateral: Array<ReconciledExposureCollateralType>;
  reconcileFundUnits?: Maybe<Array<FundUnitReconciliationType>>;
  reconcilePositions?: Maybe<Array<ReconciliationType>>;
  referenceData?: Maybe<Array<Maybe<ReferenceData>>>;
  registerExtracts?: Maybe<Array<RegisterExtract>>;
  report?: Maybe<Report>;
  reportTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  reports?: Maybe<Array<Maybe<Report>>>;
  role?: Maybe<Role>;
  roles?: Maybe<Array<Maybe<Role>>>;
  screens?: Maybe<Array<Maybe<Screen>>>;
  /** returns an array of clientAccount positions on selected day and per instruments */
  shareRegister?: Maybe<Array<ShareRegisterItem>>;
  shareRegisterDelta?: Maybe<Array<ShareRegisterItem>>;
  shareRegisterUnits?: Maybe<Array<ShareRegisterUnits>>;
  /** returns an array of clientAccount positions per day and per instruments */
  shareRegisters?: Maybe<Array<ShareRegisterItem>>;
  sustainalyticsDataModel: Array<SustainalyticsDataModel>;
  sustainalyticsDataService?: Maybe<Array<Maybe<SustainalyticsDataModelWithStatus>>>;
  sustainalyticsFieldDefinitions?: Maybe<Array<Maybe<SustainalyticsFieldDefinitionModel>>>;
  sustainalyticsFieldMappingDefinitions?: Maybe<Array<Maybe<SustainalyticsProductModel>>>;
  sustainalyticsFieldMappings?: Maybe<Array<Maybe<SustainalyticsProductModel>>>;
  sustainalyticsReport?: Maybe<SustainalyticsReport>;
  sustainalyticsReportService?: Maybe<Array<Maybe<SustainalyticsReport>>>;
  sustainalyticsReportUrl?: Maybe<SustainalyticsReportUrl>;
  sustainalyticsUniverseOfAccess?: Maybe<Array<Maybe<SustainalyticsSecurityListModel>>>;
  swifts?: Maybe<Array<Swift>>;
  tAccountChart?: Maybe<TAccountChart>;
  tAccountChartAccountsExport?: Maybe<Scalars['String']['output']>;
  tAccountChartMappingsExport?: Maybe<Scalars['String']['output']>;
  tAccountCharts?: Maybe<Array<Maybe<TAccountChart>>>;
  ticketUsers?: Maybe<Array<TicketUser>>;
  tickets?: Maybe<Array<Maybe<Ticket>>>;
  timeserie?: Maybe<TimeSeries>;
  timeseries?: Maybe<Array<Maybe<TimeSeries>>>;
  tradingmanager?: Maybe<Array<Maybe<TradingManagerColumn>>>;
  tradingmanager2?: Maybe<Array<Maybe<TradingManagerColumn>>>;
  transaction?: Maybe<Transaction>;
  transactionItems?: Maybe<Array<Maybe<TransactionItem>>>;
  transactionitemStatus?: Maybe<Scalars['Float']['output']>;
  transactions?: Maybe<Array<Transaction>>;
  transferAgentOrder?: Maybe<TransferAgentOrder>;
  transferAgentOrders?: Maybe<Array<Maybe<TransferAgentOrder>>>;
  transferAgentTotalUnits?: Maybe<Array<Maybe<TransferAgentTotalUnit>>>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  /** Used to assign tickets to teams */
  usersLimited: Array<UserLimited>;
  valuation?: Maybe<Valuation>;
  valuationmappings?: Maybe<Array<Maybe<ValuationMapping>>>;
  valuations?: Maybe<Array<Maybe<Valuation>>>;
};


export type QueryAccountPerformanceArgs = {
  accountId: Scalars['GraphQLObjectId']['input'];
  filter?: InputMaybe<PerformanceFilterInput>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAccountingRunArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryAccountingRunsArgs = {
  accountingPeriod?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  lastOnly?: InputMaybe<Scalars['Boolean']['input']>;
  maxNumber?: InputMaybe<Scalars['Int']['input']>;
  statusIn?: InputMaybe<Array<AccountingRunStatusEnum>>;
};


export type QueryAdjustmentAmountArgs = {
  baseCurrency?: InputMaybe<CurrencyEnum>;
  clientId: Scalars['GraphQLObjectId']['input'];
  date: Scalars['GraphQLDateString']['input'];
};


export type QueryAgreementsArgs = {
  filter?: InputMaybe<AgreementFilterInput>;
};


export type QueryAllInstrumentsInPositionArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientTypes?: InputMaybe<Array<PartyType>>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  instrumentIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  instrumentPositionType?: InputMaybe<InstrumentPositionType>;
  modelTypes?: InputMaybe<Array<InstrumentModelTypeEnum>>;
};


export type QueryAnnualReportsArgs = {
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
};


export type QueryAttachmentArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryAttachmentsArgs = {
  fileIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type QueryBankAccountBalancesArgs = {
  filter?: InputMaybe<BankAccountBalanceFilterInput>;
};


export type QueryBankAccountTransactionsArgs = {
  filter?: InputMaybe<BankAccountTransactionFilterInput>;
};


export type QueryBaseCalendarsArgs = {
  filter?: InputMaybe<BaseCalendarFilterInput>;
};


export type QueryBrokerTransactionsArgs = {
  filter?: InputMaybe<BrokerTransactionFilterInput>;
};


export type QueryCalculateNavArgs = {
  date: Scalars['GraphQLDateString']['input'];
  fundId: Scalars['GraphQLObjectId']['input'];
};


export type QueryCalendarArgs = {
  fundId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  names?: InputMaybe<Array<CalendarNameEnum>>;
  years?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryCashBalancesArgs = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  instrumentIds: Array<Scalars['GraphQLObjectId']['input']>;
  viewFromDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type QueryCashLadderArgs = {
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusNotIn?: InputMaybe<Array<TransactionStatus>>;
};


export type QueryClientAccountsArgs = {
  clientIds?: InputMaybe<Array<InputMaybe<Scalars['GraphQLObjectId']['input']>>>;
};


export type QueryCostsArgs = {
  filter?: InputMaybe<CostFilterInput>;
};


export type QueryCurveDefinitionArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryCustodianPositionsArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  filterZeroPositions?: InputMaybe<Scalars['Boolean']['input']>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  include?: InputMaybe<Array<InputMaybe<CustodianPositionType>>>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  isinIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<StatusEnum>>;
  typeIn?: InputMaybe<Array<CustodianPositionType>>;
};


export type QueryCustomersArgs = {
  filter?: InputMaybe<CustomerFilterInput>;
};


export type QueryDartArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type QueryExposuresCollateralsArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  dates?: InputMaybe<Array<Scalars['GraphQLDateString']['input']>>;
};


export type QueryExternalAccountsPositionsArgs = {
  custodianAccountNumbers: Array<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  includeAllCashAccounts?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFirstTransactionArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
};


export type QueryFirstTransactionsArgs = {
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  instrumentIds: Array<Scalars['GraphQLObjectId']['input']>;
};


export type QueryFredSeriesObservationsArgs = {
  id: Scalars['String']['input'];
  observationEnd?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  observationStart?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type QueryFredSeriessArgs = {
  id: Scalars['String']['input'];
};


export type QueryFundUnitsArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type QueryFxValuationsArgs = {
  date?: InputMaybe<Scalars['GraphQLSwedenDate']['input']>;
};


export type QueryGetRedisKeyArgs = {
  key: Scalars['String']['input'];
};


export type QueryHistoricalDataArgs = {
  adjustmentAbnormal?: InputMaybe<Scalars['Boolean']['input']>;
  adjustmentFollowDPDF?: InputMaybe<Scalars['Boolean']['input']>;
  adjustmentNormal?: InputMaybe<Scalars['Boolean']['input']>;
  adjustmentSplit?: InputMaybe<Scalars['Boolean']['input']>;
  calendarCodeOverride?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  enumField?: InputMaybe<HistoricalFieldsEnum>;
  field?: InputMaybe<Scalars['String']['input']>;
  machine?: InputMaybe<RefDataMachine>;
  maxDataPoints?: InputMaybe<Scalars['Int']['input']>;
  nonTradingDayFillMethod?: InputMaybe<NonTradingDayFillMethodEnum>;
  nonTradingDayFillOption?: InputMaybe<NonTradingDayFillOptionEnum>;
  overrideOption?: InputMaybe<OverrideOptionEnum>;
  periodicityAdjustment?: InputMaybe<PeriodicityAdjustmentEnum>;
  periodicitySelection?: InputMaybe<PeriodicitySelectionEnum>;
  pricingOption?: InputMaybe<PricingOptionEnum>;
  returnRelativeDate?: InputMaybe<Scalars['Boolean']['input']>;
  securities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate: Scalars['Date']['input'];
};


export type QueryInstrumentArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInstrumentAccountPositionsArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  filterZeroPositions?: InputMaybe<Scalars['Boolean']['input']>;
  groupPositionsBy?: InputMaybe<PositionSelectorType>;
  instrumentIds: Array<Scalars['GraphQLObjectId']['input']>;
};


export type QueryInstrumentsArgs = {
  aliasIn?: InputMaybe<Array<Scalars['String']['input']>>;
  filter?: InputMaybe<InstrumentFilterInput>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryInstrumentsUsedByArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type QueryIssuerprogramsArgs = {
  filter?: InputMaybe<IssuerProgramsFilterInput>;
};


export type QueryJournalEntriesArgs = {
  accountingPeriod?: InputMaybe<Scalars['String']['input']>;
  accountingRunId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  batch?: InputMaybe<AccountingBatchType>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type QueryJournalEntryArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  portfolioTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type QueryLastValuationsArgs = {
  endDate?: InputMaybe<Scalars['GraphQLSwedenDate']['input']>;
  instrumentIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type QueryLimitArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type QueryMarginRequirementsArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  dates?: InputMaybe<Array<Scalars['GraphQLDateString']['input']>>;
};


export type QueryMiniSwiftsArgs = {
  miniFilterIn?: InputMaybe<MiniSwiftFilterInput>;
};


export type QueryMiniTransactionArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type QueryMiniTransactionsArgs = {
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  filter?: InputMaybe<TransactionFilterInput>;
  includePortfolioSwapConstituents?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InputMaybe<TransactionStatus>>>;
  type?: InputMaybe<Array<InputMaybe<TransactionType>>>;
};


export type QueryNavArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryNavsArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  lastOnly?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<ReportStatusEnum>>;
};


export type QueryNotesArgs = {
  filter?: InputMaybe<NoteFilterInput>;
};


export type QueryPartiesArgs = {
  filter?: InputMaybe<PartyFilterInput>;
};


export type QueryPartyArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPartyExternalAccountArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type QueryPartyUsageArgs = {
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type QueryPerformanceArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  filter?: InputMaybe<PerformanceFilterInput>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
  useCache?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPerformance2Args = {
  clientId: Scalars['GraphQLObjectId']['input'];
  filter?: InputMaybe<PerformanceFilterInput>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPerformanceCaArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  filter?: InputMaybe<PerformanceFilterInput>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPerformanceCacheArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type QueryPortfolioSwapConstituentsArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  filterZeroPositions?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioInstrumentId: Scalars['GraphQLObjectId']['input'];
};


export type QueryPositionsArgs = {
  excludeCollateral?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PositionFilter>;
  filterZeroPositions?: InputMaybe<Scalars['Boolean']['input']>;
  groupPositionsBy?: InputMaybe<PositionSelectorType>;
  includeAllCashAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPricesArgs = {
  filter?: InputMaybe<PriceFilterInput>;
};


export type QueryQuotesArgs = {
  filter?: InputMaybe<QuoteFilterInput>;
};


export type QueryReconcileExposureCollateralArgs = {
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
};


export type QueryReconcileFundUnitsArgs = {
  date: Scalars['GraphQLDateString']['input'];
  filterOutZeroDiff?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryReconcilePositionsArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  date: Scalars['GraphQLDateString']['input'];
};


export type QueryReferenceDataArgs = {
  enumFields?: InputMaybe<Array<InputMaybe<ReferenceFieldsEnum>>>;
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  machine?: InputMaybe<RefDataMachine>;
  securities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryRegisterExtractsArgs = {
  filter?: InputMaybe<RegisterExtractFilterInput>;
};


export type QueryReportArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryReportTypesArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryReportsArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  lastOnly?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<ReportStatusEnum>>;
  type?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryRoleArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryRolesArgs = {
  statusIn?: InputMaybe<Array<DocumentStatusEnum>>;
};


export type QueryScreensArgs = {
  filter?: InputMaybe<FilterInput>;
};


export type QueryShareRegisterArgs = {
  filter?: InputMaybe<ShareRegisterFilterInput>;
};


export type QueryShareRegisterDeltaArgs = {
  fromDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  toDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type QueryShareRegisterUnitsArgs = {
  filter?: InputMaybe<ShareRegisterFilterInput>;
};


export type QueryShareRegistersArgs = {
  fromDate: Scalars['GraphQLDateString']['input'];
  toDate: Scalars['GraphQLDateString']['input'];
};


export type QuerySustainalyticsDataModelArgs = {
  fieldClusterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldIds?: InputMaybe<Array<Scalars['String']['input']>>;
  identifiers: Array<Scalars['String']['input']>;
  packageIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productId: Scalars['String']['input'];
};


export type QuerySustainalyticsDataServiceArgs = {
  count?: InputMaybe<Scalars['Boolean']['input']>;
  fieldClusterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldIds?: InputMaybe<Array<Scalars['String']['input']>>;
  identifiers?: InputMaybe<Array<Scalars['String']['input']>>;
  packageIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySustainalyticsReportArgs = {
  identifier: Scalars['String']['input'];
  productId: Scalars['String']['input'];
};


export type QuerySustainalyticsReportServiceArgs = {
  count?: InputMaybe<Scalars['Boolean']['input']>;
  productId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySustainalyticsReportUrlArgs = {
  identifier: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
};


export type QuerySwiftsArgs = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  filterIn?: InputMaybe<SwiftFilterInput>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<SwiftStatusEnum>>;
};


export type QueryTAccountChartArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTAccountChartAccountsExportArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  delimiter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTAccountChartMappingsExportArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  delimiter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTAccountChartsArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTicketsArgs = {
  filter?: InputMaybe<TicketFilterInput>;
};


export type QueryTimeserieArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  filter?: InputMaybe<TimeSeriesFilterInput>;
  includeItems?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTimeseriesArgs = {
  filter?: InputMaybe<TimeSeriesFilterInput>;
  firstItem?: InputMaybe<Scalars['Boolean']['input']>;
  includeItems?: InputMaybe<Scalars['Boolean']['input']>;
  lastItem?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTradingmanagerArgs = {
  baseCurrency?: InputMaybe<CurrencyEnum>;
  excludeCollateral?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PositionFilter>;
  filterZeroPositions?: InputMaybe<Scalars['Boolean']['input']>;
  groupPositionsBy?: InputMaybe<PositionSelectorType>;
  includeAllCashAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTradingmanager2Args = {
  baseCurrency?: InputMaybe<CurrencyEnum>;
  clientId: Scalars['GraphQLObjectId']['input'];
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  excludeCollateral?: InputMaybe<Scalars['Boolean']['input']>;
  filterZeroPositions?: InputMaybe<Scalars['Boolean']['input']>;
  groupPositionsBy?: InputMaybe<PositionSelectorType>;
  includeAllCashAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTransactionArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type QueryTransactionItemsArgs = {
  filter?: InputMaybe<TransactionItemFilterInput>;
};


export type QueryTransactionsArgs = {
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  filter?: InputMaybe<TransactionFilterInput>;
  includePortfolioSwapConstituents?: InputMaybe<Scalars['Boolean']['input']>;
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  status?: InputMaybe<Array<InputMaybe<TransactionStatus>>>;
};


export type QueryTransferAgentOrderArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryTransferAgentOrdersArgs = {
  filter?: InputMaybe<OrderFilterInput>;
};


export type QueryTransferAgentTotalUnitsArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  sourceId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type QueryUserArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  personalNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
};


export type QueryUsersLimitedArgs = {
  filter?: InputMaybe<LimitedUserFilterInput>;
};


export type QueryValuationArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryValuationmappingsArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  filter?: InputMaybe<ValuationMappingFilterInput>;
  valuationInstrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type QueryValuationsArgs = {
  endDate?: InputMaybe<Scalars['GraphQLSwedenDate']['input']>;
  instrumentIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  startDate?: InputMaybe<Scalars['GraphQLSwedenDate']['input']>;
};

export type Quote = {
  __typename?: 'Quote';
  _id: Scalars['GraphQLObjectId']['output'];
  ask?: Maybe<Scalars['String']['output']>;
  bid?: Maybe<Scalars['String']['output']>;
  /** Broker is the quoting party */
  brokerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  close?: Maybe<Scalars['String']['output']>;
  createTimestamp: Scalars['Timestamp']['output'];
  currency: CurrencyEnum;
  /** DataVendor is for example Bloomberg */
  dataVendorId: Scalars['GraphQLObjectId']['output'];
  date: Scalars['GraphQLDateString']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  last?: Maybe<Scalars['String']['output']>;
  /** Market is for example exchange, OTC, NASDAQ, Turquoise etc */
  market?: Maybe<Scalars['String']['output']>;
  mid?: Maybe<Scalars['String']['output']>;
  type: QuoteTypeEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type QuoteFilterInput = {
  dateIn?: InputMaybe<Array<Scalars['GraphQLDateString']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};

export type QuoteInput = {
  ask?: InputMaybe<Scalars['String']['input']>;
  bid?: InputMaybe<Scalars['String']['input']>;
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  close?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyEnum;
  dataVendorId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  last?: InputMaybe<Scalars['String']['input']>;
  market?: InputMaybe<Scalars['String']['input']>;
  mid?: InputMaybe<Scalars['String']['input']>;
  quoteTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  type: QuoteTypeEnum;
};

export enum QuoteTypeEnum {
  Clean = 'Clean',
  PctOfNominal = 'PctOfNominal',
  PerUnit = 'PerUnit',
  Pips4 = 'Pips4',
  Spread = 'Spread',
  Yield = 'Yield'
}

export enum RangeEnum {
  R0To5 = 'R0To5',
  R5To10 = 'R5To10',
  R10To25 = 'R10To25',
  R25To50 = 'R25To50',
  R50To100 = 'R50To100'
}

export enum ReconcileTypeEnum {
  ExposureCollateral = 'ExposureCollateral',
  NettingSet = 'NettingSet',
  NettingSetBaseCurrency = 'NettingSetBaseCurrency'
}

export type ReconciledExposureCollateralType = {
  __typename?: 'ReconciledExposureCollateralType';
  agreementType: AgreementType;
  baseCurrency: CurrencyEnum;
  client?: Maybe<ClientInfo>;
  clientId: Scalars['GraphQLObjectId']['output'];
  counterpartyExposure: Scalars['Float']['output'];
  counterpartyInitialMarginCollateral: Scalars['Float']['output'];
  counterpartyInitialMarginNetRequirement: Scalars['Float']['output'];
  counterpartyInitialMarginPosted: Scalars['Float']['output'];
  counterpartyInitialMarginSecuritiesCollateral: Scalars['Float']['output'];
  counterpartyMarginCall: Scalars['Float']['output'];
  counterpartyNetExposure: Scalars['Float']['output'];
  counterpartyQuantity: Scalars['Float']['output'];
  counterpartyValuationDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  counterpartyVariationMarginCollateral: Scalars['Float']['output'];
  counterpartyVariationMarginPosted: Scalars['Float']['output'];
  counterpartyVariationMarginSecuritiesCollateral: Scalars['Float']['output'];
  creditYieldDeltaSum: Scalars['Float']['output'];
  currency: CurrencyEnum;
  exposure: Scalars['Float']['output'];
  exposureDifference: Scalars['Float']['output'];
  externalAccount?: Maybe<PartyExternalAccount>;
  externalAccountId: Scalars['GraphQLObjectId']['output'];
  initialMarginCollateral: Scalars['Float']['output'];
  initialMarginCollateralDifference: Scalars['Float']['output'];
  initialMarginNetRequirement: Scalars['Float']['output'];
  initialMarginRequirement: Scalars['Float']['output'];
  initialMarginSecuritiesCollateral: Scalars['Float']['output'];
  initialMarginSecuritiesCollateralDifference: Scalars['Float']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  interestRateYieldDeltaSum: Scalars['Float']['output'];
  marginCall: Scalars['Float']['output'];
  marginCallsMade: Scalars['Boolean']['output'];
  marginType?: Maybe<MarginType>;
  minimumTransferAmount: Scalars['Float']['output'];
  missingReport?: Maybe<Scalars['Boolean']['output']>;
  netExposure: Scalars['Float']['output'];
  netExposureDifference: Scalars['Float']['output'];
  netInitialMarginRequirementDifference: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  quantityDifference: Scalars['Float']['output'];
  reconcileType: ReconcileTypeEnum;
  roundingAmount: Scalars['Float']['output'];
  threshold: Scalars['Float']['output'];
  type?: Maybe<ExposureCollateralType>;
  valuationDate: Scalars['GraphQLDateString']['output'];
  variationMarginCollateral: Scalars['Float']['output'];
  variationMarginCollateralDifference: Scalars['Float']['output'];
  variationMarginPosted: Scalars['Float']['output'];
  variationMarginSecuritiesCollateral: Scalars['Float']['output'];
  variationMarginSecuritiesCollateralDifference: Scalars['Float']['output'];
};

export type ReconciliationType = {
  __typename?: 'ReconciliationType';
  client?: Maybe<Party>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  custodian?: Maybe<PartyInfo>;
  custodianDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  custodianId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  custodianPositionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  custodianQuantity?: Maybe<Scalars['Float']['output']>;
  diff?: Maybe<Scalars['Float']['output']>;
  externalAccount?: Maybe<PartyExternalAccount>;
  externalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  id?: Maybe<Scalars['GraphQLObjectId']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  isCashAccount?: Maybe<Scalars['Boolean']['output']>;
  positionDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  positionQuantity?: Maybe<Scalars['Float']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
};

export type RefDataError = {
  __typename?: 'RefDataError';
  category?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  code?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  message?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  source?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  subcategory?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type RefDataFieldException = {
  __typename?: 'RefDataFieldException';
  errors?: Maybe<Array<RefDataError>>;
  fieldId?: Maybe<Array<Scalars['String']['output']>>;
};

export type RefDataFieldExceptionsArray = {
  __typename?: 'RefDataFieldExceptionsArray';
  fieldExceptions?: Maybe<Array<RefDataFieldException>>;
};

export enum RefDataMachine {
  Daka = 'Daka',
  Office = 'Office'
}

export type ReferenceData = {
  __typename?: 'ReferenceData';
  eidDataArray?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fieldData?: Maybe<Array<Maybe<FieldDataItem>>>;
  fieldExceptionsArray?: Maybe<Array<RefDataFieldExceptionsArray>>;
  id?: Maybe<Scalars['GraphQLObjectId']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  security?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  securityError?: Maybe<Array<RefDataError>>;
};

export enum ReferenceFieldsEnum {
  CDS_SPREAD_MID_MODIFIED_DURATION = 'CDS_SPREAD_MID_MODIFIED_DURATION',
  CFI_CODE = 'CFI_CODE',
  CNTRY_OF_DOMICILE = 'CNTRY_OF_DOMICILE',
  COMPANY_IS_PRIVATE = 'COMPANY_IS_PRIVATE',
  CPN_TYP = 'CPN_TYP',
  CRNCY = 'CRNCY',
  DAYS_TO_SETTLE = 'DAYS_TO_SETTLE',
  DISC_MRGN_MID = 'DISC_MRGN_MID',
  DUR_ADJ_MID = 'DUR_ADJ_MID',
  ECBC_COVERED_BD_LABEL_INDICATOR = 'ECBC_COVERED_BD_LABEL_INDICATOR',
  EXCH_CODE = 'EXCH_CODE',
  GB_MANAGEMENT_PROCEEDS = 'GB_MANAGEMENT_PROCEEDS',
  GICS_SUB_INDUSTRY = 'GICS_SUB_INDUSTRY',
  ID_BB_COMPANY = 'ID_BB_COMPANY',
  ID_EXCH_SYMBOL = 'ID_EXCH_SYMBOL',
  ID_ISIN = 'ID_ISIN',
  INDUSTRY_SUBGROUP_NUM = 'INDUSTRY_SUBGROUP_NUM',
  ISSUER = 'ISSUER',
  ISSUER_INDUSTRY = 'ISSUER_INDUSTRY',
  LAST_UPDATE_BID_RT = 'LAST_UPDATE_BID_RT',
  LAST_UPDATE_DT_EXCH_TZ = 'LAST_UPDATE_DT_EXCH_TZ',
  LEGAL_ENTITY_IDENTIFIER = 'LEGAL_ENTITY_IDENTIFIER',
  MARKET_SECTOR_DES = 'MARKET_SECTOR_DES',
  PAR_AMT = 'PAR_AMT',
  PAYMENT_RANK = 'PAYMENT_RANK',
  PX_ASK = 'PX_ASK',
  PX_BID = 'PX_BID',
  PX_CLOSE = 'PX_CLOSE',
  PX_LAST = 'PX_LAST',
  RTG_FITCH = 'RTG_FITCH',
  RTG_FITCH_LONG = 'RTG_FITCH_LONG',
  RTG_FITCH_LT_ISSUER_DEFAULT = 'RTG_FITCH_LT_ISSUER_DEFAULT',
  RTG_MDY_ISSUER = 'RTG_MDY_ISSUER',
  RTG_MOODY = 'RTG_MOODY',
  RTG_MOODY_LONG_TERM = 'RTG_MOODY_LONG_TERM',
  RTG_SP = 'RTG_SP',
  RTG_SP_LONG = 'RTG_SP_LONG',
  RTG_SP_LT_LC_ISSUER_CREDIT = 'RTG_SP_LT_LC_ISSUER_CREDIT',
  SECURITY_NAME = 'SECURITY_NAME',
  SEC_ID_WITH_YK_PARSEKYABLE_RT = 'SEC_ID_WITH_YK_PARSEKYABLE_RT',
  SERIES = 'SERIES',
  TICKER = 'TICKER',
  TIME = 'TIME',
  YAS_ISPREAD_TO_GOVT = 'YAS_ISPREAD_TO_GOVT',
  YLD_YTM_MID = 'YLD_YTM_MID'
}

export type RegisterExtract = {
  __typename?: 'RegisterExtract';
  _id: Scalars['GraphQLObjectId']['output'];
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  date: Scalars['GraphQLDateString']['output'];
  items: Array<RegisterExtractItem>;
  source: PartyInfo;
  sourceId: Scalars['GraphQLObjectId']['output'];
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo: UserInfo;
};

export type RegisterExtractFilterInput = {
  dateIn?: InputMaybe<Array<Scalars['GraphQLDateString']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};

export type RegisterExtractInput = {
  /** if _id is omitted, document will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  date: Scalars['GraphQLDateString']['input'];
  items: Array<RegisterExtractItemInput>;
  sourceId: Scalars['GraphQLObjectId']['input'];
};

export type RegisterExtractItem = {
  __typename?: 'RegisterExtractItem';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: CountryCodeEnum;
  date: Scalars['GraphQLDateString']['output'];
  detailedCustomerSpecifics: Scalars['String']['output'];
  email: Scalars['String']['output'];
  fax: Scalars['String']['output'];
  fundClassName: Scalars['String']['output'];
  fundId: Scalars['String']['output'];
  fundPrice: Scalars['String']['output'];
  fundShortName: Scalars['String']['output'];
  instrumentId: Scalars['GraphQLObjectId']['output'];
  isin: Scalars['String']['output'];
  legalPerson: Scalars['String']['output'];
  nationalRegistrationId: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  purchaseAmount: Scalars['String']['output'];
  quantity: Scalars['String']['output'];
  taClientAccount: Scalars['String']['output'];
  taClientAccountLabel: Scalars['String']['output'];
  taClientName: Scalars['String']['output'];
  taxCountry: CountryCodeEnum;
};

export type RegisterExtractItemInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: CountryCodeEnum;
  date: Scalars['GraphQLDateString']['input'];
  detailedCustomerSpecifics: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fax: Scalars['String']['input'];
  fundClassName: Scalars['String']['input'];
  fundId: Scalars['String']['input'];
  fundPrice: Scalars['String']['input'];
  fundShortName: Scalars['String']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  isin: Scalars['String']['input'];
  legalPerson: Scalars['String']['input'];
  nationalRegistrationId: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  purchaseAmount: Scalars['String']['input'];
  quantity: Scalars['String']['input'];
  taClientAccount: Scalars['String']['input'];
  taClientAccountLabel: Scalars['String']['input'];
  taClientName: Scalars['String']['input'];
  taxCountry: CountryCodeEnum;
};

export type Report = {
  __typename?: 'Report';
  _id: Scalars['GraphQLObjectId']['output'];
  base64?: Maybe<Scalars['String']['output']>;
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  clientName: Scalars['String']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  data?: Maybe<Scalars['GraphQLJSON']['output']>;
  date: Scalars['GraphQLDateString']['output'];
  /** fileId is the same as reportId, both is an id of a file */
  fileId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  reportId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  sourceId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  sourceName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ReportStatusEnum>;
  type: Scalars['String']['output'];
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
};

export enum ReportStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted',
  /** When locked, not possible to edit */
  Locked = 'Locked'
}

export type Role = {
  __typename?: 'Role';
  _id: Scalars['String']['output'];
  assets: Array<PermissionAssetEnum>;
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients: Array<RoleClientInfo>;
  comment?: Maybe<Scalars['String']['output']>;
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  name: Scalars['String']['output'];
  nbrOfActiveUsers: Scalars['Int']['output'];
  nbrOfUsers: Scalars['Int']['output'];
  permissionType: PermissionTypeEnum;
  roleType: RoleTypeEnum;
  status: DocumentStatusEnum;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type RoleClientInfo = {
  __typename?: 'RoleClientInfo';
  _id: Scalars['GraphQLObjectId']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum RoleTypeEnum {
  Backend = 'Backend',
  Frontend = 'Frontend'
}

export type RoundTransactionInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  /** Apply +/- according to how it should be applied to settlementAmount, same currency as settlement currency */
  rounding: Scalars['Float']['input'];
};

export enum ScienceBasedTargetInitiativeEnum {
  Committed = 'Committed',
  NoTarget = 'NoTarget',
  TargetSet = 'TargetSet'
}

export type Screen = {
  __typename?: 'Screen';
  _id: Scalars['GraphQLObjectId']['output'];
  approved?: Maybe<Scalars['Boolean']['output']>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createTimestamp: Scalars['Timestamp']['output'];
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  issuer?: Maybe<Party>;
  issuerProgram?: Maybe<IssuerProgram>;
  /** document in client or issuerprogram collection depending on screenType */
  objectId: Scalars['GraphQLObjectId']['output'];
  screenType?: Maybe<ScreenType>;
  status?: Maybe<DocumentStatusEnum>;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo: UserInfo;
  /** A list of versions of the document */
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type ScreenInput = {
  approved: Scalars['Boolean']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  objectId: Scalars['GraphQLObjectId']['input'];
  screenType: ScreenType;
  status?: InputMaybe<DocumentStatusEnum>;
};

export enum ScreenType {
  Issuer = 'Issuer',
  IssuerProgram = 'IssuerProgram'
}

export type Selector = {
  __typename?: 'Selector';
  accountIds: Array<Maybe<Scalars['GraphQLObjectId']['output']>>;
  currencies: Array<Maybe<Scalars['String']['output']>>;
  instrumentIds: Array<Maybe<Scalars['GraphQLObjectId']['output']>>;
  statuses: Array<Maybe<Scalars['String']['output']>>;
};

export type SelectorInput = {
  accountIds: Array<InputMaybe<Scalars['GraphQLObjectId']['input']>>;
  currencies: Array<InputMaybe<Scalars['String']['input']>>;
  instrumentIds: Array<InputMaybe<Scalars['GraphQLObjectId']['input']>>;
  statuses: Array<InputMaybe<Scalars['String']['input']>>;
};

export type ShareRegisterFilterInput = {
  tradeDateEnd?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type ShareRegisterItem = {
  __typename?: 'ShareRegisterItem';
  /** sum of settledAmount on orders */
  amount: Scalars['String']['output'];
  clientAccount: Scalars['String']['output'];
  customer?: Maybe<Customer>;
  /** summed upon to and including date */
  date: Scalars['GraphQLDateString']['output'];
  fund?: Maybe<PartyInfo>;
  /** fundId */
  fundId: Scalars['GraphQLObjectId']['output'];
  instrument: Instrument;
  isin: Scalars['String']['output'];
  /** sum of settledUnits on orders */
  units: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type ShareRegisterUnits = {
  __typename?: 'ShareRegisterUnits';
  /** summed upon to and including date */
  date: Scalars['GraphQLDateString']['output'];
  fund?: Maybe<PartyInfo>;
  /** fundId */
  fundId: Scalars['GraphQLObjectId']['output'];
  instrument: Instrument;
  isin: Scalars['String']['output'];
  /** sum of settledUnits on orders */
  units: Scalars['String']['output'];
};

export type ShareholderEngagement = {
  __typename?: 'ShareholderEngagement';
  agm: Scalars['GraphQLDateString']['output'];
  comment: Scalars['String']['output'];
  date: Scalars['GraphQLDateString']['output'];
  method: EngagementMethod;
  norm: StandardScreenNorm;
  principle: StandardScreenPrinciple;
};

export type ShareholderEngagementInput = {
  agm: Scalars['GraphQLDateString']['input'];
  comment: Scalars['String']['input'];
  date: Scalars['GraphQLDateString']['input'];
  method: EngagementMethod;
  norm: StandardScreenNorm;
  principle: StandardScreenPrinciple;
};

export enum SourceType {
  BloombergFit = 'BloombergFit',
  Coacs = 'Coacs',
  Emsx = 'Emsx',
  Fxgo = 'Fxgo',
  Internal = 'Internal',
  MarkitWire = 'MarkitWire',
  Tradeweb = 'Tradeweb'
}

export enum SsiTradeType {
  Collateral = 'Collateral',
  Security = 'Security'
}

export enum StandardScreenNorm {
  BusinessEthics = 'BusinessEthics',
  Environment = 'Environment',
  HumanRights = 'HumanRights',
  LabourRights = 'LabourRights',
  None = 'None'
}

export enum StandardScreenPrinciple {
  None = 'None',
  Principle1 = 'Principle1',
  Principle2 = 'Principle2',
  Principle3 = 'Principle3',
  Principle4 = 'Principle4',
  Principle5 = 'Principle5',
  Principle6 = 'Principle6',
  Principle7 = 'Principle7',
  Principle8 = 'Principle8',
  Principle9 = 'Principle9',
  Principle10 = 'Principle10'
}

export enum StandardScreenStatus {
  Compliant = 'Compliant',
  NonCompliant = 'NonCompliant',
  Watchlist = 'Watchlist'
}

export enum StatusEnum {
  Active = 'Active',
  Deleted = 'Deleted'
}

export enum SustainableDevelopmentGoal {
  /** No Poverty */
  Goal1_NoPoverty = 'Goal1_NoPoverty',
  /** Zero Hunger */
  Goal2_ZeroHunger = 'Goal2_ZeroHunger',
  /** Good Health and Well-being */
  Goal3_GoodHealthAndWellBeing = 'Goal3_GoodHealthAndWellBeing',
  /** Quality Education */
  Goal4_QualityEducation = 'Goal4_QualityEducation',
  /** Gender Equality */
  Goal5_GenderEquality = 'Goal5_GenderEquality',
  /** Clean Water and Sanitation */
  Goal6_CleanWaterAndSanitation = 'Goal6_CleanWaterAndSanitation',
  /** Affordable and Clean Energy */
  Goal7_AffordableAndCleanEnergy = 'Goal7_AffordableAndCleanEnergy',
  /** Decent Work and Economic Growth */
  Goal8_DecentWorkAndEconomicGrowth = 'Goal8_DecentWorkAndEconomicGrowth',
  /** Industry, Innovation and Infrastructure */
  Goal9_IndustryInnovationAndInfrastructure = 'Goal9_IndustryInnovationAndInfrastructure',
  /** Reduced Inequality */
  Goal10_ReducedInequality = 'Goal10_ReducedInequality',
  /** Sustainable Cities and Communities */
  Goal11_SustainableCitiesAndCommunities = 'Goal11_SustainableCitiesAndCommunities',
  /** Responsible Consumption and Production */
  Goal12_ResponsibleConsumptionAndProduction = 'Goal12_ResponsibleConsumptionAndProduction',
  /** CLimate Action */
  Goal13_ClimateAction = 'Goal13_ClimateAction',
  /** Life Below Water */
  Goal14_LifeBelowWater = 'Goal14_LifeBelowWater',
  /** Life on Land */
  Goal15_LifeOnLand = 'Goal15_LifeOnLand',
  /** Peace, Justice and Strong Institutions */
  Goal16_PeaceJusticeAndStrongInstitutions = 'Goal16_PeaceJusticeAndStrongInstitutions',
  /** Partnerships to achieve the Goal */
  Goal17_PartnershipsToAchieveTheGoal = 'Goal17_PartnershipsToAchieveTheGoal'
}

export type SustainalyticsDataModel = {
  __typename?: 'SustainalyticsDataModel';
  entityId?: Maybe<Scalars['String']['output']>;
  entityName?: Maybe<Scalars['String']['output']>;
  fieldNames: Array<SustainalyticsFieldDefinitionModel>;
  fields?: Maybe<Scalars['GraphQLJSON']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  issuerId?: Maybe<Scalars['String']['output']>;
  issuerName?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsDataModelWithStatus = {
  __typename?: 'SustainalyticsDataModelWithStatus';
  entityId?: Maybe<Scalars['String']['output']>;
  entityName?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Scalars['GraphQLJSON']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  issuerId?: Maybe<Scalars['String']['output']>;
  issuerName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<SustainalyticsStatus>;
};

export type SustainalyticsFieldClusterModel = {
  __typename?: 'SustainalyticsFieldClusterModel';
  fieldClusterId?: Maybe<Scalars['String']['output']>;
  fieldClusterName?: Maybe<Scalars['String']['output']>;
  fieldDefinitions?: Maybe<Array<Maybe<SustainalyticsFieldDefinitionModel>>>;
  fieldIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type SustainalyticsFieldDefinitionModel = {
  __typename?: 'SustainalyticsFieldDefinitionModel';
  description?: Maybe<Scalars['String']['output']>;
  fieldId?: Maybe<Scalars['String']['output']>;
  fieldLength?: Maybe<Scalars['String']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  fieldType?: Maybe<Scalars['String']['output']>;
  possibleValues?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsPackageModel = {
  __typename?: 'SustainalyticsPackageModel';
  clusters?: Maybe<Array<Maybe<SustainalyticsFieldClusterModel>>>;
  packageId?: Maybe<Scalars['String']['output']>;
  packageName?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsProductModel = {
  __typename?: 'SustainalyticsProductModel';
  packages?: Maybe<Array<Maybe<SustainalyticsPackageModel>>>;
  productId?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsReport = {
  __typename?: 'SustainalyticsReport';
  entityId?: Maybe<Scalars['String']['output']>;
  entityName?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  issuerId?: Maybe<Scalars['String']['output']>;
  issuerName?: Maybe<Scalars['String']['output']>;
  reports?: Maybe<Array<Maybe<SustainalyticsReportModel>>>;
};

export type SustainalyticsReportModel = {
  __typename?: 'SustainalyticsReportModel';
  lastUpdate?: Maybe<Scalars['String']['output']>;
  reportId?: Maybe<Scalars['String']['output']>;
  reportName?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsReportUrl = {
  __typename?: 'SustainalyticsReportUrl';
  filename?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsSecurityListModel = {
  __typename?: 'SustainalyticsSecurityListModel';
  entityIds?: Maybe<Array<Scalars['String']['output']>>;
  securityListId?: Maybe<Scalars['String']['output']>;
  securityListName?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsStatus = {
  __typename?: 'SustainalyticsStatus';
  hasPermissions?: Maybe<Scalars['Boolean']['output']>;
  matched?: Maybe<Scalars['String']['output']>;
};

export type Swaplab2Results = {
  __typename?: 'Swaplab2Results';
  creditYieldDelta?: Maybe<Scalars['Float']['output']>;
  interestRateYieldDelta?: Maybe<Scalars['Float']['output']>;
};

export enum SwedishFinancialAuthorityCategoryEnum {
  Banks = 'Banks',
  Emu = 'Emu',
  HousingInstitutes = 'HousingInstitutes',
  MunicipalAdministration = 'MunicipalAdministration',
  OfWhichThePensionsAuthority = 'OfWhichThePensionsAuthority',
  OtherEu = 'OtherEu',
  OtherForeign = 'OtherForeign',
  OtherSwedishFinancialInstitutions = 'OtherSwedishFinancialInstitutions',
  PensionInstitutes = 'PensionInstitutes',
  SocialSecurityFunds = 'SocialSecurityFunds',
  SumForeign = 'SumForeign',
  SwedishFinancialCompanies = 'SwedishFinancialCompanies',
  SwedishHouseholds = 'SwedishHouseholds',
  SwedishInsuranceCompanies = 'SwedishInsuranceCompanies'
}

export type Swift = {
  __typename?: 'Swift';
  _id: Scalars['GraphQLObjectId']['output'];
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  correspondingTransactionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  createTimestamp: Scalars['Timestamp']['output'];
  error?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parsed: Scalars['GraphQLJSON']['output'];
  /**
   * Currently the swift original is a report. We want to move it to
   * the "text" field.
   */
  reportIds: Array<Scalars['GraphQLObjectId']['output']>;
  status: SwiftStatusEnum;
  text: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
};

export type SwiftFilterInput = {
  /** Corporate action event */
  caev?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Corresponding transaction */
  correspondingTransactionId?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  /** Corporate action event reference */
  externalId?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Message type */
  msgType?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Record date, format: YYYY-MM-DD */
  rdteEnd?: InputMaybe<Scalars['String']['input']>;
  /** Record date, format: YYYY-MM-DD */
  rdteStart?: InputMaybe<Scalars['String']['input']>;
};

export enum SwiftStatusEnum {
  Confirmed = 'Confirmed',
  Deleted = 'Deleted',
  Pending = 'Pending',
  Preliminary = 'Preliminary',
  /** Messages of type 566 gets status settled when matched */
  Settled = 'Settled',
  /** Used for msgType 566 when original swift of msgType 564 has been updated and swift of msgType is insignificant */
  UpdatedPrevious = 'UpdatedPrevious',
  /**
   * Used for messages with caev: DRIP/DVOP, theese are received earlier (not received two days after ex-date
   * like other caevs)
   */
  Waiting = 'Waiting'
}

export type TAccount = {
  __typename?: 'TAccount';
  description?: Maybe<Scalars['String']['output']>;
  group1?: Maybe<TAccountGroup1Enum>;
  group2?: Maybe<TAccountGroup2Enum>;
  group3?: Maybe<TAccountGroup3Enum>;
  group4?: Maybe<TAccountGroup4Enum>;
  group5?: Maybe<TAccountGroup5Enum>;
  number: Scalars['String']['output'];
  sruCode?: Maybe<Scalars['String']['output']>;
  type?: Maybe<TAccountTypeEnum>;
};

export type TAccountChart = {
  __typename?: 'TAccountChart';
  _id: Scalars['GraphQLObjectId']['output'];
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  createUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  locked?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tAccountMappings?: Maybe<Array<TAccountMapping>>;
  tAccounts?: Maybe<Array<TAccount>>;
  version?: Maybe<Scalars['Int']['output']>;
};

export enum TAccountGroup1Enum {
  Assets = 'Assets',
  Equity = 'Equity',
  Liabilities = 'Liabilities'
}

export enum TAccountGroup2Enum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  DividendPaid = 'DividendPaid',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  ProfitLossCurrentYear = 'ProfitLossCurrentYear',
  Q = 'Q',
  R = 'R',
  RetainedEarnings = 'RetainedEarnings',
  S = 'S',
  ShareCapital = 'ShareCapital',
  T = 'T'
}

export enum TAccountGroup3Enum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  DividendPaid = 'DividendPaid',
  E = 'E',
  Expenses = 'Expenses',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  RetainedEarnings = 'RetainedEarnings',
  Revenue = 'Revenue',
  S = 'S',
  ShareCapital = 'ShareCapital',
  T = 'T',
  Tax = 'Tax'
}

export enum TAccountGroup4Enum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  ManagementFees = 'ManagementFees',
  N = 'N',
  None = 'None',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T'
}

export enum TAccountGroup5Enum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  None = 'None',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T'
}

export type TAccountInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  group1?: InputMaybe<Scalars['String']['input']>;
  group2?: InputMaybe<Scalars['String']['input']>;
  group3?: InputMaybe<Scalars['String']['input']>;
  group4?: InputMaybe<Scalars['String']['input']>;
  group5?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['String']['input'];
  sruCode?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TAccountTypeEnum>;
};

export type TAccountMapping = {
  __typename?: 'TAccountMapping';
  name?: Maybe<Scalars['String']['output']>;
  selector: TAccountMappingSelector;
  values?: Maybe<Array<TAccountMappingKeyValuePair>>;
};

export type TAccountMappingInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  selector: TAccountMappingSelectorInput;
  values?: InputMaybe<Array<TAccountMappingKeyValuePairInput>>;
};

export type TAccountMappingKeyValuePair = {
  __typename?: 'TAccountMappingKeyValuePair';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TAccountMappingKeyValuePairInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TAccountMappingSelector = {
  __typename?: 'TAccountMappingSelector';
  categories?: Maybe<Array<Scalars['String']['output']>>;
  currencies?: Maybe<Array<Scalars['String']['output']>>;
  instruments?: Maybe<Array<InstrumentSelector>>;
  key?: Maybe<TAccountMappingSelectorKeyEnum>;
  notMatchCategories?: Maybe<Scalars['Boolean']['output']>;
  notMatchCurrencies?: Maybe<Scalars['Boolean']['output']>;
  notMatchProductTypes?: Maybe<Scalars['Boolean']['output']>;
  productTypes?: Maybe<Array<Scalars['String']['output']>>;
};

export type TAccountMappingSelectorInput = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  currencies?: InputMaybe<Array<Scalars['String']['input']>>;
  instruments?: InputMaybe<Array<InstrumentSelectorInput>>;
  key?: InputMaybe<TAccountMappingSelectorKeyEnum>;
  notMatchCategories?: InputMaybe<Scalars['Boolean']['input']>;
  notMatchCurrencies?: InputMaybe<Scalars['Boolean']['input']>;
  notMatchProductTypes?: InputMaybe<Scalars['Boolean']['input']>;
  productTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum TAccountMappingSelectorKeyEnum {
  Equity = 'Equity',
  OpeningBalance = 'OpeningBalance',
  Rounding = 'Rounding'
}

export enum TAccountTypeEnum {
  Asset = 'Asset',
  Income = 'Income',
  Liability = 'Liability'
}

/**
 * Subset of PermissionAssetEnum, used for validation since a role with any value from TeamPermissionAssetEnum can only
 * have length 1
 */
export enum TeamPermissionAssetEnum {
  /** Used for Operations team role. One role/team. */
  Operations = 'Operations',
  /** Used for PortfolioManagers team role. One role/team. */
  PortfolioManagers = 'PortfolioManagers'
}

export type Ticket = {
  __typename?: 'Ticket';
  _id: Scalars['GraphQLObjectId']['output'];
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  creator: UserInfo;
  creatorId: Scalars['GraphQLObjectId']['output'];
  previousStates: Array<TicketState>;
  readByUserIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  references?: Maybe<Array<TicketReference>>;
  state: TicketState;
  title: Scalars['String']['output'];
  type: TicketTypeEnum;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
};

export type TicketFilterInput = {
  assigneeIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  creatorIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<TicketStatusEnum>>;
  typeIn?: InputMaybe<Array<TicketTypeEnum>>;
};

export type TicketInput = {
  /** if _id is omitted, a document will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  references?: InputMaybe<Array<TicketReferenceInput>>;
  state: TicketStateInput;
  title: Scalars['String']['input'];
  type: TicketTypeEnum;
};

export type TicketReference = {
  __typename?: 'TicketReference';
  collection: CollectionNameEnum;
  documentId: Scalars['GraphQLObjectId']['output'];
};

export type TicketReferenceInput = {
  collection: CollectionNameEnum;
  documentId: Scalars['GraphQLObjectId']['input'];
};

export type TicketState = {
  __typename?: 'TicketState';
  assigneeIds: Array<Scalars['GraphQLObjectId']['output']>;
  assignees: Array<UserInfo>;
  /** comment is a markdown string */
  comment: Scalars['String']['output'];
  status: TicketStatusEnum;
  timestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
};

export type TicketStateInput = {
  assigneeIds: Array<Scalars['GraphQLObjectId']['input']>;
  comment: Scalars['String']['input'];
  status: TicketStatusEnum;
};

export enum TicketStatusEnum {
  Closed = 'Closed',
  Open = 'Open'
}

export enum TicketTypeEnum {
  AccountingRunStatusError = 'AccountingRunStatusError',
  CorporateActionError = 'CorporateActionError',
  Error = 'Error',
  ExposureCollateralError = 'ExposureCollateralError',
  ExternalDataError = 'ExternalDataError',
  FundUnitReconciliationError = 'FundUnitReconciliationError',
  MarginCall = 'MarginCall',
  MatchBrokerTransactionError = 'MatchBrokerTransactionError',
  PositionReconciliationError = 'PositionReconciliationError'
}

export type TicketUser = {
  __typename?: 'TicketUser';
  _id: Scalars['GraphQLObjectId']['output'];
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients?: Maybe<Array<Maybe<PartyInfo>>>;
  name: Scalars['String']['output'];
};

export type TimeSeries = {
  __typename?: 'TimeSeries';
  _id: Scalars['GraphQLObjectId']['output'];
  client?: Maybe<Party>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  dates?: Maybe<Array<Maybe<Scalars['GraphQLDateString']['output']>>>;
  description?: Maybe<Scalars['String']['output']>;
  /** The level of Value At Risk expressed as number, e.g 0.95 for 95% VaR */
  downSideVar?: Maybe<Scalars['Float']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  items?: Maybe<Array<Maybe<TimeSeriesItem>>>;
  /** The objectId points either to an instrument or a portfolio */
  objectId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  status: TimeSeriesStatus;
  title?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  volatility?: Maybe<Scalars['Float']['output']>;
};


export type TimeSeriesDownSideVarArgs = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  level?: InputMaybe<Scalars['Float']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type TimeSeriesVolatilityArgs = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type TimeSeriesFilterInput = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  /** ignored for timeserie() */
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  /** ignored for timeserie() */
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  /** ignored for timeserie() */
  instrumentIsinIn?: InputMaybe<Array<Scalars['ISIN']['input']>>;
  /** ignored for timeserie() */
  instrumentNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  /** ignored for timeserie() */
  statusIn?: InputMaybe<Array<TimeSeriesStatus>>;
  /** ignored for timeserie() */
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TimeSeriesInput = {
  /** If _id is omitted, a TimeSeries will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** clientId defaults to SYSTEM */
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  items: Array<TimeSeriesItemInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  /**
   * Type is typically one of: Price(Close),
   * Price(Last), Return(Total), Volume
   */
  type?: InputMaybe<Scalars['String']['input']>;
};

export type TimeSeriesItem = {
  __typename?: 'TimeSeriesItem';
  _id: Scalars['GraphQLObjectId']['output'];
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type TimeSeriesItemInput = {
  date: Scalars['GraphQLDateString']['input'];
  value: Scalars['Float']['input'];
};

export enum TimeSeriesStatus {
  Active = 'Active',
  Deleted = 'Deleted'
}

export type TimeSeriesUpsertInput = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  items: Array<TimeSeriesItemInput>;
  type: Scalars['String']['input'];
};

export enum TimezoneEnum {
  AmericaChicago = 'AmericaChicago',
  AmericaNew_York = 'AmericaNew_York',
  AsiaHong_Kong = 'AsiaHong_Kong',
  AsiaTokyo = 'AsiaTokyo',
  EuropeAmsterdam = 'EuropeAmsterdam',
  EuropeBerlin = 'EuropeBerlin',
  EuropeBrussels = 'EuropeBrussels',
  EuropeCopenhagen = 'EuropeCopenhagen',
  EuropeHelsinki = 'EuropeHelsinki',
  EuropeLondon = 'EuropeLondon',
  EuropeLuxembourg = 'EuropeLuxembourg',
  EuropeOslo = 'EuropeOslo',
  EuropeParis = 'EuropeParis',
  EuropeStockholm = 'EuropeStockholm',
  EuropeZurich = 'EuropeZurich'
}

export enum TradeItemType {
  AccruedInterest = 'AccruedInterest',
  Commission = 'Commission',
  StampDuty = 'StampDuty'
}

export type TradingManagerColumn = {
  __typename?: 'TradingManagerColumn';
  account?: Maybe<PartyAccount>;
  accountDescription?: Maybe<Scalars['String']['output']>;
  /** // Account data */
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  accountName?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  baseCurrency?: Maybe<CurrencyEnum>;
  bloombergTicker?: Maybe<Scalars['String']['output']>;
  client?: Maybe<ClientInfo>;
  /** // Client data */
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  creditRatings?: Maybe<Scalars['GraphQLJSON']['output']>;
  creditYieldDelta?: Maybe<Scalars['Float']['output']>;
  /** // Position data */
  currency?: Maybe<CurrencyEnum>;
  /** // Exposure */
  exposure?: Maybe<Scalars['Float']['output']>;
  externalAccount?: Maybe<PartyExternalAccount>;
  /** // Custody Account data */
  externalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  externalAccountName?: Maybe<Scalars['String']['output']>;
  externalAccountType?: Maybe<PartyExternalAccountType>;
  financedExposure?: Maybe<Scalars['Float']['output']>;
  financedLocalExposure?: Maybe<Scalars['Float']['output']>;
  financing?: Maybe<Scalars['Float']['output']>;
  fxRate?: Maybe<Scalars['Float']['output']>;
  fxRateDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  /** // Connect full instrument and accounts for simple access */
  instrument?: Maybe<Instrument>;
  /** // Instrument data */
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  interestRateYieldDelta?: Maybe<Scalars['Float']['output']>;
  isCashAccount?: Maybe<Scalars['Boolean']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  issuerCreditRatings?: Maybe<Scalars['GraphQLJSON']['output']>;
  /** // Issuer data */
  issuerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  issuerIndustryCode?: Maybe<Scalars['String']['output']>;
  issuerLei?: Maybe<Scalars['String']['output']>;
  issuerName?: Maybe<Scalars['String']['output']>;
  issuerType?: Maybe<IssuerTypeEnum>;
  localExposure?: Maybe<Scalars['Float']['output']>;
  localFinancing?: Maybe<Scalars['Float']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  maturityDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  modelType?: Maybe<InstrumentModelTypeEnum>;
  name?: Maybe<Scalars['String']['output']>;
  portfolioInstrument?: Maybe<Instrument>;
  /** // Portfolio swap related */
  portfolioInstrumentId: Scalars['GraphQLObjectId']['output'];
  quantity?: Maybe<Scalars['Float']['output']>;
  /** // Issuer program data */
  sustainabilityClass?: Maybe<IssuerProgramCategory>;
  /** // Yield Deltas from Swaplab2 */
  swaplab2Results?: Maybe<Swaplab2Results>;
  valuationAccruedInterest?: Maybe<Scalars['Float']['output']>;
  valuationCleanPrice?: Maybe<Scalars['Float']['output']>;
  valuationCurrency?: Maybe<CurrencyEnum>;
  /** // Valuation */
  valuationDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  valuationPrice?: Maybe<Scalars['Float']['output']>;
};


export type TradingManagerColumnCreditYieldDeltaArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type TradingManagerColumnInterestRateYieldDeltaArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type TradingManagerColumnSwaplab2ResultsArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

/** A Transaction consists of one or more Transaction.items. */
export type Transaction = {
  __typename?: 'Transaction';
  /** _id is the database objectId generated when transaction is first saved. */
  _id: Scalars['GraphQLObjectId']['output'];
  attachments?: Maybe<Array<Attachment>>;
  /**
   * BestExecutionType is an enum telling in which capacity a transaction is
   * conducted under the MiFID II regulation.
   */
  bestExecutionType?: Maybe<BestExecutionType>;
  broker?: Maybe<Party>;
  /**
   * The objectId of the broker of the transaction. The broker is the party with
   * who the transaction i settled.
   */
  brokerId: Scalars['GraphQLObjectId']['output'];
  /**
   * BrokerTradeId is the unique reference of a transaction supplied by the broker.
   * The reference number is used for matching reconciling transaction with broker
   * confirmation.
   */
  brokerTradeId?: Maybe<Scalars['String']['output']>;
  brokerTransaction?: Maybe<BrokerTransaction>;
  /** CarryOwnCostDate is a date string with the date where the transaction should affect adjustment amount. */
  carryOwnCostDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  clearingHouse?: Maybe<Party>;
  /** Clearing House is used for transactions with agreement type clearingAccount/omnibusClearingAccount */
  clearingHouseId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  client?: Maybe<Party>;
  /** ClientId is the objectId of the Party(Type=Client) owning the Transaction. */
  clientId: Scalars['GraphQLObjectId']['output'];
  corporateActionSwift?: Maybe<MiniSwift>;
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  /** Description is a free text field where comments can be stored. */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * Error is a free text field where error messages should be posted during trade
   * matching. When trade Status is set to Instructed it should be erased.
   */
  error?: Maybe<Scalars['String']['output']>;
  /**
   * ExternalId is a unique reference of the transaction in delivering system. If
   * transaction is created without a external system a unique number is generated.
   */
  externalId: Scalars['String']['output'];
  /** Items consists of a list of one or more transaction items. */
  items?: Maybe<Array<TransactionItem>>;
  /** Number is generated as a unbroken sequence when accounting journal is Confirmed. */
  number: Scalars['Int']['output'];
  /** parentId makes it possible to link a transaction to another transaction. */
  parentId: Scalars['GraphQLObjectId']['output'];
  /**
   * Source is an enum which differs for different types of trades,
   * other transactions should have source Internal.
   */
  source: SourceType;
  /**
   * Status is an enum describing where in the processing the transaction is at the
   * moment and which user can update or delete it. Status has implications for
   * accounting module.
   */
  status?: Maybe<TransactionStatus>;
  /** tradeDate is calculated from tradeTimestamp with Europe/Stockholm timezone */
  tradeDate: Scalars['GraphQLDateString']['output'];
  /**
   * TradeTimeStamp is the time the transaction is created. If not supplied it will
   * be generated at the time of creation. If only date is given time is set to
   * 12.00.00.
   */
  tradeTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  /** Type is used for identifying the the creating transaction object. */
  type?: Maybe<TransactionType>;
  /**
   * UniqueTradeId is the reference number of the transaction reported to central
   * repository under the EMIR regulation.
   */
  uniqueTradeId?: Maybe<Scalars['String']['output']>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  /** Id of user who updated/created transaction */
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  /** A list of versions of the document */
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type TransactionFilterInput = {
  accountIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  externalIdIn?: InputMaybe<Array<Scalars['String']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  instrumentIdsIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  sourceIn?: InputMaybe<Array<SourceType>>;
  /** Added start and end date to comply with legacy PositionFilter */
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<TransactionStatus>>;
  statusNotIn?: InputMaybe<Array<TransactionStatus>>;
  tradeTimestampEnd?: InputMaybe<Scalars['Timestamp']['input']>;
  tradeTimestampStart?: InputMaybe<Scalars['Timestamp']['input']>;
  typesIn?: InputMaybe<Array<TransactionType>>;
  typesNotIn?: InputMaybe<Array<TransactionType>>;
  updatedAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedBefore?: InputMaybe<Scalars['Timestamp']['input']>;
  valueDateEnd?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  valueDateStart?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type TransactionIdentifier = {
  /** Used for CreateRedeem transactions which we often receive earlier than expected */
  newValueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  rounding?: InputMaybe<Scalars['Float']['input']>;
  transactionId: Scalars['GraphQLObjectId']['input'];
  transactionItemId: Scalars['GraphQLObjectId']['input'];
};

export type TransactionItem = {
  __typename?: 'TransactionItem';
  _id: Scalars['GraphQLObjectId']['output'];
  /** Account gives the Account object. */
  account?: Maybe<PartyAccount>;
  /**
   * AccountId is the objectId of the Account defined in Party(Client).
   * A value of '000000000000000000000000' is interpreted as no yAccount.
   */
  accountId: Scalars['GraphQLObjectId']['output'];
  /** Amount is the amount of the item in the item currency. */
  amount: Scalars['Float']['output'];
  /**
   * Ids of bankAccountTransactions that the item amount has matched with, used for reconciliation
   * of bank accounts
   */
  bankAccountTransactionIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  /** Array of matched bank account transactions */
  bankAccountTransactions?: Maybe<Array<BankAccountTransaction>>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  /** Currency denotes the currency of the item amount. */
  currency?: Maybe<CurrencyEnum>;
  /**
   * Custodian gives the Custodian of the ExternalAccount: Custodian can be the actual
   * custodian of a SecuritiesAccount or the counter party under
   * [ISDA](https://en.wikipedia.org/wiki/ISDA_Master_Agreement),
   * GMSLA or GMRA
   */
  custodian?: Maybe<Party>;
  /**
   * Error is a free text field where error messages should be posted bank account transaction
   * matching. When transactionsStatus is set to Confirmed it should be erased.
   */
  error?: Maybe<Scalars['String']['output']>;
  /** ExternalAccount gives the ExternalAccount object. */
  externalAccount?: Maybe<PartyExternalAccount>;
  /**
   * ExternalAccountId is the objectId of the ExternalAccount defined in Party(Client).
   * A ExternalAccount in a wider definition is a counter party risk netting set and
   * can be a SecuritiesAccount as well as an ISDA CSA.
   * A value of '000000000000000000000000' is interpreted as no ExternalAccount.
   */
  externalAccountId: Scalars['GraphQLObjectId']['output'];
  /**
   * FxRate is set so that the sum of the items will be zero in the Client
   * accounting currency.
   */
  fxRate?: Maybe<Scalars['Float']['output']>;
  /** Instrument gives the Instrument object. */
  instrument?: Maybe<Instrument>;
  /**
   * InstrumentId is mandatory and is the objectId of the instrument in the
   * item. Fot item types in Security, CreateRedeem, Collateral and
   * CollateralPledge the instrumentId points to the Instrument collection
   * while SettlementAmount and PayableReceivable
   * points to instruments defined in the Party(Client) collection.
   */
  instrumentId: Scalars['GraphQLObjectId']['output'];
  /**
   * PerformanceType is an enum that dictates how to handle Normal transaction items,
   * Portfolio transaction items and PortfolioItem transaction items, explained in
   * the enum above. Here Portfolio and PortfolioItem are mutually exclusive and used to
   * either look at the portfolio swap instrument itself or the constituents
   */
  performanceType: TransactionItemPerformanceType;
  /** Portfolio Instrument gives the Instrument object. */
  portfolioInstrument?: Maybe<Instrument>;
  /**
   * PortfolioInstrumentId points to the portfolio swap instrument for all transaction items
   * performanceType = PortfolioItem and for all other, Normal and Portfolio,
   * portfolioInstrumentId = ObjectId("000000000000000000000000").
   */
  portfolioInstrumentId: Scalars['GraphQLObjectId']['output'];
  /** Price is mandatory for Security item type. */
  price?: Maybe<Scalars['Float']['output']>;
  /** Quantity is mandatory for Security item type. */
  quantity?: Maybe<Scalars['Float']['output']>;
  transaction?: Maybe<Transaction>;
  /** Id of transaction which item belongs to. */
  transactionId: Scalars['GraphQLObjectId']['output'];
  transactionStatus: TransactionStatus;
  transactionTradeDate: Scalars['GraphQLDateString']['output'];
  transactionType: TransactionType;
  /** Type is an enum defining the business logic of the item. */
  type: TransactionItemType;
  /**
   * ValueDate denotes the date a SettlementAmount becomes a current account balance
   * or the date a Security is delivered. With Europe/Stockholm timezone
   */
  valueDate?: Maybe<Scalars['GraphQLDateString']['output']>;
};

export type TransactionItemFilterInput = {
  accountIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  externalAccountIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  externalAccountTypeIn?: InputMaybe<Array<PartyExternalAccountType>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  includePortfolioSwapConstituents?: InputMaybe<Scalars['Boolean']['input']>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  itemTypeIn?: InputMaybe<Array<TransactionItemType>>;
  positionTypeIn?: InputMaybe<Array<PositionTypeEnum>>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<TransactionStatus>>;
  tradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  transactionIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  valueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export enum TransactionItemPerformanceType {
  /**
   * Normal is used for all transaction items that have nothing to do with
   * portfolio swaps
   */
  Normal = 'Normal',
  /**
   * Portfolio is used for all transaction items that involves the
   * portfolio swaps directly
   */
  Portfolio = 'Portfolio',
  /**
   * PortfolioItem is used for all transaction items that involves the
   * constituent of portfolio swaps. They are interlinked to the portfolio
   * swaps by portfolioInstrumentId of an transaction item
   */
  PortfolioItem = 'PortfolioItem'
}

export enum TransactionItemType {
  /**
   * AccruedInterest in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  AccruedInterest = 'AccruedInterest',
  /**
   * Collateral is an off-balance position which is not position building in
   * terms of performance or risk calculations but included inventory position for the
   * purpose of reconciling a Securities ExternalAccount. Collateral is not included
   * in accounting or performance calculations.
   */
  Collateral = 'Collateral',
  /**
   * CollateralPledge is an off-balance position which is not position building in
   * terms of performance or risk calculations but included in netting-sets such as
   * ISDA CSA or GMSLA. CollateralPledge is not included in accounting or performance
   * calculations.
   */
  CollateralPledge = 'CollateralPledge',
  /** Same as CollateralPledge, need this to separate if CollateralPledge is initial margin or variation margin */
  CollateralPledgeInitialMargin = 'CollateralPledgeInitialMargin',
  /**
   * Commission in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  Commission = 'Commission',
  /**
   * CreateRedeem denoted creations and redemptions of fund units. It is not position
   * building and not included in performance calculations. It belongs to the Balance
   * accounting model.
   */
  CreateRedeem = 'CreateRedeem',
  /** A third CreateRedeem type. Only books amount. Doesn't set quantity and amount in the NAV process. */
  CreateRedeemAdjustmentAmount = 'CreateRedeemAdjustmentAmount',
  /** Do we need this one? Yes! Because NAV trade initialization and population differs from standard CreateRedeem. */
  CreateRedeemAdjustmentShares = 'CreateRedeemAdjustmentShares',
  /**
   * CustodyFee in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  CustodyFee = 'CustodyFee',
  /**
   * Dividend in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  Dividend = 'Dividend',
  /**
   * DividendPaid denotes dividend paid from fund classes. It is not position
   * building and not included in performance calculations.
   */
  DividendPaid = 'DividendPaid',
  /**
   * Fee in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  Fee = 'Fee',
  /**
   * ForeignTax in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  ForeignTax = 'ForeignTax',
  /**
   * Interest in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  Interest = 'Interest',
  /**
   * ManagementCost in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  ManagementCost = 'ManagementCost',
  /**
   * ManagementFee in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  ManagementFee = 'ManagementFee',
  /**
   * PayableReceivable denotes balance sheet items other than current accounts or
   * financial instruments such as margins or loans and deposits under GMSLA or
   * GMRA agreements. PayableReceivable belong to the Asset performance model and
   * the Balance accounting model.
   */
  PayableReceivable = 'PayableReceivable',
  /**
   * Rebate is a type of fee , used to be able to differentiate between dividends and fundrebates. We book fundrebates
   * with quantity as stocktrades with price 0 and cash fundrebates as rebates.
   */
  Rebate = 'Rebate',
  /**
   * Security is position building and belongs to the Inventory accounting
   * model and the Asset performance model.
   */
  Security = 'Security',
  /** Similar to CollateralPledge but posting collateral instead of receiving. */
  SecurityLoan = 'SecurityLoan',
  /**
   * SettlementAmounts is payments on current accounts and is position
   * building belongs to the Asset performance and the Balance accounting model.
   */
  SettlementAmount = 'SettlementAmount',
  /**
   * StampDuty in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  StampDuty = 'StampDuty',
  /**
   * Tax in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  Tax = 'Tax',
  /**
   * TaxRestitution in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  TaxRestitution = 'TaxRestitution'
}

export enum TransactionStatus {
  /**
   * Confirmed status is set when trade is confirmed with counterparty by the
   * Operations unit.
   */
  Confirmed = 'Confirmed',
  /**
   * Deleted denotes a transaction that has been deleted but still
   * exists in the database.
   */
  Deleted = 'Deleted',
  /** History is used when setting up a new client with historic transactions. */
  History = 'History',
  /**
   * Instructed denotes when a transaction has been
   * instructed with the Custodian.
   */
  Instructed = 'Instructed',
  /** Internal is used for transactions created only for internal use. */
  Internal = 'Internal',
  /**
   * Pending is a transaction entered by an external system or user that has not
   * been confirmed by the Operations unit.
   */
  Pending = 'Pending',
  /**
   * Preliminary is a status where one or more parameters is
   * not yet confirmed and will be treated as such in accounting
   * terms and will not be given a number until it reaches
   * at least Confirmed status.
   */
  Preliminary = 'Preliminary',
  /**
   * Settled denotes when a security has been delivered in custody
   * account or cash has been settled on a current account.
   */
  Settled = 'Settled'
}

export enum TransactionType {
  BondTrade = 'BondTrade',
  Collateral = 'Collateral',
  CreateRedeem = 'CreateRedeem',
  DerivativeTrade = 'DerivativeTrade',
  Dividend = 'Dividend',
  Fee = 'Fee',
  FundTrade = 'FundTrade',
  FxOutright = 'FxOutright',
  FxSpot = 'FxSpot',
  Insert = 'Insert',
  Interest = 'Interest',
  IrSwap = 'IrSwap',
  StockTrade = 'StockTrade',
  Trade = 'Trade',
  Transfer = 'Transfer'
}

export type TransferAgentOrder = {
  __typename?: 'TransferAgentOrder';
  _id: Scalars['GraphQLObjectId']['output'];
  adjustmentAmount: Scalars['String']['output'];
  adjustmentShares: Scalars['String']['output'];
  amount: Scalars['String']['output'];
  attachments?: Maybe<Array<Attachment>>;
  carryOwnTransactionCost: Scalars['Boolean']['output'];
  client: Party;
  clientAccount?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['GraphQLObjectId']['output'];
  clientName?: Maybe<Scalars['String']['output']>;
  clientNumber?: Maybe<Scalars['String']['output']>;
  createTimestamp: Scalars['Timestamp']['output'];
  currency: CurrencyEnum;
  description: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  instrument: Instrument;
  isin: Scalars['ISIN']['output'];
  netPrice: Scalars['String']['output'];
  /** If subType is ACCOUNT_SWITCH, referenceExternalId must be set to corresponding externalId */
  referenceExternalId: Scalars['String']['output'];
  settledAmount: Scalars['String']['output'];
  settledUnits: Scalars['String']['output'];
  source: Party;
  sourceId: Scalars['GraphQLObjectId']['output'];
  status: TransactionStatus;
  subType: TransferAgentOrderSubTypeEnum;
  tradeDate: Scalars['GraphQLDateString']['output'];
  transactionIds: Array<Scalars['GraphQLObjectId']['output']>;
  transactionType: TransferAgentOrderEnum;
  units: Scalars['String']['output'];
  updateTimestamp: Scalars['Timestamp']['output'];
  /** Id of the user that updated/created */
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  valueDate: Scalars['GraphQLDateString']['output'];
  /** A list of versions of the document */
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type TransferAgentOrderAttachmentsInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
};

export enum TransferAgentOrderEnum {
  BUY = 'BUY',
  SELL = 'SELL'
}

export type TransferAgentOrderInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  adjustmentAmount?: InputMaybe<Scalars['String']['input']>;
  adjustmentShares?: InputMaybe<Scalars['String']['input']>;
  amount: Scalars['String']['input'];
  carryOwnTransactionCost?: InputMaybe<Scalars['Boolean']['input']>;
  clientAccount?: InputMaybe<Scalars['String']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  isin: Scalars['ISIN']['input'];
  netPrice?: InputMaybe<Scalars['String']['input']>;
  /** If subType is ACCOUNT_SWITCH referenceExternalId, must be set to corresponding externalId */
  referenceExternalId: Scalars['String']['input'];
  settledAmount?: InputMaybe<Scalars['String']['input']>;
  settledUnits?: InputMaybe<Scalars['String']['input']>;
  sourceId: Scalars['GraphQLObjectId']['input'];
  status: TransactionStatus;
  subType: TransferAgentOrderSubTypeEnum;
  tradeDate: Scalars['GraphQLDateString']['input'];
  transactionIds: Array<Scalars['GraphQLObjectId']['input']>;
  transactionType: TransferAgentOrderEnum;
  units: Scalars['String']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export enum TransferAgentOrderSubTypeEnum {
  ACCOUNT_SWITCH = 'ACCOUNT_SWITCH',
  NORMAL = 'NORMAL'
}

export type TransferAgentTotalUnit = {
  __typename?: 'TransferAgentTotalUnit';
  _id: Scalars['GraphQLObjectId']['output'];
  date: Scalars['GraphQLDateString']['output'];
  instrument: Instrument;
  isin: Scalars['ISIN']['output'];
  source: Party;
  sourceId: Scalars['GraphQLObjectId']['output'];
  totalUnits: Scalars['Float']['output'];
  updateTimestamp: Scalars['Timestamp']['output'];
};

export type TrialInput = {
  clientId: Scalars['GraphQLObjectId']['input'];
};

export enum TrueFalseNone {
  False = 'False',
  None = 'None',
  True = 'True'
}

export type UpdateAccountingRunInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  accountingPeriod: Scalars['String']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  clientTAccountChartId: Scalars['GraphQLObjectId']['input'];
  endDate: Scalars['GraphQLDateString']['input'];
  journalEntries: Array<JournalEntryInput>;
  keepManualJournalEntries?: InputMaybe<Scalars['Boolean']['input']>;
  masterTAccountChartId: Scalars['GraphQLObjectId']['input'];
  type: AccountingRunTypeEnum;
};

export type UpdateBrokerTransactionInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  correspondingTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BrokerTransactionStatus>;
};

export type UpdateCurveDefinitionInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  definition?: InputMaybe<CurveDefinitionDefinitionInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CurveDefinitionType>;
};

export type UpdateInstrumentInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  aliases?: InputMaybe<Array<AliasInput>>;
  benchmarkId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  bic?: InputMaybe<Scalars['String']['input']>;
  bloombergDailyDownload?: InputMaybe<Scalars['Boolean']['input']>;
  bloombergQuoteMultiplier?: InputMaybe<Scalars['Float']['input']>;
  bloombergTicker?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<InstrumentCategory>;
  cic?: InputMaybe<Scalars['String']['input']>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  creditRatings?: InputMaybe<Array<InputMaybe<CreditRatingInput>>>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  exchange?: InputMaybe<Scalars['String']['input']>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  isin?: InputMaybe<Scalars['ISIN']['input']>;
  issuerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  issuerProgramId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  /** https://www.iso20022.org/market-identifier-codes */
  mic?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<InstrumentModelInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  placeOfSettlementId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  productType?: InputMaybe<InstrumentProductTypeEnum>;
  quantityDecimals?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<InstrumentStatusEnum>;
  type?: InputMaybe<InstrumentTypeEnum>;
};

export type UpdateJournalEntryInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  accountingRunId: Scalars['GraphQLObjectId']['input'];
  batch?: InputMaybe<AccountingBatchType>;
  clientId: Scalars['GraphQLObjectId']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  externalNumber?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  portfolioTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  transactions?: InputMaybe<Array<AccountingTransactionInput>>;
};

export type UpdateMiniTransactionInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  accruedInterest?: InputMaybe<Scalars['Float']['input']>;
  agreementType?: InputMaybe<AgreementType>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  bestExecutionType?: InputMaybe<BestExecutionType>;
  bonusShares?: InputMaybe<Scalars['Float']['input']>;
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  brokerTradeId?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  clearingBrokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clearingHouseId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  close?: InputMaybe<Scalars['Boolean']['input']>;
  commission?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  dividendPaid?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  fee?: InputMaybe<Scalars['Float']['input']>;
  foreignTax?: InputMaybe<Scalars['Float']['input']>;
  fxRate?: InputMaybe<Scalars['Float']['input']>;
  initialMargin?: InputMaybe<Scalars['Boolean']['input']>;
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  itemType?: InputMaybe<FeeInterestItemType>;
  loan?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  reinvestedDividend?: InputMaybe<Scalars['Boolean']['input']>;
  reinvestedRebate?: InputMaybe<Scalars['Boolean']['input']>;
  rounding?: InputMaybe<Scalars['Float']['input']>;
  secondAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  secondAmount?: InputMaybe<Scalars['Float']['input']>;
  secondCurrency?: InputMaybe<CurrencyEnum>;
  secondInstrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  source?: InputMaybe<SourceType>;
  stampDuty?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TransactionStatus>;
  taxRestitution?: InputMaybe<Scalars['Boolean']['input']>;
  tradeTimestamp?: InputMaybe<Scalars['DateStringOrTimestamp']['input']>;
  uniqueTradeId?: InputMaybe<Scalars['String']['input']>;
  valueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type UpdatePartyInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  accountant?: InputMaybe<Scalars['String']['input']>;
  accountingCompanyType?: InputMaybe<AccountingCompanyTypeEnum>;
  accountingCurrency?: InputMaybe<Scalars['String']['input']>;
  accountingFrequency?: InputMaybe<AccountingFrequencyEnum>;
  accounts?: InputMaybe<Array<InputMaybe<PartyAccountInput>>>;
  address?: InputMaybe<Scalars['String']['input']>;
  aliases?: InputMaybe<Array<AliasInput>>;
  approval?: InputMaybe<Scalars['String']['input']>;
  beneficialOwner?: InputMaybe<BeneficialOwnerInput>;
  bloombergEquityTicker?: InputMaybe<Scalars['String']['input']>;
  businessIdentifierCode?: InputMaybe<Scalars['String']['input']>;
  ceo?: InputMaybe<Scalars['String']['input']>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  complaintsManager?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryCodeEnum>;
  creditRatingScale?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  creditRatings?: InputMaybe<Array<InputMaybe<CreditRatingInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  esg?: InputMaybe<PartyEsgInput>;
  externalAccounts?: InputMaybe<Array<InputMaybe<PartyExternalAccountInput>>>;
  fundInfo?: InputMaybe<PartyFundInfoInput>;
  inLedgerNumber?: InputMaybe<Scalars['Int']['input']>;
  inLedgerTradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  industryCode?: InputMaybe<Scalars['String']['input']>;
  instrumentIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  issuerType?: InputMaybe<IssuerTypeEnum>;
  legalEntityIdentifier?: InputMaybe<Scalars['String']['input']>;
  legalNumber?: InputMaybe<Scalars['String']['input']>;
  limitSlackChannel?: InputMaybe<Scalars['String']['input']>;
  limitStartDate?: InputMaybe<Scalars['Date']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  nace?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  quantityDecimals?: InputMaybe<Scalars['Int']['input']>;
  regulator?: InputMaybe<Scalars['String']['input']>;
  standardSettlementInstructions?: InputMaybe<Array<PartyStandardSettlementInstructionInput>>;
  status?: InputMaybe<PartyStatusEnum>;
  sustainalyticsId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<TimezoneEnum>;
  types?: InputMaybe<Array<InputMaybe<PartyType>>>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReportInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  base64?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  data?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  name: Scalars['String']['input'];
  sourceId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  status?: InputMaybe<ReportStatusEnum>;
  type: Scalars['String']['input'];
};

export type UpdateRoleInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  assets?: InputMaybe<Array<PermissionAssetEnum>>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissionType?: InputMaybe<PermissionTypeEnum>;
  roleType?: InputMaybe<RoleTypeEnum>;
  status?: InputMaybe<DocumentStatusEnum>;
};

export type UpdateSwiftInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  correspondingTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  /** Needed for msgType 566 when updating old swift of msgType 564 */
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parsed?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  reportIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  status?: InputMaybe<SwiftStatusEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTAccountChartInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tAccountMappings?: InputMaybe<Array<TAccountMappingInput>>;
  tAccounts?: InputMaybe<Array<TAccountInput>>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTransactionInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  bestExecutionType?: InputMaybe<BestExecutionType>;
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  brokerTradeId?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  clearingHouseId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Array<UpdateTransactionItemInput>>;
  number?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<TransactionStatus>;
  tradeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  type?: InputMaybe<TransactionType>;
  uniqueTradeId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTransactionItemInput = {
  /** _id should be stated when updating an item, not required when adding new items though */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  bankAccountTransactionIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  error?: InputMaybe<Scalars['String']['input']>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  fxRate?: InputMaybe<Scalars['Float']['input']>;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  performanceType?: InputMaybe<TransactionItemPerformanceType>;
  portfolioInstrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  transactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionTradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  transactionType?: InputMaybe<TransactionType>;
  type: TransactionItemType;
  valueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type UpdateUserInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  bidExpiration?: InputMaybe<Scalars['Int']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  frontendRoleId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  logins?: InputMaybe<Array<UserLoginInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  noteIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  password?: InputMaybe<Scalars['String']['input']>;
  personalNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  status?: InputMaybe<UserStatusEnum>;
};

export enum UseOfProceed {
  /** Access to essential services */
  AccessEssentialServices = 'AccessEssentialServices',
  /** Affordable housing */
  AffordableHousing = 'AffordableHousing',
  /** Affordable basic infrastructure */
  AffordableInfraStructure = 'AffordableInfraStructure',
  /** Clean Transportation */
  CleanTransportation = 'CleanTransportation',
  /** climate change adaptation */
  ClimateChangeAdaption = 'ClimateChangeAdaption',
  /** eco-efficient and/or circular economy adapted products, */
  EcoEfficientProducts = 'EcoEfficientProducts',
  /** Employment generation (including SME financing and Microfinance) */
  EmploymentGeneration = 'EmploymentGeneration',
  /** Energy efficiency */
  EnergyEfficiency = 'EnergyEfficiency',
  /** Environmentally sustainable management of living natural resources and land use */
  EnvironmentallySustainableManagement = 'EnvironmentallySustainableManagement',
  /** Green Buildings */
  GreenBuldings = 'GreenBuldings',
  Other = 'Other',
  /** Pollution prevention and control */
  PollutionPreventionAndControl = 'PollutionPreventionAndControl',
  /** Renewable energy */
  RenewableEnergy = 'RenewableEnergy',
  /** Socioeconomic advancement and empowerment */
  SocioEconomicAdvancement = 'SocioEconomicAdvancement',
  /** Food security and sustainable food systems */
  SustainableFoodSystems = 'SustainableFoodSystems',
  /** Sustainable water and wastewater management */
  SustainableWaterManagement = 'SustainableWaterManagement',
  /** Terrestrial and aquatic biodiversity conservation */
  TerrestrialandAcquaticConservation = 'TerrestrialandAcquaticConservation'
}

export type User = {
  __typename?: 'User';
  _id: Scalars['GraphQLObjectId']['output'];
  bidExpiration?: Maybe<Scalars['Int']['output']>;
  company: Scalars['String']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  frontendRole: Role;
  frontendRoleId: Scalars['GraphQLObjectId']['output'];
  lastBankIdLoginTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  lastLoginTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  lastPasswordUpdate?: Maybe<Scalars['Timestamp']['output']>;
  logins?: Maybe<Array<UserLogin>>;
  name: Scalars['String']['output'];
  noteIds: Array<Scalars['GraphQLObjectId']['output']>;
  notes: Array<Note>;
  password?: Maybe<Scalars['String']['output']>;
  personalNumber: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  roleIds: Array<Scalars['GraphQLObjectId']['output']>;
  roles: Array<Role>;
  status: UserStatusEnum;
  /** Latest terms of service from notes collection */
  termsOfService?: Maybe<Note>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type UserFilterInput = {
  status?: InputMaybe<UserStatusEnum>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  _id: Scalars['GraphQLObjectId']['output'];
  company: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  personalNumber: Scalars['String']['output'];
};

export type UserLimited = {
  __typename?: 'UserLimited';
  _id: Scalars['GraphQLObjectId']['output'];
  /** Only returned when filtering by asset */
  asset?: Maybe<PermissionAssetEnum>;
  /** Only returned when filtering by asset */
  clientIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  status: UserStatusEnum;
};

export type UserLogin = {
  __typename?: 'UserLogin';
  timeStamp: Scalars['Timestamp']['output'];
  userAgent: Scalars['String']['output'];
};

export type UserLoginInput = {
  timeStamp: Scalars['Timestamp']['input'];
  userAgent: Scalars['String']['input'];
};

export enum UserStatusEnum {
  Confirmed = 'Confirmed',
  Disabled = 'Disabled',
  Pending = 'Pending'
}

export type Valuation = {
  __typename?: 'Valuation';
  _id: Scalars['GraphQLObjectId']['output'];
  date: Scalars['GraphQLSwedenDate']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  records?: Maybe<Array<Maybe<ValuationRecord>>>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
};

export type ValuationMapping = {
  __typename?: 'ValuationMapping';
  _id: Scalars['GraphQLObjectId']['output'];
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  collectionName?: Maybe<MappingCollectionNameEnum>;
  createTimestamp: Scalars['Timestamp']['output'];
  currency?: Maybe<CurrencyEnum>;
  curveDefinition?: Maybe<CurveDefinition>;
  instrument?: Maybe<Instrument>;
  /** instrumentId should be a id to an instrument or null */
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  issuerProgram?: Maybe<IssuerProgram>;
  issuerProgramId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  mappingType?: Maybe<MappingTypeEnum>;
  modelType?: Maybe<InstrumentModelTypeEnum>;
  parameterType?: Maybe<MappingParameterEnum>;
  referenceId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  status?: Maybe<ValuationMappingStatus>;
  updateTimestamp: Scalars['Timestamp']['output'];
  /** Id of the user that updated/created */
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  validFrom: Scalars['GraphQLDateString']['output'];
  validTo: Scalars['GraphQLDateString']['output'];
  /** A list of versions of the document */
  versions?: Maybe<Array<Maybe<Scalars['GraphQLJSON']['output']>>>;
};

export type ValuationMappingFilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  mappingTypeIn?: InputMaybe<Array<MappingTypeEnum>>;
  parameterTypeIn?: InputMaybe<Array<MappingParameterEnum>>;
  statusIn?: InputMaybe<Array<InputMaybe<ValuationMappingStatus>>>;
};

export type ValuationMappingInput = {
  /** if _id is omitted, an ticket will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  collectionName?: InputMaybe<MappingCollectionNameEnum>;
  currency?: InputMaybe<CurrencyEnum>;
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  issuerProgramId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  mappingType?: InputMaybe<MappingTypeEnum>;
  modelType?: InputMaybe<InstrumentModelTypeEnum>;
  parameterType?: InputMaybe<MappingParameterEnum>;
  referenceId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  status?: InputMaybe<ValuationMappingStatus>;
  validFrom: Scalars['GraphQLDateString']['input'];
  validTo: Scalars['GraphQLDateString']['input'];
};

export enum ValuationMappingStatus {
  Active = 'Active',
  Deleted = 'Deleted'
}

export type ValuationPrice = {
  __typename?: 'ValuationPrice';
  currency?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PriceType>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type ValuationPriceInput = {
  currency: CurrencyEnum;
  type: PriceType;
  value: Scalars['Float']['input'];
};

export type ValuationRecord = {
  __typename?: 'ValuationRecord';
  bloombergTicker?: Maybe<Scalars['String']['output']>;
  prices?: Maybe<Array<Maybe<ValuationPrice>>>;
  source?: Maybe<Scalars['String']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  writeTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  writeUser?: Maybe<Scalars['String']['output']>;
};

export enum PermissionTypeEnum {
  Read = 'Read',
  ReadWrite = 'ReadWrite'
}
