import { Column, Table } from "@tanstack/react-table";
import React, { useEffect } from "react";
import { keyBy } from "lodash";
import { useQueryState } from "../../common/use-query-state";
//import { valueFormat } from "../../../../common/src";

// https://tanstack.com/table/v8/docs/examples/react/filters

export const Filter = ({ column, table }): React.ReactElement => {
    return <div className="reacttable-filter">{column.columnDef.filter && column.columnDef.filter({ column, table })}</div>;
};

export const DefaultColumnFilter = ({ column }: { column: Column<any, unknown>; table: Table<any> }) => {
    const filterValue: any = column.getFilterValue();
    const [queryValue, setQueryValue] = useQueryState(column.id, filterValue || "");

    useEffect(() => {
        if (queryValue !== filterValue && queryValue !== "") {
            column.setFilterValue(queryValue);
        }
    }, [filterValue, queryValue, column]);

    return (
        <input
            type="text"
            className="form-control"
            value={filterValue || ""}
            onChange={(e) => {
                column.setFilterValue(e.target.value);
                setQueryValue(e.target.value || "");
            }}
            size={column.columnDef.maxSize || null}
        />
    );
};

export const SelectColumnFilter = ({ column }: { column: Column<any, unknown>; table: Table<any> }) => {
    const filterValue: any = column.getFilterValue();
    const [queryValue, setQueryValue] = useQueryState(column.id, filterValue || "");
    const options = React.useMemo(() => Array.from(column.getFacetedUniqueValues().keys()).sort(), [column]);
    column.columnDef.filterFn = "equals";

    useEffect(() => {
        if (queryValue !== filterValue && queryValue !== "") {
            column.setFilterValue(queryValue);
        }
    }, [filterValue, queryValue, column]);

    return (
        <select
            id="custom-select"
            className="custom-select form-select"
            value={filterValue}
            onChange={(e) => {
                column.setFilterValue(e.target.value);
                setQueryValue(e.target.value || "");
            }}
        >
            <option value="">All</option>
            {options.map((option: any) => (
                <option key={"key" + option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
};

//Closure
export const SelectColumnFilterType = (enumType) => {
    const SelectColumnFilterComponent = ({ enumType, column }) => {
        const filterValue: any = column.getFilterValue();
        const [queryValue, setQueryValue] = useQueryState(column.id, filterValue || "");

        const options: string[] = Object.values(enumType);

        useEffect(() => {
            if (queryValue !== filterValue && queryValue !== "") {
                column.setFilterValue(queryValue);
            }
        }, [filterValue, queryValue, column]);

        return (
            <select
                id="custom-select"
                className="custom-select"
                value={filterValue}
                onChange={(e) => {
                    column.setFilterValue(e.target.value || undefined);
                    setQueryValue(e.target.value || "");
                }}
            >
                <option value="">All</option>
                {options.map((option: any) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        );
    };

    return ({ column }: { column: Column<any, unknown>; table: Table<any> }): React.ReactElement => {
        return <SelectColumnFilterComponent enumType={enumType} column={column} />;
    };
};

export const SelectNotZeroColumnFilter = ({ column }: { column: Column<any, unknown>; table: Table<any> }) => {
    const filterValue: any = column.getFilterValue();
    const [queryValue, setQueryValue] = useQueryState(column.id, filterValue || "");

    useEffect(() => {
        if (queryValue !== filterValue && queryValue !== "") {
            column.setFilterValue(queryValue);
        }
    }, [filterValue, queryValue, column]);

    return (
        <select
            id="custom-select"
            className="custom-select"
            value={filterValue}
            onChange={(e) => {
                column.setFilterValue(e.target.value);
                setQueryValue(e.target.value || "");
            }}
        >
            <option value="">All</option>
            <option value="0">Exclude 0</option>
        </select>
    );
};

//Closure
export const SelectColumnFilterArrayOfIds = (lookup: { _id: string; name: string }[]) => {
    const SelectColumnFilterComponent = ({ lookup, column }) => {
        const filterValue: any = column.getFilterValue();
        const [queryValue, setQueryValue] = useQueryState(column.id, filterValue || "");

        useEffect(() => {
            if (queryValue !== filterValue && queryValue !== "") {
                column.setFilterValue(queryValue);
            }
        }, [filterValue, queryValue, column]);

        const byName = keyBy(lookup, "name");
        const optionNames = Object.keys(byName).sort();

        return (
            <select
                id="custom-select"
                className="custom-select"
                value={filterValue}
                onChange={(e) => {
                    column.setFilterValue(e.target.value || undefined);
                    setQueryValue(e.target.value || "");
                }}
            >
                <option value="">All</option>
                {optionNames.map((optionName: string) => (
                    <option key={optionName} value={byName[optionName]._id}>
                        {optionName}
                    </option>
                ))}
            </select>
        );
    };

    return ({ column }: { column: Column<any, unknown>; table: Table<any> }): React.ReactElement => {
        column.columnDef.filterFn = "arrIncludes";
        return <SelectColumnFilterComponent lookup={lookup} column={column} />;
    };
};

//Closure
export const SelectColumnFilterArrayOfStrings = ({ column }: { column: Column<any, unknown>; table: Table<any> }): React.ReactElement => {
    const filterValue: any = column.getFilterValue();
    const [queryValue, setQueryValue] = useQueryState(column.id, filterValue || "");

    const options = React.useMemo(() => {
        const values = Array.from(column.getFacetedUniqueValues().keys());
        const uniqueValues = values.reduce((acc, value) => {
            value.forEach((x) => acc.add(x));
            return acc;
        }, new Set());
        return Array.from(uniqueValues).sort();
    }, [column]);

    column.columnDef.filterFn = "arrIncludes";

    useEffect(() => {
        if (queryValue !== filterValue && queryValue !== "") {
            column.setFilterValue(queryValue);
        }
    }, [filterValue, queryValue, column]);

    return (
        <select
            id="custom-select"
            className="custom-select"
            value={filterValue}
            onChange={(e) => {
                column.setFilterValue(e.target.value || undefined);
                setQueryValue(e.target.value || "");
            }}
        >
            <option value="">All</option>
            {options.map((optionName: string) => (
                <option key={optionName} value={optionName}>
                    {optionName}
                </option>
            ))}
        </select>
    );
};

// filterFn: notEquals
export const notEquals = (row, columnId, filterValue) => {
    const value = row.getValue(columnId);
    return value != filterValue;
};

// filterFn: includesValueFormat, use with 'valueFormat' cell
export const includesValueFormat = (row, columnId, filterValue) => {
    const value = row.getValue(columnId)?.toString();
    return value?.includes(filterValue);
};

// filterFn: numberGreaterThan, use in numeric value column
export const numberEqualOrGreaterThan = (row, columnId, filterValue) => {
    const search = Number(filterValue);
    const value = row.getValue(columnId);
    return !!(value && value >= search);
};

// filterFn: startsWith
export const startsWith = (row, columnId, filterValue) => {
    const value = row.getValue(columnId)?.toString();
    return !!(value && value.startsWith(filterValue));
};
