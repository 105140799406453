import { gql } from "urql";

export const getClients = gql`
    query getClients {
        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
            accountingCurrency
            firstTradeDate
            accounts {
                type
                parentAccountId
                benchmarks {
                    instrumentId
                    currency
                    instrument {
                        currency
                    }
                    comment
                    showBenchmark
                }
            }
            topAccount {
                benchmarks {
                    instrumentId
                    currency
                    instrument {
                        currency
                    }
                    comment
                    showBenchmark
                }
            }
        }
    }
`;

export const getPerformanceData = gql`
    query getPerformanceData(
        $clientId: GraphQLObjectId!
        $endDate: GraphQLDateString
        $endDateString: GraphQLDateString
        $accountingCurrency: CurrencyEnum = SEK
    ) {
        client: party(_id: $clientId) {
            _id
            name
            instruments {
                _id
            }
            accounts {
                _id
                name
                description
                parentAccountId
                type
                childrenAccounts {
                    _id
                }
                benchmarks {
                    instrumentId
                    currency
                    mainBenchmark
                    showBenchmark
                    showExternal
                    offset
                    comment
                    instrument {
                        name
                        longName
                        timeSeries(typeIn: ["Price(Close)"], exchanged: $accountingCurrency, endDate: $endDateString) {
                            type
                            dates
                            values
                        }
                    }
                }
                modelIndexBenchmark {
                    name
                    currency
                    timeSeries {
                        items {
                            date
                            value
                        }
                    }
                }
                weights {
                    date
                    model
                }
            }
            topAccount {
                _id
            }
        }

        riskFreeTimeSeries: timeseries(
            includeItems: true
            filter: { typeIn: "Price(Close)", instrumentNameIn: "OMRXMM", endDate: $endDate }
        ) {
            dates
            values
            type
        }

        performance(clientId: $clientId, filter: { endDate: $endDateString }) {
            id
            currency
            dates
            values
            cashFlows
            series
            instrumentPerformances {
                accountId
                instrumentId
                firstTradeDate
                instrument {
                    name
                    longName
                    currency
                    modelType
                    creditRating
                    benchmark {
                        _id
                        currency
                        name
                        longName
                        timeSeries(typeIn: ["Price(Close)"], exchanged: $accountingCurrency, endDate: $endDateString) {
                            type
                            dates
                            values
                        }
                    }
                }
                series
                values
                cashFlows
                positions
            }
        }
    }
`;

export const getPieData = gql`
    query getPieData($clientId: GraphQLObjectId!, $endDate: GraphQLDateString) {
        tradingmanager(
            groupPositionsBy: AccountId
            filterZeroPositions: true
            filter: { clientIds: [$clientId], endDate: $endDate }
            lookThrough: false
        ) {
            instrumentId
            accountId
            account {
                type
                description
            }
            name
            currency
            quantity
            valuationDate
            valuationPrice
            localExposure
            exposure
            fxRate
            portfolioInstrumentId
            valuationDate
        }
    }
`;
