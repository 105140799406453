import React, { useState, useCallback, useEffect, useContext } from "react";
import { gql, useQuery } from "urql";
import { useParams, useNavigate } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { keyBy } from "lodash";
import { Limits } from "../LimitV3Page";
import { DurationRiskTab } from "./tabs/DurationRisk";
import { ScenarioRiskTab } from "./tabs/ScenarioRisk";
import { PositionsGroupedPage } from "../PositionsGroupedPage";
import { PartyType } from "../../types.generated";
import { ClientContextSelector } from "../../contexts/ClientContextSelector";
import { ClientContext } from "../../contexts/ClientContext";
import { emptyObjectId } from "../../../../common/src";
import { useQueryArgs } from "../../common/Utils";

const getClient = gql`
    query {
        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
            accountingCurrency
            fundInfo {
                classes {
                    startDate
                }
            }
        }
    }
`;

const tabs = {
    risk: "risk",
    positions: "positions",
    limits: "limits",
    scenario: "scenario"
};

export function PortfolioRiskAndLimits(): React.ReactElement {
    const navigate = useNavigate();
    const { tabId } = useParams<"tabId">();
    const [activeTabId, setActiveTabId] = useState(tabId);
    const { client: clientContext } = useContext(ClientContext);
    const { queryArgs } = useQueryArgs();

    const [{ fetching, error, data }] = useQuery({ query: getClient });

    useEffect(() => {
        if (clientContext && clientContext.dashName) {
            if (!(tabId in tabs)) {
                setActiveTabId("risk");
                navigate("/portfoliorisk/" + clientContext.dashName + "/risk");
            } else {
                if (tabId !== activeTabId) {
                    setActiveTabId(tabId);
                }
            }
        } /*else if (clientContext._id === emptyObjectId && (tabId || Object.keys(queryArgs).length)) {
            navigate("/portfoliorisk/" + clientContext.dashName, { replace: true });
        }*/
    }, [tabId, data, setActiveTabId, clientContext, navigate, queryArgs, activeTabId]);

    const onTabChange = useCallback(
        (tabId) => {
            //const types = data && data.party ? cloneDeep(data.party.types) : null;
            //const tabs = availableTabs(types);
            if (!(tabId in tabs)) {
                navigate("/portfoliorisk/" + clientContext.dashName + "/risk", { replace: true });
                setActiveTabId("risk");
            } else {
                if (tabId !== activeTabId) {
                    navigate("/portfoliorisk/" + clientContext.dashName + "/" + tabId, { replace: true });
                    setActiveTabId(tabId);
                }
            }
        },
        [setActiveTabId, activeTabId, clientContext, navigate]
    );

    if (fetching)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    const clientsByName = keyBy(data.clients, "name");

    if (
        clientContext &&
        clientContext._id !== emptyObjectId &&
        !Object.keys(clientsByName).includes(clientContext.dashName.replace(/-/g, " "))
    ) {
        return (
            <div className="loader">
                <h3>No client with that name</h3>
            </div>
        );
    }

    return (
        <div>
            <div className="row">
                <div className="col-xs-6 col-sm-4">
                    <ClientContextSelector typeIn={[PartyType.Client]} />
                </div>
            </div>
            {clientContext && clientContext._id !== emptyObjectId ? (
                <div className="row">
                    <div className="card card-body bg-light col-12">
                        <Tabs onSelect={onTabChange} activeKey={activeTabId} transition={false}>
                            <Tab eventKey="risk" title="Risk">
                                <DurationRiskTab
                                    client={{
                                        _id: clientsByName[clientContext.dashName.replace(/-/g, " ")]._id,
                                        name: clientsByName[clientContext.dashName.replace(/-/g, " ")].name
                                    }}
                                />
                            </Tab>
                            <Tab eventKey="positions" title="Positions">
                                <PositionsGroupedPage />
                            </Tab>
                            <Tab eventKey="limits" title="Limits">
                                <Limits clientId={clientsByName[clientContext.dashName.replace(/-/g, " ")]._id} />
                            </Tab>
                            <Tab eventKey="scenario" title="Scenario">
                                <ScenarioRiskTab
                                    client={{
                                        _id: clientsByName[clientContext.dashName.replace(/-/g, " ")]._id,
                                        name: clientsByName[clientContext.dashName.replace(/-/g, " ")].name
                                    }}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
