import React from "react";
import { gql, useQuery } from "urql";
import { cloneDeep } from "lodash";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { sortBy } from "lodash";
import { Button } from "react-bootstrap";
import { useQueryState } from "react-router-use-location-state";

import { Svgs } from "../../../components/src";
import { twoDecPriceFormat, fourDecPriceFormat, DateHelper } from "../../../common/src";

import { useQueryArgs } from "../common/Utils";
import { exportToXlsx, sheetType } from "../common/exportToXlsx";
import { TransferAgentOrderForm } from "./TransferAgentOrderForm";
import { ReactTable } from "../components/react-table/ReactTable";
import { SelectColumnFilter } from "../components/react-table/ReactTableFilters";
import { DateForm } from "../common/dateForm";

const getTransferAgentOrders = gql`
    query getTransferAgentOrders($tradeDateStart: GraphQLDateString, $statusIn: [TransactionStatus!]) {
        transferAgentOrders(filter: { tradeDateStart: $tradeDateStart, statusIn: $statusIn }) {
            _id
            status
            currency
            amount
            units
            isin
            carryOwnTransactionCost
            client {
                _id
                name
            }
            instrument {
                _id
                name
                longName
            }
            tradeDate
            valueDate
            transactionType
            externalId
            sourceId
            source {
                _id
                name
            }
            clientName
            clientNumber
            clientAccount
            updateTimestamp
            createTimestamp
            netPrice
            settledUnits
            settledAmount
            description
            subType
            referenceExternalId
            updateUserId
            updateUserInfo {
                _id
                name
            }
        }
    }
`;

type OrderGroupPropsType = {
    data: any;
};

export function OrderGroup(props: OrderGroupPropsType): React.ReactElement {
    const location = useLocation();
    const download = () => {
        const currentRecords: sheetType = orders;
        console.log(currentRecords);
        exportToXlsx([currentRecords], "orders.xlsx");
    };

    const orders = cloneDeep(props.data);

    const transferAgentOrders = sortBy(orders, "tradeDate").reverse();

    transferAgentOrders.forEach((order) => {
        order.fundName = order.client.name ? order.client.name : null;
        order.instrumentId = order.instrument._id ? order.instrument._id : null;
        order.instrumentName = order.instrument.name ? order.instrument.name : null;
        order.carryOwn = order.carryOwnTransactionCost ? "True" : "False";
        order.sourceName = order.source.name ? order.source.name : null;
        order.units = order.units ? parseFloat(order.units) : null;
        order.amount = order.amount ? parseFloat(order.amount) : null;
    });

    const columns = React.useMemo(
        () => [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/transferagentorders/${row.original._id}` + location.search}>{"#"}</Link>;
                },
                size: 20
            },
            {
                header: "Fund",
                accessorKey: "fundName",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <a href={`/parties/${row.original.client._id}`} target={"_blank"}>
                            {cellProps.getValue()}
                        </a>
                    );
                },
                size: 120
            },
            {
                header: "Trade date",
                accessorKey: "tradeDate",
                headerStyle: { textAlign: "right" },
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>,
                filter: SelectColumnFilter,
                size: 75
            },
            {
                header: "Type",
                accessorKey: "transactionType",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/transferagentorders/${row.original._id}` + location.search}>{cellProps.getValue()}</Link>;
                },
                size: 40
            },
            {
                header: "Instrument",
                accessorKey: "instrumentName",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return row.original.instrumentId ? (
                        <a href={`/instruments/${row.original.instrumentId}`} target={"_blank"}>
                            {cellProps.getValue()}
                        </a>
                    ) : (
                        <div>{cellProps.getValue()}</div>
                    );
                },
                size: 80
            },
            {
                header: "Isin",
                accessorKey: "isin",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return row.original.instrumentId ? (
                        <a href={`/instruments/${row.original.instrumentId}`} target={"_blank"}>
                            {cellProps.getValue()}
                        </a>
                    ) : (
                        <div>{cellProps.getValue()}</div>
                    );
                },
                size: 75
            },
            {
                header: "Status",
                accessorKey: "status",
                filter: SelectColumnFilter,
                size: 75
            },
            {
                header: "Units",
                accessorKey: "units",
                filterFn: "startsWith",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{fourDecPriceFormat(cellProps.getValue())}</div>,
                size: 90
            },
            {
                header: "Amount",
                accessorKey: "amount",
                filterFn: "startsWith",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                size: 75
            },
            {
                header: "Currency",
                accessorKey: "currency",
                filter: SelectColumnFilter,
                size: 60
            },
            {
                header: "Carry own",
                accessorKey: "carryOwn",
                filter: SelectColumnFilter,
                size: 50
            },
            {
                header: "Value date",
                accessorKey: "valueDate",
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>,
                filter: SelectColumnFilter,
                size: 75
            },
            {
                header: "Source",
                accessorKey: "sourceName",
                filter: SelectColumnFilter,
                size: 70
            },
            {
                header: "Client name",
                accessorKey: "clientName",
                filter: SelectColumnFilter,
                size: 150
            },
            {
                header: "Client number",
                accessorKey: "clientNumber",
                filter: SelectColumnFilter,
                size: 90,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/backoffice/customers?clientNumber=${row.original.clientNumber}`}>{cellProps.getValue()}</Link>;
                }
            },
            {
                header: "Client account",
                accessorKey: "clientAccount",
                filter: SelectColumnFilter,
                size: 90,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/backoffice/customers?clientAccount=${row.original.clientAccount}`}>{cellProps.getValue()}</Link>;
                }
            },
            {
                header: "External id",
                accessorKey: "externalId",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/transferagentorders/${row.original._id}` + location.search}>{cellProps.getValue()}</Link>;
                },
                size: 160
            },
            {
                header: "Create timestamp",
                accessorKey: "createTimestamp",
                size: 100
            },
            {
                header: "Update timestamp",
                accessorKey: "updateTimestamp",
                size: 100
            },
            {
                header: "subType",
                accessorKey: "subType",
                Filter: SelectColumnFilter,
                size: 75
            },
            {
                header: "referenceExternalId",
                accessorKey: "referenceExternalId",
                Filter: SelectColumnFilter,
                size: 100
            },
            {
                header: "Net price",
                accessorKey: "netPrice",
                filterFn: "startsWith",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{cellProps.getValue()}</div>,
                size: 40
            },
            {
                header: "Settled units",
                accessorKey: "settledUnits",
                filterFn: "startsWith",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{cellProps.getValue()}</div>,
                size: 60
            },
            {
                header: "Settled amount",
                accessorKey: "settledAmount",
                filterFn: "startsWith",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{cellProps.getValue()}</div>,
                size: 60
            },
            {
                header: "Description",
                accessorKey: "description",
                filter: SelectColumnFilter,
                size: 60
            },
            {
                header: "Updated by",
                id: "updateUserInfo.name",
                accessorFn: (row) => (row.updateUserInfo ? row.updateUserInfo.name : null),
                filter: SelectColumnFilter
            }
        ],
        [location.search]
    );

    return (
        <div>
            <p>Total number of orders: {transferAgentOrders.length}</p>
            <div
                style={{ width: "20px", height: "20px", marginBottom: "0.5rem" }}
                onClick={() => {
                    download();
                }}
            >
                <Svgs.Excel />
            </div>
            <ReactTable
                columns={columns}
                data={transferAgentOrders}
                defaultHiddenColumns={[
                    "status",
                    "netPrice",
                    "isin",
                    "currency",
                    "description",
                    "clientName",
                    "referenceExternalId",
                    "fundName"
                ]}
            />
        </div>
    );
}

export function TransferAgentOrdersPage(): React.ReactElement {
    const { id }: any = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { queryArgs } = useQueryArgs();

    const [tradeDateStart] = useQueryState("tradeDateStart", DateHelper.addMonths(new Date(), -1).toISOString().slice(0, 10));

    const variables = {
        tradeDateStart: queryArgs.tradeDate ? queryArgs.tradeDate : tradeDateStart,
        statusIn: ["Pending", "Deleted", "Confirmed", "Settled"]
    };

    const [{ fetching, error, data }, refetch] = useQuery({ query: getTransferAgentOrders, variables, requestPolicy: "network-only" });

    if (fetching) return <div>Loading...</div>;
    if (error) return <div>Error! ${error.message}`</div>;

    return (
        <div>
            <div className="d-flex">
                <DateForm defaultDateString={tradeDateStart} dateName={"tradeDateStart"}></DateForm>
                <div className="ms-auto">
                    <Button
                        variant="info"
                        type="submit"
                        className="float-end"
                        style={{ marginRight: "2px" }}
                        onClick={() => {
                            navigate(`/transferagentorders/new` + location.search);
                        }}
                    >
                        New order
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className={"col-12" + (id ? " col-sm-5 col-md-6 col-lg-7 col-xl-8 col-xxl-9" : "")}>
                    <OrderGroup data={data.transferAgentOrders} />
                </div>

                {id ? (
                    <div className="col-12 col-sm-7 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
                        <Link to={`/transferagentorders` + location.search} className="close btn-close float-end" />
                        <TransferAgentOrderForm refetch={refetch}></TransferAgentOrderForm>{" "}
                    </div>
                ) : null}
            </div>
        </div>
    );
}
