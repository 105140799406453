import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import MarkdownIt from "markdown-it";
import ReactMarkdown from "react-markdown";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import { gql, useMutation, useQuery } from "urql";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { Alert } from "react-bootstrap";
import { keyBy, sortBy, cloneDeep } from "lodash";

import { YesNoModal } from "../components/YesNoModal";
import { AttachmentForm } from "./AttachmentForm";
import { useAlertTimeOut } from "../common/Utils";
import { recursivelyRemoveKey } from "../../../common/src/utils/FormatFunctions";
import { TextField, SelectField, SubmitButton, MultipleSelectField, SearchListField } from "../components/form";
import { Attachment } from "../../../components/src/Svgs";
import { emptyObjectId } from "../../../common/src";
import {
    SustainableDevelopmentGoal,
    UseOfProceed,
    Party,
    TrueFalseNone,
    CreditStatus,
    EuTaxonomyEnvironmentalObjective,
    IssuerProgramCategory,
    SustainableDevelopmentGoalDescriptions,
    UseOfProceedDescriptions,
    EuTaxonomyEnvironmentalObjectiveDescriptions
} from "../types.generated";
import { usePrevious } from "../common/Utils";
import { SYSTEM_PARTY_ID } from "../Constants";
interface FormData {
    _id?: string;
    issuerId?: string;
    spoProviderId?: string;
    guarantorId?: string;
    clientId?: string;
    issuer?: Party;
    name?: string;
    category?: string;
    attachments?: Attachment[];
    sustainableDevelopmentGoals?: string[];
    useOfProceeds?: string[];
    euTaxonomyEnvironmentalObjectives?: EuTaxonomyEnvironmentalObjective[];
    negativePledge?: TrueFalseNone;
    creditStatus?: CreditStatus;
    crossDefault?: TrueFalseNone;
    covered?: TrueFalseNone;
    status?: string;
    //instrumentIds: string[];
}

interface IssuerProgram {
    _id?: string;
    issuerId?: string;
    spoProviderId?: string;
    guarantorId?: string;
    clientId?: string;
    issuer?: Party;
    name?: string;
    category?: string;
    attachments?: Attachment[];
    sustainableDevelopmentGoals?: string[];
    useOfProceeds?: string[];
    euTaxonomyEnvironmentalObjectives?: EuTaxonomyEnvironmentalObjective[];
    negativePledge?: TrueFalseNone;
    creditStatus?: CreditStatus;
    crossDefault?: TrueFalseNone;
    covered?: TrueFalseNone;
    comment?: string;
    status?: string;
}

const GET_DATA = gql`
    query getData($filter: IssuerProgramsFilterInput) {
        issuerprograms(filter: $filter) {
            _id
            spoProvider {
                name
                _id
            }
            spoProviderId
            clientId
            category
            issuerId
            sustainableDevelopmentGoals
            name
            guarantorId
            guarantor {
                _id
                name
            }
            issuer {
                _id
                name
                clients {
                    name
                    _id
                }
            }
            status
            useOfProceeds
            euTaxonomyEnvironmentalObjectives
            negativePledge
            crossDefault
            covered
            creditStatus {
                senior
                secured
                pariPassu
            }
            attachments {
                id: fileId
                fileId
                fileName
                mimeType
                mD5
                updateTimestamp
            }
            comment
        }
        issuers: parties(filter: { typeIn: [Issuer] }) {
            _id
            name
        }
        esgdataproviders: parties(filter: { typeIn: [EsgDataProvider] }) {
            _id
            name
        }
    }
`;

const GET_ESGDATAPROVIDERS = gql`
    query {
        esgdataproviders: parties(filter: { typeIn: [EsgDataProvider] }) {
            _id
            name
        }
    }
`;

const CREATE_ISSUERPROGRAM = gql`
    mutation createIssuerProgram($input: IssuerProgramInput!) {
        create: createIssuerProgram(input: $input) {
            _id
        }
    }
`;

export const UPDATE_ISSUERPROGRAM = gql`
    mutation updateIssuerProgram($_id: GraphQLObjectId!, $input: IssuerProgramInput!) {
        update: updateIssuerProgram(_id: $_id, input: $input) {
            _id
        }
    }
`;

export function IssuerProgramPage(): React.ReactElement {
    const { id }: any = useParams();
    const mdParser = new MarkdownIt();
    const previousId = usePrevious(id);

    let issuerProgramId = "000000000000000000000000";
    if (id && id !== "new") {
        issuerProgramId = id;
    }

    const [comment, setComment] = useState("");
    const [editComment, setEditComment] = useState(false);
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [{ fetching: loading, error, data }, refetch] = useQuery({
        query: GET_DATA,
        variables: { filter: { idIn: [issuerProgramId] } },
        requestPolicy: "network-only"
    });
    const [{ fetching: loadingDataProviders, error: errorDataProviders, data: dataEsgdataproviders }] = useQuery({
        query: GET_ESGDATAPROVIDERS
    });

    const [formData, setFormData] = useState(null);
    const [esgDataProviders, setDataProviders] = useState([
        { key: "000000000000000000000000", name: "None", value: "None", _id: "000000000000000000000000" }
    ]);
    const [stateCreate, createMutation] = useMutation(CREATE_ISSUERPROGRAM);
    const [stateUpdate, updateMutation] = useMutation(UPDATE_ISSUERPROGRAM);

    const navigate = useNavigate();

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    const callBackOnChangeAttachment = useCallback(
        (attachments) => {
            if (data && data.issuerprograms && data.issuerprograms.length > 0) {
                let input = {
                    issuerId: data.issuerprograms[0].issuerId,
                    clientId: data.issuerprograms[0].clientId,
                    attachments,
                    name: data.issuerprograms[0].name
                };
                input = recursivelyRemoveKey(input, "__typename");
                input = recursivelyRemoveKey(input, "id");
                updateMutation({ _id: data.issuerprograms[0]._id, input });
            }
        },
        [data, updateMutation]
    );

    const isins = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const instruments = [];

    if (data && data.funds) {
        data.funds.forEach((fund) => {
            fund.fundInfo.classes.forEach((c) => {
                isins.push(c.instrument.isin);
                instruments.push(c.instrument);
            });
        });
    }

    let clients;

    if (data && data.issuerprograms && data.issuerprograms.length > 0) {
        const issuerProgramClients = data.issuerprograms[0].issuer.clients;
        clients = issuerProgramClients.map((client) => {
            return { value: client.name, name: client.name, _id: client._id, key: client._id };
        });
        //clients = [{ value: client.name, name: client.name, _id: client._id, key: client._id }];
    } else {
        clients = [{ value: "System", name: "System", _id: SYSTEM_PARTY_ID, key: SYSTEM_PARTY_ID }];
    }

    const instrumentsByIsin = keyBy(instruments, "isin");

    useEffect(() => {
        if ((stateCreate && stateCreate.data && !stateCreate.data.error) || (stateUpdate && stateUpdate.data && !stateUpdate.data.error)) {
            refetch();
        }
    }, [stateCreate, stateUpdate, refetch]);

    useEffect(() => {
        if (previousId !== id) {
            setFormData(null);
        } else if (!loading && !error) {
            if (formData === null) {
                if (id && id !== "new" && data.issuerprograms && data.issuerprograms.length > 0) {
                    const issuerprogram: IssuerProgram = data.issuerprograms[0];
                    const initFormData: FormData = {
                        _id: issuerprogram._id,
                        issuerId: issuerprogram.issuerId,
                        guarantorId: issuerprogram.guarantorId,
                        spoProviderId: issuerprogram.spoProviderId,
                        clientId: issuerprogram.clientId,
                        name: issuerprogram.name,
                        category: issuerprogram.category,
                        attachments: issuerprogram.attachments,
                        sustainableDevelopmentGoals: issuerprogram.sustainableDevelopmentGoals,
                        useOfProceeds: issuerprogram.useOfProceeds,
                        euTaxonomyEnvironmentalObjectives: issuerprogram.euTaxonomyEnvironmentalObjectives,
                        negativePledge: issuerprogram.negativePledge,
                        creditStatus: issuerprogram.creditStatus,
                        crossDefault: issuerprogram.crossDefault,
                        covered: issuerprogram.covered,
                        status: issuerprogram.status
                    };
                    setFormData(initFormData);
                    setComment(issuerprogram.comment);
                } else {
                    // default

                    const initFormData: FormData = {
                        _id: "",
                        issuerId: emptyObjectId,
                        guarantorId: emptyObjectId,
                        spoProviderId: emptyObjectId,
                        clientId: SYSTEM_PARTY_ID,
                        name: "",
                        category: "Green",
                        attachments: [],
                        sustainableDevelopmentGoals: [],
                        useOfProceeds: [],
                        euTaxonomyEnvironmentalObjectives: [],
                        negativePledge: TrueFalseNone.None,
                        creditStatus: { senior: TrueFalseNone.None, secured: TrueFalseNone.None, pariPassu: TrueFalseNone.None },
                        crossDefault: TrueFalseNone.None,
                        covered: TrueFalseNone.None,
                        status: "Active"
                    };
                    setFormData(initFormData);
                }
            }
        }
    }, [previousId, id, loading, error, formData, data, instrumentsByIsin, instruments]);

    useEffect(() => {
        if (dataEsgdataproviders) {
            let allEsgDataProviders = dataEsgdataproviders.esgdataproviders.map((c) => {
                return { value: c.name, name: c.name, _id: c._id, key: c._id };
            });
            allEsgDataProviders.push({ key: "000000000000000000000000", name: "None", value: "None", _id: "000000000000000000000000" });
            allEsgDataProviders = sortBy(allEsgDataProviders, "name");
            setDataProviders(allEsgDataProviders);
        }
    }, [dataEsgdataproviders]);

    if (loading)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );
    if (error)
        return (
            <div className="loader">
                <h3>Failed loading data</h3>
            </div>
        );

    if (issuerProgramId !== "000000000000000000000000" && !data.issuerprograms)
        return (
            <div className="loader">
                <h3>No issuerprogram with that id</h3>
            </div>
        );

    if (!formData) return <div></div>;

    let isCreateMode = false;
    if (id === "new") {
        isCreateMode = true;
    }
    const issuerById = keyBy(data.issuers, "_id");

    const issuers = cloneDeep(data.issuers);
    issuers.push({ _id: "000000000000000000000000", name: "None" });
    const pageTitle = isCreateMode ? "New issuerprogram" : "Edit issuerprogram";
    const submitButtonLabel = isCreateMode ? "Create" : "Update";
    if (loadingDataProviders) return <p>Loading clients</p>;
    if (errorDataProviders) return <p>errorClients: {JSON.stringify(errorDataProviders, null, 2)}</p>;

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete issuerprogram with id " + modal.payload.id + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        updateMutation({ _id: modal.payload.id, input: modal.payload.input })
                            .then((result) => {
                                if (result.error) {
                                    setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                } else {
                                    navigate("/issuerprograms/" + result.data.update._id, { replace: true });

                                    setAlert({
                                        color: "success",
                                        visible: true,
                                        message: `The issuerprogram with id '${modal.payload.id}' was updated and deleted successfully!`
                                    });
                                }
                            })
                            .catch((error) => {
                                console.error(error.toString());
                            });
                    }}
                />
            ) : null}
            <div className="container page">
                <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12">
                        <Link to="/issuerprograms">Isssuerprograms</Link>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12">
                        <h3>{pageTitle}</h3>
                    </div>
                </div>
                <div className="row">
                    <Formik
                        enableReinitialize={true}
                        validateOnMount={true}
                        initialValues={formData}
                        validate={(validateFormData) => {
                            const errors: any = {};
                            if (validateFormData.issuerId === "0000000000000000000000000" || !validateFormData.issuerId) {
                                errors.issuerId = "No issuer selected";
                            } else {
                                if (!(validateFormData.issuerId in issuerById)) {
                                    errors.issuerId = "No issuer with that id";
                                }

                                if (validateFormData.name === "") {
                                    errors.name = "Issuerprogram must have a name";
                                }
                            }

                            setFormData(validateFormData);

                            return Object.keys(errors).length > 0 ? errors : {};
                        }}
                        onSubmit={async (values) => {
                            const submitValues: FormData = values;

                            let input: IssuerProgram = {
                                issuerId: submitValues.issuerId,
                                guarantorId: submitValues.guarantorId,
                                spoProviderId: submitValues.spoProviderId,
                                clientId: submitValues.clientId,
                                name: submitValues.name,
                                category: submitValues.category,
                                attachments: submitValues.attachments,
                                sustainableDevelopmentGoals: submitValues.sustainableDevelopmentGoals,
                                useOfProceeds: submitValues.useOfProceeds,
                                euTaxonomyEnvironmentalObjectives: submitValues.euTaxonomyEnvironmentalObjectives,
                                negativePledge: submitValues.negativePledge,
                                creditStatus: submitValues.creditStatus,
                                crossDefault: submitValues.crossDefault,
                                covered: submitValues.covered,
                                status: submitValues.status
                            };

                            input = recursivelyRemoveKey(input, "__typename");
                            input = recursivelyRemoveKey(input, "id");
                            delete input.attachments;
                            if (isCreateMode) {
                                delete input._id;
                                await createMutation({ input })
                                    .then((result) => {
                                        if (result.error) {
                                            setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                        } else {
                                            navigate("/issuerprograms/" + result.data.create._id, { replace: true });

                                            setAlert({
                                                color: "success",
                                                visible: true,
                                                message: `New issuerprogram '${result.data.create._id}' created successfully!`
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error.toString());
                                    });
                            } else {
                                if (input.status && input.status === "Deleted") {
                                    setModal({ showModal: true, payload: { input: input, id: id } });
                                } else {
                                    await updateMutation({ _id: id, input })
                                        .then((result) => {
                                            if (result.error) {
                                                setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                            } else {
                                                navigate("/issuerprograms/" + result.data.update._id, { replace: true });
                                                setAlert({
                                                    color: "success",
                                                    visible: true,
                                                    message: `The issuerprogram '${id}' updated successfully!`
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            setAlert({ color: "danger", visible: true, message: error.toString() });
                                        });
                                }
                            }
                        }}
                    >
                        {({ isSubmitting, values }) => (
                            <Fragment>
                                <div className="col-12">
                                    <Form autoComplete="off">
                                        <div className="form row">
                                            <div className="col">
                                                <div>
                                                    <TextField className="" name="_id" label="Id" disabled={true} />
                                                </div>
                                                <div>
                                                    <SearchListField
                                                        className=""
                                                        name="issuerId"
                                                        label={<Link to={"/parties/" + values.issuerId}>Issuer</Link>}
                                                        options={issuers}
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                                <div>
                                                    <SearchListField
                                                        className=""
                                                        name="guarantorId"
                                                        label={<Link to={"/parties/" + values.guarantorId}>Guarantor</Link>}
                                                        options={issuers}
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                                <div>
                                                    <TextField className="" name="name" label="Name" disabled={isSubmitting} />
                                                </div>
                                                <SelectField
                                                    className=""
                                                    name="spoProviderId"
                                                    label={<Link to={"/parties/" + values.spoProviderId}>SPO provider</Link>}
                                                    options={esgDataProviders}
                                                    disabled={isSubmitting}
                                                />
                                                <SelectField
                                                    className=""
                                                    name="clientId"
                                                    label={<Link to={"/parties/" + values.clientId}>Client</Link>}
                                                    options={clients}
                                                    disabled={isSubmitting}
                                                />
                                                <SelectField
                                                    name="category"
                                                    label="Category"
                                                    options={Object.keys(IssuerProgramCategory)}
                                                    className=""
                                                    disabled={isSubmitting}
                                                />

                                                <SelectField
                                                    name="status"
                                                    label="Status"
                                                    options={["Active", "Deleted"]}
                                                    className=""
                                                    disabled={isSubmitting}
                                                />
                                                <div className="form-row">
                                                    <SelectField
                                                        name="negativePledge"
                                                        label="Negative pledge"
                                                        options={Object.values(TrueFalseNone)}
                                                        className="p-2"
                                                        disabled={isSubmitting}
                                                    />
                                                    <SelectField
                                                        name="crossDefault"
                                                        label="Cross default"
                                                        options={Object.values(TrueFalseNone)}
                                                        className="p-2"
                                                        disabled={isSubmitting}
                                                    />
                                                    <SelectField
                                                        name="covered"
                                                        label="Covered"
                                                        options={Object.values(TrueFalseNone)}
                                                        className="p-2"
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                                <div className="form row">
                                                    <div className="col-5">
                                                        <h5>Credit status </h5>
                                                    </div>
                                                </div>

                                                {values.creditStatus ? (
                                                    <div className="form row">
                                                        <SelectField
                                                            name="creditStatus.secured"
                                                            label="Secured"
                                                            options={Object.values(TrueFalseNone)}
                                                            className="col-3"
                                                            disabled={isSubmitting}
                                                        />
                                                        <SelectField
                                                            name="creditStatus.senior"
                                                            label="Senior"
                                                            options={Object.values(TrueFalseNone)}
                                                            className="col-3"
                                                            disabled={isSubmitting}
                                                        />
                                                        <SelectField
                                                            name="creditStatus.pariPassu"
                                                            label="Pari passu"
                                                            options={Object.values(TrueFalseNone)}
                                                            className="col-3"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                ) : null}

                                                {id === "000000000000000000000000" ||
                                                !data.issuerprograms ||
                                                !data.issuerprograms.length ? null : (
                                                    <AttachmentForm
                                                        clientId={values.clientId}
                                                        attachments={data.issuerprograms[0].attachments || []}
                                                        onChange={callBackOnChangeAttachment}
                                                    />
                                                )}
                                            </div>
                                            <div className="col">
                                                <MultipleSelectField
                                                    className=""
                                                    size={10}
                                                    //format={(value, item) => {
                                                    //    return item;
                                                    //}}
                                                    name="sustainableDevelopmentGoals"
                                                    label="Sustainable development goals"
                                                    options={Object.values(SustainableDevelopmentGoal)}
                                                    tooltips={SustainableDevelopmentGoalDescriptions}
                                                    disabled={isSubmitting}
                                                />
                                                <MultipleSelectField
                                                    className=""
                                                    size={10}
                                                    //format={(value, item) => {
                                                    //    return item.toString();
                                                    //}}
                                                    name="useOfProceeds"
                                                    label="Use of proceeds"
                                                    options={Object.values(UseOfProceed)}
                                                    tooltips={UseOfProceedDescriptions}
                                                    disabled={isSubmitting}
                                                />
                                                <MultipleSelectField
                                                    className=""
                                                    size={10}
                                                    //format={(value, item) => {
                                                    //    return item.toString();
                                                    //}}
                                                    name="euTaxonomyEnvironmentalObjectives"
                                                    label="EU taxonomy - Environmental objective"
                                                    options={Object.values(EuTaxonomyEnvironmentalObjective)}
                                                    tooltips={EuTaxonomyEnvironmentalObjectiveDescriptions}
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                        </div>
                                        <SubmitButton disabled={isSubmitting} label={submitButtonLabel} />
                                        {alert.visible ? (
                                            <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                                                {alert.message}
                                            </Alert>
                                        ) : null}
                                    </Form>
                                </div>
                            </Fragment>
                        )}
                    </Formik>
                </div>
                <div className="form row mt-1">
                    <div className="col">
                        <div className="mb-1">
                            {id === "000000000000000000000000" || !data.issuerprograms || !data.issuerprograms.length ? null : (
                                <DropdownButton id="button" title={"Comment"} align="end" size="sm">
                                    <Dropdown.Item
                                        key={"edit"}
                                        as="button"
                                        onClick={() => {
                                            setEditComment(true);
                                        }}
                                    >
                                        <div>Edit</div>
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                        key={"cancel"}
                                        as="button"
                                        onClick={() => {
                                            setComment(data.issuerprograms[0].comment);
                                            setEditComment(false);
                                        }}
                                    >
                                        <div>Cancel</div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        key={"save"}
                                        as="button"
                                        onClick={async () => {
                                            const input: IssuerProgram = {
                                                name: data.issuerprograms[0].name,
                                                issuerId: data.issuerprograms[0].issuerId,
                                                clientId: data.issuerprograms[0].clientId,
                                                comment: comment
                                            };

                                            await updateMutation({ _id: id, input })
                                                .then((result) => {
                                                    if (result.error) {
                                                        setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                                    } else {
                                                        navigate("/issuerprograms/" + result.data.update._id, { replace: true });

                                                        setAlert({
                                                            color: "success",
                                                            visible: true,
                                                            message: `The issuerprogram '${id}' updated successfully!`
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    setAlert({ color: "danger", visible: true, message: error.toString() });
                                                });

                                            setEditComment(false);
                                        }}
                                    >
                                        <div>Save</div>
                                    </Dropdown.Item>
                                </DropdownButton>
                            )}
                        </div>

                        {editComment ? (
                            <MdEditor
                                style={{ height: "500px" }}
                                value={comment}
                                renderHTML={(text) => mdParser.render(text)}
                                onChange={({ text }) => setComment(text)}
                            />
                        ) : (
                            <ReactMarkdown>{comment || ""}</ReactMarkdown>
                        )}
                    </div>
                </div>
                {id === "000000000000000000000000" || !data.issuerprograms || !data.issuerprograms.length ? null : (
                    <div className="form row mt-1">
                        <div className="col">
                            <Link to={"/instruments?issuerProgramIdIn=" + id}>Instruments</Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
