import React, { useContext, Fragment, useEffect, useCallback, useState } from "react";
import { Formik, Form } from "formik";
import useAxios from "axios-hooks";
import axios from "axios";
import { QRCodeCanvas } from "qrcode.react";
import { isMobile } from "react-device-detect";
import queryString from "query-string";
import { FormikErrors } from "formik";

import { loginContext } from "./Login";
import { TextField } from "../components/form";
import { useInterval } from "../common/Utils";
import {
    REACT_APP_API_URI,
    REACT_APP_AUTH_URI,
    REACT_APP_DB_NAME,
    REACT_APP_NAME,
    REACT_APP_NODEJOBAPI_URI,
    REACT_APP_PYJOBAPI_URI
} from "../env";

enum BidStatus {
    pending = "pending",
    failed = "failed",
    complete = "complete"
}

enum BidHintCode {
    certificateErr = "certificateErr",
    expiredTransaction = "expiredTransaction",
    noClient = "noClient",
    outstandingTransaction = "outstandingTransaction",
    started = "started",
    startFailed = "startFailed",
    userCancel = "userCancel",
    userSign = "userSign"
}

type BidAuth = {
    autoStartToken: string;
    orderRef: string;
    qrCode: string;
};

type BankIdData = {
    autoStartToken: string;
    qrStartToken: string;
    orderRef: string;
    status?: BidStatus;
    hintCode?: BidHintCode;
    qrCode?: string;
};

type BidCollect = {
    status: BidStatus;
    hintCode: BidHintCode;
    qrCode: string;
    access_token?: string;
    token_type?: string;
    expires_in?: string;
    user_display_name?: string;
    user_id?: string;
    accepted_terms?: boolean;
};

export const LoginScreen = (): React.ReactElement => {
    const [bankIdData, setBankIdData] = useState<BankIdData>({
        autoStartToken: "",
        qrStartToken: "",
        orderRef: "",
        status: BidStatus.pending,
        hintCode: BidHintCode.outstandingTransaction,
        qrCode: ""
    });
    const [_, bidCollect] = useAxios({ withCredentials: true }, { manual: true });
    const [__, bid] = useAxios({ withCredentials: true }, { manual: true });
    const [___, passwordLogin] = useAxios({ withCredentials: true }, { manual: true });

    const { login, setLogin } = useContext(loginContext);

    useInterval(
        () => {
            bidCollectFnc();
        },
        // Delay in milliseconds or null to stop it
        !login.loggedIn && !login.usePassword && bankIdData.status === BidStatus.pending ? 1500 : null
    );

    const bidFnc = useCallback(async () => {
        let response = null;
        try {
            response = await bid({
                method: "GET",
                url: REACT_APP_AUTH_URI + "/bid_auth",
                headers: {
                    Accept: "application/json; charset=utf-8",
                    "Content-Type": "application/json"
                }
            });
        } catch (e) {
            if (e.name !== "CanceledError") {
                console.error(e);
            }
        }

        if (response && response.data && response.data.orderRef) {
            const data: BidAuth = response.data;

            setBankIdData({
                autoStartToken: data.autoStartToken,
                qrStartToken: "",
                orderRef: data.orderRef,
                status: BidStatus.pending,
                hintCode: BidHintCode.outstandingTransaction,
                qrCode: data.qrCode
            });
        }
    }, [bid]);

    useEffect(() => {
        if (!login.loggedIn && !login.usePassword && bankIdData.orderRef === "") {
            bidFnc();
        }
    }, [bankIdData.orderRef, bidFnc, login]);

    const bidCollectFnc = async () => {
        let response = null;
        try {
            response = await bidCollect({
                method: "GET",
                url: REACT_APP_AUTH_URI + "/bid_auth/" + bankIdData.orderRef,
                headers: {
                    Accept: "application/json; charset=utf-8",
                    "Content-Type": "application/json"
                }
            });
        } catch (e) {
            console.error(e);
            return;
        }

        if (response && response.data) {
            const data: BidCollect = response.data;
            if (data.status === BidStatus.complete) {
                const expireDate = new Date();
                expireDate.setSeconds(expireDate.getSeconds() + Number(data.expires_in));
                const expires = expireDate.toISOString();
                setLogin({
                    ...login,
                    ...{
                        expires: expires,
                        loggedIn: true,
                        token: data.access_token,
                        username: data.user_display_name,
                        userId: data.user_id ? data.user_id : "",
                        statusMessage: "",
                        errorMessage: "",
                        acceptedTerms: data.accepted_terms
                    }
                });
                setBankIdData({
                    autoStartToken: "",
                    qrStartToken: "",
                    orderRef: "",
                    status: BidStatus.pending,
                    hintCode: BidHintCode.outstandingTransaction,
                    qrCode: ""
                });

                if (REACT_APP_DB_NAME === "prod") {
                    axios
                        .post(
                            REACT_APP_NODEJOBAPI_URI + "/set",
                            {},
                            {
                                withCredentials: true,
                                headers: {
                                    Accept: "application/json; charset=utf-8",
                                    "Content-Type": "application/json",
                                    authorization: `Bearer ${data.access_token}`
                                }
                            }
                        )
                        .catch(function (error) {
                            console.error(error);
                        });
                    axios
                        .post(
                            REACT_APP_PYJOBAPI_URI + "/set",
                            {},
                            {
                                withCredentials: true,
                                headers: {
                                    Accept: "application/json; charset=utf-8",
                                    "Content-Type": "application/json",
                                    authorization: `Bearer ${data.access_token}`
                                }
                            }
                        )
                        .catch(function (error) {
                            console.error(error);
                        });
                }
            } else {
                const newBankIdData = { ...bankIdData, status: data.status, hintCode: data.hintCode, qrCode: data.qrCode };
                setBankIdData(newBankIdData);
            }
        }
    };

    async function passwordLoginFnc(personalNumber, password) {
        const form = queryString.stringify({
            username: personalNumber,
            client_id: REACT_APP_DB_NAME,
            grant_type: "password",
            password: password
        });
        return passwordLogin({
            method: "POST",
            url: REACT_APP_AUTH_URI + "/token",
            headers: {
                Accept: "application/json; charset=utf-8",
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: form
        });
    }

    if (login.token) return null;

    if (login.usePassword) {
        return (
            <div
                className="loginscreen d-flex justify-content-center align-items-center"
                style={{
                    backgroundImage: "url(/background.jpg)",
                    height: "100vh"
                }}
            >
                <div className="row">
                    <div className="col me-4 ms-4" style={{ background: "white", borderRadius: "5px" }}>
                        <h2
                            className="row text-center py-2"
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                                background: "#2186c5",
                                color: "white"
                            }}
                        >
                            {REACT_APP_NAME.toUpperCase()}
                        </h2>
                        <h2 className="row ms-2 me-2" style={{ justifyContent: "center", alignItems: "center" }}>
                            <span>LOGGA IN LÖSENORD</span>
                        </h2>
                        <div className="row">
                            <div className="col">
                                <Formik
                                    initialValues={{ personalNumber: "", password: "" }}
                                    onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                                        setSubmitting(true);
                                        await passwordLoginFnc(submitValues.personalNumber, submitValues.password)
                                            .then((result) => {
                                                const expireDate = new Date();
                                                expireDate.setSeconds(expireDate.getSeconds() + Number(result.data.expires_in));
                                                const expires = expireDate.toISOString();
                                                setLogin({
                                                    ...login,
                                                    ...{
                                                        expires: expires,
                                                        loggedIn: true,
                                                        token: result.data.access_token,
                                                        username: result.data.user_display_name,
                                                        userId: result.data.user_id ? result.data.user_id : "",
                                                        statusMessage: "",
                                                        errorMessage: "",
                                                        acceptedTerms: result.data.accepted_terms
                                                    }
                                                });
                                                axios
                                                    .post(
                                                        REACT_APP_API_URI + "/set",
                                                        {},
                                                        {
                                                            withCredentials: true,
                                                            headers: {
                                                                "Content-Type": "application/json",
                                                                authorization: `Bearer ${result.data.access_token}` //this should not be needed
                                                            }
                                                        }
                                                    )
                                                    .catch(function (error) {
                                                        console.error(error);
                                                    });

                                                if (REACT_APP_DB_NAME === "prod") {
                                                    axios
                                                        .post(
                                                            REACT_APP_NODEJOBAPI_URI + "/set",
                                                            {},
                                                            {
                                                                withCredentials: true,
                                                                headers: {
                                                                    "Content-Type": "application/json",
                                                                    authorization: `Bearer ${result.data.access_token}` //this should not be needed
                                                                }
                                                            }
                                                        )
                                                        .catch(function (error) {
                                                            console.error(error);
                                                        });
                                                    axios
                                                        .post(
                                                            REACT_APP_PYJOBAPI_URI + "/set",
                                                            {},
                                                            {
                                                                withCredentials: true,
                                                                headers: {
                                                                    "Content-Type": "application/json",
                                                                    authorization: `Bearer ${result.data.access_token}` //this should not be needed
                                                                }
                                                            }
                                                        )
                                                        .catch(function (error) {
                                                            console.error(error);
                                                        });
                                                }
                                            })
                                            .catch((error) => {
                                                const formErrors: FormikErrors<Record<string, string>> = {};
                                                if (error?.response?.data?.error === "Unauthorized: incorrect password") {
                                                    formErrors["password"] = "Unauthorized: incorrect password";
                                                } else if (error?.response?.data?.error === "User not found") {
                                                    formErrors["personalNumber"] = "User not found";
                                                } else if (error?.response?.data?.error) {
                                                    formErrors["personalNumber"] = error?.response?.data?.error;
                                                } else if (error?.response?.status === 429) {
                                                    formErrors["personalNumber"] = error.response.data;
                                                } else {
                                                    console.error(error);
                                                    formErrors["personalNumber"] = "error " + error.toString();
                                                }
                                                setErrors(formErrors);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                            });
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Fragment>
                                            <Form autoComplete="off">
                                                <div>
                                                    <div className="form-row">
                                                        <div className="form-group col-12">
                                                            <TextField
                                                                name="personalNumber"
                                                                label="Personnummer"
                                                                className="center"
                                                                disabled={isSubmitting}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-12">
                                                            <TextField
                                                                name="password"
                                                                label="Lösenord"
                                                                type="password"
                                                                className="center"
                                                                disabled={isSubmitting}
                                                                autoComplete={"current-password"}
                                                            />
                                                        </div>
                                                    </div>

                                                    <button
                                                        className="btn loginbtn btn-block"
                                                        id="loginsubmitbutton"
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                    >
                                                        LOGGA IN
                                                    </button>
                                                </div>
                                            </Form>
                                        </Fragment>
                                    )}
                                </Formik>
                            </div>
                        </div>

                        <div className="row mt-4" style={{ justifyContent: "center", alignItems: "center" }}>
                            <button
                                className="btn btn-link "
                                type="button"
                                onClick={() => {
                                    const usePassword = REACT_APP_DB_NAME === "prod" ? !login.usePassword : true;
                                    setLogin({ ...login, usePassword, statusMessage: "", errorMessage: "" });
                                }}
                            >
                                Logga in med {login.usePassword ? "mobilt Bank ID" : "lösenord"} istället
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        if (!bankIdData.orderRef) {
            return <div></div>;
        }
        return (
            <div
                className="loginscreen"
                style={{
                    backgroundImage: "url(/background.jpg)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh"
                }}
            >
                <div className="row">
                    <div className="col me-4 ms-4" style={{ background: "white", borderRadius: "5px" }}>
                        <h2
                            className="row"
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                                background: "#2186c5",
                                color: "white"
                            }}
                        >
                            <span className="m-2">{REACT_APP_NAME.toUpperCase()}</span>
                        </h2>
                        <h2 className="d-flex me-4 ms-4 justify-content-center">
                            <span>LOGGA IN BANKID</span>
                        </h2>
                        <div className="row">
                            <div className="col">
                                {bankIdData.status === BidStatus.pending ? (
                                    <div className="d-flex justify-content-center">
                                        {isMobile ? (
                                            <button
                                                className="btn mt-4"
                                                onClick={async () => {
                                                    if (bankIdData && bankIdData.autoStartToken) {
                                                        const url = `https://app.bankid.com/?autostarttoken=${bankIdData.autoStartToken}&redirect=${window.location.href}`;
                                                        window.location.replace(url);
                                                    }
                                                }}
                                            >
                                                Open BankId on same device
                                            </button>
                                        ) : (
                                            <>
                                                {bankIdData.hintCode === BidHintCode.outstandingTransaction ? (
                                                    <QRCodeCanvas value={bankIdData.qrCode} />
                                                ) : (
                                                    <div>
                                                        {bankIdData.hintCode === BidHintCode.userSign
                                                            ? "Sign in with your BankID app"
                                                            : bankIdData.hintCode}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <div className="d-flex mt-4 justify-content-center">
                                        <button
                                            className="btn loginbtn btn-block mt-4"
                                            onClick={() => {
                                                setBankIdData({
                                                    autoStartToken: "",
                                                    qrStartToken: "",
                                                    orderRef: "",
                                                    status: BidStatus.pending,
                                                    hintCode: BidHintCode.outstandingTransaction,
                                                    qrCode: ""
                                                });
                                            }}
                                        >
                                            Restart BankID login
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="row mt-4" style={{ justifyContent: "center", alignItems: "center" }}>
                            <button
                                className="btn btn-link "
                                type="button"
                                onClick={() => {
                                    const usePassword = REACT_APP_DB_NAME === "prod" ? !login.usePassword : true;
                                    setLogin({ ...login, usePassword, statusMessage: "", errorMessage: "" });
                                }}
                            >
                                Logga in med {login.usePassword ? "mobilt Bank ID" : "lösenord"} istället
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

/*
import * as Yup from "yup";
validationSchema={Yup.object({
    personalNumber: Yup.string().length(12, "Måste vara 12 siffor")
})}
*/
