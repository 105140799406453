import React, { Fragment, useState, useEffect, ReactElement } from "react";
import { cloneDeep, sortBy } from "lodash";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { recursivelyRemoveKey } from "../../../../../common/src";

import { useAlertTimeOut } from "../../../common/Utils";
import {
    SubmitButton,
    TextField,
    DateField,
    IntegerField,
    CheckBoxField,
    SelectField,
    MultipleSelectField
} from "../../../components/form";
import { useGetPartyQuery, useUpdatePartyFundInfoMutation } from "../PartyQuery.generated";
import {
    EsgComponentEnum,
    FundReportComponentEnum,
    FundTypeEnum,
    FundReportEnum,
    CalendarNameEnum,
    CalendarNameEnumDescriptions,
    EsgComponentEnumDescriptions,
    FundReportComponentEnumDescriptions,
    FundReportEnumDescriptions
} from "../../../types.generated";
import { formikUrqlErrorFormater } from "../../../common/formik-urql-error-helper";
interface PartyFundInfoTabPropsType {
    partyId: string;
}

export const PartyFundInfoTab = ({ partyId }: PartyFundInfoTabPropsType): ReactElement => {
    const [{ fetching: loading, error, data }, refetch] = useGetPartyQuery({
        variables: { _id: partyId === "new" ? "000000000000000000000000" : partyId },
        requestPolicy: "cache-and-network"
    });

    const [currentParty, setCurrentParty] = useState(cloneDeep(null));
    const [_, updatePartyFundInfo] = useUpdatePartyFundInfoMutation();
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        if (data) {
            setCurrentParty(cloneDeep(data.party));
        }
    }, [data, refetch]);

    if (loading) return <div>Loading</div>;

    if (error) return <div>{"Error: " + error.message}</div>;

    if (!currentParty) return <div></div>;

    const defaultClassDefinition = {
        dividendPaying: false,
        enabled: true,
        instrumentId: "",
        managementFee: "",
        managementFeeStruct: "FLAT",
        minimumInitialSubscription: "10000000",
        minimumSubscriptionAdded: "0",
        numberOfDecimalsNAVPerUnit: 4,
        numberOfDecimalsUnits: 4,
        redemptionFee: "0",
        sIFA_ID: "",
        sIFA_UNIT: "",
        shareClass: "",
        startDate: "",
        startNAVPerUnit: "",
        subscriptionFee: "",
        tradeCutOff: "10:00",
        earlyCloseTradeCutOff: "",
        sfdr: "",
        fxHedged: false,
        cleanClass: false,
        mainClass: false,
        carryOwnCostPossible: false
    };

    let clientOptions = data.clients.map((d) => ({ key: d._id, value: d.name }));
    clientOptions = [{ key: "000000000000000000000000", value: "None" }, ...sortBy(clientOptions, "value")];

    let custodianOptions = data.custodians.map((d) => ({ key: d._id, value: d.name }));
    custodianOptions = [{ key: "000000000000000000000000", value: "None" }, ...sortBy(custodianOptions, "value")];

    const fxHedgeAccountOptions = [
        { key: "000000000000000000000000", name: "None" },
        ...currentParty.accounts.map((d) => ({ key: d._id, value: d.name }))
    ];

    const fundTypeOptions = [{ key: null, value: "None" }].concat(
        sortBy(
            Object.keys(FundTypeEnum).map((name) => {
                return { key: name, value: name };
            }),
            "value"
        )
    );

    return (
        <div id="partyfundinfoform" className="partyfundinfoform form">
            <div className="form row mb-5">Fund specific information.</div>
            <Formik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={currentParty.fundInfo}
                validationSchema={Yup.object({
                    fundDescription: Yup.string().required().min(2),
                    fundType: Yup.string().required().min(2),
                    tradeFrequency: Yup.string().required().min(2),
                    fundManager: Yup.string().required().min(2),
                    createRedeemInstrumentId: Yup.string().required().min(2),
                    portfolio: Yup.string().required().min(2),
                    riskLevel: Yup.number().required()
                })}
                onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                    const input: any = recursivelyRemoveKey(submitValues, "__typename");

                    await updatePartyFundInfo({ partyId: partyId, input })
                        .then((result) => {
                            if ("error" in result && result.error) {
                                const message = formikUrqlErrorFormater(result.error, setErrors);
                                setAlert({ color: "danger", visible: true, message });
                            } else {
                                setAlert({ color: "success", visible: true, message: `The party has been updated successfully!` });
                                refetch();
                            }
                        })
                        .catch((error) => {
                            setAlert({ color: "danger", visible: true, message: error.toString() });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ isSubmitting, values }) => {
                    return (
                        <Fragment>
                            <Form autoComplete="off">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group form-row">
                                            <TextField
                                                name="fundDescription"
                                                label="Fund description*"
                                                type="text"
                                                className="col-sm-4 col-md-3 "
                                                disabled={isSubmitting}
                                            />
                                            <IntegerField
                                                className="col-sm-4 col-md-3"
                                                name="riskLevel"
                                                label="Risk level KIID-category"
                                                disabled={isSubmitting}
                                                min={0}
                                                max={100}
                                            />
                                            <TextField
                                                name="tradeFrequency"
                                                label="Trade frequency*"
                                                type="text"
                                                className="col-sm-4 col-md-3"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-group form-row">
                                            <SelectField
                                                name="fundType"
                                                label="Fund type*"
                                                options={fundTypeOptions}
                                                className="col-sm-4 col-md-3"
                                                disabled={isSubmitting}
                                            />
                                            <SelectField
                                                name="fundCompanyId"
                                                label={
                                                    values && values.fundCompanyId ? (
                                                        <Link to={"/parties/" + values.fundCompanyId}>Fund company</Link>
                                                    ) : (
                                                        "Fund company"
                                                    )
                                                }
                                                options={clientOptions}
                                                className="col-sm-4 col-md-3"
                                                disabled={isSubmitting}
                                            />
                                            <SelectField
                                                name="depositaryId"
                                                label={
                                                    <a
                                                        href={"https://www.investopedia.com/terms/d/depository.asp"}
                                                        target={"_blank"}
                                                        rel="noreferrer"
                                                    >
                                                        {"Depositary"}
                                                    </a>
                                                }
                                                options={custodianOptions}
                                                className="col-sm-4 col-md-3"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-group form-row">
                                            <TextField
                                                name="fundManager"
                                                label="Fund manager*"
                                                type="text"
                                                className="col-sm-4 col-md-3"
                                                disabled={isSubmitting}
                                            />
                                            <SelectField
                                                name="taId"
                                                label={
                                                    <a
                                                        href={"https://www.investopedia.com/terms/t/transferagent.asp"}
                                                        target={"_blank"}
                                                        rel="noreferrer"
                                                    >
                                                        {"Transfer agent"}
                                                    </a>
                                                }
                                                options={custodianOptions}
                                                className="col-sm-4 col-md-3"
                                                disabled={isSubmitting}
                                            />
                                            <SelectField
                                                name="custodianId"
                                                label={
                                                    <a
                                                        href={"https://www.investopedia.com/terms/c/custodian.asp"}
                                                        target={"_blank"}
                                                        rel="noreferrer"
                                                    >
                                                        {"Custodian"}
                                                    </a>
                                                }
                                                options={custodianOptions}
                                                className="col-sm-4 col-md-3"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-group form-row">
                                            <SelectField
                                                name="createRedeemInstrumentId"
                                                label="CreateRedeem instrument*"
                                                options={[
                                                    { key: "000000000000000000000000", value: "None" },
                                                    ...currentParty.instruments.map((d) => ({ key: d._id, value: d.name }))
                                                ]}
                                                className="col-sm-4 col-md-3"
                                                disabled={isSubmitting}
                                                size={12}
                                            />
                                            <TextField
                                                className="col-sm-4 col-md-3"
                                                name="portfolio"
                                                label="Portfolio*"
                                                type="text"
                                                disabled={isSubmitting}
                                            />
                                            <DateField
                                                className="col-sm-4 col-md-3"
                                                name={"endDate"}
                                                label="End date"
                                                disabled={isSubmitting}
                                                size={12}
                                            />
                                        </div>
                                        <div className="form-group form-row">
                                            <MultipleSelectField
                                                className="col-3"
                                                size={10}
                                                name="calendarNames"
                                                label={
                                                    values && values.calendarNames && values.calendarNames.length ? (
                                                        <Link to={"/calendars/" + partyId}>Calendar</Link>
                                                    ) : (
                                                        "Calendar"
                                                    )
                                                }
                                                options={Object.values(CalendarNameEnum)}
                                                tooltips={CalendarNameEnumDescriptions}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-group form-row">
                                            <div className="col-12">
                                                <label style={{ fontWeight: "bold" }}>Classes</label>
                                            </div>
                                            <FieldArray
                                                name="classes"
                                                render={(arrayHelpers) => (
                                                    <Fragment>
                                                        {values && values.classes && values.classes.length > 0 ? (
                                                            values.classes.map((item, index) => (
                                                                <div key={index} className="form-group form-row col-sm-12 mb-4">
                                                                    <div className="col-sm-10 col-md-8 col-lg-6">
                                                                        <fieldset>
                                                                            <legend>{index + 1}</legend>
                                                                            <div className="form-group form-row">
                                                                                <SelectField
                                                                                    name={`classes[${index}].instrumentId`}
                                                                                    label={
                                                                                        <Link to={"/instruments/" + item.instrumentId}>
                                                                                            Instrument
                                                                                        </Link>
                                                                                    }
                                                                                    options={data.fundInternals.map((d) => ({
                                                                                        key: d._id,
                                                                                        value: d.name
                                                                                    }))}
                                                                                    className="col-sm-6"
                                                                                    disabled={isSubmitting}
                                                                                    size={12}
                                                                                />
                                                                                <DateField
                                                                                    className="form-group col-sm-6"
                                                                                    name={`classes[${index}].startDate`}
                                                                                    label="Start date*"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group form-row">
                                                                                <TextField
                                                                                    name={`classes[${index}].subscriptionFee`}
                                                                                    label="Subscription fee*"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                />
                                                                                <TextField
                                                                                    name={`classes[${index}].managementFee`}
                                                                                    label="Management fee*"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group form-row">
                                                                                <TextField
                                                                                    name={`classes[${index}].redemptionFee`}
                                                                                    label="Redemption fee*"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                />
                                                                                <TextField
                                                                                    name={`classes[${index}].managementFeeStruct`}
                                                                                    label="Management fee struct*"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group form-row">
                                                                                <TextField
                                                                                    name={`classes[${index}].startNAVPerUnit`}
                                                                                    label="Start NAV per unit*"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                />
                                                                                <TextField
                                                                                    name={`classes[${index}].minimumInitialSubscription`}
                                                                                    label="Minimum initial subscription*"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group form-row">
                                                                                <TextField
                                                                                    name={`classes[${index}].sIFA_ID`}
                                                                                    label="SIFA_ID*"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                />
                                                                                <TextField
                                                                                    name={`classes[${index}].minimumSubscriptionAdded`}
                                                                                    label="Minimum subscription added*"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group form-row">
                                                                                <TextField
                                                                                    name={`classes[${index}].tradeCutOff`}
                                                                                    label="Trade cutoff*"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                />
                                                                                <TextField
                                                                                    name={`classes[${index}].earlyCloseTradeCutOff`}
                                                                                    label="Early close trade cutoff"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group form-row">
                                                                                <IntegerField
                                                                                    name={`classes[${index}].numberOfDecimalsUnits`}
                                                                                    label="Number of decimals units*"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                    min={0}
                                                                                    max={10000}
                                                                                />
                                                                                <IntegerField
                                                                                    name={`classes[${index}].numberOfDecimalsNAVPerUnit`}
                                                                                    label="Number of decimals NAV per unit*"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                    min={0}
                                                                                    max={10000}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group form-row">
                                                                                <SelectField
                                                                                    name={`classes[${index}].fxHedgeAccountId`}
                                                                                    label={"Fx hedge account"}
                                                                                    options={fxHedgeAccountOptions}
                                                                                    className="col-sm-6"
                                                                                    disabled={isSubmitting}
                                                                                    size={fxHedgeAccountOptions.length}
                                                                                />
                                                                                <TextField
                                                                                    name={`classes[${index}].sfdr`}
                                                                                    label="SFDR"
                                                                                    className="col-sm-6"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group form-row">
                                                                                <TextField
                                                                                    name={`classes[${index}].sIFA_UNIT`}
                                                                                    label="SIFA_UNIT*"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                />
                                                                                <TextField
                                                                                    name={`classes[${index}].shareClass`}
                                                                                    label="Share class*"
                                                                                    className="col-sm-6"
                                                                                    disabled={false}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group form-row">
                                                                                <CheckBoxField
                                                                                    name={`classes[${index}].mainClass`}
                                                                                    label="Main class"
                                                                                    className="col-sm-6 d-flex flex-row mt-4 justify-content-start"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <CheckBoxField
                                                                                    name={`classes[${index}].dividendPaying`}
                                                                                    label="Dividend paying"
                                                                                    className="col-sm-6 d-flex flex-row mt-4 justify-content-start"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group form-row">
                                                                                <CheckBoxField
                                                                                    name={`classes[${index}].enabled`}
                                                                                    label="Enabled"
                                                                                    className="col-sm-6 d-flex flex-row mt-4 justify-content-start"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <CheckBoxField
                                                                                    name={`classes[${index}].fxHedged`}
                                                                                    label="Fx hedged"
                                                                                    className="col-sm-6 d-flex flex-row mt-4 justify-content-start"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group form-row">
                                                                                <CheckBoxField
                                                                                    name={`classes[${index}].cleanClass`}
                                                                                    label="Clean class"
                                                                                    className="col-sm-6 d-flex flex-row mt-4 justify-content-start"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <CheckBoxField
                                                                                    name={`classes[${index}].carryOwnCostPossible`}
                                                                                    label="Carry own cost possible"
                                                                                    className="col-sm-6 d-flex flex-row mt-4 justify-content-start"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                            </div>
                                                                        </fieldset>
                                                                    </div>
                                                                    <div className="col-sm-2 col-md-2 col-lg-1">
                                                                        <Button
                                                                            className="me-1 mt-2 btn-danger btn-sm"
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.remove(index)}
                                                                        >
                                                                            -
                                                                        </Button>
                                                                        <Button
                                                                            className="btn-sm mt-2"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                arrayHelpers.insert(index, defaultClassDefinition)
                                                                            }
                                                                        >
                                                                            +
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <Button
                                                                className="btn-sm ms-1"
                                                                type="button"
                                                                onClick={() => arrayHelpers.push(defaultClassDefinition)}
                                                            >
                                                                +
                                                            </Button>
                                                        )}
                                                    </Fragment>
                                                )}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <MultipleSelectField
                                                className="col-3"
                                                size={10}
                                                name="esgComponents"
                                                label="Esg components"
                                                options={Object.values(EsgComponentEnum)}
                                                tooltips={EsgComponentEnumDescriptions}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <MultipleSelectField
                                                className="col-3"
                                                size={10}
                                                name="fundReportComponents"
                                                label="Fund report components"
                                                options={Object.values(FundReportComponentEnum)}
                                                tooltips={FundReportComponentEnumDescriptions}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <MultipleSelectField
                                                className="col-3"
                                                size={5}
                                                name="fundReportTypes"
                                                label="Fund report types"
                                                options={Object.values(FundReportEnum)}
                                                tooltips={FundReportEnumDescriptions}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        {alert.visible ? (
                                            <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                                                {alert.message}
                                            </Alert>
                                        ) : null}
                                        <SubmitButton disabled={isSubmitting} label="Update" />
                                    </div>
                                </div>
                            </Form>
                        </Fragment>
                    );
                }}
            </Formik>
        </div>
    );
};
