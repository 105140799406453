import React, { useMemo } from "react";
import { gql, useQuery } from "urql";
import { Card } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { PartyType, PermissionAssetEnum } from "../types.generated";

import Diagram from "../images/diagram.svg";
import SearchDollar from "../images/search_dollar.svg";
import Briefcase from "../images/briefcase.svg";
import Paper from "../images/paper.svg";
import Leaf from "../images/leaf.svg";
import Cog from "../images/cog.svg";
import { getMe } from "./SideNav";
import { ClientContextSelector } from "../contexts/ClientContextSelector";
import { IClient, ClientSelectorPlaceHolder } from "../contexts/ClientContext";
import { emptyObjectId } from "../../../common/src";

const getClients = gql`
    query {
        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
            dashName
        }
    }
`;

export function ClientPage(): React.ReactElement {
    const { clientDashName } = useParams<"clientDashName">();
    const [{ fetching, error, data }] = useQuery({ query: getClients });
    const [{ fetching: userLoading, error: userError, data: userData }] = useQuery({ query: getMe });

    const defaultClientName = "Select client";

    const [dashNameLookup] = useMemo(() => {
        const dashNameLookup: Record<string, IClient> = {};
        const nameLookup: Record<string, IClient> = {};
        const idLookup: Record<string, IClient> = {};
        if (data && data.clients) {
            for (const client of data.clients) {
                dashNameLookup[client.dashName] = client;
                nameLookup[client.name] = client;
                idLookup[client._id] = client;
            }
        }

        dashNameLookup[ClientSelectorPlaceHolder.dashName] = ClientSelectorPlaceHolder;
        nameLookup[ClientSelectorPlaceHolder.name] = ClientSelectorPlaceHolder;
        idLookup[ClientSelectorPlaceHolder._id] = ClientSelectorPlaceHolder;

        return [dashNameLookup, nameLookup, idLookup];
    }, [data]);

    if (fetching || userLoading)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (userError) {
        console.log("userError: ", userError);
        return (
            <div>
                <p>user error:</p>
                <pre> {JSON.stringify(userError, null, 2)}</pre>
            </div>
        );
    }

    let client = null;
    if (clientDashName) {
        client = dashNameLookup[clientDashName];
    }

    if (clientDashName && !client) {
        return (
            <div className="loader">
                <h3>No client with that name</h3>
            </div>
        );
    }

    const accessToShow = {
        performanceAnalysis: false,
        portfolioManager: false,
        portfolioRiskTradingLimits: false, // TODO: is there a special access control for risk tab?
        financialStatementsReporting: false,
        esg: false,
        operations: false
    };

    const frontendAssets: PermissionAssetEnum[] = userData.me.frontendRole.assets;

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Performance)) {
        accessToShow.performanceAnalysis = true;
    }

    if (
        frontendAssets.length === 0 ||
        frontendAssets.includes(PermissionAssetEnum.Position) ||
        frontendAssets.includes(PermissionAssetEnum.Limit)
    ) {
        accessToShow.portfolioRiskTradingLimits = true;
    }

    if (
        frontendAssets.length === 0 ||
        frontendAssets.includes(PermissionAssetEnum.Transaction) ||
        frontendAssets.includes(PermissionAssetEnum.Reconciliation)
    ) {
        accessToShow.operations = true;
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Accounting)) {
        if (
            frontendAssets.length === 0 ||
            (frontendAssets.includes(PermissionAssetEnum.Nav) &&
                frontendAssets.includes(PermissionAssetEnum.Position) &&
                frontendAssets.includes(PermissionAssetEnum.Transaction))
        ) {
            accessToShow.financialStatementsReporting = true;
        }
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.PortfolioManager)) {
        accessToShow.portfolioManager = true;
    }
    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Esg)) {
        accessToShow.esg = true;
    }

    return (
        <div className="container">
            <div className="row mb-5">
                <div className="col-md-auto">
                    <ClientContextSelector typeIn={[PartyType.Client]} defaultClientName={defaultClientName} />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="d-flex flex-wrap justify-content-center">
                        {accessToShow.performanceAnalysis ? (
                            <div className="mx-1 my-1">
                                <Link to={clientDashName ? "/performance/" + clientDashName : "/performance"}>
                                    <Card onClick={null} style={{ height: "156px" }} className="hover__pointer">
                                        <Card.Body>
                                            <div className="text-center portal__buttons">
                                                <img src={Diagram} style={{ width: "100px" }} className="portal__icon__color" />
                                                <Card.Title className="portal__button__text"> Performance Analysis</Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        ) : null}
                        {accessToShow.portfolioRiskTradingLimits ? (
                            <div className="mx-1 my-1">
                                <Link to={clientDashName ? "/portfoliorisk/" + clientDashName : "/portfoliorisk"}>
                                    <Card onClick={null} style={{ height: "156px" }} className="hover__pointer">
                                        <Card.Body>
                                            <div className="text-center portal__buttons">
                                                <img src={SearchDollar} style={{ width: "100px" }} className="portal__icon__color" />
                                                <Card.Title className="portal__button__text">
                                                    {"Portfolio Risk & Trading limits"}
                                                </Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        ) : null}
                        {accessToShow.portfolioManager ? (
                            <div className="mx-1 my-1">
                                <Link to={client && client._id !== emptyObjectId ? "/pm/" + client._id : "/pm"}>
                                    <Card onClick={null} style={{ height: "156px" }} className="hover__pointer">
                                        <Card.Body>
                                            <div className="text-center portal__buttons">
                                                <img src={Briefcase} style={{ width: "100px" }} className="portal__icon__color" />
                                                <Card.Title className="portal__button__text">{"Portfolio Management"}</Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        ) : null}
                        {accessToShow.financialStatementsReporting ? (
                            <div className="mx-1 my-1">
                                <Link to={client ? "/financialstatements/?clientId=" + client._id : "/financialstatements"}>
                                    <Card onClick={null} style={{ height: "156px" }} className="hover__pointer">
                                        <Card.Body>
                                            <div className="text-center">
                                                <img src={Paper} style={{ width: "100px" }} className="portal__icon__color" />
                                            </div>
                                            <div className="justify-content-center portal__buttons">
                                                <Card.Title className="portal__button__text" style={{ textAlign: "center" }}>
                                                    {/* textAlign: "center" for safari-browser*/}
                                                    {"Financial Statements"}
                                                </Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        ) : null}
                        {accessToShow.esg ? (
                            <div className="mx-1 my-1">
                                <Link to={clientDashName ? "/esg/" + clientDashName : "/esg"}>
                                    <Card id="/esg" onClick={null} style={{ height: "156px" }} className="hover__pointer">
                                        <Card.Body>
                                            <div className="text-center">
                                                <img src={Leaf} style={{ width: "100px" }} className="portal__icon__color" />
                                            </div>
                                            <div className="text-center">
                                                <Card.Title className="portal__button__text">{"ESG"}</Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        ) : null}
                        {accessToShow.operations ? (
                            <div className="mx-1 my-1">
                                <Link to={clientDashName ? "/operations/" + clientDashName : "/operations"}>
                                    <Card onClick={null} style={{ height: "156px" }} className="hover__pointer">
                                        <Card.Body>
                                            <div className="text-center">
                                                <img src={Cog} style={{ width: "100px" }} className="portal__icon__color" />
                                            </div>
                                            <div className="text-center portal__buttons">
                                                <Card.Title className="portal__button__text" style={{ paddingTop: "5px" }}>
                                                    {"Operations"}
                                                </Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
