import React, { useMemo } from "react";
import { gql, useQuery } from "urql";
import { Link, useParams } from "react-router-dom";

import { ReactTable } from "../components/react-table/ReactTable";
import { SelectColumnFilter } from "../components/react-table/ReactTableFilters";

const REGISTER_EXTRACT = gql`
    query registerExtracts($_id: GraphQLObjectId!) {
        registerExtracts(filter: { idIn: [$_id] }) {
            _id
            date
            clientId
            client {
                _id
                name
            }
            updateUserId
            updateUserInfo {
                name
            }
            createTimestamp
            updateTimestamp
            items {
                address
                city
                country
                date
                detailedCustomerSpecifics
                email
                fundClassName
                fundId
                fundPrice
                fundShortName
                instrumentId
                isin
                legalPerson
                nationalRegistrationId
                organizationName
                postalCode
                purchaseAmount
                quantity
                taClientAccount
                taClientAccountLabel
                taClientName
                taxCountry
            }
        }
    }
`;

export const RegisterExtractPage = (): React.ReactElement => {
    const params = useParams();

    const [{ fetching, data, error }] = useQuery({
        query: REGISTER_EXTRACT,
        variables: { _id: params.id ? params.id : "000000000000000000000000" },
        requestPolicy: "cache-and-network"
    });

    const columns = useMemo(() => {
        return [
            { header: "address", accessorKey: "address" },
            { header: "city", accessorKey: "city" },
            { header: "country", accessorKey: "country" },
            { header: "date", accessorKey: "date" },
            { header: "detailedCustomerSpecifics", accessorKey: "detailedCustomerSpecifics" },
            { header: "email", accessorKey: "email" },
            { header: "fundClassName", accessorKey: "fundClassName", filter: SelectColumnFilter },
            { header: "fundId", accessorKey: "fundId" },
            { header: "fundPrice", accessorKey: "fundPrice" },
            { header: "fundShortName", accessorKey: "fundShortName", filter: SelectColumnFilter },
            { header: "instrumentId", accessorKey: "instrumentId" },
            { header: "isin", accessorKey: "isin" },
            { header: "legalPerson", accessorKey: "legalPerson" },
            { header: "nationalRegistrationId", accessorKey: "nationalRegistrationId" },
            { header: "organizationName", accessorKey: "organizationName" },
            { header: "postalCode", accessorKey: "postalCode" },
            { header: "purchaseAmount", accessorKey: "purchaseAmount" },
            { header: "quantity", accessorKey: "quantity" },
            { header: "taClientAccount", accessorKey: "taClientAccount" },
            { header: "taClientAccountLabel", accessorKey: "taClientAccountLabel" },
            { header: "taClientName", accessorKey: "taClientName" },
            { header: "taxCountry", accessorKey: "taxCountry" }
        ];
    }, []);

    if (fetching) return <div>Loading...</div>;

    if (error)
        return (
            <div>
                <p>Error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (data.registerExtracts.length !== 1) return <div>No data found</div>;

    return (
        <div>
            <div className="print-none">
                <Link to={"/registerextracts"}>TA register extracts</Link>
            </div>
            <ReactTable
                initialPageSize={400}
                columns={columns}
                data={data.registerExtracts[0].items}
                defaultHiddenColumns={[
                    "email",
                    "postalCode",
                    "address",
                    "city",
                    "fundId",
                    "instrumentId",
                    "legalPerson",
                    "organizationName",
                    "country",
                    "taxCountry",
                    "fundPrice"
                ]}
                exportToXlsxFile="register_extract.xlsx"
            />
        </div>
    );
};
