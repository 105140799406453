import { Form, Formik } from "formik";
import React, { useState } from "react";
import { gql, useMutation, useQuery } from "urql";
import { SearchMultipleSelectField, SelectField, SubmitButton, TextField } from "../../components/form";
import { Alert } from "react-bootstrap";
import { CustomerInput, SwedishFinancialAuthorityCategoryEnum } from "../../types.generated";
import { useAlertTimeOut } from "../../common/Utils";
import { useNavigate } from "react-router";

const GET_CLIENTS = gql`
    query clients {
        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
        }
    }
`;

const GET_CUSTOMER = gql`
    query customers($id: GraphQLObjectId!) {
        customers(filter: { idIn: [$id] }) {
            _id
            clientIds
            clientNumber
            clientAccount
            clientAccountLabel
            organizationName
            swedishFinancialAuthorityCategory
            swedishFinancialAuthorityCategoryString
        }
    }
`;

const UPDATE_CUSTOMER = gql`
    mutation upsertCustomers($input: [CustomerInput!]!) {
        upsertCustomers(input: $input) {
            _id
        }
    }
`;

const getDefaultCustomerInput = (): CustomerInput => {
    return {
        clientIds: [],
        clientNumber: "",
        clientAccount: "",
        clientAccountLabel: "",
        organizationName: "",
        swedishFinancialAuthorityCategory: SwedishFinancialAuthorityCategoryEnum.Banks
    };
};

interface ICustomerForm {
    id: string;
}
export function CustomerForm({ id }: ICustomerForm): React.ReactElement {
    const isEditMode = id !== "new";

    const navigate = useNavigate();

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    useAlertTimeOut(alert, setAlert, 5);

    const [{ fetching: fetchingClients, error: _errorClients, data: dataClients }] = useQuery({
        query: GET_CLIENTS
    });

    const [{ fetching, error, data }] = useQuery({
        query: GET_CUSTOMER,
        variables: { id },
        pause: !isEditMode,
        requestPolicy: "network-only"
    });

    const [_, upsertCustomers] = useMutation(UPDATE_CUSTOMER);

    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    if (fetching || fetchingClients) return <div className="miniform-loading">Loading</div>;

    if (error) {
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );
    }

    const customer = isEditMode ? data.customers[0] : getDefaultCustomerInput();

    const clients = dataClients.clients;
    return (
        <div className="customerform form">
            <h3>{isEditMode ? "Edit Customer" : "New Customer"}</h3>
            <Formik
                enableReinitialize={true}
                initialValues={customer}
                onSubmit={async (submitValues, { setSubmitting }) => {
                    const cust = { ...submitValues };
                    delete cust.__typename;
                    delete cust.swedishFinancialAuthorityCategoryString;

                    await upsertCustomers({ input: [cust] })
                        .then((result) => {
                            if (result.error) {
                                setAlert({ color: "danger", visible: true, message: result.error.toString() });
                            } else {
                                setAlert({
                                    color: "success",
                                    visible: true,
                                    message: `Customer ${isEditMode ? "updated" : "created"} successfully!`
                                });

                                navigate(`/backoffice/customers/${result.data.upsertCustomers[0]._id}`);
                            }
                        })
                        .catch((error) => {
                            setAlert({ color: "danger", visible: true, message: error.toString() });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ isSubmitting, values, errors }) => {
                    return (
                        <Form autoComplete="off">
                            {alert.visible ? (
                                <Alert variant={alert.color} onClose={onDismissAlert} dismissible className="mt-2">
                                    {alert.message}
                                </Alert>
                            ) : null}
                            <SearchMultipleSelectField
                                name="clientIds"
                                label="Clients (owner)"
                                className="w-100"
                                disabled={isSubmitting}
                                options={clients.map((d) => ({ key: d._id, value: d.name, text: d.name }))}
                            />
                            <TextField name="clientNumber" label="Client number" className="mt-4" disabled={isSubmitting} />
                            <TextField name="clientAccount" label="Client account" className="mt-3" disabled={isSubmitting} />
                            <TextField name="clientAccountLabel" label="Client account label" className="mt-3" disabled={isSubmitting} />
                            <TextField name="organizationName" label="Organization name" className="mt-3" disabled={isSubmitting} />
                            <SelectField
                                name="swedishFinancialAuthorityCategory"
                                label="Swedish financial authority category"
                                className="mt-3"
                                options={Object.values(SwedishFinancialAuthorityCategoryEnum).sort()}
                                disabled={isSubmitting}
                            />
                            <div>
                                <i>{values.swedishFinancialAuthorityCategoryString}</i>
                            </div>
                            <SubmitButton disabled={isSubmitting || Object.keys(errors).length > 0} label={"Save"} />
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
