import React, { useReducer, useEffect } from "react";
import Bugsnag from "@bugsnag/js";
//import stableStringify from "json-stable-stringify";
import { jwtDecode } from "jwt-decode";

import { LoginScreen } from "./LoginScreen";
import { clearLocalClientState } from "../contexts/ClientContext";
import { REACT_APP_AUTH_URI, REACT_APP_DB_NAME, REACT_APP_API_URI } from "../env";

export interface ILogin {
    expires: string;
    loggedIn: boolean;
    acceptedTerms: boolean;
    token: string;
    personalNumber: string;
    userId: string;
    username: string;
    usePassword: boolean;
    errorMessage: string;
    statusMessage: string;
}

export type ILoginContextType = {
    login: ILogin;
    setLogin: (login: ILogin) => void;
};

export const getDefaultLoginValue = (): ILogin => {
    Bugsnag.leaveBreadcrumb("getDefaultLoginValue");
    return {
        expires: null,
        loggedIn: false,
        acceptedTerms: false,
        token: "",
        personalNumber: "",
        userId: "",
        username: "",
        usePassword: REACT_APP_DB_NAME === "prod" ? false : true,
        errorMessage: "",
        statusMessage: ""
    };
};

const reducer = (login, newLogin) => {
    if (newLogin === null) {
        localStorage.removeItem("login");
        return getDefaultLoginValue();
    }
    return { ...login, ...newLogin };
};

const localState: ILogin = JSON.parse(localStorage.getItem("login"));

const loginContext = React.createContext<ILoginContextType>({
    login: getDefaultLoginValue(),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLogin: (): void => {}
});

function Login({ children }: { children: React.ReactNode }): React.ReactElement {
    if (!REACT_APP_AUTH_URI) {
        console.error("REACT_APP_AUTH_URI: ", REACT_APP_AUTH_URI);
    }
    if (!REACT_APP_DB_NAME) {
        console.error("REACT_APP_DB_NAME: ", REACT_APP_DB_NAME);
    }
    if (!REACT_APP_API_URI) {
        console.error("REACT_APP_API_URI: ", REACT_APP_API_URI);
    }
    const [login, setLogin] = useReducer(reducer, localState || getDefaultLoginValue());

    useEffect(() => {
        let thisLogin = JSON.parse(localStorage.getItem("login"));
        window.addEventListener("storage", () => {
            // When local storage changes
            thisLogin = JSON.parse(localStorage.getItem("login"));
            setLogin(thisLogin);
        });

        localStorage.setItem("login", JSON.stringify(login));
        if (!login.loggedIn) {
            clearLocalClientState();
        }

        if (login && login.token) {
            try {
                const decodedToken: any = jwtDecode(login.token);
                if (decodedToken.aud !== REACT_APP_DB_NAME) {
                    clearLocalClientState();
                    setLogin(getDefaultLoginValue());
                }
            } catch (e) {
                console.error(e);
                clearLocalClientState();
                setLogin(getDefaultLoginValue());
            }
        }
    }, [login]);

    if (login.loggedIn) {
        Bugsnag.setUser(login.userId, "", "");
        return <loginContext.Provider value={{ login, setLogin }}>{children}</loginContext.Provider>;
    } else {
        Bugsnag.setUser("", "", "");
        return (
            <loginContext.Provider value={{ login, setLogin }}>
                <LoginScreen />
            </loginContext.Provider>
        );
    }
}

export { Login, loginContext };
