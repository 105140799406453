import React, { Fragment, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { gql, useQuery, useMutation } from "urql";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import stableStringify from "json-stable-stringify";

import { YesNoModal } from "../components/YesNoModal";
import { SubmitButton, TextField, SelectField, DateField, NumberField, TransactionField } from "../components/form";
import { useAlertTimeOut, usePrevious } from "../common/Utils";
import { Alert } from "react-bootstrap";
import {
    InstrumentModelTypeEnum,
    MatchingCoacsStatus,
    SourceType,
    SwiftStatusEnum,
    SwiftStatusEnumDescriptions,
    TransactionStatus
} from "../types.generated";

const GET_CORPORATE_ACTION = gql`
    query miniSwifts($miniFilterIn: MiniSwiftFilterInput) {
        miniSwifts(miniFilterIn: $miniFilterIn) {
            _id
            fundId
            clientId
            fund {
                _id
                name
            }
            recordDate
            exDate
            paymentDate
            isin
            type
            instrument {
                _id
                name
            }
            currency
            amount
            foreignTax
            msgType
            caev
            status
            text
            externalId
            error
            correspondingTransactionId
            updateTimestamp
            parsed
            reportIds
            name
        }
    }
`;

const GET_DATA = gql`
    query miniTransactions($filter: TransactionFilterInput) {
        miniTransactions(filter: $filter) {
            _id
            externalId
            brokerId
            brokerTradeId
            source
            status
            instrument {
                modelType
            }
        }
    }
`;

const MATCH_CORPORATE_ACTIONS = gql`
    mutation MatchCoacsTransactions($input: [MatchCoacsTransactionsInput!]!) {
        matchCoacsTransactions(input: $input) {
            swiftTransaction {
                _id
                fundId
                clientId
                fund {
                    _id
                    name
                }
                recordDate
                exDate
                paymentDate
                isin
                type
                instrument {
                    _id
                    name
                }
                currency
                amount
                foreignTax
                msgType
                caev
                status
                text
                externalId
                error
                correspondingTransactionId
                updateTimestamp
                parsed
                reportIds
                name
            }
        }
    }
`;

const UPDATE_CORPORATE_ACTION = gql`
    mutation UpdateSwifts($input: [UpdateSwiftInput!]!) {
        updateSwifts(input: $input) {
            _id
            clientId
            fund {
                _id
                name
            }
            recordDate
            exDate
            paymentDate
            isin
            type
            instrument {
                _id
                name
            }
            currency
            amount
            foreignTax
            msgType
            caev
            status
            text
            externalId
            error
            correspondingTransactionId
            updateTimestamp
            parsed
            reportIds
            name
        }
    }
`;

type CorporateActionFormPropsType = {
    id?: string;
    tradeDate: string;
    refetch?: () => void;
};

export function CorporateActionForm(props: CorporateActionFormPropsType): React.ReactElement {
    const previousProps = usePrevious(props);
    const id = props.id ? props.id : "000000000000000000000000";

    const [{ fetching: getCorporateActionLoading, error: getCorporateActionError, data: getCorporateActionData }, refetch] = useQuery({
        query: GET_CORPORATE_ACTION,
        variables: {
            miniFilterIn: {
                idIn: [id],
                statusIn: [
                    SwiftStatusEnum.Pending,
                    SwiftStatusEnum.Preliminary,
                    SwiftStatusEnum.Confirmed,
                    SwiftStatusEnum.UpdatedPrevious,
                    SwiftStatusEnum.Waiting,
                    SwiftStatusEnum.Settled
                ]
            }
        },
        requestPolicy: "network-only"
    });

    const [{ fetching: loading, error: getDataError, data }] = useQuery({
        query: GET_DATA,
        variables: {
            filter: { statusNotIn: [TransactionStatus.Deleted], startDate: props.tradeDate, sourceIn: [SourceType.Coacs] }
        },
        requestPolicy: "network-only"
    });

    const [values, setValues] = useState(null);
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    const [__stateUpdate, updateCorporateAction] = useMutation(UPDATE_CORPORATE_ACTION);
    const [__stateMatch, matchCorporateActions] = useMutation(MATCH_CORPORATE_ACTIONS);

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        if (stableStringify(props) !== stableStringify(previousProps)) {
            setValues(null);
        } else if (!getCorporateActionLoading && !getCorporateActionError) {
            if (values === null && getCorporateActionData.miniSwifts && getCorporateActionData.miniSwifts.length) {
                const inputValues = cloneDeep(getCorporateActionData.miniSwifts[0]);
                inputValues.clientName = inputValues.fund ? inputValues.fund.name : null;
                inputValues.instrumentName = inputValues.instrument ? inputValues.instrument.name : null;
                setValues(inputValues);
            }
        }
    }, [props, previousProps, values, getCorporateActionData, getCorporateActionError, getCorporateActionLoading]);

    if (getCorporateActionLoading || loading) return <div className="miniform-loading">Loading</div>;

    if (getCorporateActionError) return <div>{"Error: " + getCorporateActionError.message}</div>;
    if (getDataError) return <div>{"Error: " + getDataError.message}</div>;

    if (!values) return <div></div>;

    const miniTransactions = cloneDeep(data.miniTransactions);
    const corporateActionsTransactions = [{ brokerTradeId: null, name: "No match", source: "", _id: "" }];
    const modelTypes = [InstrumentModelTypeEnum.Stock, InstrumentModelTypeEnum.Bond];
    miniTransactions.forEach((transaction) => {
        transaction["name"] = transaction.name ? transaction["name"] : transaction["externalId"];
        delete transaction["externalId"];
        // can only match with transactions that have not been matched
        if (
            transaction.instrument &&
            modelTypes.includes(transaction.instrument.modelType) &&
            ((!transaction.brokerTradeId && transaction.status === TransactionStatus.Pending) ||
                (values.correspondingTransactionId && transaction._id.toString() === values.correspondingTransactionId.toString()))
        ) {
            corporateActionsTransactions.push(transaction);
        }
    });

    return (
        <div className="text-start">
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete corporate action with id " + modal.payload._id + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        let updatedValues: any;
                        updateCorporateAction({
                            input: [
                                {
                                    _id: modal.payload._id,
                                    status: SwiftStatusEnum.Deleted,
                                    error: modal.payload.error,
                                    clientId: modal.payload.clientId
                                }
                            ]
                        })
                            .then((result) => {
                                if (result.error) {
                                    setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                } else {
                                    updatedValues = result.data.updateSwifts[0];
                                    setAlert({
                                        color: "success",
                                        visible: true,
                                        message: `The corporate action with id '${modal.payload._id}' was deleted successfully!`
                                    });
                                }
                            })
                            .catch((error) => {
                                console.error(error.toString());
                            })
                            .finally(() => {
                                if (updatedValues) {
                                    refetch();
                                    updatedValues.clientName = updatedValues.fund ? updatedValues.fund.name : null;
                                    updatedValues.instrumentName = updatedValues.instrument ? updatedValues.instrument.name : null;
                                    setValues(updatedValues);
                                    if (props.refetch) {
                                        props.refetch();
                                    }
                                }
                            });
                    }}
                />
            ) : null}

            <h3>Corporate action</h3>

            <div id="corporateactionform" className="corporateactionform form">
                <Formik
                    enableReinitialize={true}
                    initialValues={values}
                    onSubmit={async (submitValues, { setSubmitting }) => {
                        //console.log("submitValues: ", submitValues);
                        const input = { ...submitValues };
                        let updatedValues: any = null;
                        if (input._id !== null) {
                            if (
                                input.correspondingTransactionId &&
                                values.correspondingTransactionId !== input.correspondingTransactionId
                            ) {
                                await matchCorporateActions({
                                    input: [
                                        {
                                            swiftTransactionId: values._id,
                                            swiftExternalId: values.externalId,
                                            swiftTransactionError: "Manually matched",
                                            transactionId: input.correspondingTransactionId,
                                            transactionError: "Manually matched",
                                            matchingStatus: MatchingCoacsStatus.Confirmed
                                        }
                                    ]
                                })
                                    .then((result) => {
                                        if (result.error) {
                                            setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                        } else {
                                            updatedValues = result.data.matchCoacsTransactions.swiftTransaction;
                                            setAlert({
                                                color: "success",
                                                visible: true,
                                                message: `Corporate action '${updatedValues._id}' was updated successfully!`
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error.toString());
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                        if (updatedValues) {
                                            refetch();
                                            updatedValues.clientName = updatedValues.fund ? updatedValues.fund.name : null;
                                            updatedValues.instrumentName = updatedValues.instrument ? updatedValues.instrument.name : null;
                                            setValues(updatedValues);
                                            if (props.refetch) {
                                                props.refetch();
                                            }
                                        }
                                    });
                            } else if (!input.correspondingTransactionId && values.correspondingTransactionId) {
                                matchCorporateActions({
                                    input: [
                                        {
                                            swiftTransactionId: values._id,
                                            swiftExternalId: values.externalId,
                                            swiftTransactionError: null,
                                            transactionId: values.correspondingTransactionId,
                                            transactionError: null,
                                            matchingStatus: MatchingCoacsStatus.Mismatch
                                        }
                                    ]
                                })
                                    .then((result) => {
                                        if (result.error) {
                                            setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                        } else {
                                            updatedValues = result.data.matchCoacsTransactions.swiftTransaction;
                                            setAlert({
                                                color: "success",
                                                visible: true,
                                                message: `Corporate action '${updatedValues._id}' was updated successfully!`
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error.toString());
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                        if (updatedValues) {
                                            refetch();
                                            updatedValues.clientName = updatedValues.fund ? updatedValues.fund.name : null;
                                            updatedValues.instrumentName = updatedValues.instrument ? updatedValues.instrument.name : null;
                                            setValues(updatedValues);
                                            if (props.refetch) {
                                                props.refetch();
                                            }
                                        }
                                    });
                            } else {
                                if (input.status && input.status === SwiftStatusEnum.Deleted) {
                                    setModal({ showModal: true, payload: input });
                                } else {
                                    updateCorporateAction({
                                        input: [{ _id: input._id, status: input.status, error: input.error, clientId: input.clientId }]
                                    })
                                        .then((result) => {
                                            if (result.error) {
                                                setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                            } else {
                                                updatedValues = result.data.updateSwifts[0];
                                                setAlert({
                                                    color: "success",
                                                    visible: true,
                                                    message: `Corporate action '${updatedValues._id}' was updated successfully!`
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            console.error(error.toString());
                                        })
                                        .finally(() => {
                                            setSubmitting(false);
                                            if (updatedValues) {
                                                refetch();
                                                updatedValues.clientName = updatedValues.fund ? updatedValues.fund.name : null;
                                                updatedValues.instrumentName = updatedValues.instrument
                                                    ? updatedValues.instrument.name
                                                    : null;
                                                setValues(updatedValues);
                                                if (props.refetch) {
                                                    props.refetch();
                                                }
                                            }
                                        });
                                }
                            }
                        }
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, values, errors }) => {
                        if (Object.keys(errors).length > 0) {
                            console.log(errors);
                        }
                        return (
                            <Fragment>
                                <div className="row">
                                    <div>
                                        <Form autoComplete="off">
                                            <div className="form-row">
                                                <TextField
                                                    name="clientName"
                                                    label={<Link to={"/parties/" + values.clientId}>Client</Link>}
                                                    className="col-6"
                                                    disabled={true}
                                                />
                                                <TextField name="caev" label="Type" className="col-6" disabled={true} />
                                            </div>
                                            <div className="form-row">
                                                <DateField name="exDate" label="Trade date" className="col-4" disabled={true} />
                                                <DateField name="paymentDate" label="Value date" className="col-4" disabled={true} />
                                                <TextField name="currency" label="Currency" className="col-4" disabled={true} />
                                            </div>
                                            <div className="form-row">
                                                <TextField
                                                    name="instrumentName"
                                                    label={<Link to={"/instruments/" + values.instrument._id}>Instrument</Link>}
                                                    className="col-4"
                                                    disabled={true}
                                                />
                                                <NumberField name="foreignTax" label="Foreign tax" className="col-4" disabled={true} />
                                                <NumberField name="amount" label="Amount" className="col-4" disabled={true} />
                                            </div>
                                            <div className="form-row">
                                                <SelectField
                                                    name="status"
                                                    label="Status"
                                                    className="col-6"
                                                    options={Object.keys(SwiftStatusEnum)}
                                                    tooltips={SwiftStatusEnumDescriptions}
                                                    disabled={false}
                                                />
                                                <TransactionField
                                                    name="correspondingTransactionId"
                                                    label={
                                                        values.correspondingTransactionId ? (
                                                            <Link to={"/transaction/" + values.correspondingTransactionId} target="_blank">
                                                                Matched with
                                                            </Link>
                                                        ) : (
                                                            <div>Matched with</div>
                                                        )
                                                    }
                                                    className="col-6"
                                                    options={corporateActionsTransactions}
                                                    disabled={false}
                                                />
                                            </div>
                                            <div className="form-row">
                                                <TextField
                                                    name="error"
                                                    label="Error"
                                                    className="col-4"
                                                    disabled={values._id ? false : true}
                                                />
                                                <TextField
                                                    name="externalId"
                                                    label={"External id"}
                                                    type="text"
                                                    className="col-4"
                                                    disabled={true}
                                                />

                                                <TextField name="_id" label="Id" type="text" className="col-4" disabled={true} />
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <Link className="" target="_blank" to={`/corporateaction/` + values._id}>
                                                        Original
                                                    </Link>
                                                </div>
                                            </div>
                                            <SubmitButton disabled={isSubmitting || Object.keys(errors).length > 0} label={"Save"} />
                                            {alert.visible ? (
                                                <Alert variant={alert.color} onClose={onDismissAlert} dismissible className="mt-2">
                                                    {alert.message}
                                                </Alert>
                                            ) : null}
                                        </Form>
                                    </div>
                                </div>
                            </Fragment>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}
