import React from "react";
import { useQuery, gql } from "urql";
import { Grid, Column } from "../../../../components/src";
import { numberFormatFun } from "../../../../common/src";
import { TooltipLabel } from "../../components/TooltipLabel";
import { sortBy } from "lodash";
import { dateFormaterShort } from "../dateFormater";
import { useLocation } from "react-router-dom";
import { InstrumentModelTypeEnum, TransactionItemType } from "../../types.generated";
import { Link } from "react-router-dom";
import { round } from "lodash";

const getTransactions = gql`
    query getTransactions($clientIds: [GraphQLObjectId!], $tradeTimestampStart: DateTime, $tradeTimestampEnd: DateTime) {
        transactions(
            includePortfolioSwapConstituents: true
            filter: { clientIds: $clientIds, tradeTimestampStart: $tradeTimestampStart, tradeTimestampEnd: $tradeTimestampEnd }
        ) {
            _id
            clientId
            client {
                name
            }
            status
            externalId
            carryOwnCostDate
            tradeDate
            tradeTimestamp
            items {
                type
                amount
                quantity
                price
                currency
                valueDate
                instrumentId
                instrument {
                    _id
                    name
                    longName
                    modelType
                }
            }
        }
    }
`;

const getItemsArray = (array, nameType) => {
    const items = [];
    for (let i = 0; i < array.length; i++) {
        if (array[i].type === nameType) {
            items.push(array[i]);
        }
    }
    return items;
};

const sumgroupCreateRedeem = (transactions) => {
    const sumNettingSets = function (transactions, dateSum, clientIdSum) {
        return transactions
            .filter(({ tradeDate }) => tradeDate === dateSum)
            .filter(({ clientId }) => clientId === clientIdSum)
            .reduce(function (prev, cur) {
                return prev + cur.settlementAmount;
            }, 0);
    };
    const createRedeemGrouped = [];
    for (let i = 0; i < transactions.length; i++) {
        const existingItem = createRedeemGrouped.find(
            (x) => x.tradeDate === transactions[i].tradeDate && x.clientId === transactions[i].clientId
        );
        if (typeof existingItem === "undefined") {
            const newObject: any = {};
            newObject._id = transactions[i]._id;
            newObject.clientId = transactions[i].clientId;
            newObject.client = {};
            newObject.client.name = transactions[i].client.name;
            newObject.tradeDate = transactions[i].tradeDate;
            newObject.valueDate = transactions[i].valueDate;
            newObject.type = transactions[i].type;
            newObject.adj = "-";
            newObject.instrument = {};
            newObject.instrument.name = "Aggregate";
            newObject.instrument.longName = "Subscription aggregate";
            newObject.quantity = null;
            newObject.price = null;
            newObject.hash = "##";
            newObject.currency = transactions[i].currency;
            newObject.settlementAmount = sumNettingSets(transactions, transactions[i].tradeDate, transactions[i].clientId);
            createRedeemGrouped.push(newObject);
        }
    }
    return createRedeemGrouped;
};

const transactionItemsFlattener = (transactions, viewAll: boolean) => {
    // Set this to false if want to see prices as they are
    const showFormattedPrices = true;
    const newTransactions = [];
    const createRedeemTransactions = [];
    for (let i = 0; i < transactions.length; i++) {
        if (transactions[i].status === "Deleted") {
            continue;
        }
        let j;
        const transaction: any = {};
        // Transaction "flattener" handled in a more "general place"?
        transaction._id = transactions[i]._id;
        transaction.hash = "##";
        transaction.tradeDate = transactions[i].tradeDate;
        if (transactions[i].carryOwnCostDate) {
            transaction.adj = "x";
        } else {
            transaction.adj = "-";
        }
        transaction.carryOwnCostDate = transactions[i].carryOwnCostDate;
        transaction.clientId = transactions[i].clientId;
        transaction.quantity = 0;
        transaction.client = transactions[i].client;

        const itemTypes = [];
        for (j = 0; j < transactions[i].items.length; j++) {
            itemTypes.push(transactions[i].items[j].type);
        }

        if (!viewAll) {
            const typesToIgnore = [
                TransactionItemType.Fee,
                TransactionItemType.ManagementFee,
                TransactionItemType.CustodyFee,
                TransactionItemType.ManagementCost
            ];
            const typeToAlwaysKeep = TransactionItemType.Security;
            let foundIgnored = false;
            if (getItemsArray(transactions[i].items, typeToAlwaysKeep).length == 0) {
                for (j = 0; j < typesToIgnore.length; j++) {
                    if (getItemsArray(transactions[i].items, typesToIgnore[j]).length > 0) {
                        foundIgnored = true;
                        break;
                    }
                }
            }
            if (foundIgnored === true) {
                continue;
            }
        }

        const securityItems = getItemsArray(transactions[i].items, TransactionItemType.Security);
        const interestItems = getItemsArray(transactions[i].items, TransactionItemType.Interest);
        const dividendItems = getItemsArray(transactions[i].items, TransactionItemType.Dividend);
        const createRedeemItems = getItemsArray(transactions[i].items, TransactionItemType.CreateRedeem);
        const bonusFeeItems = getItemsArray(transactions[i].items, TransactionItemType.CreateRedeemAdjustmentShares);
        const collateralItems = getItemsArray(transactions[i].items, TransactionItemType.Collateral);
        const settlementAmountItems = getItemsArray(transactions[i].items, TransactionItemType.SettlementAmount);
        const feeItems = getItemsArray(transactions[i].items, TransactionItemType.Fee);
        const managementFeeItems = getItemsArray(transactions[i].items, TransactionItemType.ManagementFee);
        const managementCostItems = getItemsArray(transactions[i].items, TransactionItemType.ManagementCost);
        const custodyFeeItems = getItemsArray(transactions[i].items, TransactionItemType.CustodyFee);
        const rebateItems = getItemsArray(transactions[i].items, TransactionItemType.Rebate);
        const foreignTaxItems = getItemsArray(transactions[i].items, TransactionItemType.ForeignTax);
        const taxRestitutionItems = getItemsArray(transactions[i].items, TransactionItemType.TaxRestitution);
        const fundDividendItems = getItemsArray(transactions[i].items, TransactionItemType.DividendPaid);

        if (securityItems.length === 1 && (settlementAmountItems.length === 1 || settlementAmountItems.length === 0)) {
            transaction.type = "Trade";

            transaction.currency = securityItems[0].currency;
            transaction.quantity = securityItems[0].quantity;

            if (showFormattedPrices) {
                // If any prices have too many significant numbers for a good column width then round it
                const significantNumbersLimit = 10;
                if (securityItems[0].price.toString().length > significantNumbersLimit) {
                    // The number should be truncated to handle large number but current problem is only with decimals...
                    securityItems[0].price = round(securityItems[0].price, significantNumbersLimit);
                }
                // Show bonds as per cent
                if (securityItems[0].instrument.modelType === InstrumentModelTypeEnum.Bond) {
                    transaction.price = securityItems[0].price * 100;
                    // Round it too handle floating number precision issue
                    transaction.price = round(transaction.price, 10);
                } else {
                    transaction.price = securityItems[0].price;
                }
            }
            transaction.instrumentId = securityItems[0].instrumentId;
            transaction.instrument = securityItems[0].instrument;
        } else if (interestItems.length === 1) {
            transaction.type = "Interest";
            transaction.currency = interestItems[0].currency;
            transaction.instrumentId = interestItems[0].instrumentId;
            transaction.instrument = interestItems[0].instrument;
        } else if (feeItems.length === 1) {
            transaction.type = "Fee";
            transaction.currency = feeItems[0].currency;
            transaction.instrumentId = feeItems[0].instrumentId;
            transaction.instrument = feeItems[0].instrument;
        } else if (managementFeeItems.length === 1) {
            transaction.type = "ManagementFee";
            transaction.currency = managementFeeItems[0].currency;
            transaction.instrumentId = managementFeeItems[0].instrumentId;
            transaction.instrument = managementFeeItems[0].instrument;
        } else if (managementCostItems.length === 1) {
            transaction.type = TransactionItemType.ManagementCost;
            transaction.currency = managementCostItems[0].currency;
            transaction.instrumentId = managementCostItems[0].instrumentId;
            transaction.instrument = managementCostItems[0].instrument;
        } else if (custodyFeeItems.length === 1) {
            transaction.type = "CustodyFee";
            transaction.currency = custodyFeeItems[0].currency;
            transaction.instrumentId = custodyFeeItems[0].instrumentId;
            transaction.instrument = custodyFeeItems[0].instrument;
        } else if (rebateItems.length === 1) {
            transaction.type = TransactionItemType.Rebate;
            transaction.currency = rebateItems[0].currency;
            transaction.instrumentId = rebateItems[0].instrumentId;
            transaction.instrument = rebateItems[0].instrument;
        } else if (fundDividendItems.length === 1) {
            transaction.type = "FundDividend";
            transaction.currency = fundDividendItems[0].currency;
            transaction.instrumentId = fundDividendItems[0].instrumentId;
            transaction.instrument = fundDividendItems[0].instrument;
        } else if (dividendItems.length === 1) {
            transaction.type = "Dividend";
            transaction.currency = dividendItems[0].currency;
            transaction.instrumentId = dividendItems[0].instrumentId;
            transaction.instrument = dividendItems[0].instrument;
        } else if (createRedeemItems.length === 1) {
            transaction.type = "CreateRedeem";
            transaction.currency = createRedeemItems[0].currency;
            transaction.quantity = createRedeemItems[0].quantity;
            transaction.price = createRedeemItems[0].price;
            transaction.instrumentId = createRedeemItems[0].instrumentId;
            transaction.instrument = createRedeemItems[0].instrument;
        } else if (bonusFeeItems.length === 1) {
            transaction.type = "CreaRedBonFee";
            transaction.currency = bonusFeeItems[0].currency;
            transaction.quantity = bonusFeeItems[0].quantity;
            transaction.price = bonusFeeItems[0].price;
            transaction.instrumentId = bonusFeeItems[0].instrumentId;
            transaction.instrument = bonusFeeItems[0].instrument;
        } else if (collateralItems.length === 1) {
            transaction.type = "Collateral";
            transaction.currency = collateralItems[0].currency;
            transaction.quantity = collateralItems[0].quantity;
            transaction.price = collateralItems[0].price;
            transaction.instrumentId = collateralItems[0].instrumentId;
            transaction.instrument = collateralItems[0].instrument;
        } else if (settlementAmountItems.length === 2 && securityItems.length === 0) {
            if (settlementAmountItems[0].currency === settlementAmountItems[1].currency) {
                transaction.type = "Transfer";
            } else {
                transaction.type = "FXSpot";
                transaction.instrumentId = "000";
                transaction.instrument = {};
                transaction.instrument.name = settlementAmountItems[0].currency + " vs " + settlementAmountItems[1].currency;
            }
            transaction.currency = settlementAmountItems[0].currency;
            transaction.settlementAmount = settlementAmountItems[0].amount;
        } else if (
            settlementAmountItems.length === 2 &&
            securityItems.length === 1 &&
            settlementAmountItems[0].currency !== settlementAmountItems[1].currency
        ) {
            transaction.type = "FXSwapSpot";
            transaction.currency = settlementAmountItems[0].currency;
            transaction.settlementAmount = settlementAmountItems[0].amount;
        } else if (securityItems.length === 2) {
            transaction.type = "SecurityVsSecurity";
            transaction.currency = securityItems[0].currency;
            transaction.settlementAmount = securityItems[0].amount;
        } else if (foreignTaxItems.length === 1 && settlementAmountItems.length === 1) {
            transaction.type = "TaxAdjustment";
            transaction.currency = settlementAmountItems[0].currency;
            transaction.settlementAmount = settlementAmountItems[0].amount;
            transaction.instrumentId = foreignTaxItems[0].instrumentId;
            transaction.instrument = foreignTaxItems[0].instrument;
        } else if (taxRestitutionItems.length === 1 && settlementAmountItems.length === 1) {
            transaction.type = "TaxAdjustment";
            transaction.currency = settlementAmountItems[0].currency;
            transaction.settlementAmount = settlementAmountItems[0].amount;
            transaction.instrumentId = taxRestitutionItems[0].instrumentId;
            transaction.instrument = taxRestitutionItems[0].instrument;
        } else {
            //console.log("Not recognized type", transactions[i]);
        }

        if (settlementAmountItems.length === 1) {
            transaction.settlementAmount = settlementAmountItems[0].amount;
            transaction.valueDate = settlementAmountItems[0].valueDate;
        } else if (settlementAmountItems.length === 0) {
            transaction.settlementAmount = 0;
            if (securityItems.length !== 0) {
                transaction.valueDate = securityItems[0].valueDate;
            } else if (collateralItems.length !== 0) {
                transaction.valueDate = collateralItems[0].valueDate;
            }
        } else if (settlementAmountItems.length === 2) {
            transaction.valueDate = settlementAmountItems[0].valueDate;
        }

        if (!viewAll) {
            // PAI transactions
            if (transactions[i].externalId.slice(-11).slice(0, 3) === "PAI") continue;
            if (transactions[i].externalId.slice(0, 3) === "PAI") continue;
            // All Transfers
            if (transaction.type === "Transfer") continue;
            // Interest threshold
            // Different threshold because relatively small negative amounts are important to see for cash management purposes
            const interestThresholdAbs = 1000; // Any currency!
            const interestThreshold = 50000; // Any currency!
            if (transaction.settlementAmount > 0) {
                if (transaction.type === "Interest" && transaction.settlementAmount < interestThreshold) continue;
            }
            if (transaction.type === "Interest" && Math.abs(transaction.settlementAmount) < interestThresholdAbs) continue;
            if (transaction.type === "CreateRedeem") {
                // Add CreateRedeem to a list and aggregate later
                createRedeemTransactions.push(transaction);
                continue;
            }
        }
        newTransactions.push(transaction);
    }
    const createRedeemAgg = sumgroupCreateRedeem(createRedeemTransactions);
    return newTransactions.concat(createRedeemAgg).sort((a, b) => (a.tradeDate > b.tradeDate ? 1 : -1));
};

export interface TransactionProps {
    clientIds: string[];
    tradeTimestampStart: string;
    tradeTimestampEnd: string;
    viewAll: boolean;
}

export const Transaction = ({ clientIds, tradeTimestampStart, tradeTimestampEnd, viewAll }: TransactionProps): React.ReactElement => {
    const location = useLocation();
    const [{ fetching, error, data }] = useQuery({
        query: getTransactions,
        variables: { clientIds, tradeTimestampStart, tradeTimestampEnd }
    });
    if (fetching) return <div>Loading...</div>;
    if (error) return <div>Error! ${error.message}</div>;
    let transactions = transactionItemsFlattener(data.transactions, viewAll);
    transactions = sortBy(transactions, "tradeTimestamp.valueOf()").reverse();

    return (
        <div className="row">
            <div className="col">
                <p>Number of transactions: {transactions.length}</p>
                <Grid header="Transactions" data={transactions} sortable tableClassName="table-xs">
                    <Column
                        field="hash"
                        title="ID"
                        format={(value, item) => {
                            return (
                                <Link to={"/transaction/" + item._id} target="_blank">
                                    {value}
                                </Link>
                            );
                        }}
                    />
                    <Column
                        field="client.name"
                        title="Client"
                        className="grid-column-sticky"
                        format={(_, item) => {
                            return <Link to={"/parties/" + item.clientId}>{item.client.name}</Link>;
                        }}
                    />
                    <Column className="nowrap left" field="tradeDate" title="Trade date" />
                    <Column className="nowrap left" field="valueDate" title="Value date" format={dateFormaterShort} />
                    <Column
                        field="type"
                        format={(value, item) => {
                            return <Link to={location.pathname + "?id=" + item._id}>{value}</Link>;
                        }}
                    />
                    <Column
                        className="nowrap maxwidth150px left"
                        field="instrumentId"
                        title="Instrument"
                        format={(_, item) => {
                            return item.instrument ? <Link to={"/instruments/" + item.instrumentId}>{item.instrument.name}</Link> : "";
                        }}
                    />
                    <Column field="quantity" format={numberFormatFun("# ##0")} />
                    <Column
                        field="price"
                        title={<TooltipLabel text={"Price"} content={"Price shown may be formatted. Bonds are shown in per cent."} />}
                    />
                    <Column field="settlementAmount" title="Settle. amount" format={numberFormatFun("# ##0")} />
                    <Column field="currency" />
                    <Column
                        className="nowrap left"
                        field="adj"
                        title="Adj"
                        format={(value, item) => {
                            return <Link to={"/nolink/" + item.carryOwnCostDate}>{value}</Link>;
                        }}
                    />
                </Grid>
            </div>
        </div>
    );
};
