import React, { Fragment, useEffect, useState } from "react";
import { useQuery, gql, useMutation } from "urql";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";

import { SearchListField, SelectField, SubmitButton, TextField } from "../components/form";
import { formikUrqlErrorFormater } from "../common/formik-urql-error-helper";
import { useAlertTimeOut } from "../common/Utils";
import {
    CreateCurveDefinitionInput,
    CurrencyEnum,
    CurveDefinitionDefinitionInput,
    CurveDefinitionType,
    CurveDefinitionType2,
    UpdateCurveDefinitionInput
} from "../types.generated";

export const getCurveDefinition = gql`
    query getCurveDefinition($_id: GraphQLObjectId!) {
        curveDefinition(_id: $_id) {
            _id
            clientId
            name
            type
            definition {
                currency
                dayCount
                forwardPeriod
                type
                issuerProgramId
                instruments {
                    instrumentId
                }
            }
        }
    }
`;

export const getData = gql`
    query getData {
        instruments {
            _id
            name
        }
        issuerprograms {
            _id
            name
        }
        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
        }
    }
`;

const CREATE_CURVEDEFINITION = gql`
    mutation createCurveDefinition($input: CreateCurveDefinitionInput!) {
        createCurveDefinition(input: $input) {
            _id
        }
    }
`;

const UPDATE_CURVEDEFINITION = gql`
    mutation updateCurveDefinition($input: UpdateCurveDefinitionInput!) {
        updateCurveDefinition(input: $input) {
            _id
        }
    }
`;

const defaultCurveDefinition = {
    name: "",
    clientId: null,
    type: CurveDefinitionType.BasisCurve,
    currency: CurrencyEnum.SEK,
    dayCount: "Act/360",
    forwardPeriod: "3m",
    definitionType: CurveDefinitionType2.YieldCurveDefinition,
    issuerProgramId: "000000000000000000000000",
    instruments: []
};

export const CurveDefinitionPage = (): React.ReactElement => {
    const [curveDefinition, setCurveDefinition] = useState(defaultCurveDefinition);
    const { id } = useParams<"id">();
    const curveDefinitionId = id && id !== "new" ? id : "000000000000000000000000";
    const isEditMode = !!(curveDefinitionId !== "000000000000000000000000");

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });

    const [{ fetching: fetchingClients, error: errorClients, data: dataClients }] = useQuery({ query: getData });
    const [{ fetching, error, data }] = useQuery({ query: getCurveDefinition, variables: { _id: curveDefinitionId }, pause: !isEditMode });

    const [_, createCurveDefinition] = useMutation(CREATE_CURVEDEFINITION);
    const [__, updateCurveDefinition] = useMutation(UPDATE_CURVEDEFINITION);

    const navigate = useNavigate();
    const location = useLocation();

    useAlertTimeOut(alert, setAlert, 5);
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useEffect(() => {
        let curveDefinitionFlat = defaultCurveDefinition;
        if (isEditMode && data) {
            curveDefinitionFlat = {
                name: data.curveDefinition.name,
                clientId: data.curveDefinition.clientId,
                type: data.curveDefinition.type,
                currency: data.curveDefinition.definition.currency,
                dayCount: data.curveDefinition.definition.dayCount,
                forwardPeriod: data.curveDefinition.definition.forwardPeriod,
                definitionType: data.curveDefinition.definition.type,
                issuerProgramId: data.curveDefinition.definition.issuerProgramId,
                instruments: data.curveDefinition.definition.instruments.map((x) => x.instrumentId)
            };
        }
        setCurveDefinition(curveDefinitionFlat);
    }, [data, isEditMode]);

    if (fetching || fetchingClients) return <p>Loading</p>;
    if (errorClients) return <p>error: {JSON.stringify(errorClients, null, 2)}</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <div className="container page">
            <Button
                type="button"
                className="print-none float-end btn btn-sm"
                onClick={() => {
                    const win = window.open("/curvedefinitions", "_self");
                    win.focus();
                }}
            >
                All curve definitions
            </Button>

            <h1>Curve definitions</h1>

            <Formik
                initialValues={curveDefinition}
                enableReinitialize={true}
                validateOnMount={true}
                validationSchema={Yup.object({
                    name: Yup.string().required().min(2).typeError("Required"),
                    clientId: Yup.string().required().min(2).typeError("Required")
                })}
                onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                    const definitionInput: CurveDefinitionDefinitionInput = {
                        currency: submitValues.currency,
                        dayCount: submitValues.dayCount,
                        forwardPeriod: submitValues.forwardPeriod,
                        type: submitValues.definitionType,
                        issuerProgramId: submitValues.issuerProgramId,
                        instruments: submitValues.instruments
                            .filter((x) => x != null)
                            .map((x) => {
                                return {
                                    instrumentId: x
                                };
                            })
                    };

                    if (isEditMode) {
                        const input: UpdateCurveDefinitionInput = {
                            _id: curveDefinitionId,
                            clientId: submitValues.clientId,
                            name: submitValues.name,
                            type: submitValues.type,
                            definition: definitionInput
                        };

                        await updateCurveDefinition({ input })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error, setErrors);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    setAlert({
                                        color: "success",
                                        visible: true,
                                        message: "The curve definition updated successfully!"
                                    });

                                    if (result.data && result.data.updateCurveDefinition) {
                                        const path = location.pathname.split("/");
                                        path.pop();
                                        path.push(result.data.updateCurveDefinition._id);
                                        navigate(path.join("/"), { replace: true });
                                    }
                                }
                                return true;
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        const input: CreateCurveDefinitionInput = {
                            clientId: submitValues.clientId,
                            name: submitValues.name,
                            type: submitValues.type,
                            definition: definitionInput
                        };

                        await createCurveDefinition({ input })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error, setErrors);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    setAlert({
                                        color: "success",
                                        visible: true,
                                        message: "New curve definition created successfully!"
                                    });

                                    if (result.data && result.data.createCurveDefinition) {
                                        const path = location.pathname.split("/");
                                        path.pop();
                                        path.push(result.data.createCurveDefinition._id);
                                        navigate(path.join("/"), { replace: true });
                                    }
                                }
                                return true;
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({ isSubmitting, values }) => (
                    <Form autoComplete="off">
                        <div className="row">
                            <div className="col-sm-6 col-md-4">
                                <TextField name="name" label={"Name"} type="text" className="" spellCheck="false" disabled={isSubmitting} />
                                <SelectField
                                    name="definitionType"
                                    label="Definition type"
                                    options={Object.values(CurveDefinitionType2).sort()}
                                    className=""
                                    disabled={isSubmitting}
                                />
                                <TextField
                                    name="dayCount"
                                    label="dayCount"
                                    type="text"
                                    className=""
                                    spellCheck="false"
                                    disabled={isSubmitting}
                                />
                                <TextField
                                    name="forwardPeriod"
                                    label="forwardPeriod"
                                    type="text"
                                    className=""
                                    spellCheck="false"
                                    disabled={isSubmitting}
                                />
                                <h6>Instruments</h6>
                                <FieldArray
                                    name="instruments"
                                    render={(arrayHelpers) => (
                                        <Fragment>
                                            {values.instruments && values.instruments.length > 0 ? (
                                                values.instruments.map((instrId, index) => (
                                                    <div key={index} className="form-group form-row">
                                                        <div className="col-10">
                                                            <fieldset>
                                                                <legend>{index + 1}</legend>
                                                                <SearchListField
                                                                    className=""
                                                                    name={`instruments[${index}]`}
                                                                    label={<Link to={"/instruments/" + instrId}>Instrument</Link>}
                                                                    options={dataClients.instruments}
                                                                    disabled={isSubmitting}
                                                                />
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-2">
                                                            <Button
                                                                className="me-1 mt-2 btn-danger btn-sm"
                                                                type="button"
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                -
                                                            </Button>
                                                            <Button
                                                                className="btn-sm mt-2"
                                                                type="button"
                                                                onClick={() => arrayHelpers.insert(index, null)}
                                                            >
                                                                +
                                                            </Button>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <Button className="btn-sm ms-1" type="button" onClick={() => arrayHelpers.push(null)}>
                                                    +
                                                </Button>
                                            )}
                                        </Fragment>
                                    )}
                                />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <SelectField
                                    name="type"
                                    label="Type"
                                    options={Object.keys(CurveDefinitionType).sort()}
                                    className=""
                                    disabled={isSubmitting}
                                />
                                <SelectField
                                    name="clientId"
                                    label={<Link to={"/parties/" + values.clientId}>Client (owner)</Link>}
                                    options={dataClients.clients.map((d) => ({ key: d._id, value: d.name }))}
                                    className=""
                                    disabled={isSubmitting}
                                />
                                <SelectField
                                    name="currency"
                                    label="Currency*"
                                    options={Object.values(CurrencyEnum).sort()}
                                    className=""
                                    disabled={isSubmitting}
                                />
                                <SearchListField
                                    name="issuerProgramId"
                                    label={
                                        values.issuerProgramId == "000000000000000000000000" ? (
                                            "Issuer program"
                                        ) : (
                                            <div className="pb-2">
                                                <Link to={"/issuerprograms/" + values.issuerProgramId}>Issuer program</Link>
                                            </div>
                                        )
                                    }
                                    options={[...[{ _id: "000000000000000000000000", name: "None " }], ...dataClients.issuerprograms]}
                                    className=""
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                {alert.visible ? (
                                    <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                                        {alert.message}
                                    </Alert>
                                ) : null}

                                <SubmitButton disabled={isSubmitting} label={isEditMode ? "Update" : "Create"} />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
