import React, { useEffect, useState, useMemo } from "react";
import { cloneDeep, sortBy, keyBy } from "lodash";
import { Formik, Form } from "formik";
import { useQuery, useMutation } from "urql";

import { PartialDeep } from "type-fest";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";

import {
    NoteFilterInput,
    Note,
    NoteInput,
    CollectionNameEnum,
    NoteReference,
    NoteAssetEnum,
    EsgActivityEnum,
    EsgAreaEnum,
    NoteTag,
    NoteStatusEnum,
    EsgAreaEnumDescriptions
} from "../../types.generated";
import { useQueryState } from "../../common/use-query-state";
import { recursivelyRemoveKey } from "../../../../common/src";
import { usePrevious } from "../../common/Utils";
import {
    DateField,
    SearchMultipleSelectField,
    SearchListField,
    SelectField,
    SubmitButton,
    MultipleSelectField
} from "../../components/form";
import { formikUrqlErrorFormater } from "../../common/formik-urql-error-helper";
import { GET_NOTES, GET_OPTIONS, UPSERT_NOTES } from "./queries";
import { ButtonsView, NoteView } from "./NoteComponents";
import { NotePageProps } from "./NoteComponents";
import { YesNoModal } from "../../components/YesNoModal";
import { MarkDownField } from "../../components/form/MarkDownField";

const TODAY = new Date().toISOString().slice(0, 10);

const getDefaultEsgNote = (): Partial<Note> => {
    return {
        __typename: "Note",
        _id: "new",
        title: "",
        clientIds: [],
        creatorId: "",
        refs: [{ __typename: "NoteReference", collection: CollectionNameEnum.None, documentId: "000000000000000000000000" }],
        tags: [
            { __typename: "NoteTag", type: "EsgArea", value: EsgAreaEnum.BusinessEthics },
            { __typename: "NoteTag", type: "EsgActivity", value: EsgActivityEnum.Dialogue },
            { __typename: "NoteTag", type: "Date", value: TODAY }
        ],
        data: "",
        asset: NoteAssetEnum.Esg,
        createTimestamp: "",
        updateTimestamp: "",
        updateUserId: "",
        status: NoteStatusEnum.Active
    };
};

export const defaultRef: NoteReference = {
    __typename: "NoteReference",
    collection: CollectionNameEnum.None,
    documentId: "000000000000000000000000"
};

export const defaultTag: NoteTag = { __typename: "NoteTag", type: "EsgArea", value: EsgAreaEnum.BusinessEthics };

export const EsgNotePage = ({ routeToNotes, routeToNotesText }: NotePageProps): React.ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams<"id">();
    const previousId: string = usePrevious(id);
    const [edit, setEdit] = useQueryState("edit", false);
    const [formData, setFormData] = useState<PartialDeep<Note>>(null);
    const filter: NoteFilterInput = { idIn: [id === "new" ? "000000000000000000000000" : id] };
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [{ data, error }, refetch] = useQuery({
        query: GET_NOTES,
        variables: { filter },
        requestPolicy: "cache-and-network"
    });

    const [{ data: dataOptions, fetching: fetchingOptions, error: errorOptions }] = useQuery({
        query: GET_OPTIONS,
        requestPolicy: "cache-and-network"
    });

    const [_state, executeMutation] = useMutation(UPSERT_NOTES);

    useEffect(() => {
        if (previousId && previousId !== id) {
            setFormData(null);
            refetch();
        } else if (data && formData === null && id === "new") {
            const initFormData = getDefaultEsgNote();
            setFormData(initFormData);
        } else if (formData === null && data && data.notes.length === 1) {
            const init = cloneDeep(data.notes[0]);
            setFormData(init);
        }
    }, [previousId, id, data, formData, refetch]);

    const { refOptions, docNameById } = useMemo(() => {
        if (dataOptions) {
            const dataOptions2 = cloneDeep(dataOptions);
            const newRefOptions: Record<string, { _id: string; name: string }[]> = {};

            const parties = sortBy(dataOptions2.parties, "name");

            let instruments = [];
            dataOptions2.instruments.forEach((instrument) => {
                if (instrument.longName) {
                    instruments.push({ _id: instrument._id, name: instrument.longName });
                } else {
                    instruments.push({ _id: instrument._id, name: instrument.name });
                }
            });
            instruments = sortBy(instruments, "name");
            //console.log("instruments:", instruments);

            const issuerProgramOptionsExtended = dataOptions2.issuerprograms.map((issuerProgram) => {
                issuerProgram.name = issuerProgram.issuer ? issuerProgram.issuer.name + " " + issuerProgram.name : issuerProgram.name;
                return issuerProgram;
            });
            const issuerprograms = sortBy(issuerProgramOptionsExtended, "name");
            const screens = sortBy(dataOptions2.screens, "name");

            parties.push({ _id: "000000000000000000000000", name: "" });
            instruments.push({ _id: "000000000000000000000000", name: "" });
            issuerprograms.push({ _id: "000000000000000000000000", name: "" });
            screens.push({ _id: "000000000000000000000000", name: "" });

            newRefOptions[CollectionNameEnum.Instrument] = instruments;
            newRefOptions[CollectionNameEnum.IssuerProgram] = issuerprograms;
            newRefOptions[CollectionNameEnum.Party] = parties;
            newRefOptions[CollectionNameEnum.Screen] = screens;
            newRefOptions[CollectionNameEnum.None] = [{ _id: "000000000000000000000000", name: "None" }];

            let docNameById: Record<string, { _id: string; name: string }> = {};
            for (const collection of Object.keys(newRefOptions)) {
                docNameById = { ...docNameById, ...keyBy(newRefOptions[collection], "_id") };
            }

            return { refOptions: newRefOptions, docNameById: docNameById };
        } else {
            const newRefOptions: Record<string, { _id: string; name: string }[]> = {};
            newRefOptions[CollectionNameEnum.Instrument] = [{ _id: "000000000000000000000000", name: "" }];
            newRefOptions[CollectionNameEnum.IssuerProgram] = [{ _id: "000000000000000000000000", name: "" }];
            newRefOptions[CollectionNameEnum.Party] = [{ _id: "000000000000000000000000", name: "" }];
            newRefOptions[CollectionNameEnum.Screen] = [{ _id: "000000000000000000000000", name: "" }];
            newRefOptions[CollectionNameEnum.None] = [{ _id: "000000000000000000000000", name: "None" }];
            return { refOptions: newRefOptions, docNameById: {} };
        }
    }, [dataOptions]);

    if (!formData) return <p>Loading...</p>;

    if (error) {
        const message = formikUrqlErrorFormater(error as any, null);
        return (
            <div>
                <h3>error</h3>
                <p>{message}</p>;
            </div>
        );
    }

    if (fetchingOptions || !refOptions) return <p>Loading....</p>;

    if (errorOptions) {
        const message = formikUrqlErrorFormater(errorOptions as any, null);
        return (
            <div>
                <h3>error</h3>
                <p>{message}</p>;
            </div>
        );
    }

    if (id !== "new" && data.notes.length === 0) {
        return <div>No note with id {id}</div>;
    }

    if (!formData) {
        return <div></div>;
    }

    const clients = sortBy(dataOptions.clients, "name");

    return (
        <div className="page">
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete note with id " + modal.payload[0]._id + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        executeMutation({ input: modal.payload })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    if (id === "new") {
                                        const path = location.pathname.split("/");
                                        path.pop();
                                        path.push(result.data.upsertNotes[0]._id);
                                        navigate(path.join("/"), { replace: true });
                                    } else {
                                        const init = cloneDeep(result.data.upsertNotes[0]);

                                        setFormData(init);
                                        setEdit(false);
                                    }
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            });
                    }}
                />
            ) : null}
            <Button
                type="button"
                className="btn-sm mb-3"
                onClick={() => {
                    // not sure I like this button instead of Link
                    const win = window.open(routeToNotes, "_self");
                    win.focus();
                }}
            >
                {routeToNotesText}
            </Button>
            {edit || id === "new" ? (
                <Formik
                    enableReinitialize={true}
                    validateOnMount={true}
                    initialValues={formData}
                    validate={(_note) => {
                        const errors: any = {};
                        if (!_note.clientIds || _note.clientIds.length === 0) {
                            errors.clientIds = "Required";
                        }

                        return Object.keys(errors).length > 0 ? errors : {};
                    }}
                    onSubmit={async (submitValues, { setErrors }) => {
                        submitValues.tags[0].value = submitValues.tags[0].value.toString();
                        let input: NoteInput[] = [
                            {
                                _id: submitValues._id === "new" ? null : submitValues._id,
                                title: submitValues.title,
                                clientIds: submitValues.clientIds,
                                data: submitValues.data,
                                refs: submitValues.refs,
                                tags: submitValues.tags,
                                asset: NoteAssetEnum.Esg,
                                status: submitValues.status
                            }
                        ];
                        input = recursivelyRemoveKey(input, "__typename");
                        if (input[0].status && input[0].status === NoteStatusEnum.Deleted) {
                            setModal({ showModal: true, payload: input });
                        } else {
                            await executeMutation({ input })
                                .then((result) => {
                                    if ("error" in result && result.error) {
                                        const message = formikUrqlErrorFormater(result.error, setErrors);
                                        setAlert({ color: "danger", visible: true, message });
                                    } else {
                                        if (id === "new") {
                                            const path = location.pathname.split("/");
                                            path.pop();
                                            path.push(result.data.upsertNotes[0]._id);
                                            navigate(path.join("/"), { replace: true });
                                        } else {
                                            const init = cloneDeep(result.data.upsertNotes[0]);

                                            setFormData(init);
                                            setEdit(false);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    setAlert({ color: "danger", visible: true, message: error.toString() });
                                });
                        }
                    }}
                >
                    {({ isSubmitting, values, errors }) => {
                        // value on Tag in backend is not an array but in Area tag case we want to save an array -> storing array as
                        // comma separated string for now and parsing per below to array for MultipleSelectField in form
                        if (values && values.tags && values.tags.length && typeof values.tags[0].value === "string") {
                            values.tags[0].value = values.tags[0].value.split(",") as unknown as any;
                        }
                        let collection = CollectionNameEnum.None;

                        let options: {
                            _id: string;
                            name: string;
                        }[] = [];
                        if (values && values.refs && values.refs.length > 0) {
                            collection = values.refs[0].collection;
                            options = refOptions[collection];
                        }
                        return (
                            <Form autoComplete="off">
                                <div className="row">
                                    <div className="col-md-9">{id === "new" ? <h3>New note</h3> : <h3>Edit note</h3>}</div>
                                    <div className="col-md-3">
                                        {id === "new" ? null : ButtonsView(null, setEdit, "/esgnotes/new", "New Esg note")}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-9 mt-3">
                                        <div className="form-row">
                                            <SearchMultipleSelectField
                                                name="clientIds"
                                                label="This document is owned by"
                                                className="w-100"
                                                disabled={isSubmitting}
                                                options={clients.map((d) => ({ key: d._id, value: d.name, text: d.name }))}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <SelectField
                                                name={"status"}
                                                label={"Status"}
                                                options={Object.keys(NoteStatusEnum)}
                                                className="col-12"
                                                disabled={isSubmitting || id === "new"}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <SelectField
                                                name={`tags[1].value`}
                                                label={"Activity"}
                                                options={Object.keys(EsgActivityEnum)}
                                                className="col-12"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <MultipleSelectField
                                                name={`tags[0].value`}
                                                label={"Area"}
                                                options={Object.keys(EsgAreaEnum).map((d) => ({ key: d, value: d }))}
                                                tooltips={EsgAreaEnumDescriptions}
                                                className=""
                                                disabled={isSubmitting}
                                                size={12}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <SelectField
                                                name={`refs[0].collection`}
                                                label={"Collection"}
                                                options={Object.keys(refOptions)}
                                                className="col-12"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        {values.refs[0].collection !== CollectionNameEnum.None ? (
                                            <div className="form-row">
                                                <SearchListField
                                                    name={`refs[0].documentId`}
                                                    label={collection}
                                                    options={options}
                                                    className="col-12"
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                        ) : null}
                                        <div className="form-row">
                                            <label className="ms-1">Date</label>
                                            <DateField className="col-12" name={`tags[2].value`} label=" " disabled={isSubmitting} />
                                        </div>
                                    </div>
                                    <div className="col-sm-9 mt-3">
                                        <div className="form-row">
                                            <MarkDownField
                                                name="data"
                                                label={"Note"}
                                                type="text"
                                                className="col-12"
                                                style={{ height: `calc(100vh - 25rem` }}
                                                initialEditMode={true}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {alert.visible ? (
                                    <div className="row">
                                        <div className="col-12 form-row">
                                            <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                                                {alert.message}
                                            </Alert>
                                        </div>
                                    </div>
                                ) : null}

                                <div className="row mt-2">
                                    <div className="col-12 form-row pb-2">
                                        <SubmitButton
                                            className="btn btn-primary ms-2"
                                            disabled={isSubmitting || Object.keys(errors).length > 0}
                                            label={id === "new" ? "Create" : "Update"}
                                        />
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                NoteView(formData, ButtonsView(setEdit, null, "/esgnotes/new", "New Esg note"), docNameById)
            )}
        </div>
    );
};
