import React, { useMemo } from "react";
import { gql, useQuery } from "urql";
import { Link } from "react-router-dom";

import { ReactTable } from "../components/react-table/ReactTable";
import { SelectColumnFilter } from "../components/react-table/ReactTableFilters";
import { sortBy } from "lodash";

const REGISTER_EXTRACTS = gql`
    query registerExtracts {
        registerExtracts {
            _id
            date
            clientId
            client {
                _id
                name
            }
            updateUserId
            updateUserInfo {
                name
            }
            createTimestamp
            updateTimestamp
            sourceId
            source {
                _id
                name
            }
        }
    }
`;

export const RegisterExtractsPage = (): React.ReactElement => {
    const [{ fetching, data, error }] = useQuery({
        query: REGISTER_EXTRACTS,
        requestPolicy: "cache-and-network"
    });

    const columns = useMemo(() => {
        const columns = [];
        columns.push({
            header: "Id",
            accessorKey: "_id",
            cell: (cellProps) => {
                const { row } = cellProps;
                return <Link to={`/registerextracts/${row.original._id}`}>#</Link>;
            },
            size: 8
        });
        columns.push({
            header: "Client",
            id: "client.name",
            accessorKey: "client.name",
            filter: SelectColumnFilter,
            cell: (cellProps) => {
                const { row } = cellProps;
                if (row.original.client) {
                    return <Link to={`/parties/${row.original.client._id}`}>{cellProps.getValue()}</Link>;
                } else {
                    return null;
                }
            },
            size: 100
        });
        columns.push({
            header: "Source",
            id: "source.name",
            accessorKey: "source.name",
            filter: SelectColumnFilter,
            cell: (cellProps) => {
                const { row } = cellProps;
                if (row.original.source) {
                    return <Link to={`/parties/${row.original.source._id}`}>{cellProps.getValue()}</Link>;
                } else {
                    return null;
                }
            }
        });
        columns.push({
            header: "Date",
            accessorKey: "date"
        });
        columns.push({
            header: "Updated by",
            id: "updateUserInfo.name",
            accessorKey: "updateUserInfo.name"
        });
        columns.push({
            header: "Update timestamp",
            accessorKey: "updateTimestamp",
            size: 150
        });
        columns.push({
            header: "Create timestamp",
            accessorKey: "createTimestamp",
            size: 150
        });

        return columns;
    }, []);

    if (fetching) return <div>Loading...</div>;

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (data.registerExtracts === null) return <div>No documents</div>;

    const registerExtracts = sortBy(data.registerExtracts, "date").reverse();
    return (
        <div>
            <div className="row">
                <p>Number of documents: {data.registerExtracts.length}</p>
                <ReactTable columns={columns} data={registerExtracts} defaultHiddenColumns={[]} />
            </div>
        </div>
    );
};
