import React, { useEffect, useState } from "react";
import { gql, useQuery } from "urql";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";

import stableStringify from "json-stable-stringify";
import { DateHelper } from "../../../common/src";
import { valueFormat } from "../../../common/src";

import { useQueryArgs, usePrevious, removeNull } from "../common/Utils";

import { TransactionItemFilterInput } from "../types.generated";
import { SubmitButton, NullableMultiSelectField, NullableToggleDateField } from "../components/form";
import { Excel } from "../../../components/src/Svgs";
import { exportToXlsx } from "../../../components/src/exportToXlsx";
import { cloneDeep, orderBy } from "lodash";
import { Button } from "react-bootstrap";
import { ReactTable } from "../components/react-table/ReactTable";
import { SelectColumnFilter } from "../components/react-table/ReactTableFilters";
import { MiniTransactionForm, miniTransactionTypes } from "./MiniTransactionForm";
import { Panel } from "@fluentui/react";

const GET_ClIENTS = gql`
    query getClients {
        clients: parties(filter: { typeIn: [Fund, Client], withTransactions: true }) {
            _id
            name
        }
    }
`;

const GET_TRANSACTION_ITEMS = gql`
    query transactionItems($filter: TransactionItemFilterInput) {
        transactionItems(filter: $filter) {
            clientId
            client {
                _id
                name
            }
            transactionId
            transactionStatus
            transactionTradeDate
            transactionType
            _id
            type
            amount
            quantity
            price
            currency
            performanceType
            instrumentId
            instrument {
                _id
                name
            }
            fxRate
            valueDate
            externalAccountId
            externalAccount {
                _id
                name
            }
            accountId
            account {
                _id
                name
                description
            }
            custodian {
                _id
                name
            }
            transaction {
                updateUserInfo {
                    name
                }
                description
                brokerId
                broker {
                    _id
                    name
                }
                updateTimestamp
            }
            portfolioInstrumentId
            portfolioInstrument {
                name
            }
        }
    }
`;

export function TransactionItemsPage(): React.ReactElement {
    const startDate: string = DateHelper.addDays(new Date(), -30).toISOString().slice(0, 10);
    const endDate: string = new Date().toISOString().slice(0, 10);
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const { queryArgs, pushQueryArgs } = useQueryArgs();
    const previousQueryArgs = usePrevious(queryArgs);
    const [showFilter, setShowFilter] = useState(false);

    const [filter, setFilter] = useState<Partial<TransactionItemFilterInput>>({
        clientIds: null,
        startDate: "startDate" in queryArgs ? (queryArgs.startDate as string) : startDate,
        endDate: "endDate" in queryArgs ? (queryArgs.endDate as string) : endDate,
        includePortfolioSwapConstituents: true
    });

    const [{ fetching: loadingClients, error: errorClients, data: dataClients }] = useQuery({
        query: GET_ClIENTS,
        requestPolicy: "cache-and-network"
    });

    const [{ fetching: loading, error, data: dataItems }] = useQuery({
        query: GET_TRANSACTION_ITEMS,
        variables: { filter },
        requestPolicy: "cache-and-network"
    });

    useEffect(() => {
        if (queryArgs && stableStringify(queryArgs) !== stableStringify(previousQueryArgs)) {
            let newFilter: Partial<TransactionItemFilterInput> = {
                startDate: startDate,
                endDate: endDate,
                includePortfolioSwapConstituents: true
            };

            if ("clientIds" in queryArgs && queryArgs["clientIds"]) {
                newFilter = {
                    ...filter,
                    clientIds: Array.isArray(queryArgs["clientIds"]) ? queryArgs["clientIds"] : [queryArgs["clientIds"] as any]
                };
            }

            if ("startDate" in queryArgs) {
                newFilter = { ...filter, startDate: queryArgs["startDate"] as string };
            }
            if ("endDate" in queryArgs) {
                newFilter = { ...filter, endDate: queryArgs["endDate"] as string };
            }

            if (stableStringify(filter) !== stableStringify(newFilter)) {
                setFilter(newFilter);
                pushQueryArgs(removeNull({ ...queryArgs, ...newFilter }));
                if (newFilter.clientIds !== null || newFilter.startDate !== null || newFilter.endDate !== null) {
                    setShowFilter(true);
                }
            }
        }
    }, [filter, previousQueryArgs, queryArgs, pushQueryArgs, startDate, endDate]);

    const columns = React.useMemo(() => {
        const accountNameExtendedByName: Record<string, { _id: string; name: string }> = {};

        if (dataItems && dataItems.transactionItems) {
            dataItems.transactionItems.forEach((item) => {
                if (item.account) {
                    accountNameExtendedByName[item.account.name] = {
                        _id: item.account.name,
                        name: item.account.name + " - " + item.account.description
                    };
                }
            });
        }
        return [
            {
                header: "Client",
                id: "client.name",
                accessorKey: "client.name",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    let name = cellProps.getValue();
                    if (name && name.length > 20) {
                        name = name.slice(-20);
                    }
                    return <Link to={`/parties/${row.original.client._id}`}>{cellProps.getValue()}</Link>;
                },
                size: 100
            },
            {
                header: "Broker",
                id: "transaction.broker.name",
                accessorKey: "transaction.broker.name",
                accessorFn: (row) => (row.transaction.broker ? row.transaction.broker.name : null),
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.transaction.broker) {
                        return <Link to={`/parties/${cellProps.getValue()}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return <div></div>;
                    }
                },
                size: 120
            },
            {
                header: "Trade date",
                accessorKey: "transactionTradeDate",
                headerStyle: { textAlign: "right" },
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>,
                size: 80
            },
            {
                header: "Value date",
                accessorKey: "valueDate",
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>,
                size: 80
            },
            {
                header: "Description",
                id: "transaction.description",
                accessorKey: "transaction.description"
            },
            {
                header: "Type",
                accessorKey: "type",
                filter: SelectColumnFilter,
                size: 100
            },
            {
                header: "Transaction type",
                accessorKey: "transactionType",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const isMiniTransaction = miniTransactionTypes.includes(cellProps.getValue());
                    return isMiniTransaction && row.original ? (
                        <Link to={`/transactionitems/${row.original.transactionId}${location.search}`}>{cellProps.getValue()}</Link>
                    ) : (
                        <div>{cellProps.getValue()}</div>
                    );
                },
                filter: SelectColumnFilter,
                size: 95
            },
            {
                header: "Status",
                accessorKey: "transactionStatus",
                filter: SelectColumnFilter,
                size: 60
            },
            {
                header: "Quantity",
                accessorKey: "quantity",
                filterFn: "startsWith",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{valueFormat(cellProps.getValue())}</div>,
                size: 70
            },
            {
                header: "Amount",
                accessorKey: "amount",
                filterFn: "startsWith",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{valueFormat(cellProps.getValue())}</div>,
                size: 70
            },
            {
                header: "Price",
                accessorKey: "price",
                filterFn: "startsWith",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{valueFormat(cellProps.getValue())}</div>,
                size: 70
            },
            {
                header: "Currency",
                accessorKey: "currency",
                filter: SelectColumnFilter,
                size: 40
            },
            {
                header: "Performance type",
                accessorKey: "performanceType",
                filter: SelectColumnFilter,
                size: 70
            },
            {
                header: "Instrument",
                id: "instrument.name",
                accessorKey: "instrument.name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.instrument) {
                        if (row.original.instrument.__typename === "Instrument") {
                            return <Link to={`/instruments/${row.original.instrumentId}`}>{cellProps.getValue()}</Link>;
                        } else {
                            return (
                                <Link to={`/parties/${row.original.clientId}/instruments/${row.original.instrumentId}`}>
                                    {cellProps.getValue()}
                                </Link>
                            );
                        }
                    } else {
                        return <div></div>;
                    }
                },
                size: 100
            },
            {
                header: "Transaction id",
                accessorKey: "transactionId",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <Link to={`/transaction/${row.original.transactionId}`} target="_blank">
                            {cellProps.getValue()}
                        </Link>
                    );
                },
                size: 50
            },
            {
                header: "External account",
                id: "externalAccount.name",
                accessorKey: "externalAccount.name",
                accessorFn: (row) => (row.externalAccount ? row.externalAccount.name : null),
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <Link to={`/parties/${row.original.client._id}/externalAccounts/${row.original.externalAccountId}`}>
                            {cellProps.getValue()}
                        </Link>
                    );
                },
                size: 100
            },
            {
                header: "Account",
                id: "account.name",
                accessorKey: "account.name",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <Link to={`/parties/${row.original.client._id}/accounts/${row.original.accountId}`}>
                            {accountNameExtendedByName[cellProps.getValue()].name}
                        </Link>
                    );
                },
                size: 100
            },
            {
                header: "Updated by",
                id: "transaction.updateUserInfo.name",
                accessorKey: "transaction.updateUserInfo.name",
                accessorFn: (row) => (row.transaction.updateUserInfo ? row.transaction.updateUserInfo.name : null),
                filter: SelectColumnFilter
            },
            {
                header: "Update timestamp",
                id: "transaction.updateTimestamp",
                accessorKey: "transaction.updateTimestamp"
            },
            {
                header: "Portfolio Instrument",
                id: "portfolioInstrument",
                accessorKey: "portfolioInstrument.name",
                size: 100
            }
        ];
    }, [location.search, dataItems]);

    if (loading || loadingClients) return <div>Loading...</div>;
    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );
    if (errorClients)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(errorClients, null, 2)}</pre>
            </div>
        );

    const clients: { key: string; value: string }[] = dataClients.clients.map((c) => {
        return { key: c._id, value: c.name };
    });

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    const transactionItemsOrdered = orderBy(dataItems.transactionItems, "transactionTradeDate", "desc");

    return (
        <div>
            <div className="row">
                <div className="col">
                    {showFilter ? (
                        <Button variant="link" onClick={toggleFilter}>
                            Hide filter
                        </Button>
                    ) : (
                        <Button variant="link" onClick={toggleFilter}>
                            Show filter
                        </Button>
                    )}
                </div>
            </div>
            {showFilter ? (
                <div className="row ms-1">
                    <div className="col">
                        <Formik
                            enableReinitialize={true}
                            initialValues={filter}
                            validate={() => {
                                const errors: any = {};
                                return Object.keys(errors).length > 0 ? errors : {};
                            }}
                            onSubmit={async (submitValues) => {
                                const newValues: Partial<TransactionItemFilterInput> = removeNull(submitValues);
                                pushQueryArgs(newValues);
                                setFilter({ ...newValues, includePortfolioSwapConstituents: true });
                            }}
                        >
                            {({ isSubmitting, values: temp }) => {
                                const _values: Partial<TransactionItemFilterInput> = temp;
                                return (
                                    <Form autoComplete="off">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="row mt-4">
                                                    <div className="col-sm-12">
                                                        <NullableMultiSelectField
                                                            name="clientIds"
                                                            label={"Client"}
                                                            className=""
                                                            disabled={isSubmitting}
                                                            options={clients}
                                                            size={5}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <NullableToggleDateField
                                                            name="startDate"
                                                            label="Start date"
                                                            disabled={isSubmitting}
                                                        />
                                                        <NullableToggleDateField
                                                            name="endDate"
                                                            label="End date"
                                                            className="mt-3"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-sm-4">
                                                <div className="row mt-4">
                                                    <div className="col-sm-12">
                                                        <SubmitButton className="btn btn-primary" disabled={loading} label={"Apply"} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            ) : null}

            <div className="row">
                <div className="col">
                    <button
                        id="export-excel"
                        className="export-link"
                        type="button"
                        onClick={() => {
                            const items = orderBy(cloneDeep(dataItems.transactionItems), "transactionTradeDate", "desc");
                            items.forEach((item) => {
                                item.clientName = item.client ? item.client.name : "";
                                item.externalAccountName = item.externalAccount ? item.externalAccount.name : "";
                                item.brokerName = item.transaction.broker ? item.transaction.broker.name : "";
                                item.custodianName = item.custodian ? item.custodian.name : "";
                                item.accountName = item.account ? item.account.name : "";
                                item.instrumentName = item.instrument ? item.instrument.name : "";
                                item.updateUserName = item.transaction.updateUserInfo ? item.transaction.updateUserInfo.name : "";
                                item.updateTimestamp = item.transaction.updateTimestamp ? item.transaction.updateTimestamp : "";

                                item.status = item.transactionStatus;
                                item.tradeDate = item.transactionTradeDate;

                                delete item.client;
                                delete item.externalAccount;
                                delete item.custodian;
                                delete item.account;
                                delete item.instrument;
                                delete item.transaction;
                                delete item.transactionStatus;
                                delete item.transactionTradeDate;
                            });

                            exportToXlsx(items, "TransactionItems.xlsx");
                        }}
                    >
                        <Excel />
                    </button>
                </div>
            </div>
            <div className="row ms-2">
                <ReactTable
                    columns={columns}
                    data={transactionItemsOrdered}
                    defaultHiddenColumns={[
                        "transaction.description",
                        "transaction.broker.name",
                        "account.name",
                        "externalAccount.name",
                        "transaction.updateTimestamp"
                    ]}
                />
                <Panel
                    isOpen={params.id != null}
                    isBlocking={false}
                    onDismiss={() => navigate(`/transactionitems${location.search}`)}
                    layerProps={{ eventBubblingEnabled: true }}
                >
                    {params.id ? <MiniTransactionForm id={params.id} type={null} /> : null}
                </Panel>
            </div>
        </div>
    );
}
