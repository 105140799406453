import React, { Fragment, useMemo } from "react";
import { useQuery, gql } from "urql";
import { Link } from "react-router-dom";
import { keyBy } from "lodash";

import {
    MappingTypeEnum,
    MappingParameterEnum,
    InstrumentModelTypeEnum,
    MappingCollectionNameEnum,
    CurrencyEnum,
    ValuationMappingStatus
} from "../types.generated";
import { SelectColumnFilter, SelectColumnFilterType } from "../components/react-table/ReactTableFilters";
import { ReactTable } from "../components/react-table/ReactTable";
import { Button } from "react-bootstrap";

export const getValuationsMappings = gql`
    query getValuationsMappings {
        valuationmappings {
            _id
            clientId
            client {
                name
            }
            validFrom
            validTo
            instrumentId
            instrument {
                name
            }
            issuerProgramId
            issuerProgram {
                name
            }
            modelType
            mappingType
            parameterType
            referenceId
            collectionName
            currency
            status
            createTimestamp
            updateTimestamp
            updateUserInfo {
                name
            }
        }

        curveDefinitions {
            _id
            name
        }
    }
`;

export const ValuationMappingsPage = (): React.ReactElement => {
    const [{ fetching, error, data }] = useQuery({ query: getValuationsMappings });

    const columns = useMemo(() => {
        return [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/valuationmapping/${row.original._id}`}>{cellProps.getValue()}</Link>;
                },
                size: 75
            },
            {
                header: "Client",
                id: "client.name",
                accessorKey: "client.name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/parties/${row.original.clientId}`}>{cellProps.getValue()}</Link>;
                },
                size: 110
            },
            {
                header: "Valid from",
                accessorKey: "validFrom",
                filter: SelectColumnFilter
            },
            {
                header: "Valid to",
                accessorKey: "validTo",
                filter: SelectColumnFilter
            },
            {
                header: "Instrument",
                id: "instrument.name",
                accessorKey: "instrument.name",
                accessorFn: (row) => (row.instrument ? row.instrument.name : null),
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original.instrumentId}`}>{cellProps.getValue()}</Link>;
                },
                size: 110
            },
            {
                header: "Issuer program",
                id: "issuerProgram.name",
                accessorKey: "issuerProgram.name",
                accessorFn: (row) => (row.issuerProgram ? row.issuerProgram.name : null),
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/issuerprograms/${row.original.issuerProgramId}`}>{cellProps.getValue()}</Link>;
                },
                size: 110
            },
            {
                header: "Model type",
                accessorKey: "modelType",
                filter: SelectColumnFilterType(InstrumentModelTypeEnum)
            },
            {
                header: "Mapping type",
                accessorKey: "mappingType",
                filter: SelectColumnFilterType(MappingTypeEnum),
                size: 75
            },
            {
                header: "Parameter type",
                accessorKey: "parameterType",
                filter: SelectColumnFilterType(MappingParameterEnum),
                size: 90
            },
            {
                header: "Reference id",
                accessorKey: "referenceId",
                cell: (cellProps) => {
                    const { row } = cellProps;

                    let curveDefinitionById: Record<string, { _id: string; name: string }> = {};
                    if (data) {
                        curveDefinitionById = keyBy(data.curveDefinitions, "_id");
                    }
                    let show = cellProps.getValue();
                    if (row.original.referenceId in curveDefinitionById) {
                        show = curveDefinitionById[row.original.referenceId].name;
                    }
                    if (row.original.collectionName === "CurveDefinition") {
                        return <Link to={`/curvedefinitions/${row.original.referenceId}`}>{show}</Link>;
                    } else {
                        return <div>{show}</div>;
                    }
                },
                size: 90
            },
            {
                header: "Collection name",
                accessorKey: "collectionName",
                filter: SelectColumnFilterType(MappingCollectionNameEnum),
                size: 90
            },
            {
                header: "Currency",
                accessorKey: "currency",
                filter: SelectColumnFilterType(CurrencyEnum),
                size: 75
            },
            {
                header: "Status",
                accessorKey: "status",
                filter: SelectColumnFilterType(ValuationMappingStatus),
                size: 75
            },
            {
                header: "Update timestamp",
                accessorKey: "updateTimestamp"
            },
            {
                header: "Updated by",
                id: "updateUserInfo.name",
                accessorKey: "updateUserInfo.name",
                filter: SelectColumnFilter
            }
        ];
    }, [data]);

    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <div>
            <div className="row mb-3">
                <div className="col">
                    <Button
                        type="button"
                        className="float-end btn btn-sm mb-3"
                        onClick={() => {
                            const win = window.open("/valuationmapping/new", "_self");
                            win.focus();
                        }}
                    >
                        New valuation mapping
                    </Button>
                </div>
            </div>

            <Fragment>
                <p>Number of valuation mappings: {data.valuationmappings.length}</p>
                <ReactTable columns={columns} data={data.valuationmappings} defaultHiddenColumns={[]} />
            </Fragment>
        </div>
    );
};
