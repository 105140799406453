import React, { useState } from "react";
import { gql, useMutation, useQuery } from "urql";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import { Formik, Form } from "formik";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Svgs } from "../../../components/src";
import * as Yup from "yup";

import { SelectField, SubmitButton, SearchListField, DateField } from "../components/form";
import {
    MappingCollectionNameEnum,
    MappingTypeEnum,
    CurrencyEnum,
    InstrumentModelTypeEnum,
    ValuationMappingStatus,
    MappingParameterEnum,
    InstrumentModelTypeEnumDescriptions
} from "../types.generated";
import { formikUrqlErrorFormater } from "../common/formik-urql-error-helper";
import { useAlertTimeOut } from "../common/Utils";

const defaultMapping = {
    collectionName: MappingCollectionNameEnum.CurveDefinition, // (?)
    currency: CurrencyEnum.SEK, // (?)
    instrumentId: null, // (?) "000000000000000000000000" does not work
    issuerProgramId: null, //"000000000000000000000000",
    validFrom: "1901-01-01",
    validTo: "2199-12-31",
    mappingType: MappingTypeEnum.Currency, // (?)
    modelType: null, // (?)
    parameterType: MappingParameterEnum.ForwardCurve, // (?)
    referenceId: null, // (?) curvedefinition "000000000000000000000000" does not work
    status: ValuationMappingStatus.Active // (?)
};

const GET_DATA = gql`
    query getData($filter: ValuationMappingFilterInput) {
        valuationmappings(filter: $filter) {
            _id
            clientId
            instrumentId
            issuerProgramId
            validFrom
            validTo
            mappingType
            modelType
            parameterType
            referenceId
            currency
            collectionName
            status
        }

        instruments {
            _id
            name
        }

        issuerprograms {
            _id
            name
        }

        curveDefinitions {
            _id
            name
        }

        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
        }
    }
`;

const UPSERT_VALUATIONMAPPING = gql`
    mutation upsertValuationMappings($input: [ValuationMappingInput!]!) {
        upsertValuationMappings(input: $input) {
            _id
        }
    }
`;

const ModelTypeOptions = ["None", ...Object.keys(InstrumentModelTypeEnum).sort()];

export const ValuationMappingPage = (): React.ReactElement => {
    const { id } = useParams<"id">();
    const valuationMappingId = id && id !== "new" ? id : "000000000000000000000000";
    const isEditMode = !!(valuationMappingId !== "000000000000000000000000");

    const navigate = useNavigate();
    const location = useLocation();

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    const [{ fetching: loading, error, data }] = useQuery({ query: GET_DATA, variables: { filter: { idIn: [valuationMappingId] } } });
    const [_, upsertValuationMapping] = useMutation(UPSERT_VALUATIONMAPPING);

    if (loading)
        return (
            <div className="loader">
                <Svgs.Loader />
            </div>
        );

    if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
    if (isEditMode && data.valuationmappings.length === 0) return <pre>Not found!</pre>;

    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    const valuationMapping = isEditMode ? cloneDeep(data.valuationmappings[0]) : defaultMapping;

    if (!valuationMapping.modelType) {
        valuationMapping.modelType = "None";
    }

    return (
        <div className="container page">
            <div className="print-none">
                <Link to={"/valuationmappings"}>Valuation Mappings</Link>
            </div>

            <Button
                type="button"
                className="float-end btn btn-sm mb-3"
                onClick={() => {
                    const win = window.open("/valuationmappings", "_self");
                    win.focus();
                }}
            >
                All valuation mappings
            </Button>
            <h1>Valuation mapping</h1>
            <Formik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={valuationMapping}
                validationSchema={Yup.object({
                    referenceId: Yup.string().required().min(24).max(24).typeError("Required")
                })}
                onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                    const mapping = cloneDeep(submitValues);
                    delete mapping.__typename;
                    if (isEditMode) {
                        mapping._id = valuationMappingId;
                    }

                    if (mapping.modelType === "None") {
                        delete mapping.modelType;
                    }

                    const input = [mapping];

                    await upsertValuationMapping({ input })
                        .then((result) => {
                            if ("error" in result && result.error) {
                                const message = formikUrqlErrorFormater(result.error, setErrors);
                                setAlert({ color: "danger", visible: true, message });
                            } else {
                                setAlert({
                                    color: "success",
                                    visible: true,
                                    message: isEditMode
                                        ? "The valuation mapping updated successfully!"
                                        : "New valuation mapping created successfully!"
                                });

                                // redirect to edit page on success
                                if (result.data && result.data.upsertValuationMappings) {
                                    const _id = result.data.upsertValuationMappings[0]._id;
                                    const path = location.pathname.split("/");
                                    path.pop();
                                    path.push(_id);
                                    navigate(path.join("/"), { replace: true });
                                }
                            }
                            return true;
                        })
                        .catch((error) => {
                            setAlert({ color: "danger", visible: true, message: error.toString() });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ isSubmitting, values }) => (
                    <Form autoComplete="off">
                        <div className="form-row">
                            <div className="col-sm-6 col-md-4">
                                <SearchListField
                                    className=""
                                    name="instrumentId"
                                    label={
                                        !values.instrumentId ? (
                                            "Instrument"
                                        ) : (
                                            <div className="pb-2">
                                                <Link to={"/instruments/" + values.instrumentId}>Instrument</Link>
                                            </div>
                                        )
                                    }
                                    options={[
                                        ...[
                                            { _id: null, name: "None" },
                                            { _id: "000000000000000000000000", name: "None " }
                                        ],
                                        ...data.instruments
                                    ]}
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <SearchListField
                                    className=""
                                    name="issuerProgramId"
                                    label={
                                        !values.instrumentId ? (
                                            "Issuer program"
                                        ) : (
                                            <div className="pb-2">
                                                <Link to={"/issuerprograms/" + values.instrumentId}>Issuer program</Link>
                                            </div>
                                        )
                                    }
                                    options={[
                                        ...[
                                            { _id: null, name: "None" },
                                            { _id: "000000000000000000000000", name: "None " }
                                        ],
                                        ...data.issuerprograms
                                    ]}
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-sm-6 col-md-4">
                                <DateField className="" name="validFrom" label="Valid from" disabled={isSubmitting} />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <DateField className="" name="validTo" label="Valid to" disabled={isSubmitting} />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-sm-6 col-md-4">
                                <SelectField
                                    name="modelType"
                                    label="Model type"
                                    options={ModelTypeOptions}
                                    tooltips={InstrumentModelTypeEnumDescriptions}
                                    className=""
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <SelectField
                                    name="collectionName"
                                    label="Collection name"
                                    options={Object.keys(MappingCollectionNameEnum).sort()}
                                    className=""
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-sm-6 col-md-4">
                                <SelectField
                                    name="mappingType"
                                    label="Mapping type"
                                    options={Object.keys(MappingTypeEnum).sort()}
                                    className=""
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <SelectField
                                    name="currency"
                                    label="Currency"
                                    options={Object.keys(CurrencyEnum).sort()}
                                    className=""
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-sm-6 col-md-4">
                                <SelectField
                                    name="parameterType"
                                    label="Parameter type"
                                    options={Object.keys(MappingParameterEnum).sort()}
                                    className=""
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <SelectField
                                    name="status"
                                    label="Status"
                                    options={Object.keys(ValuationMappingStatus).sort()}
                                    className=""
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-sm-6 col-md-4">
                                <SelectField
                                    name="referenceId"
                                    label="Reference"
                                    options={[
                                        ...[{ key: null, value: "None" }],
                                        ...data.curveDefinitions.map((d) => ({ key: d._id, value: d.name }))
                                    ]}
                                    className=""
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <SelectField
                                    name="clientId"
                                    label={<Link to={"/parties/" + values.clientId}>Client (owner)</Link>}
                                    options={data.clients.map((d) => ({ key: d._id, value: d.name }))}
                                    className=""
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>

                        {alert.visible ? (
                            <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                                {alert.message}
                            </Alert>
                        ) : null}

                        <SubmitButton disabled={isSubmitting} label={isEditMode ? "Update" : "Create"} />
                    </Form>
                )}
            </Formik>
        </div>
    );
};
